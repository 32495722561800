@charset "UTF-8";

.table-defult {
    width:100%;margin:0 auto;border-top:2px solid #000;box-sizing:border-box;
    td {
        padding:16px 0;border-bottom:1px solid $gray02;vertical-align:middle;
        &:first-child {text-align:center;font-weight:600;vertical-align:middle;}
        &:nth-child(2) {padding-left:32px;}
        &:last-child {text-align:right;padding-right:24px; }
        @include defaultTxt;color:$black;
    }
    &+.box-paging {display:block;margin-top:50px;}
}

.table-default2 {
    width:100%;  border-top:1px solid $gray02; border-bottom:1px solid $gray02;
    tr {display:block;padding:5px 40px;}
    th {display:block;width:100%;text-align:left;}
    td {display:block;width:100%;}
    dl {
        display:flex;align-items:center;flex-wrap:wrap;width:100%;
        dt {
            width:50%;@include defaultTxt;
            &:nth-of-type(n+2){margin-top:5px;}
        }
        dd {
            width:50%; text-align:right; @include defaultTxt;
            &:nth-of-type(n+2) {margin-top:5px;}
            *{@extend %fontInherit;}
        }
    }
    .item-title {
        padding-top: 20px;
        *{@include normalTxt;}
    }
    .depth-02 {
        padding:0 40px 5px;
        td {
            padding:0;border-top:none;
            dt {
                position:relative;padding-left:22px;
                &::before {
                    display: block; content: "";position: absolute; left: 10px; top: 11px;  width: 6px; height:1px;  text-align: left; background: $gray05;
                }
                &::after {
                    display: block; content: ""; position: absolute; left: 10px; top: 5px; height: 6px; width:1px; text-align: left; background: $gray05;
                }
            }
        }
    }
    .c-hotpink {
        padding: 0 40px;
        dt, dd {
            @include normalTxt;color:$red; padding-top: 20px;
        }
    }
    .table-default2{
        background: $gray06; padding:20px 0px; display: block;
    }
    tbody{
         padding-top: 20px; padding-bottom: 35px; display: block;
    }
    .table-result {
        background: $gray06;padding:20px 40px;
        .txt-vt{
            @include subContsTitle;
            *{@extend %fontInherit;}
        }
        * {@include mainTxt;}
    }
    & + .table-default2{
        margin-top: 30px;
    }
}

p.bang-notice {
    margin-top:10px;@include defaultTxt;
}

ul.bang-notice {
    margin-top:30px;
    li {
        @include text(400,26px,1.46,$gray05,-1px);
        @include backgroundImg('/assets/images/pc/icon/ico-notice.svg',top left,  32px 32px);
         padding-left:42px;
         &+li {margin-top:10px;}
    }
}

.box-list{
    margin-top:50px;
    &.no-margin{margin-top: 0;}
    h2 {margin-bottom:20px;@include mainTxt;}
}

.table-list-wrap {
    .table-title {margin-bottom:20px; @include mainTxt;}
    .table-list {
        width:100%;margin:0 auto;border-top:1px solid $black;box-sizing:border-box;text-align:center;table-layout:fixed;
        thead {border-bottom:1px solid $gray02;}
        th {
            padding:20px 0;vertical-align:middle;
            @include normalTxt;color:$black;
        }
        td {
            padding:20px 0;border-bottom:1px solid $gray02;vertical-align:middle;
            @include defaultTxt;color:$black;
            a {display:inline-block;max-width:100%;@include defaultTxt;color:$black;vertical-align:middle;}
            &.subject {text-align:left;}
        }
    }

    .center {
        text-align:center;
        th {border-right:none;}
    }
    &+.table-list-wrap {margin-top:40px;}
}

.c-black {color:$black;font-weight:600;}
.c-gray {color:$gray05;}
.c-purple {color:$purple;font-weight:600;}
.c-hotpink {
    color:$hotpink;font-weight:600;
    dl{
        dt{
            font-size: 20px !important;
        }
        dd{
           font-size: 24px !important;
        }
    }
}

.box-table {
    .table-title{
        padding:0 0 30px 40px;border-bottom:1px solid $gray02;
        @include mainTxt;
    }
    // Table Type01(Basic)
    .table-type-01 {
        width:100%;border-bottom:2px solid $gray02;
        tr {
            th {
                padding:20px;
                @include normalTxt;color:$black;text-align:left;
            }
            td {
                padding:20px;
                @include defaultTxt;color:$black;text-align:right;
            }
            &.is-depth {
                * {
                    padding-top:0;
                    @include smallTxt;
                }
            }
            &.emp-txt {
                * {color:$red;}
            }
        }
        tfoot {
            tr {
                background-color:$gray01;
                * {padding:40px 20px;}
            }
        }
    }
    // Table Type03
    .table-type-03 {
        max-width:90%;width:100%;margin:0 auto;
        tr {
            border-bottom:2px solid $gray02;
            th {
                padding:40px 30px;
                @include normalTxt;color:$black;text-align:left;
            }
            td {
                padding:40px 30px;vertical-align:middle;
                @include defaultTxt;color:$black;text-align:right;
            }
            &.bg-gray {
                border:none;
                td, th {
                    padding:22px 30px;background-color:$gray01;
                    @include defaultTxt;
                }
                th {border-radius:14px 0 0 14px;}
                td {border-radius:0 14px 14px 0;}
            }
        }
        .ball {
            display:inline-block; width:48px; height:48px; line-height:48px; border-radius:50%; color:$white; text-align:center;
            &-purple {
                @extend .ball;background-color:$purple;
            }
            &-gray {
                @extend .ball;background-color:$gray04;position:relative;margin-left:50px;
                &::before {
                    content:'';display:inline-block;position:absolute;left:-50px;top:50%;width:40px;height:40px;transform:translateY(-50%);
                    @include backgroundImg('/assets/images/pc/icon/ico-arrow-gray.svg', center, contain);
                }
            }
        }
    }
    // Table Type04
    .table-title.no-border{margin-bottom: 10px; border: none; padding: 0;}
    .table-type-04{
        width: 100%;
        th {
            background-color: $gray06; padding: 20px 0px; text-align: center;
            @include normalTxt;vertical-align: middle;
        }
        td{
            padding: 20px 0px; border-bottom: 1px solid $gray02; vertical-align: middle;text-align: center;
            @include defaultTxt; color: $black;            
        }
        &.center{
            th, td{
                text-align: center; padding:20px 0;
            }

        }
        .txt-center {text-align: center !important;}
    }
    .table-type-05 {
        width: 100%;border-top: 2px solid $gray02;
        td {
            vertical-align: middle; padding: 40px 0; border-bottom: 2px solid $gray02;
            strong{
                display: block; @include defaultTxt; font-weight: 400;
                & + strong{margin-top:10px; }
                & + span{margin-top:10px; }
            }
            span{display: block;@include smallDepthTxt;}
            button{@include smallDepthTxt; text-decoration: underline;}
            &:last-of-type{text-align: right;}
        }
    }
}
    .tbl_list{
        width: 100%; margin:10px 0;
        th {
            background-color: $gray06; padding: 20px 0px; text-align: center;
            @include normalTxt;vertical-align: middle;
        }
        td{
            padding: 20px 0px; border-bottom: 1px solid $gray02; vertical-align: middle;text-align: center;
            @include defaultTxt; color: $black;
        }
        &.center{
            th, td{
                text-align: center; padding:20px 0;
            }

        }
    }
// Table Type02
.radio-block {
    .default-txt {
        margin-bottom:20px;
        .txt-vt {@extend %fontInherit;}
    }
    ul {
        border-top:1px solid $gray02;
        li {height:auto;}
        .btn-radio-type2 {
            height:100%;border-radius:0;
            label {
                align-items:center;/*padding:40px;*/padding:20px 30px;transition:background-color 0.3s, color 0.3s;
                span {margin-right:20px;}
                p {@include defaultTxt;}
            }
            input:checked ~ label {
                background-color:#f9f5ff;
                * {color: $black;}
            }
        }
    }
}
/*.wide-box {
    .radio-block {
        ul {
            li {height:auto;}
            .btn-radio-type2 {
                label {}
            }
        }
    }
}*/

// 페이징
.box-paging {
    margin:70px 0 0;text-align:center;
    .btn-text-more {
        @include mainTxt;
        &>span {@include mainTxt;}
    }
}

// 게시판
.box-board {
    .board-search {display:flex; justify-content:flex-end; align-items:baseline;margin-bottom:20px;}
    //리스트
    table {
        table-layout:fixed;
        td {
            a {
                display:inline-block;overflow:hidden;text-overflow:ellipsis;max-width:100%;white-space:nowrap;text-align:left;
                @include defaultTxt;color:$black;
            }
            .point {@include normalTxt;color:$purple;}
        }
        .notice {
            a {@include normalTxt;}
            .point {@include normalTxt;color:$purple;}
        }
    }
    .box-btn {margin-top:50px;text-align:center;}
    //검색결과안내
    .no-result {
        .no-result-txt {
            display:block;padding-top:270px;@include mainTxt;text-align:center;
            @include backgroundImg('/assets/images/pc/icon/ico-board-no-data.svg', center center, 120px 120px);
            .small-txt {display:block;margin-top:10px;@include defaultTxt;text-align:center;}
        }
    }
    //상세
    .list-wrap.type-ul {
        .list-view {
            dt {
                padding:30px 0;border-bottom:1px solid $gray02;
                .view-tit {display:block;margin-bottom:10px;@include subContsTitle;}
                &>time {@include smallDepthTxt2;}

                &.date-right {
                    display:flex;justify-content:space-between;gap:30px 50px;
                    &>.date {padding-top:5px;@include defaultTxt;color:$gray04;white-space:nowrap;}
                }
            }
            dd {
                padding:40px 0;border-bottom:1px solid $gray02;@include defaultTxt;color:$black;
                .view-txt {@include defaultTxt;color:$black;line-height:1.5;}
                .mov-detail{
                    min-height: 300px;
                    .view-img {margin: 30px 0;}
                    .view-txt {@include defaultTxt;color:$black;}
                    img {max-width:70%;}
                }
            }
        }

        .siblings-article {
            li {
                display:flex;flex-direction:row;padding:30px 40px;border-bottom:1px solid $gray02;
                &>b {width:120px;@include defaultTxt; font-weight: 600; text-align:left;}
                &>a {overflow:hidden;text-overflow:ellipsis;width:calc(100% - 120px);@include smallTxt; text-align:left;white-space:nowrap;}
                .no-article {width:calc(100% - 120px);@include smallTxt;text-align:left;}
            }
        }
    }
}

//검색결과와 검색엔진
.board-info {
    display:flex;justify-content: flex-end;align-items:baseline;margin-bottom:20px; flex:1; position: relative;
    .box-info {
            @include normalTxt;text-align:left; position:absolute; bottom:0; left:0;
        strong {@include normalTxt;color:$purple;}
    }
    .box-input {max-width:300px;margin:0 !important;}
}

//결과없음 또는 에러
.no-result {
    .no-result-txt {
        display:block;padding-top:270px;@include mainTxt;text-align:center;
        @include backgroundImg('/assets/images/pc/icon/ico-board-no-data.svg', center 100px, 120px 120px);
        .small-txt {display:block;margin-top:20px;@include defaultTxt;text-align:center;}
        &.not-exist {
            background-image: url('/assets/images/pc/icon/ico-not-exist.svg');
        }
    }
}

.no-search-result {
    .no-search-result-txt {
        display:block;padding-top:270px;@include mainTxt;text-align:center;
        @include backgroundImg('/assets/images/pc/icon/ico-search-dot.svg', center  100px, 120px 120px);
        .small-txt {display:block;margin-top:20px;@include defaultTxt;text-align:center;}
        &.empty-box{
             @include backgroundImg('/assets/images/pc/icon/ico-board-no-data.svg',center center,120px 120px);
        }
    }

}

.error-result {
    .error-result-txt {
        display:block;padding:270px 0 100px;@include mainTxt;text-align:center;
        @include backgroundImg('/assets/images/pc/icon/ico-plan-error.svg', center 100px, 120px 120px);
        .small-txt {display:block;margin-top:10px;@include defaultTxt;text-align:center;}
    }
}
