@charset "UTF-8";
#nav-skip {
    position:relative;z-index:1100;
    a {
        position:absolute;left:0;top:-50px;overflow:hidden;width:200px;height:30px;border-radius:3px;background:#000;color:#fff;line-height:30px;text-align:center;
        &:focus {top:0;}
    }
}

body{
    &.white{
        #wrap{
            &.main{
                #header{
                    &.on{
                        .logo{
                            background-image: url('/assets/images/pc/common/header-logo-white.png');
                        }
                        #gnb{
                            > ul{
                                > li{
                                    > a{
                                        color: $white;
                                    }
                                }
                            }
                        }
                        .top-util {
                            button {
                                width:24px;height:24px;background-position:0 0;background-repeat:no-repeat;font-size: 0;
                                &.top-search {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(.818 .818)'%3E%3Cg data-name='타원 1' style='stroke:%23ffffff;stroke-width:2px;fill:none'%3E%3Ccircle cx='10.5' cy='10.5' r='10.5' style='stroke:none'/%3E%3Ccircle cx='10.5' cy='10.5' r='9.5' style='fill:none'/%3E%3C/g%3E%3Crect data-name='사각형 341685' width='2' height='7' rx='1' transform='rotate(-45 29.02 -10.607)' style='fill:%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");}
                                &.top-login {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(1.154 1.147)'%3E%3Cellipse data-name='타원 13' cx='5.02' cy='4.994' rx='5.02' ry='4.994' transform='translate(5.826)' style='fill:none;stroke:%23ffffff;stroke-miterlimit:10;stroke-width:2px'/%3E%3Cpath data-name='패스 74' d='M23.191 25.972c0-4.393-4.856-7.954-10.846-7.954S1.5 21.58 1.5 25.972' transform='translate(-1.5 -4.268)' style='stroke-linecap:round;fill:none;stroke:%23ffffff;stroke-miterlimit:10;stroke-width:2px'/%3E%3C/g%3E%3C/svg%3E%0A");}
                                &.top-menu { background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(0 3)'%3E%3Crect data-name='사각형 340839' width='24' height='2' rx='1' style='fill:%23ffffff'/%3E%3Crect data-name='사각형 340840' width='24' height='2' rx='1' transform='translate(0 8)' style='fill:%23ffffff'/%3E%3Crect data-name='사각형 340841' width='24' height='2' rx='1' transform='translate(0 16)' style='fill:%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");}
                            }

                        }
                        &.gnb-on{
                            .logo{
                                background-image: url('/assets/images/pc/common/header-logo.png');
                            }
                            #gnb{
                                > ul{
                                    > li{
                                        > a{
                                            color: $black;
                                            &:hover{
                                                color: $purple;
                                            }
                                        }
                                    }
                                }
                            }
                            .top-util {
                                button {
                                    width:24px;height:24px;background-position:0 0;background-repeat:no-repeat;font-size: 0;
                                    &.top-search {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(.818 .818)'%3E%3Cg data-name='타원 1' style='stroke:%23191919;stroke-width:2px;fill:none'%3E%3Ccircle cx='10.5' cy='10.5' r='10.5' style='stroke:none'/%3E%3Ccircle cx='10.5' cy='10.5' r='9.5' style='fill:none'/%3E%3C/g%3E%3Crect data-name='사각형 341685' width='2' height='7' rx='1' transform='rotate(-45 29.02 -10.607)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A");}
                                    &.top-login {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(1.154 1.147)'%3E%3Cellipse data-name='타원 13' cx='5.02' cy='4.994' rx='5.02' ry='4.994' transform='translate(5.826)' style='fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3Cpath data-name='패스 74' d='M23.191 25.972c0-4.393-4.856-7.954-10.846-7.954S1.5 21.58 1.5 25.972' transform='translate(-1.5 -4.268)' style='stroke-linecap:round;fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3C/g%3E%3C/svg%3E%0A");}
                                    &.top-menu { background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(0 3)'%3E%3Crect data-name='사각형 340839' width='24' height='2' rx='1' style='fill:%23191919'/%3E%3Crect data-name='사각형 340840' width='24' height='2' rx='1' transform='translate(0 8)' style='fill:%23191919'/%3E%3Crect data-name='사각형 340841' width='24' height='2' rx='1' transform='translate(0 16)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A");}
                                }
    
                            }
                        }

                    }
                }

            }
        }
    }
    #wrap{
        #header {
            display:flex;justify-content:center;position:fixed;left:0;top:0;z-index:100;width:100%;min-width: 720px;min-height:80px; border-bottom: 1px solid $gray02;
            background-color:#fff;box-shadow:0 4px 10px -8px rgba(0,0,0,0);
            &::after {content:'';display:block;position:absolute;left:0;top:80px;z-index:101;width:100%;height:1px;background-color:#ddd;opacity:0;/*transition:0.2s;*/}

            &.on{
                background-color: transparent; border-bottom: 0;
            }

            a, button {cursor:pointer;}

            header {
                display:flex;justify-content:space-between;align-items:center;position:relative;width:1280px;height:80px;z-index:100;
                .logo{
                    width: 174px; height: 56px; @include backgroundImg('/assets/images/pc/common/header-logo.png', 0, 174px 56px);
                    img{
                        display: none;
                    }
                }
            }

            .top-info {position:absolute;right:0;top:20px;}
            .top-util {
                display:flex;gap:40px;justify-content:space-between;
                button {
                    width:24px;height:24px;background-position:0 0;background-repeat:no-repeat;font-size: 0;
                    &.top-search {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(.818 .818)'%3E%3Cg data-name='타원 1' style='stroke:%23191919;stroke-width:2px;fill:none'%3E%3Ccircle cx='10.5' cy='10.5' r='10.5' style='stroke:none'/%3E%3Ccircle cx='10.5' cy='10.5' r='9.5' style='fill:none'/%3E%3C/g%3E%3Crect data-name='사각형 341685' width='2' height='7' rx='1' transform='rotate(-45 29.02 -10.607)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A");}
                    &.top-login {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(1.154 1.147)'%3E%3Cellipse data-name='타원 13' cx='5.02' cy='4.994' rx='5.02' ry='4.994' transform='translate(5.826)' style='fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3Cpath data-name='패스 74' d='M23.191 25.972c0-4.393-4.856-7.954-10.846-7.954S1.5 21.58 1.5 25.972' transform='translate(-1.5 -4.268)' style='stroke-linecap:round;fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3C/g%3E%3C/svg%3E%0A");
                        position:relative;
                        .state {display:none;position:absolute;right:-22px;bottom:-8px;padding:3px 7px 3px 5px;border-radius:10px;background:#7c40ce;@include smallTxt;color:$white;font-size:12px;text-align:left;}
                        &.on {
                            .state {display:block;}
                        }
                    }
                    &.top-menu { background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(0 3)'%3E%3Crect data-name='사각형 340839' width='24' height='2' rx='1' style='fill:%23191919'/%3E%3Crect data-name='사각형 340840' width='24' height='2' rx='1' transform='translate(0 8)' style='fill:%23191919'/%3E%3Crect data-name='사각형 340841' width='24' height='2' rx='1' transform='translate(0 16)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A");}
                }
            }

            /*gnb over시*/
            &.gnb-on {
                height: auto; box-shadow:0 4px 10px -8px rgba(0,0,0,0.3); background-color: $white;
                &::after {opacity:1;}
                header {background:$white;}
                #gnb {
                    &>ul {
                        &>li {
                            &.on {
                                .gnb-depth1 {display:flex;}
                            }
                        }
                    }
                }
            }

            /*상단검색 선택시*/
            &.search-on {
                background-color: #fff !important;
                &::after {content:'';display:block;position:absolute;left:0;top:80px;z-index:101;width:100%;height:1px;background-color:#ddd;opacity:1;}
                header {
                    .logo{
                        background-image: url('/assets/images/pc/common/header-logo.png') !important;
                    }
                    .top-util {
                        button {
                            &.top-search {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(.818 .818)'%3E%3Cg data-name='타원 1' style='stroke:%23191919;stroke-width:2px;fill:none'%3E%3Ccircle cx='10.5' cy='10.5' r='10.5' style='stroke:none'/%3E%3Ccircle cx='10.5' cy='10.5' r='9.5' style='fill:none'/%3E%3C/g%3E%3Crect data-name='사각형 341685' width='2' height='7' rx='1' transform='rotate(-45 29.02 -10.607)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A") !important;}
                            &.top-login {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(1.154 1.147)'%3E%3Cellipse data-name='타원 13' cx='5.02' cy='4.994' rx='5.02' ry='4.994' transform='translate(5.826)' style='fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3Cpath data-name='패스 74' d='M23.191 25.972c0-4.393-4.856-7.954-10.846-7.954S1.5 21.58 1.5 25.972' transform='translate(-1.5 -4.268)' style='stroke-linecap:round;fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
                            }
                            &.top-menu { background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(0 3)'%3E%3Crect data-name='사각형 340839' width='24' height='2' rx='1' style='fill:%23191919'/%3E%3Crect data-name='사각형 340840' width='24' height='2' rx='1' transform='translate(0 8)' style='fill:%23191919'/%3E%3Crect data-name='사각형 340841' width='24' height='2' rx='1' transform='translate(0 16)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A") !important;}

                        }
                    }
                    #gnb{
                        > ul{
                            > li{
                                > a{
                                    color: $black !important;
                                }
                            }
                        }
                    }
                }

                .header-search {
                    height:100%;opacity:1;
                    .search-box {top:0;}
                }
            }
        }
    }
}

#gnb {
    &>ul {
        display:flex;justify-content:center;align-items: center;flex-direction:row;flex-wrap:nowrap;gap:0 40px; height: 80px;
        &>li {
            a {
                @include mainTxt;font-size:18px;
                &:hover {color:#7c40ce;}                
            }
            &.on {
                &>a {color:#7c40ce;}
            }
        }
    }
    .gnb-depth1 {
        display:none;justify-content:center;align-items:flex-start;gap:0 70px;position:fixed;left:0;top:81px;z-index:100;overflow:hidden;width:100%;min-height:89px;padding:30px 0 40px;background:#fff;border-bottom: 1px solid $gray02;
        &>li {
            font-size:0;
            &>a {
                display:inline-block;color:#191919;font-size:16px;font-weight:600;               
            }

            .img-brandlogo {
                display:inline-block;margin-right:5px; vertical-align: top;
                img {margin:1px 5px 0 0; vertical-align: top;}
            }
        }
    }
    .gnb-depth2 {
        margin-top:30px;
        &>li {
            &+li {margin-top:20px;}
            &>a {
                color:#666;font-size:16px;font-weight:normal;
                .p-clr {color:#e60033;}
                &:hover{
                    @include normalTxt; letter-spacing: -.4px;
                    span {font-weight: 600;}
                }
            }
        }
        .banner-randombox-wrap{
            position: relative; margin-bottom: 70px;
            .banner-randombox{
                width: 260px; height: auto; position: absolute; left: 0; top: 0; animation: none; visibility: visible;
            }
        }
    }
}

.header-search {
    display:block;position:fixed;left:0;top:81px;z-index:101;overflow:hidden;width:100%;height:0;opacity:0;
    &::after {content:'';display:block;position:absolute;left:0;top:0;z-index:102;width:100%;height:100%;background:rgba(0,0,0,0.5);}
    .search-box {
        position:relative;z-index:103;top:-20px;padding:100px 0 60px;background:#fff;transition:0.3s;
        .wrap {
            width:840px;margin:0 auto; position: relative;
            & > .btn-close{
            position:absolute;right:-230px;top:-70px;overflow:hidden;width:48px;height:48px;font-size:0;
            @include backgroundImg('/assets/images/pc/common/ico-close-thin.svg', center, 48px 48px);
            }
            fieldset {
                display:flex;justify-content:space-between;flex-wrap:nowrap;padding:28px 0 20px 0;border-bottom:1px solid #191919;
                .box-input{
                    display: flex; align-items: center; width: 100%;
                    .input-wrap{
                        width: 100%;
                        input {
                            width:100%;height: 31px;border:none;@include mainTxt;font-weight:normal;                           
                        }
                        &.txt-search {
                            input {
                                font-size: 30px; font-weight: 600;line-height: 1.2;letter-spacing: -0.6px;text-align: left; color: #662d91;
                            }
                            &.search-result {margin-top:0;}
                        }
                    }
                    .btn-search {
                        flex-shrink:1;overflow:hidden;width:24px;height:24px;font-size:0;
                        @include backgroundImg('/assets/images/pc/common/header-btn-search.svg', center, 24px 24px);
                    }
                }
            }
            .other-search {
                display:flex;flex-wrap:wrap;margin-top:40px;
                dl {
                    flex:1 1 40%;
                    dt {display:block;margin-bottom:30px;@include normalTxt;}
                    dd {
                        ol {
                            li {
                                display:flex;justify-content:flex-start;@include defaultTxt;color:$black;
                                .num {width:20px;color:$black;text-align:left;}
                                a {color:$black;}
                                &+li {margin-top:20px;}
                                &:nth-child(1),
                                &:nth-child(2),
                                &:nth-child(3) {
                                    .num {color:$purple;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.total-menu {
    position:fixed;/*left:0;*/top:0;z-index:1000;width:100vw;min-width:1280px;height:100vh;padding:100px 0 0;background:$white; display: none;
    left:-100vw;margin-left:20px;opacity:0;/*animation set*/
    .scroll-wrap {
        display:flex;justify-content:center;overflow-x:auto;overflow-y:auto;height:calc(100% - 100px);
        #total-gnb {
            width:1280px;margin-bottom:100px;
            &>ul {
                &>li {
                    display:flex;justify-content:space-between;padding:40px 0 10px;
                    &+li {border-top:1px solid #ddd;}
                    &>a {@include subContsTitle;}
                    .gnb-depth1 {
                        display:flex;flex-wrap:wrap;width:1024px;
                        &>li {
                            flex:0 1 25%;margin-bottom:30px;
                            &>a {@include mainTxt;}
                            .gnb-depth2 {
                                width:100%;margin-top:20px;
                                li {
                                    &+li {margin-top:10px;}
                                    a {
                                        @include defaultTxt;
                                        .p-clor {color:#e60033}
                                    }
                                }
                            }
                            .img-brandlogo {
                                display:inline-block;margin-right:5px; vertical-align: top;
                                img {margin:2px 5px 0 0; vertical-align: top;}
                            }
                        }
                    }
                }
            }
            a {
                &:hover {
                    color:#7c40ce !important;                    
                }
            }
        }
    }
    .btn-close {
        position:absolute;right:80px;top:60px;overflow:hidden;width:48px;height:48px;font-size:0;
        @include backgroundImg('/assets/images/pc/common/ico-close-thin.svg', center, 48px 48px);
    }
    &.on {
        display: block; opacity: 1; left:0;animation:totalMenu_on 0.3s forwards;
        @keyframes totalMenu_on {
            0% {left:-100vw;margin-left:20px;opacity:0;}
            50% {left:0;margin-left:20px;opacity:0;}
            100% {left:0;margin-left:0;opacity:1;}
        }
    }
    &.off {
        animation:totalMenu_off 0.3s forwards;
        @keyframes totalMenu_off {
            0% {left:0;margin-left:0;opacity:1;}
            50% {left:0;margin-left:20px;opacity:0;}
            100% {left:-100vw;margin-left:20px;opacity:0;}
        }
    }
}

#snb {
    display:none;position:sticky;top:300px;z-index:99;overflow-x:auto;box-sizing:border-box;background:#fff;
    &::-webkit-scrollbar {display:none;}
    .snb-list {
        display:inline-flex;justify-content:flex-start;
        &>li {
            a {
                display:flex;justify-content:center;align-items:center;box-sizing:border-box;padding:30px 60px;border-bottom:4px solid $gray02;transition:0.3s;
                @include normalTxt;text-align:center;white-space:nowrap;
                &:hover {border-bottom:4px solid $purple;}
            }
            &.on {
                a {border-bottom: 4px solid $purple;}
            }
        }
    }
}

/*** 팝업창 ***/
//상단 로그인
.sec-logbox {
    position:absolute;top:72px;z-index:99;width:320px;border:1px solid $gray02;border-radius:10px;background:#fff;
    /*right:0;*//*header태그 안에 있을때*/
    left:50%;top:72px;margin-left:277px;/*header태그 밖에 있을때*/
    &.type-login {width:360px;}
    &.mem-corp{
        .func-btn{
            li{
                margin-bottom: 0 !important;
            }
        }
    }
    .logbox-wrap {position:relative;}
    .info {
        &-user {
            display:flex; align-items: center; padding:30px 70px 30px 30px;@include mainTxt;
            &+.info-basic {border-top:1px solid $gray02;}
            .mem-modi{
                display: inline-block; width: 22px; height: 22px; margin-left: 10px; @include backgroundImg('/assets/images/pc/icon/ico-pencil.svg', 0, 100%); font-size: 0;
            }
            .member-modify {
                width: 114px; height: 30px;  line-height: 30px; margin-left:10px; border-radius: 15px; background-color: #662d91;  color:#fff; font-size: 14px;  text-align: center; font-weight:600;    letter-spacing: -0.28px;
            }
        }
        &-basic {
            padding:30px;
            .find-join {
                display:flex;justify-content:flex-start;align-items:center;margin-top:30px;
                &>li {
                    width:50%;text-align:center;@include defaultTxt;
                    &+li {border-left:1px solid $gray02;}
                    a {@include defaultTxt;}
                }
            }
            .user-product {
                dt {margin-bottom:10px;@include normalTxt;}
                dd {
                    &>p {@include normalTxt;}
                    .graph {
                        display:flex;justify-content:space-between;margin-top:30px;
                    }
                }
            }
            .usage-wrap {
                .circle-wrap {
                    position:relative;width:66px;height:66px;flex-shrink: 0;
                    svg {
                        width:66px;height:66px;transform: rotate(-0.25turn) rotateX(180deg);
                        position:absolute;top:0;left:0;bottom:0;right:0;margin:auto;height: 100%;
                    }
                    .background {
                        fill:none;stroke:$gray01;stroke-width:2;
                    }
                    .progress {
                        position: relative;
                        fill:none;
                        stroke: #7c40ce;
                        stroke-dasharray: 100 100;
                        stroke-dashoffset: 100;
                        stroke-linecap: round;
                        stroke-width: 2;
                        transition: stroke-dashoffset 1s ease-in-out;
                    }
                    .bar-circle{
                        position:absolute;left:0;top:50%;transform:translateY(-50%) rotate(-90deg);width:204px;height:14px;transition:1s ease-in-out;
                        &.on{transform:translateY(-50%) rotate(233deg);}
                        span{position:absolute;right:5px;top:0;width:14px;height:14px;background:#fff;border-radius: 50%;}
                    }
                    .txt-data {
                        position:absolute;top:50%;left:50%;height:22px;@include subContsTitle;font-weight:700;color:$skyblue;transform:translate(-50%,-50%);
                        &>span {@extend %fontInherit;}
                    }
                }
                .txt-wrap {margin-top:10px;text-align:center;}
            }

        }
        &-func {
            padding:30px;border-top:1px solid $gray02;
            .devcechg-txt {margin-top:10px;font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: right; color: #666;}
            .func-btn {
                display:flex;justify-content:flex-start;align-items:flex-start;gap:0 40px;
                &>li {
                    a, button {padding-left:30px;@include normalTxt;}
                    &+li{
                        margin-bottom: 30px;
                    }
                }               
                .btn-request-inquire {@include backgroundImg('/assets/images/pc/common/log-doc.svg', left top, 20px 20px);}
                .btn-num-auth {@include backgroundImg('/assets/images/pc/common/log-phone.svg', left top, 20px 20px);}
                .btn-join-info {@include backgroundImg('/assets/images/pc/common/log-doc-pen.svg', left top, 20px 20px);}
                .btn-charge-inquire {@include backgroundImg('/assets/images/pc/common/log-note-page.svg', left top, 20px 20px);}
            }           

            .guide-box {
                padding:30px 20px;background:#f9f5ff;border-radius:10px;
                a {
                    display:block;position:relative;
                    &::after {
                        content:'';display:block;position:absolute;right:0;top:50%;width:20px;height:20px;margin-top:-10px;
                        @include backgroundImg('/assets/images/pc/common/log-arrow-right.svg', left top, 20px 20px);
                    }
                    p {
                        @include normalTxt;
                        &+p {display:block;margin-top:10px;}
                        .txt-vt {color:#7c40ce !important;}
                    }
                }
                &.pint-bx {
                    a + a {margin-top:10px;}
                    a {
                        &::after {width:20px;height:20px;background: url(/assets/images/pc/icon/ico-arrow-right.svg) no-repeat center;background-size: 15px;;}
                        strong {display:inline-block;font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                        p {
                            display:flex; align-content: flex-start;
                            strong {display:inline-block; min-width:56px;}
                            span {margin-left:auto;padding-right:20px; font-size: 16px;font-weight: 600; line-height: 1.19;letter-spacing: -0.32px; text-align: right; color: #662d91;}
                            &.new {
                                strong {position:relative;
                                    &::after {content:''; position:absolute; right:-9px; top:0; width: 6px; height: 6px; border-radius: 50%; background-color: #ff3045;}
                                }
                            }
                        }
                    }
                    a + p {margin-top:20px;}
                }
            }
        }
    }
    .logbox-btm {
        padding:0 30px;text-align:center;
        .btn-logout {margin-bottom:30px;}
        .btn-close-x {position:absolute;right:30px;top:30px;width:30px;height:30px;@include backgroundImg('/assets/images/pc/common/log-close-x.svg', center, 30px 30px);font-size:0;}
    }
}