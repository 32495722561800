%fontInherit {font-size:inherit;font-weight:inherit;letter-spacing:inherit;line-height:inherit;color:inherit;}

.list {
    li {
        &.notice {background:$gray01;}
        a {display:block;width:100%;padding:24px;border-bottom:1px solid $gray03;
            strong {@include defaultTxt;}
            .date {
                display:flex;justify-content:space-between;align-items:center;margin-top:16px;
                p {@include smallTxt;}
            }
        }
    }
}
.icon-img{
    width:80px; height:80px;
    img{
        width: 100%;
    }
}
.icon-left{
    display: flex; gap:20px;
    .cardList{
        display: flex; gap:40px; align-items: center; padding:40px !important;
    }
    .title{@include mainTxt;}
    .desc{@include defaultTxt; margin-top: 10px;}
    .notification{
        margin-top: 10px;
    }
}
//세로형 - 이미지 + 텍스트 카드 예시 (이벤트)
.going-list-wrap {
    display:flex;flex-wrap:wrap;gap:30px;margin-top:50px;
    .cardList-wrap {
        width:406px;margin:0 0 20px;
        .cardList-img {
            width:406px;height:230px;min-height:auto; overflow:hidden;
            .date {right:10px;top:10px;padding:1px 6px;border-radius:0;@include defaultTxt;color:$white;}
            &.video-img{
                width:406px; height:230px; min-height:auto; overflow:hidden;
                img{
                    width:100%; height:100%; display: block;
                }
            }
        }
        .cardList-desc {
            margin-top:20px;
            .main-title {
                overflow:hidden;text-overflow:ellipsis;height:48px;@include mainTxt;
                display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;
            }
            .period {@include defaultTxt;}
            &.type-dday {
                position:relative;padding-right:60px;
                .d-day {position:absolute;right:10px;top:0;padding:1px 6px;border-radius:5px;background:$black;@include smallDepthTxt;color:$white;}
            }
        }
        & + .cardList-wrap{
            margin-top:0;
        }
    }
    & + .box-paging{
        margin:0;
    }
}
.cardList-wrap {
    width:100%;margin:0 auto;box-sizing:border-box;
    // padding:0 40px;
    .cardList-title {margin-bottom:10px;/*@include normalTxt;*/@include mainTxt;}
    .list-header {
        display:flex;justify-content:space-between;align-items:center;margin-bottom:28px;
        strong {display:block;@include subContsTitle;}
        span {display:block;@include defaultTxt;}
    }
    .cardList {
        width:100%;padding:40px;border:1px solid $gray02;border-radius:10px;background:$white;
        .product-name{
            h3{
                @include defaultTxt;
            }
            & > strong{
                @include mainTxt; display: block; margin: 10px 0;
            }
        }
        .box-btn.mt40{
            margin-top: 40px;
        }
        dl {
            display:flex;flex-wrap:wrap;
            dd {
                width:calc(100% - 210px);padding-bottom:20px;word-break:break-all;text-align:left;
                @include normalTxt;
                &.purple{color: $purple;}
                &:last-of-type{padding-bottom: 0;}
            }
            dt {
                width:210px;padding-bottom:20px;text-align:left;
                @include defaultTxt;
                &:last-of-type{padding-bottom: 0;}
            }
            &+.info {margin:40px 0 20px;padding-top:40px;border-top:1px solid $gray02;@include normalTxt;}
        }
        &.no-list {
            padding:78px 40px;
            dt {width:100%;text-align:center;}
        }
        &.complete {
            .list-header {
                span {color: $purple;}
            }
        }
        &.cancel {
            .list-header {
                span {color: $gray05;}
            }
        }
        &.first-full{
            padding-bottom: 20px;
            dt{
                width: 160px;
            }
            dd{
               width: 200px;
               &:first-of-type{
                width:calc(100% - 160px)
               }
            }
        }
        &.notice-card{
            .title {@include defaultTxt;}
            strong {
                display:block;margin-top:20px;text-align:right;@include contsTitle;
                em {@extend %fontInherit;}
            }
            .bang-notice {margin-top:10px;}
        }
        &.money-right {
            dl {
                dt {width:220px;}
                dd {width:calc(100% - 220px);text-align: right;}
                &+dl {
                    border-top:1px solid #ddd;margin-top:20px;padding-top:40px;
                    dt, dd {
                        &:last-of-type {padding-bottom:0;}
                        &:first-of-type {padding-bottom:20px !important;}
                    }
                }
            }
            .btn-group {
                .btns {flex:1;}
            }
        }
        &.one-title {
            padding:30px;
            dl {
                display: block;
                dt {width:100%;padding:0;@include normalTxt;}
                dd {
                    width:100%;margin-top:10px;padding:0;@include defaultTxt;
                    &+dt {margin-top:20px;}
                }
            }
            .unit-img {margin-top:30px;}
        }
        &.reverse {
            dl {
                dt {@include normalTxt;}
                dd {@include defaultTxt;}
            }
        }
        &.usim-card{
            dl{
                justify-content: space-between;
                dd{
                    text-align: right; @include mainTxt;
                }
            }
            .flex-box{
                width:100%; display: flex;  justify-content: space-between; margin-top: 25px;
                .img{
                    width:201px; border-radius: 10px; box-shadow: 10px 10px 20px rgba(0,0,0,16%);
                    img{
                        display: block;
                    }
                }
                .desc{
                    width:calc(100% - 252px);
                    .tit{
                        @include mainTxt;
                    }
                    .txt{
                        @include defaultTxt; margin-top: 10px;
                    }
                    .amount{
                        margin-top: 24px; @include subContsTitle; text-align: right; display: block;
                    }
                }
            }

        }
    }
    .underLine {
        padding:32px 40px;
        dl {
            dt {
                width:210px;padding:24px 0;border-bottom:1px solid $gray03;font-weight:400;
                &:first-of-type{padding-top:0;}
                &:last-of-type{border-bottom:0;}

            }
            dd {
                width:calc(100% - 210px);padding:24px 0;border-bottom:1px solid $gray03;text-align:left;
                @include normalTxt;
                &:first-of-type{padding-top:0;}
                &:last-of-type{border-bottom:0;}
            }
        }
    }

    &+.cardList-wrap {margin-top:50px;}
    .cardList-img{
        width:100%; min-height:400px;
        background: $gray01;
        border-radius:7px;
        position: relative;
        .date{
            @include defaultTxt; color: $white;
            position: absolute;
            top:20px;right:20px;
            background: $black;
            border-radius:99px;
            padding:6px 20px;
        }
    }
    .cardList-desc{
        margin-top:30px;
        .main-title{
             @include subContsTitle;
             @include ellipsis2;
        }
        .sub-title{
            @include normalTxt;
            @include ellipsis;
        }
        .period{
            margin-top:10px;
            @include smallTxt; font-weight: 600;
        }
    }

}
.card-check {
    margin-bottom:30px;padding-bottom:30px;border-bottom:1px solid $gray02;
    label {
        span {font-size:28px;}
    }
}
.item-top {
    display: flex; width:100%; padding-bottom:30px; align-items: center; gap:30px;
    .item-img {
        width:180px; height:202px;
        img {width:100%;}
    }
    .item-txt{
        width:calc(100% - 184px);
        .item-txt-tit {display: inline-block;margin-bottom:9px;@include normalTxt;}
        .item-txt-desc {@include smallDepthTxt;}
    }
}
.product-imgCard-btn{
    .cardList{
        border-radius:20px 20px 0 0; padding:0; border-bottom:0;
        .item-top{
            padding:40px;
            .item-img{
                width:180px; height:202px;
                img{
                    width:100%;
                }
            }
        }
        dl{
            background:$gray01; padding:40px;
            dt{
                width:302px;
            }
            dd{
                width:calc(100% - 302px); text-align: right;
                span.c-red{
                    @include defaultTxt; color: $pinkpurple; font-weight: 600;
                }
            }
        }
    }
}
.product-imgCard {
    .cardList {
        padding:40px;
        &+.cardList {margin-top:20px;}
    }
    .chip-wrap {margin-bottom:20px;}
    .card-list-header{
        display: flex;justify-content: space-between; position: relative; width: 100%;
    }
    .item-left {
        display: flex; align-items: center; width: 100%; max-width: 180px;
        .item-img{
            position: relative; width: 100px; height: 100px;flex-shrink: 0;overflow: hidden;
            img{
                position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 100%; height: 100%; object-fit: contain;
            }
        }
    }
    .item-right {
        width: calc(100% - 210.0002px);
        .item-txt {
            position: relative;height: 100%;

        }
        .item-txt-wrap{
            display: flex; align-items: center; margin-bottom: 10.0001px;
            .item-txt-tit{
                @include mainTxt;
            }
        }
        .desc{
            @include smallTxt; @include ellipsis2;
        }
        .bang-notice{
            margin-top: 20.0002px;
            li{
                margin-top: 0;
            }
        }
        .item-price {
           display: flex; justify-content: space-between; align-items: center; width: 100%; margin-top: 23.9998px;
            & > span{@include defaultTxt;}
            & > strong{
                @include mainTxt;color: $purple;
                em{@extend %fontInherit;}
            }
        }
    }
    .phone-info {
        display:flex;gap:50px;
        .phone-color-box {
            width:260px;min-height:305px;
            .phone-img {
                position:relative;height:260px;
                li {
                    display:flex;justify-content:center;align-items:center;position:absolute;left:0;top:0;height:260px;opacity:0;transition:0.2s;
                    img {width:100%;}
                    &.on {opacity:1;}
                }
            }
            .color-type {
                display:flex;gap:11px;justify-content:center;margin-top:20px;
                li {
                    position:relative;width:22px;height:22px;cursor:pointer;
                    &.on {
                        &::before {content:'';display:block;position:absolute;top:50%;left:50%;width:100%;height:100%;transform:translate(-50%,-50%);border:1px solid $black;border-radius:50%;}
                    }
                    span {display:block;position:absolute;top:50%;left:50%;transform: translate(-50%,-50%);overflow:hidden;width:16px;height:16px;border-radius:50%;background-color:#e4f7ff;}
                }
            }
        }
        .phone-txt-box {
            width:calc(100% - 100px);
            .box-tit {
                margin-bottom:30px;
                .tit {@include contsTitle;}
                .txt {margin-top:10px;@include defaultTxt;}
            }
            .phone-charge-box {
                .total-box {
                    display:flex;justify-content:space-between;align-items:center;margin-bottom:20px;
                    strong {@include mainTxt;}
                    span {@include mainContsTitle;}
                }
                .charge-wrap{
                    &>div {
                        display:flex;justify-content:space-between;padding:40px;border-radius:7px;background-color:$bg;
                        &+div {margin-top:10px;}
                        .plan {
                            &-title {@include normalTxt;}
                            &-name {display:block;margin-top:10px;@include defaultTxt;}
                            &-price {@include normalTxt;}
                        }
                        &.accordian {
                            display:block;
                            .acc-trigger {
                                text-align:left !important;
                                &:after {top:11px;}
                                .plan {
                                    &-title {display:block;}
                                    &-price {display:block;margin-top:20px;text-align:right;}
                                }
                            }
                            .acc-conts {
                                padding-top:20px;
                                .cardList {
                                    border:none;border-top:1px solid $gray02;padding:20px 0 0 0;border-radius:0;background:none;
                                    dl {
                                        margin-bottom:-10px;
                                        dt {width:60%;padding-bottom:10px;@include defaultTxt; }
                                        dd {width:40%;padding-bottom:10px;@include defaultTxt;}
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .btn-text-right {margin-top:20px;}
            .box-btn {
                margin-top:30px;text-align:right;
                .btns {width:260px;}
            }
        }
    }

    &.type-simple {
        .phone-txt-box {
            .box-info {
                display:flex;justify-content:space-between;
                .box-tit {
                    flex:1 1 70%;margin-bottom:0;
                    .tit {@include contsTitle;}
                    .txt {margin-top:10px;@include defaultTxt;}
                }
                .item-price {
                    strong {display:block;@include contsTitle;color:$purple;text-align:right;}
                    span {display:block;margin-top:10px;@include smallDepthTxt;color:$black;text-align:right;}
                }
            }
            .notification {margin-top:30px;;}
            .box-item {margin-top:40px;padding-top:20px;border-top:1px solid $gray02;}
            .box-btn {margin-top:30px;}
        }
    }
}
.payment-imgCard-btn {
    &+.payment-imgCard-btn {margin-top:20px;}
    .cardList {
        padding:40px;
        .item-top {
            display:block;padding-bottom:0;
            .chip-wrap {margin-bottom:20px;}
            .payment-desc {
                display: block;width:100%;
                dt {
                    display: flex; justify-content: space-between;align-items:center;width:100%;padding-bottom:10px;
                    .tit {@include contsTitle;}
                    .charge {@include mainContsTitle;color:$purple;}
                }
                dd {
                    position:relative;width:100%;padding:0 300px 0 0;
                    .notification {
                        margin-top:30px;
                        li {@include defaultTxt;}
                    }
                    .box-btn {margin-top:0;}
                    .btns {position:absolute;right:0;top:10px;width:260px;}
                }
            }
            .payment-desc.smp-type {
                dd {padding:0;}
                dd:after {content:'';display:block;clear:both;}
                dd .notification {float:left;width:50%;}
                dd .box-btn {float:right;}
                dd .box-btn .btns {display:block;position:relative;left:0;top:0;}
                dd .box-btn .btns + .btns {margin-top:10px;}
            }
        }
         .item-bottom{
            margin-top:40px;padding-top:20px;border-top:1px solid $gray02;
            .btn-text-right,
            .txt {
                @include normalTxt;
                span {color:$purple;}
            }
         }
    }
    &.payment-list{
        .cardList{
            & + .cardList{margin-top: 30px;}
            position: relative; border: none;
            label {
                display:block;position:absolute;left:0;top:0;width:100%;height:100%;overflow:hidden;border:1px solid $gray02; border-radius:10px;text-indent:-999em;transition:0.3s; cursor:pointer;
                &:hover{
                    border-color:$purple;
                }
            }
            input[type=radio] {
                position:absolute;left:0;top:0;opacity:0;
                &:checked + label {border-color: $purple;}
            }

        }
    }
}


.notification {
    &>li {
        position:relative;padding-left:10px;
        @include defaultTxt; line-height: 1.5;
        & + li{margin-top: 5px;}
        &::before {content:'';width:3px;height:3px;background: $gray05;border-radius: 50%;position: absolute;top:9px;left:0;}
        &:last-child {margin-bottom:0;}
        .list-hyphen {
            &>li {margin-bottom:0;color:$gray05;}
        }       
    }
    .txt-eps {color:#ff0000; font-weight: 600;}
    &.txt-rd{
        li{
            color: $red;
            &::before{
                content:'';
                width:3px;
                height:3px;
                background: $red;
                border-radius: 50%;
                position: absolute;
                top:6px;
                left:0;
            }
        }
    }
    li.txt-rd{
        &::before{
            content:'';
            width:3px;
            height:3px;
            background: $red;
            border-radius: 50%;
            position: absolute;
            top:6px;
            left:0;
        }
    }
}
.notification-free {
    .notification {
         display:flex;justify-content:flex-start;gap:5px 40px;flex-wrap:wrap;
         &>li {margin:0;box-sizing:border-box;white-space:nowrap;}
         &.half {
             &>li {flex:0 1 48%;}
         }
         &.free {
             &>li {width:auto;}
         }
     }
 }

.list-hyphen {
    &>li {
        position:relative;margin-bottom:10px;padding-left:10px;
        @include defaultTxt;color:$gray04;line-height:1.5; /* 예외케이스로 line-height 지정*/
        &::before {content:'-';width:3px;height:3px;position:absolute;top:-1px;left:0;}
        &:last-child{margin-bottom:0;}
    }
}

.other-list{
    width:100%;
    li{
        border-bottom:1px solid $gray03;
        padding:30px 0; display: flex; justify-content: space-between; align-items: center; gap:30px;
        &:last-child{
            border-bottom:0;
        }
        .other-list-img{
            width:200px; height:126px; overflow:hidden; border-radius:24px;
            img{
                width: 100%; display: block;
            }
        }
        dl{
            width:calc(100% - 200px);
            dt{
                @include mainTxt; margin-bottom: 7px; @include ellipsis;
            }
            dd{
                @include smallDepthTxt; @include ellipsis;
            }
        }
    }
}
//요금제 아코디언 카드 리스트
.payment-accordian-card {
    margin-top: 20px;
    .accordian {
        &>.acc-trigger {
            display:block; width:100%; position:relative;margin-bottom:40px;padding-bottom:10px;border-bottom:1px solid $gray02;
            @include mainTxt; text-align:left;font-weight:600;
            .title {@include contsTitle;}
            &:after {
                content:"";position:absolute;right:0;top:50%;width:20px;height:20px;margin-top:-15px;
                @include backgroundImg('/assets/images/pc/icon/rec-arrow-bottom-lightgray.svg', center, 20px 20px);
            }
            &.on {
                border-bottom:1px solid $black;
                &:after {margin-top:-25px;@include backgroundImg('/assets/images/pc/icon/rec-arrow-top-black.svg', center, 20px 20px);transform:none;}
            }
        }
    }
    .acc-conts {
        padding:0;border-radius:0;background:none;
        .acc-conts-wrap {
            &>a {
                border:1px solid $gray02;border-radius:7px;box-sizing:border-box;background:$white;transition:0.3s; display:block;position:relative;padding:40px;
                .mark {position:absolute;left:-1px;top:-1px;}
                &:hover {
                    border-color:$purple;
                    .header {@include backgroundImg('/assets/images/pc/icon/ico-arrow-black-s.svg', right top 5px, 20px 20px);}
                }
            }
            & > input[type=radio] {
                @include hidden;
                &:checked + label {border-color: $purple;}
            }
            &>label {
                display:block;position:relative;padding:40px; cursor: pointer; border:1px solid $gray02;border-radius:7px;box-sizing:border-box;background:$white;transition:0.3s;
                .mark {position:absolute;left:-1px;top:-1px;}
                &:hover{
                    border-color:$purple;
                }
            }

            &+.acc-conts-wrap {margin-top:30px;}
            &:first-of-type {
                .bottom {display:block;}
            }
        }
    }
    .chip-wrap{ margin-bottom:20px;}
    .header {
        display:flex;justify-content:flex-start;align-items:center;padding-right:40px;transition:0.3s;
        @include backgroundImg('/assets/images/pc/icon/ico-arrow-gray-s.svg', right top 5px, 20px 20px);
        dt {
            width:340px;
            .title {@include mainTxt;}
        }
        dd {
            display:flex;justify-content:space-between;width:calc(100% - 340px);
            .feature {
                display:flex;justify-content:flex-start;margin:0;
                span {
                    width:165px;height:20px;padding-left:30px;@include defaultTxt;color:$black;line-height:20px;
                    &.vol {
                        &::before {@include backgroundImg('/assets/images/pc/icon/ico-data-black.svg', center, 20px 20px);}
                    }
                    &.limit {
                        &::before {@include backgroundImg('/assets/images/pc/icon/ico-call-fill-black.svg', center, 20px 20px);}
                    }
                    &.supply {
                        &::before {@include backgroundImg('/assets/images/pc/icon/ico-sms-fill-black.svg', center, 20px 20px);}
                    }
                }
            }
            .pay-amount {
                padding:0;
                .origin-pay {@include defaultTxt;}
                .discount-pay {@include subContsTitle;color:$purple;}
            }
        }
    }
    .bottom {
        /*display:none;*/margin-top:20px;
        &>.notification {
            display:flex;justify-content:flex-start;flex-wrap:wrap;gap:20px 40px;padding:20px;border-radius:10px;background:$bg;
            &>li {margin:0;}
        }
    }
    &+.payment-accordian-card {margin-top:70px;}
}

//요금제 - 카드 리스트
.payment-list-card-wrap{
    .title{
        @include mainTxt; margin-bottom: 30px;
    }
    .payment-list-card{
        border:1px solid #ddd; border-radius:7px; padding:40px;
        .sub-title{@include mainTxt;}
        .notification{margin-top: 20px;}
        & + .payment-list-card{
            margin-top: 30px;
        }
    }

}

//금액
.pay-amount{
    padding-top:20px; display: flex; align-items: flex-end; justify-content: flex-end; gap:10px;
    .origin-pay{
        @include defaultTxt; display: inline-block; text-decoration: line-through;
    }
    .discount-pay{
        @include mainTxt; color:$purple; font-weight: 700;
    }
    .undeter-pay{
        @include mainTxt;
    }
}
//물음표 버튼
.qos-popup{
    display: inline-block; width: 16px; height:16px; @include backgroundImg('/assets/images/pc/icon/ico-tip.svg', center,contain); margin-left:10px; vertical-align: middle;
}
// 요금제 디테일 카드
.payment-detail-card-wrap{
    padding: 40px;border: solid 1px $gray02; border-radius:7px;
    .txt-vt{@extend %fontInherit;}
    & > ul{
        & > li:not(:first-of-type){
            border-top: solid 1px $gray02; padding-top: 40px;margin-top: 40px;
        }
    }
    .detail-title{
        @include defaultTxt; display: block; margin-bottom: 20px;
        & + em{
            @include contsTitle; display: block; text-align: right;
        }
    }
    .notification{
        * {
            @include smallTxt;
        }
        li{
            display: flex; justify-content: space-between;
        }
        .total-price{@include smallTxt; font-weight: 600;}
    }
}

// 아이콘과 텍스트있는 카드
.icon-txt-card{
    img{width: 120px;}
    display: flex; gap: 30px; align-items: center; border: solid 1px $gray02;border-radius:10px; padding: 20px !important; @include smallTxt;
    &.cardList{
        .card-img{
            width: 60px; height: 60px;
            img{display: block; width: 100%;}
        }
        .card-txt{
            .main-txt{
                display: block;
                & + p{
                    margin-top: 10px;
                }
            }
            p{
                @include defaultTxt;
            }
        }
    }
}

// 이용중인 상품 리스트
.using-goods{
    & + .using-goods{margin-top: 30px;}
    .item {
        &-top{
            flex-direction: column; align-items: flex-start; padding-bottom: 40px;
            a{width: 100%;}
            .btn-box{
                display: flex; justify-content: space-between;
                .goods-title{
                    .goods {
                        &-name{
                            display: block; @include mainTxt;
                        }
                        &-date{
                            display: block; @include smallTxt; margin-top: 10px;
                        }
                    }
                }
            }
            .txt-vt{@include defaultTxt;}
            .choose-agree{
                display: flex; justify-content: space-between; align-items: center;
            }
        }
        &-bottom {
            display: flex; justify-content: space-between; border-top: 1px solid $gray02; padding-top: 40px;
            .category-tit{
                @include smallTxt; font-weight: 600;
            }
            .box-pay{
                display: flex; gap: 30px;
                * {@include defaultTxt;}
            }
        }
    }
}

.using-market {
    display: flex; justify-content: space-between;
    &.cardList{
       padding: 40px;
    }
    .item-left{
        display:block;padding-bottom:0;
        .market {
            .logo {
                display:block;font-size:0; height: 24px;
                img {vertical-align:top; height: 100%;}
            }
            .info {display:block;margin-top:20px;@include mainTxt;}
        }
        .point {
            margin-top:10px; @include defaultTxt; color:$purple;
            *{@extend %fontInherit;}
        }
    }
    .item-right {
        .pay-amount{
            margin-bottom: 20px; padding-top: 0;
        }
    }
}


//매장 찾기
.place-search-result {
    .info {
        padding-bottom:20px;@include normalTxt;
        .txt-vt{
            @extend %fontInherit; font-weight: 600;
        }
    }
    .result-list {
        border-top:1px solid $gray02;
    }
    .result-more {padding:40px 0;text-align:center;}
}
.place-address {
    position:relative;
    dl{
        padding:20px 30px;
        dt {@include mainTxt;}
        dd {
            li {
                @include defaultTxt; margin-top: 10px;
                &.txt-vt{
                    @include normalTxt; color:$purple;
                }
            }
        }
    }
    .box-btn {margin-top:30px;}
    &.loc {
        dl {width:calc(100% - 95px);}
        .btn-loc {
            display:block;position:absolute;right:30px;top:20px;overflow:hidden;width:80px;height:80px;border-radius:50%;font-size:0;text-indent:-999em;
            @include backgroundImg('/assets/images/pc/icon/ico-location.svg', center, 80px 80px);
        }
    }
}

//원형숫자 step
.num-process-wrap{
    display:flex;justify-content:flex-start;border:1px solid $gray02;border-radius:7px;padding:40px;
    & > li {
        display:flex;flex:1;position:relative;padding:60px 20px 0;
        &::before {content:'';display:block;position:absolute;left:50%;top:15px;z-index:0;width:100%;height:1px;background:$purple;}
        &:last-child::before {left:50%;top:15px;z-index:1;width:9px;height:1px;margin-left:-24px;background:$white;}
        &:first-child::after {display:none !important;}
        &:last-child::after {
            content:'';display:block;position:absolute;left:50%;top:0;z-index:1;z-index:1;width:20px;height:20px;margin:6px 0 0 -35px;transform:rotate(-90deg);
            @include backgroundImg('/assets/images/pc/icon/ico-arrow-purple.svg', center top, 20px 20px);
        }
        &.location{
            .txt-wrap{
                strong{
                    color:$purple;
                }
            }
        }
        &.disabled {
            .number {background-color: $gray02;}
            .txt-wrap {
                *{color: $gray04;}
            }
        }
        .number {
            display:inline-block;position:absolute;left:50%;top:0;width:30px;height:30px;margin-left:-15px;border-radius:50%;background-color:$purple;
            @include defaultTxt;color:$white;line-height:30px;text-align: center;
        }
        .txt-wrap {
            width:100%; text-align: center;
            strong {display:block;margin-bottom:10px;@include mainTxt;text-align:center;}
            p {display:block;@include defaultTxt;line-height:1.33;text-align:center;}
            img{display: inline-block;width: 80px; height: 80px; margin-bottom: 10px;}
        }
    }
}
.num-process-typeU {
    border:1px solid $gray02;border-radius:7px;padding:40px;
    .num-process-wrap {
        position:relative;border:none;border-right:1px solid $purple !important;border-radius:0;padding:0;
        &.row01 {
            &::after {content:'';display:block;position:absolute;right:-1px;top:0;z-index:1;width:1px;height:15px;background:$white;}
            li {
                &:last-child::before {content:'';display:block;position:absolute;left:50%;top:15px;z-index:0;width:50%;height:1px;margin-left:0;background:$purple;}
                &:last-child::after {display:none;}

                .txt-wrap {margin-bottom:40px;}
            }
        }
        &.row02 {
            flex-direction:row-reverse;
            &::before {content:'';display:block;position:absolute;right:-1px;top:15px;z-index:1;width:1px;height:100%;background:$white;}
            li {
                &::before {left:0;}
                &::after {content:'';display:block;position:absolute;left:-50%;top:15px;z-index:0;width:100%;height:1px;background:$purple;}
                &:last-child::before {left:50%;top:15px;z-index:1;width:9px;height:1px;margin-left:15px;background:$white;}
                &:last-child::after {
                    content:'';display:block;position:absolute;left:50%;top:0;z-index:1;z-index:1;width:20px;height:20px;margin:6px 0 0 15px;transform:rotate(90deg);
                    @include backgroundImg('/assets/images/pc/icon/ico-arrow-purple.svg', center top, 20px 20px);
                }
            }
        }
    }

}

//체크리스트
.box-check-list{
    @include backgroundImg('/assets/images/pc/icon/bg-checklist.png',right bottom, 302px 198px);
    display: flex; align-items: center; height: 200px; border: 1px solid $gray02; border-radius: 10px;
    ul{
        display: flex; flex-wrap: wrap; max-width: 790px; margin-left: 40px;
        li{
            padding-left: 22px; width: calc(50% - 20px);margin-top: 20px;
            @include backgroundImg('/assets/images/pc/icon/ico-checklist.svg',left center, 16px 16px);
            @include defaultTxt;
            &:first-of-type{margin-top: 0;}
            &:nth-of-type(2){margin-top: 0;}
            &:nth-of-type(2n) {
                margin-left: 40px;
            }
        }
    }
}

//회색상단 카드리스트
.reward-card-wrap {
    overflow:hidden;border:1px solid $gray02;border-radius:7px;
    dl {
        dt {
            padding:30px 40px;background:$gray01;
            strong {@include defaultTxt;}
            .txt {margin-top:10px;@include smallTxt;line-height:1.33;}
        }
        dd {
            padding:30px 40px;
            b {@include mainTxt;}
            .txt {margin-top:20px;@include smallTxt;line-height:1.33;}
        }
    }
}

.topGray-card-wrap {
    dl {
        overflow:hidden;border:1px solid $gray02;border-radius:7px;
        dt {
            padding:18px 30px;border-bottom:1px solid $gray02;background:$gray01;
            strong {@include mainTxt;}
            .txt {margin-top:10px;@include smallTxt;}
        }
        dd {
            padding:30px;
            .txt {margin-top:10px;@include defaultTxt;}
        }
    }
}


// 페이징버튼
.box-paging {
    display:flex;justify-content:center;
    .paging {
        &-first {
            display:block;width:50px;height:50px;margin-right:5px;
            @include backgroundImg('/assets/images/pc/common/paging-first.svg', center center, 50px 50px);
        }
        &-prev {
            display:block;width:50px;height:50px;margin-right:5px;
            @include backgroundImg('/assets/images/pc/common/paging-prev.svg', center center, 50px 50px);
        }
        &-next {
            display:block;width:50px;height:50px;margin-left:5px;
            @include backgroundImg('/assets/images/pc/common/paging-next.svg', center center, 50px 50px);
        }
        &-end {
            display:block;width:50px;height:50px;margin-left:5px;
            @include backgroundImg('/assets/images/pc/common/paging-end.svg', center center, 50px 50px);
        }
    }
    .paging-list {
        display:flex;justify-content:center;align-items:center;gap:5px;padding:0 15px;
        &>li {
            a {
                display:flex;justify-content:center;align-items:center;position:relative;width:50px;height:50px;@include defaultTxt;
                &::after {content:'';display:block;position:absolute;left:50%;bottom:9px;width:0;height:2px;margin-left:0;background:$black;transition:0.2s;}
            }
            &.active,
            &:hover {
                a {
                    @include normalTxt;
                    &::after {width:12px;margin-left:-6px;}
                }
            }

        }
    }
}

.img-txt-present {
    &>li {
        display:flex;justify-content:space-between;align-items:center;padding:50px 120px;
        &+li {border-top:1px solid $gray02;}
        .method {
            flex-grow:1; width:calc(100% - 470px);
            &>strong {@include contsTitle;}
            &>p {display:block;margin-top:10px;@include defaultTxt;font-size:20px;}
            &>.link{
                li{
                   margin-top: 10px;
                }
            }
        }
        .img {width:470px;}
    }

}


//카드리스트 테이블스타일로 변환
.transfer-table {
    .cardList-wrap {
        .cardList {
            padding:0;border-left:none;border-right:none;border-radius:0;
            dl {
                padding:30px 40px 10px;
                dt {width:250px;@include normalTxt;text-align:left;}
                dd {width:calc(100% - 250px);text-align:right;}
                &+dl {
                   align-items:center;padding:35px 40px;border-bottom:1px solid $gray02;background:$gray06;
                   dt {
                       padding-bottom:0;@include mainTxt;
                       .add-txt {font-weight:normal;}
                   }
                   dd {padding-bottom:0;@include contsTitle;color:$purple;text-align:right;}
                }
            }
            &+.cardList {margin-top:50px;}
        }
    }
}

.cardList.no-list {
    p {margin:0;padding:40px;@include mainTxt;text-align:center;}
}

.img-cardList {
    display:flex;flex-wrap:wrap;gap:20px;margin-top:40px;
    .cardList-wrap {
        /*width:413px;*/margin:0 0 50px;
        .cardList-img {
            /*width:413px;*/height:258px;min-height:auto;
            img {width:100%;height:258px;}
            .date {right:10px;top:10px;padding:1px 6px;border-radius:0;@include defaultTxt;color:$white;}
        }
        .cardList-desc {
            margin-top:20px;
            .main-title {@include mainTxt;}
            .sub-title {margin-top:10px;}
            .period {@include defaultTxt;}
        }
    }
}
.back-thumb-list{
    display: flex; flex-wrap: wrap; gap:20px;
    .back-thumb-card{
        position: relative; flex: 0 1 auto;  width:630px; height:630px; border-radius:14px; overflow: hidden; transform: translate3d(0,0,0);
        & > a{
            position: relative; width: 100%;  height: 100%; padding: 40px; display: block;
            .back-conts{
                display: flex; flex-direction: column; justify-content: space-between; position: relative; z-index: 2; width: 100%; height: 100%;
                .back-header{
                    *{color:$white !important;}
                    h3{@include defaultTxt; word-break: break-all;}
                    p{@include contsTitle; margin:10px 0 20px; word-break: break-all;}
                }
            }
            .back-bg{
                    display: flex; align-items: center; justify-content: center;  position: absolute;  top: 0; left: 0; width: 100%;  height: 100%;  overflow: hidden; border-radius:14px;
                img{
                    width: 100%; height: 100%; object-fit: cover;
                }
            }
            &::before{
                content: ""; position: absolute; top: 0; left: 0;  z-index: 1; display: block; width: 100%; height: 100%; background-color: #a1a1a1; opacity: .2; border-radius:14px;
            }
            .btns{
                width:100%;
            }
        }
    }

    &.type-free {
        gap:30px;
        li {
            width:406px;height:435px;
            &.thumb-b {width:100%;max-width:1278px;}
            &.thumb-m {width:842px;}
        }
        .back-thumb-card {
            &>a {
                .back-conts {
                    .back-header {
                        display:flex;justify-content:space-between;flex-direction:column;
                        h3 {padding-right:80px;@include normalTxt;}
                        p {@include mainVisualTxt;font-size:28px;}
                        .feature {
                            display:block;flex-direction:row;
                            &>span {
                                display:block;
                                &+span {margin-top:10px;}
                                &.limit::before {@include backgroundImg('/assets/images/pc/icon/ico-call-fill-white.svg', center, 20px 20px)}
                                &.supply::before {@include backgroundImg('/assets/images/pc/icon/ico-sms-fill-white.svg', center, 20px 20px)}
                            }
                        }
                    }
                    .back-footer {
                        .pay-amount {
                            margin-bottom:29px;
                            .origin-pay {@include mainTxt;color:$white;font-weight:normal;opacity:0.5;}
                            .discount-pay {@include mainContsTitle;color:$white;font-weight:600;}
                        }
                        .btns {width:326px;}
                    }
                }
            }
            &.thumb-b,
            &.thumb-m {
                &>a {
                    .back-conts {
                        flex-direction:row;align-items:flex-end;
                    }
                }
            }
        }
    }
}

.ico-txt-cardList {
    display:flex;justify-content:space-between;gap:20px;
    &>li {
        display:flex;justify-content:flex-start;align-items:center;flex:1;gap:30px;padding:40px;border:1px solid #ddd;padding:30px;border-radius:7px;
        img {width:80px;}
        p {
            margin-bottom:0;align-self:center;
            strong {
                display:block;@include normalTxt;
                &+span {margin-top:10px;}
            }
            span {
                display:block;@include defaultTxt;
                &+strong {margin-top:10px;}
            }
        }
    }
}

// 할부 정보 리스트
.month-plan-box{
    display: flex; align-items: center; min-height: 150px; border: 1px solid $gray02; border-radius: 10px;
    dl{
        position: relative; width: 50%; text-align: center;
        &:first-child{
            &:after{
                content: ''; position: absolute; right: 0; top: 50%; transform: translateY(-50%); width: 1px; height: 70px; background: $gray02;
            }
        }
        dt{
            margin-bottom: 10px; @include mainTxt;
        }
        dd{
            @include contsTitle; color: $purple;
        }
    }
}

// 비디오 썸네일 리스트
.video-thumb-list{
    display: flex; gap: 30px;
    .video {
        &-item {
            width: 406px;
        }
        &-thumb {
            position: relative; width: 100%; height: 250px; border-radius: 20px; overflow: hidden;
            * {
                width: 100%;
            }
            &::before {
                content: ''; display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%,-50%); width: 80px; height: 80px;
                @include backgroundImg('/assets/images/pc/icon/ico-cir-play.png',center, contain);
            }
        }
        &-title {
            margin-top: 20px;
            strong{
                @include mainTxt; font-weight: 700;
            }
        }
    }
}

.num-list {
    li {
       padding-left:20px; font-size: 16px; line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #666;
       .num {display: inline-block; text-indent: -18px;}
       & + li {margin-top:5px;}
    }
}