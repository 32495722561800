@charset "UTF-8";

/*** 전체 공통 ***/
%fontInherit {
    font-size: inherit; font-weight: inherit; letter-spacing: inherit; line-height: inherit; color: inherit;
}
.extra{
    .info-bg{
        background:$lightpurple; padding: 60px 0; overflow: visible !important;
    }
    .discount-pay{
        @include mainContsTitle; color:$purple;
        & > span{
            @include defaultTxt; margin-left: 10px;
        }
    }
    .info-wrap{
        display: flex; gap:70px;align-items: center;
        .info-img{
            width:500px; display: flex; justify-content: center; align-items: center; height: 288px;
        }
        .info{
            width:530px;
            .chip-wrap{
                margin-bottom: 20px;
            }
            & > dl{
                dt{
                    @include mainContsTitle;
                }
                dd{@include normalTxt01; margin-top: 20px;}
            }
            .flex-box{
                display: flex; justify-content: space-between; align-items: center; margin-top:20px; flex-direction: row-reverse;
            }
            .box-btn{
                position: relative;
                .not-allow{
                    width:530px; border-radius: 7px; background: $white;  padding: 50px; position: absolute; top:0; box-shadow: 0 3px 30px rgba(0, 0, 0, 0.16); overflow: hidden; display: none; z-index: 999;
                    & > p{
                        @include defaultTxt;
                    }
                }
                .google{
                    @include backgroundImg('/assets/images/extra/google_logo.png',56px center,40px 40px);
                    background-color: $white; padding-left: 5px;
                }
                .apple{
                    @include backgroundImg('/assets/images/extra/apple_logo.png',62px center,40px 40px);
                    background-color: $white; padding-left: 5px;
                }
            }
        }
    }
    .about-service{
        width:800px !important; margin:70px auto;
        .unit-sec-tit{
            .list{
                margin-top: 10px; border-top:0;
                .discount-pay{
                    @include mainTxt; color:$purple;
                }
                li{
                    padding: 20px 40px; border-top:1px solid $gray02;
                    & > strong.default-txt{
                        @include normalTxt; margin-bottom: 10px; display: block;
                    }
                    &:last-of-type{
                        border-bottom: 1px solid $gray02;
                    }
                    & > dl{
                        display: flex; gap:20px;
                        dt{
                            width:124px;
                        }
                        dd{
                            width:calc(100% - 144px);
                        }
                        & + dl{
                            margin-top: 10px;
                        }
                    }
                }
            }
            p{
                line-height: 1.5 ; 
            }
            // 안내용 이미지
            .info-img {
                display: block;
            }
            .border-img{
                margin-top: 10px; border: 1px solid $gray02; border-radius: 10px; overflow: hidden; padding: 40px 0;
                img{margin: 0 auto;}
            }
            // 아이콘 이미지
            .icon{
                display: inline-block; width: 20px !important; height: 20px !important; min-height: auto !important; margin: 0 2px;vertical-align: middle;
            }
            //원형숫자 step
            .num-process-wrap{
                flex-direction: column; border:1px solid $gray02;border-radius:10px;padding:40px;
                & > li {
                    display:flex; gap:30px;position:relative; padding : 0; padding-left:60px; margin-left: 15px; padding-bottom: 50px;
                    &::before {content:'';display:block;position:absolute;left:0;top:0;z-index:0;width:1px;height:100%;background:$purple;}
                    &:last-child {
                        padding-bottom: 0;
                        &::before {display: none;}
                        &::after{
                            left: 25px; top: -26px; transform: none;
                        }
                    }
                    &:nth-last-child(2) {
                        margin-bottom: 10px;
                    }
                    .number {
                        left: -15px; margin-left: 0;
                    }
                    .txt-wrap {
                        strong{text-align: left;}
                        p {text-align: left;}
                        .notification{
                            li{text-align: left;}
                        }
                    }
                }
            }
        }
        .unit-sm{
            margin-top: 10px;
            strong{
                @include normalTxt;
            }
        }
        .accordian{
            .acc-trigger{
                margin-bottom: 0;
                .unit-tit{
                    @include contsTitle; text-align: left; margin-bottom:0;
                }

            }
            .acc-conts{
                margin-top: 50px; background: $white; padding: 0;
            }
        }
        .payment-detail-card-wrap{
            margin-top: 10px; border:none; border-radius: 0; padding: 0;
            & >ul{
                display: flex; flex-wrap: wrap; gap:50px 0;
                 li{
                    width:50%; text-align: center;
                    &:first-of-type{margin: auto;}
                    &:not(:first-of-type){
                        border-top:0; padding-top: 0; margin-top: 0;
                    }
                    & > .img{
                        width:80px; margin:0 auto;
                        img{
                            display: block;width:100%;
                        }
                    }
                    & > .detail{
                        margin-top: 20px;
                        strong {
                            @include mainTxt;
                        }
                        .box-btn{
                            margin-top: 20px;
                            .btn-text-arrow{
                                    padding-right: 22px;
                                    background: url(/assets/images/pc/icon/ico-arrow-right.svg) no-repeat right;
                                    background-size: 15px 15px;
                                    display: inline-block;
                                    @include smallTxt;
                            }
                        }
                    }
                }
            }

        }
        .thumb-list{
            & > li{
                display: flex; gap:50px; align-items: flex-start;
                .tit{
                    @include mainTxt;
                }
                .movie{
                    position: relative; width: 100%; width:350px;  height: 200px;
                    img {
                        display: block;
                        @extend %imgSize; object-fit: cover;
                    }
                }
                .notification{margin-top: 10px;}
                & > div:not(first-of-type){
                    width: calc(100% - 400px);
                }
                & + li{
                    margin-top: 50px;
                }
            }
        }
        .slick-box{
            padding: 50px 0 50px 40px; border: 1px solid $gray02; border-radius: 10px; margin-top: 10px;
            .slick-title{
                @include normalTxt;
                & ~ .step-slick{margin-top: 20px;}
            }
            .step-slick{
                display: flex; flex-wrap: wrap;
                > div{
                    position: relative; width: 250px; margin-top: 50px; text-align: left;
                    &:nth-child(-n+3){
                        margin-top: 0;
                    }
                    &:after{
                        content: ''; position: absolute; top: 110px; right: 30px; width: 40px; height: 40px; @include backgroundImg('/assets/images/pc/icon/ico-right-arrow-thin.svg', 0 , 100%)
                    }
                    &:last-child{
                        &:after{
                            display: none;
                        }
                    }
                    img{
                        width: 150px; min-height: 260px;
                    }
                    .step{
                        display: block; width: 150px; margin-top: 20px;  @include normalTxt; text-align: center;
                    }
                    p{
                        width: 150px; text-align: center;
                    }
                }
                & + .slick-title{margin-top: 50px;}
            }
        }
        .notification{
            &+.slick-box{
                margin-top: 10px;
            }
        }
        .howto-use{
            display: flex; gap: 30px; margin-top: 10px;
            li{
                width: 385px; padding: 40px; border: 1px solid $gray02; border-radius: 10px;
                dl{
                    dt{
                        margin-bottom: 10px; @include mainTxt;
                    }
                }
            }
        }
        .service-list{
            display: flex; gap: 30px; margin-top: 50px;
            & > li{
                width: calc(50% - 15px); padding: 40px; border: 1px solid $gray02; border-radius:10px;
                h3 {
                    display: flex; justify-content: space-between;
                    span{@extend %fontInherit;}
                    & + .content{margin-top: 20px;}
                }
                .content{
                    margin-top: 10px;
                    strong{@include normalTxt01;}
                }
                .discount-pay{
                    @include mainTxt; color:$purple;
                }
            }
        }
    }
    .table-list-wrap{
        & + .table-list-wrap{margin-top: 30px;}
        .table-list{
            border: 0;
            th {
                background-color: #eff1f5;
                @include normalTxt;
            }
            td {
                padding: 20px 0; color: $gray05;
            }
            tr.now{
                td{
                    color:$purple;
                }
            }
        }
        &.table-scroll {
            overflow-x: auto;
            .table-list{
                min-width: 800px;
            }
        }
    }

    .roaming-abroad {
        .info-wrap {
            align-items: flex-end;gap:0;
            dl{
                width:100%;
                dd { font-size: 20px; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}
            }
            .info-img {width:400px;}
            .info {width:400px;margin-left:107px;}
            .plan-spc {
                display: flex;align-items: center; gap:50px; margin-top:30px;
                .spc-list {
                    display: flex;min-height: 100px; padding: 30px 20px; border-radius: 10px; background: #fff;
                    li {
                       position:relative; width:90px; height:40px; text-align: center;
                        .itm-tit {display: block; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #191919;}
                        .itm-txt {display: block; margin-top:4px; font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: center;color: #191919;}                        
                        &::before {content: '';position:absolute; left:0;top:0;width:1px; height:42px; background: #ddd;}
                        &:first-child::before {content: '';display:none;}
                    }                    
                }              
            }
            .plan-price {
                margin-left:50px; text-align: right;
                .price {
                    position:relative;top:-20px; font-size: 20px;  line-height: 1.2;letter-spacing: -0.4px;color: #666;
                    strong {display: inline-block; margin-right:10px; font-size: 40px; font-weight: bold;line-height: 1.2;letter-spacing: -0.8px;color: #662d91;}
                }
            }
        }

        .about-service{
            .accordian {
                .acc-conts {margin-top:10px;} 
            }
        }
    
    }
    .app-wrap{
        border: 1px solid #ddd; padding: 20px 40px; border-radius: 10px; text-align: center; margin-top: 20px;
        p{margin: 10px 0;}
        div{
            & + div{border-top: 1px solid #ddd; padding-top: 20px; margin-top: 20px;}
        }
        ul{
            display: flex; justify-content: space-evenly; margin-top: 20px;
            li{
                p{font-size: 14px;}
            }
        }
    }
}

.c-unit{
    .unit-tit{
        @include contsTitle; margin-bottom: 50px;
    }
    .unit-sec-tit{
        margin-bottom: 50px;
        h3{@include mainTxt;}
        p{margin-top: 10px; @include defaultTxt;}
        .notification{margin-top:10px;}
        .bang-notice{margin-top: 10px;}
        & + .unit-sec-tit{
            margin-top: 50px;
        }
    }
    .table-list{
        margin-top: 10px;
    }
    & + .c-unit{
           margin-top: 70px;
    }
}


