@charset "UTF-8";
// extend
%usageTitle {
    display: block; @include subContsTitle;
}
%fontInherit {
    font-size: inherit; font-weight: inherit; letter-spacing: inherit; line-height: inherit; color: inherit;
}

/*** 공통 ***/
.customer {
    .page-tit {
        &.tit-bang {margin-bottom: 40px;}
        &+.bang-notice {
            margin-bottom: 80px;
        }
    }
    // .txt {display:block;@include normalTxt;}
    .cardList-wrap{padding: 0;}

    //고객상담 리스트
    .counseling-list{
        .group-tit{
            @include subContsTitle; display: flex; align-items: center; gap:10px;
        }
        .counseling-list-icoWrap{
            display: flex; width:100%; justify-content: space-between; gap:20px; margin-top: 30px;
            li{
                width:calc(50% - 15px);  text-align: center;
                a{
                    display: block; padding:36px; border:1px solid $gray02; border-radius:10px; transition: all 0.3s;
                    .ico-img{
                        width:100px; height:100px; margin:0 auto;
                    }
                    .ico-tit{
                        margin-top:36px; @include mainTxt;
                    }
                    &:active,&:hover{
                        border:1px solid $black;
                        .ico-tit{
                            color:$purple;
                        }
                    }
                }
            }
        }
        .banner-img{
            margin-top:50px; display: flex; gap:28px;
            a{
                display: block; width:100%;
                img{
                    width:100%; display: block;
                }
            }
        }
        .btn-list{
            margin-top: 70px; display: flex; width:100%; gap:20px;
            li{
                flex:1; width:30%;
                .btns{
                    width:100%; padding-left: 30px; @include backgroundImg('/assets/images/pc/icon/ico-arrow-right.svg', 97% 50%, 20px); background-color: $gray06; text-align: left;
                }
            }
        }
    }
    //ARS 안내
    .counseling-ARS{
        .page-desc{
            margin-bottom: 10px !important;
        }
        .call-list{
            display: flex; margin-bottom: 20px; border: 1px solid $gray02; border-radius: 10px;
            li{
                flex: 1; position: relative; padding: 23px 0; text-align: center;
                &:first-child{
                    &:after{
                        content: ''; position: absolute; right: -1px; top: 50%; transform: translateY(-50%); z-index: 1; width: 1px; height: 95px; background: $gray02;
                    }
                }
                img{
                    width: 80px;
                }
                span{
                    display: block; margin-top: 10px; @include mainTxt;
                }
            }
        }
        .ars-box{
            margin-bottom: 50px; padding: 30px; background-color: $bg; border-radius: 10px;
            > strong{
                display: block; margin-bottom: 10px; @include normalTxt;
            }
            .notification{
                li{
                    > ul{
                        li{
                            font-size: 16px; color: $gray04;
                        }
                    }
                }
            }
        }
        .img-wrap{
            width:100%; margin:0 auto;
            & > img{
                width: 100%; display: block;
            }
        }
        .icon-txt-card {
            display:flex;justify-content:space-between;gap:40px;padding:0;border:none;
            li {
                flex:1 1 40%;border:1px solid #ddd;padding:30px;border-radius:7px;
                a {display:flex;justify-content:flex-start;align-items:center;gap:30px;}
                img {width:80px;}
                p {
                    margin-bottom:0;align-self:center;
                    strong {
                        display:block;@include subContsTitle;
                        &+span {margin-top:10px;}
                    }
                    span {
                        display:block;@include normalTxt;
                        &+strong {margin-top:10px;}
                    }
                }
            }

        }
    }
    //신청서류 양식 받기

    //대리점 개설 상담 신청
    .counseling-agency-open{
        .inner{
           min-height: auto;
        }
        .page-tit{
            @include hidden;
        }
        .appli-ct{
            margin-bottom: 30px;
        }
        .box-textarea{
            height:500px;
            textarea{
                width:100%;
            }
        }
    }
    //매장 찾기
    .counseling-find-shop{
        .page-desc{
            margin-bottom: 10px;
        }
        .fix-unit-group{
            .notiflcation{
                margin-top: 10px;
            }
        }
        .service-list{
            display: flex;  gap: 30px; margin: 10px 0 70px 0;
            li{
                display: flex; align-items: center; flex-direction: column; flex: 1; padding: 40px 0; border: 1px solid $gray02; border-radius: 10px; text-align: center;
                img{
                    width: 80px;
                }
                dl{
                    margin-top: 20px;
                    dt{
                       margin-bottom: 10px; @include normalTxt;
                    }
                    dd{
                        @include defaultTxt;
                    }
                }
            }
        }
        .map{
            display: flex;
            .map-list{
                position: relative; width: 630px; margin-right: 20px; flex-shrink: 0;
                .box-tab{
                    .tab-btn-wrap{
                        margin-bottom: 50px;
                    }
                    .tab-contents-wrap{
                        .search-input-wrap{
                            margin-bottom: 50px;
                        }
                    }
                                            .address-list-wrap{
                            .result-txt{
                                margin-bottom: 20px; @include normalTxt;
                            }
                            .address-list{
                                border-top: 1px solid $gray02; border-bottom: 1px solid $gray02;
                                li{
                                    &.on{
                                        background: #f9f5ff;
                                        a{
                                            background-image: url('/assets/images/pc/icon/ico-location-wh.svg');
                                        }
                                    }
                                    a{
                                        display: block; padding: 20px 120px 20px 30px; @include backgroundImg('/assets/images/pc/icon/ico-location.svg', 520px 50%, 80px);
                                        strong{
                                            @include normalTxt;
                                        }
                                        p{
                                            margin: 10px 0; @include defaultTxt;
                                        }
                                        span{
                                            @include defaultTxt;
                                        }
                                    }
                                }
                            }
                        }
                        .box-select{
                            display: flex; gap: 20px; margin-bottom: 50px;
                            select{
                                flex: 1;
                            }
                        }
                    // .box-paging{
                    //     position: absolute; left: 0; bottom: 0;
                    // }
                }
            }
            .map-api{
                display: block; height:500px; position: sticky !important; top:100px;  width: 100%;
            }
            .box-paging{
                .paging-list{
                    padding: 0;
                }
            }
        }
    }
    //위치 정보 활용 동의
    .counseling-loaction-info-agree{
        .content{
            width:100%; margin-bottom:60px;
        }
    }
    //지점보기
    .counseling-store-map{
       .place-address{
            dl{
                padding-right:0;
            }
       }
       .location-map{
        width:100%; margin:40px 0; background: pink; min-height:695px; border-radius:20px;
       }
    }
    //app 소개
    .app-presentation {
        .app-guide-box {
            display:flex;justify-content:space-between;align-items:center;border:1px solid $gray02;border-radius:10px;
            .guide {
                padding:50px;
                .tit {@include contsTitle;}
                .txt {margin-top:10px;@include defaultTxt;}
                .box-btn {display:flex;justify-content:flex-start;gap:0 10px;margin-top:30px;}
            }
        }
        .notification-wrap {margin-top:30px;}
    }

    //분실신고
    .reward-loss-report{
        .cardList-wrap{
            & + .cardList-wrap{
                 margin-top:40px;
            }
            .txt {
               margin-bottom:20px; font-size:16px;
               .btn-text-right-s {margin-left:20px;}
            }

        }
        .report-state{
            margin-top: 80px;
            .report-tit{
                .title{@include subContsTitle; margin-bottom: 0;}
                .desc{
                    margin-top: 10px;
                }
                .bang-notice{
                    margin-top: 30px
                }
                &.page-tit{
                    margin-bottom: 40px;
                }
            }
            .btn-list{
                li{
                    & + li{
                        margin-top: 30px;
                    }
                }
                &.mb40{
                    margin-bottom: 40px;
                }
            }
            .notification{
                margin:40px 0;
            }
        }
        .lost-regist{
            margin-top: 50px;
            .sub-contents-title{
                margin-bottom: 10px;
            }
            .notification-wrap{
                margin-top: 50px;
            }
            .link-list{
                margin-top: 10px; flex-wrap: nowrap;
                li{
                    max-width:310px;
                }
            }
        }
        .btn-group{
            margin-top: 50px;
        }
    }
    //휴대폰 분실 신고 -step
    .reward-loss-report-step{
        .input-wrap{
            & + .input-wrap{
                margin-top:40px;
            }
        }
        &.step02{
            .btn-time-group{
                display: flex; justify-content: space-between; align-items: center;
                .box-btn{
                    width:292px
                }
            }
            .btn-text-arrow{
                margin-top: 60px;
            }
        }
        &.step03{
            .accordian{
                margin-top: 80px;
            }
        }
    }

    //휴대폰 분실 신고 완료
    .reward-loss-report-finish{
        .popup-body{
            padding-bottom: 30px !important;
        }
        h2 {display:block;margin-bottom:30px;@include subContsTitle;}
        .cardList-wrap{
            margin-bottom: 50px;
        }
        .link-list {
            margin-top: 10px; width: 100%;
            li{width: 48%;}
        }
        .notification {
            margin-top: 50px;
            li{
                display: block !important;
            }
        }
        .noti-desc{
            @include defaultTxt;
            span{
                display: block; margin-top: 10px;
                @extend %fontInherit;
            }
        }
    }

    // 분실폰 위치 찾기
    .reward-phone-find {
        .map-wrap {
            display:flex; gap: 30px;
            .map {
                width:880px;height:525px;border-radius:7px; overflow: hidden;
            }
            .info {
                position:relative;
                dl {
                    &+dl {margin-top:40px;}
                    dt {display:block;@include normalTxt;}
                    dd {
                        display:block;margin-top:10px;@include defaultTxt;
                        b {font-weight:600;}
                        p{
                            @extend %fontInherit;
                            span{@extend %fontInherit;}
                        }
                    }
                }
                .map-guide{
                    margin-top: 16px; @include defaultTxt; color: $gray04;
                }
            }
        }
        .service-info {
            margin-bottom:50px;@include defaultTxt;
            strong {display:block;margin-bottom:10px;@include mainTxt;}
        }
        .box-table{
            .table-title{
                margin-bottom: 10px;
            }
            .table-type-04{
                * {text-align: center;}
                tbody{
                    td{
                        &:last-of-type {
                            @include defaultTxt;
                        }
                    }
                }
            }
        }

        .txt {            
            .btn-text-right-s {margin-top:10px;}
        }

        .mt50{margin-top: 50px;}

        /*.phone-num{
            border: 2px solid $gray02; padding: 32px 30px; border-radius: 14px; @include defaultTxt; font-weight: 400;
        }
        .notification{margin-top: 20px;}
        .contents-wrap{
            .default-txt{display: block; margin-bottom: 20px;}
            & ~ .contents-wrap{margin-top: 40px;}
            &.table-area {
                margin-top: 100px;
                .table-list-wrap{
                    .table-list {
                        th{
                            &:first-of-type{border-right: 2px solid $gray02;}
                        }
                        td{
                            padding: 20px 0;
                            &:first-of-type{border-right: 2px solid $gray02;}
                        }
                    }
                }
                & + .box-btn {
                    margin-top: 60px; display: flex; justify-content: center;
                }
            }
        }
        .map-wrap{
            margin-top: 60px; height: 380px; border-radius: 20px; overflow: hidden; background-color: $gray03;
        }
        .no-result {margin-top: 80px;}*/
    }
    //분실 신고 해제
    .reward-cancel-regi{
        section{
            margin-top:50px;
            .sub-contents-title {
                margin-bottom: 10px;
            }
        }
        .unregi {
            margin-top:50px;
            h2 {@include mainTxt;}
            .txt {margin:14px 0 0;@include defaultTxt;}
        }
        .btn-group {margin-top:50px;}
        .notification-wrap {
            margin-top:50px;
            .link-list {
                flex-wrap: nowrap; margin-top: 10px;
                li{
                    max-width: 310px;
                    a{
                        padding: 20px; min-width: 310px; gap: 0;
                    }
                }
            }
        }
        .cardList-wrap{
            .cardList{
                dl{
                    width: 50%;
                    dd{width: 50%;}
                }
            }
        }
    }
    .reward-cancel-agree{
        .agree-info {margin-bottom:10px;@include normalTxt;}
        .third-party-agree {
            margin-bottom:40px;padding:30px;border-radius:7px;border:1px solid $gray02;
            .tit {display:block;margin-bottom:10px;@include normalTxt;}
            .list {
                margin-bottom:20px;
                li {
                    @include defaultTxt;
                    &+li {margin-top:5px;}
                }
            }
            .txt {margin:0;@include defaultTxt;}
        }
        .btn-check {
            input[type=checkbox] {
                &:checked {
                    &+label {@include normalTxt;}
                }
            }
        }
    }
}

//신청 서류 받기
.counseling-document{
    .downLoad-list{
        li{
            a{
                height:103px;
            }
        }
    }
    .info-txt{
        a{margin-bottom:20px;display: flex; align-items: center; gap:10px;}
    }
}

//법인가입문의
.counseling-legally-admitted {
    margin-top: 0 !important;
    .guide-bx {
        margin: 20px 0 50px 0;
        .txt-sub { font-size: 16px; line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #666;}
        .line-bx {
            margin-bottom:10px; padding:20px; border-radius: 10px; border: solid 1px #ddd;
            .txt {font-size: 16px; line-height: 1.5; letter-spacing: -0.32px; text-align: left;color: #666;}
            .age-chk {
                margin-top:20px; padding-top:20px; border-top:1px solid #ddd;
                .btn-check {
                    & + .btn-check {margin-top:20px;}
                }
            }             
        }
    }

    .btn-text-line{
        margin-top: 30px;
    }
    .download-list{
        display: flex; align-items: stretch; gap: 30px; margin: 10px 0;
        li{
            flex: 1; border: 1px solid $gray02; border-radius: 10px;
            a{
                display: flex; align-items: flex-start; align-content: flex-start; gap: 25px; height: 148px; padding: 25px;
                img{
                    width: 48px;
                }
                > div{
                  p{
                    margin-bottom: 12px; line-height: 1.2;
                   }
                  span{
                    display: flex; align-items: center; gap: 10px; @include smallTxt; text-decoration: underline;
                  }
                }
            }            
        }
        &.col03{
            li {
                a{
                    min-height:195px; padding:20px; gap:10px; align-items: flex-start;flex-direction: column;
                    .ico-dw {align-items: flex-end;line-height:1.2;}
                }
            }
        } 
        &+.txt-sub{
            margin-bottom: 50px;
        }
    }
    .rq-form{
        > .box-input{
            &:first-of-type{
                margin-top: 0;
            }
        }
        .input-file{
            margin-top: 40px;
            .normal-txt{
                margin-bottom: 16px;
                .btn-dcmt-guide {
                    display:inline-block;width:121px;height:26px; line-height:26px;border-radius:100px; background-color: #efd9ff; text-align: center;
                    span {
                        position:relative;padding-right:10px; font-size:14px; font-weight: 600; line-height: 1.14; letter-spacing: -0.28px; text-align: left; color: #191919;
                        &::after {content: '';position: absolute;right:-5px;top:50%; width:15px; height:15px; margin-top:-7px;  background: url('/assets/images/pc/icon/ico-arrow-right.svg') no-repeat 50% 100%; background-size:15px;}
                    }
                }
            }
            .file-menu{
                margin-top: 10px;
                li{
                    display: flex; align-items: center;
                    span{
                        @include normalTxt01;
                    }
                    .ico-delete{
                        margin-left:10px;
                    }
                }
            }
        }
        .box-flex{
            width:100%;
        }
        .plan-select{
            .select-group{
                display: flex; gap: 20px; margin-top: 0;
                select{
                    flex: 1;
                }
            }
        }
        .phone-select{
            .select-group{
                display: flex; gap: 20px; margin-top: 0;
                .nice-select{
                    width: 145px;
                    &:last-of-type{
                        width: 302px;
                    }
                }
            }
        }
        .notification{
            margin-top: 10px;
            li{
                display: block !important;
            }
        }
        div.radio-phone{
            display: none;
        }
        p.radio-new{
            display: none;
        }
    }
    .info-txt{margin-top: 10px; @include defaultTxt; line-height: 24px;}
    .nice-select{
        & + .nice-select{
            margin-top: 10px;
        }
    }
}

.layer-popup {
    .counseling-legally-admitted{
        .guide-bx {
            margin:0;padding:0;
            .tit {margin-bottom:10px; font-size: 16px; font-weight: 600; line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #191919;}
        }
        &.end{
            p{
                line-height: 1.5;
                &+p{margin-top: 10px;}
            }
        }
    }
}

//제휴 문의
.counseling-store-inquiry{
    .underLine{
        display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; gap: 30px; margin: 10px 0 50px 0;
        li{
            display: flex; align-items: center; flex: 1 1 40%;
            border:1px solid $gray02; gap:20px; width:150px; height: 120px; border-radius: 10px;
            > div{
                display: flex; align-items: center; gap: 20px; padding-left: 30px;
                .ico-img{
                    display: inline-flex; width:60px; height:60px;
                }
                strong{
                    @include normalTxt01;
                }
            }
        }
    }
    .rq-form{
        .input-depth{
            gap: 20px;
            > .box-input{
                margin-top: 0;
            }
        }
    }
}
// 보낼 문자 확인 팝업
.send-sms-pop {
    .round-box {
        padding:30px;border:1px solid $gray02;border-radius:7px;text-align:left;
        .notification {
            margin-top:5px;
            li {
                display: block !important;margin-bottom:0;
            }
        }
    }
}

.search-ing {margin-top:30px;@include defaultTxt;}

.agree-area {
    .txt {display:block;margin-bottom:80px;@include normalTxt;}
}
.phone-reward {
    .phone-num {display:block;margin-bottom: 30px;@include normalTxt; font-weight: 400;}
}
.customer-protect {
    padding-bottom:60px !important;
    .page-tit {
        &+img {
            &+h2 {margin-bottom:16px;@include mainTxt;}
        }
    }

    .protect-visual {
        height:220px;background-position:center;background-size:auto 100%;background-repeat:no-repeat;
        .visual {
            display:flex;flex-direction:column;justify-content:center;width:1280px;height:220px;margin:0 auto;
            .title {display:block;@include mainContsTitle;color:$white;}
            .desc {display:block;margin-top:10px;@include normalTxt01; color:$white;}
            .txt {display:block;margin-top:10px;@include defaultTxt;color:$white;}
        }
    }
    &.spam{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/spam-visual.jpg', center center);
        }
    }
    &.smishing{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/smishing-visual.jpg', center center);
        }
    }
    &.voice{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/voice-visual.jpg', center center);
        }
        .app_list{
            margin-top: 10px;
        }
    }
    &.tm{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/tm-visual.jpg', center center);
        }
    }
    &.pharming{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/pharming-visual.jpg', center center);
        }
    }
    &.qshing{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/qshing-visual.jpg', center center);
        }
    }
    &.alter{
        .protect-visual{
             @include backgroundImg('/assets/images/pc/subpage/alter-visual.jpg', center center);
        }
    }
    &.communication{
        .notification{
            &.etc{
                li{
                    font-size:20px; font-size:#191919;
                    &::before{top:14px;}
                }
            }
        }
    }
    .protect-img-info{
        padding:40px; background-color:#fafafa; border-radius:10px; display:flex; align-items:center;
        img{width:80px; height:auto; margin-right:20px;}
        .unit-tit-b{margin:0 0 10px;}
    }
    .next-box{
        display:flex;
        li{
            flex:0 1 50%; border:1px solid #ddd; border-radius: 10px; padding: 40px 0; text-align:center; font-size:20px; font-weight:bold; color:#191919; position:relative; margin-right:80px;
            &:after{content:''; display:block; width:20px; height:20px; position:absolute; top:50%; right:-50px; transform:translateY(-50%); background:url(/assets/images/pc/icon/ico-arrow-thin-right.svg) no-repeat center; background-size: 100%;}
            &:last-child{
                margin:0;
                &::after{display:none;}
            }
            
        }
    }
    .balloon-list {
        display:flex;flex-wrap:wrap;gap:30px 50px;margin-bottom:70px;
        p {
            flex:0 1 300px;height:186px;padding:30px 30px 60px;border-radius:10px;@include defaultTxt;color:$black;
            @include backgroundImg('/assets/images/pc/subpage/img-balloon-purble.png', left top, 300px 186px);
        }
    }
    .app_list {
        display:flex;justify-content:flex-start;gap:28px;
        li {
            display:flex;flex:1 1 30%;padding:30px 29px 30px 30px;border:1px solid $gray02;border-radius:10px;
            .app-img {display:block;overflow:hidden;width:60px;height:60px;}
            dl {
                display:flex;justify-content:center;flex-direction:column;position:relative;width:calc(100% - 60px);padding-left:20px;
                dt {display:block;margin-bottom:10px;@include normalTxt;}
                dd {display:block;@include defaultTxt;}
            }
            .app-link {
                display:flex;justify-content:flex-end;position:absolute;right:0;top:0;
                a {display:block;overflow:hidden;width:20px;height:20px;margin-left:6px;}
                img {width:20px;height:20px;}
            }
        }
    }
    .action-box {
        margin:10px 0;padding:20px;border:1px solid $gray02;border-radius:7px;
        .unit-tit-s {color:$purple;}
        .notification {
            li {
                .txt-bk-b {display:block;margin-top:10px;}
            }
        }
    }
    .thumb-info-list {
        &>li {
            display:flex;justify-content:flex-start;align-items:flex-start;gap:0 50px;
            .thumb-img {width:350px;height:220px;border-radius:20px;}
            .thumb-info {
                width:calc(100% - 400px);
                .mark {display:block;margin-bottom:10px;}
                .unit-tit-m {
                    margin-top:0;
                    a {font-size:20px;}
                }
            }
            &+li {margin-top:50px;padding-top:50px;border-top:1px solid $gray02;}
        }
        .guide {
            display:flex;justify-content:flex-start;align-items:flex-start;margin-top:30px;
            dt {
                position:relative;width:135px;@include normalTxt;
                &::after {content:'';display:block;position:absolute;right:0;top:0;width:1px;height:19px;border-right:1px dotted $black;}
            }
            dd {
                width:calc(100% - 135px);padding-left:30px;box-sizing:border-box;
                ul {display:flex;justify-content:flex-start;gap:10px 50px;flex-wrap:wrap;}
            }

        }
    }
    .thumb-list {
        // overflow: hidden;
        display: flex; flex-wrap: wrap; gap: 70px 30px;
        li {
            position:relative; width: calc(33.33% - 20px); padding-bottom: 30px;
            &:nth-child(3n+1){
                margin-left: 0;
            }
            &:nth-child(-n+3) {
                margin-top: 0;
            }
            .date {
                display:block;margin:10px 0 0; @include defaultTxt;
            }
            a{
                display: block;
                img{
                    width: 100%; height: 250px;
                }
                p{
                    margin-top: 20px;
                    strong{
                        @include mainTxt; font-weight: 700;
                        @include ellipsis2;
                    }
                    .date{
                        position: absolute; bottom: 0;
                    }
                }
            }
            img {border-radius:10px;}
        }
        &.movie-list{
            li{
                a{
                    &:after{
                        content: ''; position: absolute; left: 50%; top: 75px; transform: translateX(-50%); width: 100px; height: 100px; @include backgroundImg('/assets/images/pc/icon/ico-cir-play.png', 0, 100%);
                    }
                }
            }
        }
    }
    .bbs-desc{
        display: flex; gap: 20px; margin-bottom: 50px;
        dt{
            @include normalTxt;
        }
        dd{
            a{
                @include defaultTxt; color: #4673f1; text-decoration: underline;
            }
        }
    }
    .movie-damage-prevention {
        padding-top:50px;
        .thumb-list {margin-bottom:79px;}
        .box-btn {margin-bottom:40px;}
        .bang-notice{
            .tit {display:block;margin-bottom:9px;@include smallTxt; font-weight: 600;}
            &.style-none {
                padding-left:0;background:none;@include defaultTxt;
                &::before {display:none;}
                .tit {@include normalTxt;}
                a {@include defaultTxt;}
            }
        }
    }
    // 웹툰 페이지를 위해 커스텀
    &.protect-damage-webtoon{
        .thumb-list{
            display: flex; flex-wrap: wrap; gap: 30px;
            li{
                width: 232px; margin: 0;
                a{
                    img{
                        width: 100%; height: 186px;
                    }
                    strong{
                        display: block; margin-top: 10px; @include normalTxt;
                    }
                }
            }
        }
    }

    .img-round {border-radius:10px;}
    .inner-img {vertical-align:middle;}
    .tit-ico {width:20px;height:20px;margin-right:20px;vertical-align:middle;}
    .txt-hyphen {margin-top:10px;color:$gray04;}

    .next-list {
        display:flex;
        &>li {
            position:relative;text-align:center;
            &+li {
                &::before {
                    content:'';display:block;position:absolute;top:50%;width:40px;height:40px;margin-top:-20px;
                    @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right.svg', center, auto 20px);
                }
            }
           .txt {margin-top:20px;color:$black;}
        }
        //개별
        &.img150 {
            &>li {
                &+li {
                    padding-left:113px;
                    &::before {left:37px;}
                }
            }
        }
        &.img200 {
            &>li {
                &+li {
                    padding-left:200px;
                    &::before {left:80px;}
                }
            }
        }
        &.img220 {
            &>li {
                &+li {
                    padding-left:107px;
                    &::before {left:33px;}
                }
            }
        }
    }

    //각 페이지별 조정
    &.illegal-protect {
        .page-desc {
            p {color:$black;}
        }
        .img-diagram {overflow:hidden;margin-top:10px;font-size:0;}
        .thumb-info-list {
            margin-top:50px;
            .auth-group {
                display:inline-flex;justify-content:flex-start;align-items:center;margin-top:-2px;@include defaultTxt;vertical-align:middle;
                img {
                    margin:0 9px 0 20px;
                    &:first-of-type {margin-left:0;}
                }
            }
            .unit-link {margin-top:30px;}
        }
    }
    &.spam {
        .topGray-card-wrap {
            display:flex;gap:0 30px;margin-top:10px;
            dl {flex:0 1 30%;}
        }
        .unit-box-img {
            .wrap {
                &+.wrap {margin-top:50px;}
            }
        }
        .app_list{
            margin-top: 10px;
        }
    }
    &.smishing {
        .balloon-list {
            .add-info {display:flex;align-items:center;}
        }
        .unit-box-img {
            position:relative;
            .reference {position:absolute;right:50px;top:60px;text-align:right;}
        }

    }
    &.school {
        .list-num {
            border-top:2px solid $black;border-bottom:1px solid $gray02;
            &>li {
                padding:20px;
                &+li {margin-top:0;border-top:1px solid $gray02;}
                .tit {margin:-20px -20px 20px;padding:22px 20px;background:$gray01;}
            }
        }
        /*h2 {
            a {@include contsTitle;background-size:10px 18px;}
        }
        .txt-dot {
            margin-bottom:10px;
            @include defaultTxt;
            &.style-none {
                padding-left:0;background:none;@include defaultTxt;
                &::before {display:none;}
            }
            &+.bang-notice {margin-top:0;}
        }
        .bang-notice {
            &.style-none {
                padding-left:0;background:none;@include normalTxt;
                &::before {display:none;}
            }
        }
        .list-num {
            border-top:2px solid $black;border-bottom:1px solid $gray02;
            li {
                padding:20px 20px 10px;
                &+li {margin-top:0;border-top:1px solid $gray02;}
                .tit {margin:-20px -20px 20px;padding:22px 20px;background:$gray01;}
                .txt-dot {margin:0 0 10px;}
            }
        }
        .link-box {margin:0 0 0 10px;}*/
    }
    .training-list {
        border-top:1px solid #191919;    
        li {
            position:relative; height:60px; line-height: 60px; padding-left: 40px; border-bottom: 1px solid #ddd; font-size: 16px; letter-spacing: -0.32px; color: #191919;
            .txt-link {position:absolute; right:96px; top:0; font-size:14px; color:#191919; text-decoration: underline;}
            .txt-link::before {content:''; position:absolute; right:-20px; top:50%; width:20px; height:20px; transform: translateY(-50%); background: url('/assets/images/pc/icon/ico-arrow-right02.svg') no-repeat 0 0;}
        }     
    }

}
.customer-list {
    .box-input {margin-bottom:40px;}
}

//휴대폰 분실/파손 보상
.my-fix-wrap {margin-bottom:50px;}
.reward {
    h2 {display:block;margin:70px 0 10px;@include contsTitle;}
    h3 {
        display:block;margin:50px 0 10px;@include mainTxt;
        & + .notification{
            margin-bottom: 10px;
        }
    }

    .box-gy {padding:40px;border-radius:7px;border:solid 1px $gray02;}
    .box-fill-gy {padding:40px;border-radius:7px;background:$gray01;}
    .bang-notice {line-height:1.33;}
    .txt {@include defaultTxt;line-height:1.5;}
    .img-list {
        display:flex; flex-wrap:wrap; justify-content: space-between; gap:30px;
        & > li {
            display:flex;justify-content:flex-start;align-items:center;flex:1 1 40%; border:1px solid $gray02; padding:40px;  border-radius:7px;
            .img {display:block;width:80px;height:80px;margin-right:20px;}
            .info {
                .tit {@include mainTxt;}
                .txt {margin:10px 0 0;@include defaultTxt;}
            }
        }
        &.arro-rt {
            display:flex;flex-wrap:wrap;gap:20px;padding:0;border:none;
           & > li {
                display:flex;flex:1 1 40%;border-radius:7px;border:solid 1px $gray02; padding: 0;
                ::before {
                    content:'';display:block;position:absolute;right:40px;top:50%;width:20px;height:20px;margin-top:-10px;
                    @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right.svg', center center, 20px 20px);
                }
                a {display:flex;align-items:center;position:relative;width:100%;padding:40px 60px 40px 40px;}

            }
            &.img-wd{
                .img{
                    width:200px; margin-right: 10px; display: inline-flex; align-items: center;
                }
            }
        }
        &.device-link {
            display:flex;justify-content:flex-start;border-radius:7px;border:solid 1px $gray02;
            li {
                display:flex;flex:1;flex-direction:column; border:none;
                .img {
                    display:block;width:100%;margin-bottom:20px;text-align:center;
                    img {width:208px;height:80px;}
                }
                .info {
                    .tit {display:block;@include mainTxt;text-align:center;}
                    .txt {@include normalTxt01;text-align:center;}
                    .num {margin-top:20px;@include mainTxt;color:$gray05;font-weight:400;;text-align:center;}
                    &+a {margin-top:20px;}
                }
                .btn-device {height:40px;line-height:40px;}
            }
        }
    }
    .btns {
        &.black {border:solid 2px $black;background:$white;color:$black;}
    }
    .loss-receipt {
        height: 289px; padding: 50px 0 0 50px; @include backgroundImg('/assets/images/pc/subpage/img-reward-loss.png', 100% 50%, auto); border: 1px solid $gray02; border-radius: 10px;
        h2 {margin-top:0;}
        .txt {margin-top:0;}
        &+.appli-group-wrap {margin-top:30px;}
        .notification {
            li {
                .btn-text-right-s {margin-left:20px;}
            }
        }
    }
    .destroy-receipt {
        h2 {margin-top:0;}
        .txt {margin-top:0;}
    }
    .appli-group-wrap {
        display: flex; padding:40px;border:1px solid #ddd;border-radius:7px;
        h3 {
            margin-top:0;
        }
        .appli-01 {
            position: relative; padding-right:82px;
            &:after{
                content: ''; position: absolute; right: -1px; top: 50%; transform: translateY(-50%); width: 1px; height: 114px; background-color: $gray02;
            }
            & + .appli-02{
                margin-left: 83px;
            }
        }
        .appli-02 {
           .cs{
                display: flex; align-items: center;
                img{
                    width: 80px; margin-right: 20px;
                }
                p{
                    strong{
                        display: block; margin-right: 50px; @include normalTxt;
                        &+strong{
                            margin-top: 10px;
                        }
                    }
                }
                dl{
                    display: flex; margin-bottom: 10px;
                    dt{
                        margin-right: 20px; @include normalTxt;
                    }
                    dd{
                        @include normalTxt;
                    }
                }
           }
        }
    }
    .table-list-wrap{
        .table-list{
            border-top:0;
            th{
                background-color: $gray06;
            }
            td{
                @include defaultTxt;
                &:first-child{
                    @include normalTxt01;
                }
            }
        }
    }

    .repair {
        b {@include mainTxt;}
    }
    .box {
        &-log {
            display:flex;justify-content:space-between;
            b {@include mainTxt;}
            p {margin:10px 0 0;@include defaultTxt;}
            .btn-group {
                display:flex;justify-content:flex-end;align-items:center;gap:20px;
                a {width:260px;}
            }
        }
        &-login {
            dt,dd {align-self:center;}
        }
    }
    .purple {
        border:1px solid $purple;color:$purple;
        &:hover {border:1px solid $purple;color:$purple;}
    }
    .box {
        &-callcenter, &-insurance {
            position:relative;border-radius:7px;border:solid 1px $gray02;
            b {display:block;margin-bottom:10px;@include normalTxt01}
            strong {display:block;@include subContsTitle;}
            .txt {margin:10px 0 0;}
            &::after {content:'';display:block;position:absolute;right:36px;top:50%;width:20px;height:20px;margin-top:-10px;}
        }
        &-callcenter {padding:50px 40px 40px 120px; @include backgroundImg('/assets/images/pc/icon/ico-phone-call.svg', 20px center); background-size:80px 80px;}
        &-insurance {
            display: flex; align-items: center;
            a{
                padding:52px 40px; display: block; height: 100%; width: 100%;
            }
            &::after {@include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right.svg', center center, 20px 20px);}
        }
    }
    .request-compensation {
        h3 {
            &+.txt {margin-bottom:20px;}
        }
        .wrap {
            display:flex;flex-wrap:wrap;gap:20px;
            &>div {flex:1 1 40%;}
        }
    }

    .txt {
        &+.phone-used-info {margin-top:20px;}
    }
    .phone-used-info{
        & + .notification-wrap{
            margin-top: 20px;
            h4 {@include normalTxt;}
        }
    }
    .appli-way-step{
        &+.notification {
            margin-top:10px;
        }
    }
    .amount-money {
        display:flex;flex-wrap:wrap;gap:20px;margin-top:20px;
        .reward-card-wrap {
            flex:1 1 40%;
            dl {
                padding:40px;
                dt {
                    padding:0;background:none;
                    strong {@include normalTxt; display: block; margin-bottom: 10px;}
                }
                dd {
                    padding:30px 0 0;
                    .txt {@include defaultTxt;}
                }
            }
            .wrap {
                display:flex;
                b {margin-right:60px;@include subContsTitle;}
            }
            .txt {margin-top:10px;@include defaultTxt;}
        }
    }
}

.customer {
    .self-serive {
        width:1280px; margin:0 auto;
        .group-tit {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
        .selfserive-view {
            .box-tab {margin:50px 0;}
            .service-box {
                display: flex;gap:50px; padding:70px 0 0 60px;border-radius: 10px; background:#f2f3f8;
                .img-bx {
                    img {vertical-align: bottom;}
                }
                .txt-bx {margin-top:40px;}
                .tit {margin-bottom:18px; font-size: 36px;font-weight: bold;line-height: 1.19;letter-spacing: -0.72px;text-align: left; color: #191919;}
                .txt { font-size: 20px; font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                .box-btn {
                    margin-top:30px;
                    .btns + .btns {margin-left:8px;}
                    .btns.white {border-radius: 7px; border: solid 1px #ddd; background-color: #fff;font-size: 16px; font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #666;}
                    .btn-text-right + .btn-text-right {margin-left:30px;}
                    & + .box-btn {margin-top:20px;}
                }
                .notification-wrp {
                    margin-top:60px;
                    .noti-tit {display: block; margin-bottom:10px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                }
                & + .service-box {margin-top:30px;}
            }
        }
    }
}