    //제휴카드 할인
    .use-card{
        //상세
        &-detail {
            .card-thumb{
                display:flex;justify-content:flex-start;align-items:center;margin-bottom:70px;padding:24px 0;
                .card-thumbnail{
                    display:flex; justify-content:center; align-items:center; width:500px; height:340px;
                    img {border-radius:15px;box-shadow:20px 20px 40px 0 rgba(0,0,0,0.16);}
                    
                }
                .card-txt{
                    width:calc(100% - 500px);box-sizing:border-box;padding:0 80px 0 70px;
                    .main-contents-title{
                        font-weight: 900; margin-bottom: 10px; display: block;
                    }
                    .notification{
                        li{
                            font-weight: 100;
                        }
                    }
                    .box-table{
                        .btn-text-right-s{
                            display: flex; justify-content: end; margin-bottom: 10px;
                        }
                    }
                }
            }
            .c-unit{
                .c-tit{
                    @include contsTitle; margin-bottom: 10px;
                }
                .c-desc{
                    @include defaultTxt;
                    & + .btn-text-right-s{
                        margin-top: 20px;
                    }
                }
                .notification{
                    margin-top: 10px;
                    li{
                        strong{
                            @include mainContsTitle; font-size: 22px;
                            &.sm{
                                font-size: 16px;
                            }
                        }
                    }
                }
                .num-process-wrap{
                    li{
                        padding-top: 40px;
                        &:first-of-type{
                            .txt-wrap{
                                 @include backgroundImg('/assets/images/jehyu/ico-pay-charge.svg', top center, 80px 80px);padding-top: 90px;
                            }
                        }
                        &:nth-of-type(2){
                            .txt-wrap{
                                @include backgroundImg('/assets/images/jehyu/ico-phone-open.svg', top center, 80px 80px); padding-top: 90px;
                            }
                        }
                        &:nth-of-type(3){
                            .txt-wrap{
                                  @include backgroundImg('/assets/images/jehyu/ico-performance.svg', top center, 80px 80px);padding-top: 90px;
                            }
                        }
                    }

                }
            }

            .round-gy{
                border:1px solid $gray02; border-radius: 10px; padding:70px; display: flex;
                .c-tit{
                    padding-right: 80px;
                }
                .graph-wrap{
                    position: relative;padding-left: 80px; width: calc(100% - 450px);
                    .graph-tit{
                        @include subContsTitle;
                    }
                    .graph-layout{
                        margin-top: 70px; margin-left: 66px;
                        ul{
                            li{
                                position: relative; display: flex; gap:20px; align-items: center;
                                .count{
                                    @include mainTxt; color:$gray05; width: 123px;flex-shrink: 0;
                                }
                                .graph{
                                    position: relative; width:100%;
                                    span{
                                        display: flex; align-items: center; padding-left: 30px; height: 80px; border-radius: 10px; width:100%; @include subContsTitle; flex-wrap: wrap;
                                        &.light{
                                            background-color: #ece7f3;
                                        }
                                        &.dark{
                                            background-color: $purple;@include subContsTitle; color:$white;
                                        }
                                    }
                                    &.w-sm{width: 65%;}
                                    &.m-sm{width: 50%;}
                                    &.x-sm{width: 35%;}
                                }
                                &.pro{
                                    .light{
                                        & + .dark{
                                            position: absolute; top:0; right:0; border-radius: 0 10px 10px 0;width: 40%;
                                            justify-content: center; padding: 0;flex-direction: column;
                                            em{
                                                 @include mainTxt; color:$white; display: block;
                                            }
                                        }
                                    }
                                    .count{
                                        &::before{
                                            content: '프로모션';display: block;background: $purple; padding: 7px 18px; text-align: center;@include chipTxt; width: 60%; border-radius: 20px; margin-bottom: 10px;
                                        }
                                    } 
                                    .count.txt-val01{
                                        width:180px;
                                        &::before{
                                            content: '전월 국내 가맹점 이용실적';display: block;width:90%;background: $purple; padding: 7px 10px; text-align: center; border-radius: 20px; margin-bottom: 10px;
                                        }
                                    }
                                    .count.txt-val01.wori-typ02{
                                        width:120px;
                                        &::before{
                                            content: '전월실적';display: block;width:90%;background: $purple; padding: 7px 10px; text-align: center; border-radius: 20px; margin-bottom: 10px;
                                        }
                                    }
                                }
                                & + li{
                                    margin-top: 20px;
                                }
                                .graph.align-cntr {
                                    span{
                                        align-content: center;
                                        .sb-txt{
                                            width:100%;
                                        }
                                    }                                   
                                }
                            }
                        }
                    }
                    &::before{
                        content: ''; width:1px; height: 100%; background: $gray02; position: absolute; top:0; left:0;
                    }
                }
            }
            .average-txt{
                display: inline-block; position: absolute; top:-70%; right:-40px; padding: 8px 20px; border-radius: 30px;background-color:#ec008c;@include chipTxt;text-align: center; font-weight: 600;
                    &::after{
                    content: ''; display: block;width:14px;height: 14px;
                    position: absolute; bottom: -5px; left: 50%; transform: translateX(-50%) rotate(45deg); background-color: #ec008c;
                }
            }
            .table-type-04{
                .bg{
                    & > td {
                        color: $purple; font-weight: 700; background-color:#fff8ff;
                    }
                }
            }
        }
        //카드혜택 한눈에 보기
        &-benefit{
            .card-benefit-list {
                li {
                    &+li {margin-top:50px;}
                    .card-thumb {
                        display:flex;justify-content:flex-start;align-items:center;gap:0 30px;margin-bottom:30px;
                        .card-img-wrap {width:120px;}
                        .card-info-wrap {
                            width:calc(100% - 120px);
                            .card-desc {
                                strong {@include mainTxt}
                            }
                        }
                    }
                    .box-table {
                        table {
                            tbody {
                                th {background:none;font-weight:normal;border-bottom:1px solid $gray02;}
                                td {color:$gray05;}
                            }
                        }
                    }
                }
            }
        }
    }