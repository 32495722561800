@charset "UTF-8";

/*** 전체 공통 ***/
%fontInherit {
    font-size: inherit; font-weight: inherit; letter-spacing: inherit; line-height: inherit; color: inherit;
}
.goodok{
    .info-bg{
        background:$lightpurple;
    }
    .info-wrap{
        display: flex; gap:70px;
        .info-img{
            width:504px;
        }
        .info{
            width:530px;  padding: 60px 0;
            .chip-wrap{
                margin-bottom: 20px;
                span.chip{
                    &:first-child{
                        background-color: #b728bc;
                    }
                    &:nth-child(2){
                        background-color: #5257a1;
                    }
                }
            }
            .title{
                @include mainContsTitle;
            }
            .discount-pay{
                @include mainContsTitle; color:$purple; margin-top: 10px; text-align: right;
            }
            .point{
                margin-top: 20px; @include mainTxt; font-weight: 400;
                .txt-vt{
                    @include mainTxt; font-weight: 600; color:$purple; margin-left: 10px;
                }
            }
            .box-btn{
                overflow: hidden; margin-top: 20px;
                button{
                    float: right;
                }
            }
        }
    }
    .about-service{
        width:800px !important; margin:70px auto;
        .box-btn{
            margin-top: 20px;
            .white{
                background: $gray06; color:$gray05;
            }
        }
        .accordian{
            .acc-trigger{
                margin-bottom: 0;
                .unit-tit{
                    @include contsTitle; text-align: left; margin-bottom:0;
                }

            }
            .acc-conts{
                margin-top: 50px; background: $white; padding: 0;
            }
        }
    }
}