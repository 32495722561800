@charset "UTF-8";

// extend
%usageTitle {
    display: block; @include subContsTitle;
}
%fontInherit {
    font-size: inherit; font-weight: inherit; letter-spacing: inherit; line-height: inherit; color: inherit;
}

// 내정보 공통
.my {
    .box-tab.tab-ctrl{
        .tab-btn-wrap{
            margin-bottom: 50px;
        }
    }
    .my-bundle-big{
        margin-top: 70px;
        & + .my-bundle{
            margin-top: 50px;
        }
    }
    .my-bundle{
        .main-txt{
            margin-bottom: 10px;
            & + p {
                @include defaultTxt;
            }
        }
        & + .my-bundle{
            margin-top: 50px;
        }
        .table-default2 + .table-default2 {margin-top:50px;}

        .cardList {
            .box-table {margin-top:30px;}
            .btns.w-xs {margin-left:20px; padding:0 10px;}
            .accordian {margin-top:30px;}
            .accordian .acc-trigger {padding:20px;border-top:1px solid #ddd;border-bottom:1px solid #ddd; font-size: 16px; line-height: 1.25; letter-spacing: -0.32px; text-align: left; color: #191919;}
            .accordian>.acc-trigger:after {right:20px;}
            dl {
                dd {
                     .box-table {
                        margin-top: 0;
                        .notification {
                            margin-top: 20px;
                          }
                     }
                     .box-btn {
                        .btn-text-right-s {
                            & + .btn-text-right-s {margin-left:15px;}
                        }
                     }
                }
            }
        }
    }
    // 접근권한 에러 페이지 공통
    .access-error{
        .btn-group {
            margin-top: 50px;
        }
        .info-case{
            margin-top: 70px;
        }
        .result-icon-wrap{
            background-image: url('/assets/images/pc/icon/ico-phone-wifi.svg');
            &.under-nineteen{
                background-image: url('/assets/images/pc/icon/ico-under-19.svg');
            }
            &.not-corp{
                background-image: url('/assets/images/pc/icon/ico-corp-no-data.svg');
            }
        }
    }
    .order-address-pop{
        .rq-form{
            .box-input{
                &:first-child{
                    margin-top: 0;
                }
            }
            .textarea-wrap{
                display: none;
            }
            .no-top{
                margin-top: 0;
            }
        }
    }
    //명의 변경
    .subscribe-name-change{
        .main-txt{
            margin-bottom: 10px;
            & + p {
                @include defaultTxt; margin-bottom: 10px;
            }
        }
        .num-process-wrap{
            & + .notification{
                margin-top: 10px;
            }
        }
        .contents-title{
            & + p{margin-top: 10px;}
        }
        .txt-wrap{
            p{
                @include mainTxt;
            }
        }
    }
    //명의변경 - 실명인증
    .subscribe-name-certi{
        .subtract{
            @include defaultTxt;
            display: flex; justify-content: center; align-items: center;
        }
        .btn-group{
            margin-top: 50px;
        }
    }
    .subscript-name-assignee{
        .agree-wrap {
            .btn-text-line{
               margin-top: 0;
            }
        }
    }
    //
    .subscript-name-assignee-new{
        .no-top{
            margin-top: 0;
        }
    }
    .subscribe-name-exist-phone{
        .rq-form{
            & + .rq-form{
                margin-top: 70px;
            }
        }
        .info-txt{
            margin-top: 10px;
        }
    }
    .subscribe-write-info{
        .info-account{display: none;}
        .btn-text-line{
            margin-top: 16px;
        }
    }
    //구독 중 서비스
    .subscribe-list {
        .info-area {
            .box-select {margin-bottom:50px;}
        }
        .card-board-info {margin-bottom:10px;text-align:right;}
        .cardList {
            &+.cardList {margin-top:30px;}
            .box-info {
                &>div {
                    display:flex;justify-content:space-between;align-items:center;
                    &.info-top {
                        margin-bottom:20px;
                        .payment-state {
                            @include normalTxt; color:$purple;
                            &.unpaid {@include normalTxt;color:$red;}
                        }
                        .chip-wrap{
                            .chip{
                                &:first-child{
                                    background: #b728bc;
                                }
                                &:nth-child(2){
                                    background: #5257a1;
                                }
                            }
                        }
                    }
                    &.info-product {
                        margin-bottom:10px;
                        &>strong {@include mainTxt;}
                        &>span {@include mainTxt;}
                    }
                    &.info-point {
                        &>strong {@include normalTxt;}
                        &>span {@include defaultTxt;}
                    }
                }
            }
            .date-info {
                margin-top:10px;padding:30px;border-radius:10px;background:$gray01;
                dt, dd {
                    padding-bottom:10px;
                    &:last-of-type {padding-bottom:0;}
                }
            }
            .box-btn {margin-top:30px;text-align:right;}
        }
    }
    .subscribe-detail {
        .tooltip-wrap {display:inline-block;vertical-align:top;}
        .cardList {
            dd {
                .purchase-info {
                    span {
                        display:block;@include normalTxt;
                        &+span {margin-top:5px;}
                    }
                }
                .btn-group {
                    justify-content:flex-start;margin-top:30px;
                    a {max-width:200px;}
                }
                .old-price {margin-right:10px;text-decoration:line-through;}
                .discount-price {@include normalTxt;}
                .pay-point {
                    @include normalTxt;
                    &+.txt {margin-top:10px;}
                }
                .underline-vt {font-weight:600;}
            }
        }
        .accordian-list{
            margin-top: 10px;
           & > li{
                border-top:1px solid $gray02; padding: 20px;
                &:last-child{
                    border-bottom: 1px solid $gray02;
                }
                .title{
                    display: flex; justify-content: space-between; align-items: center;
                    .status{
                        margin-right: 30px;
                    }
                }
                .status{
                    @include defaultTxt; color:$purple;
                }
                & > .acc-trigger{
                    margin-bottom: 0;
                }
                .acc-conts{
                    background: $white; padding: 0;
                    .cardList-wrap{
                        .cardList-title{
                            @include normalTxt;
                        }
                        .info{
                            @include defaultTxt; margin-bottom: 0;
                            .notification{
                                margin-top: 10px;
                            }
                        }
                        .mt30{
                            margin-top: 30px;
                        }
                        &:first-of-type{
                            margin-top: 40px;
                        }
                        &:last-child{
                            margin-bottom: 20px;
                        }
                    }
                    .money-right{
                        .main-txt{
                            @include mainTxt; margin-bottom: 0; padding-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .subscribe-purchase {
        .btn-group {
            margin-top:50px;
            .btns {width:190px;}
        }
    }
    .subscribe-payment {
        .cardList-wrap {
            &+.cardList-wrap {margin-top:50px !important;}
        }
        .cardList {
            .info-product {
                display:flex;justify-content:space-between;align-items:center;margin-bottom:40px;padding-bottom:40px;border-bottom:1px solid $gray02;
                &>strong {@include mainTxt;}
                &>span {@include subContsTitle;}
            }
            dd {
                p {
                    &+p {margin-top:10px;}
                }
                .box-btn {margin-top:30px;}
            }
            .cancel-guide {
                margin-top:40px;padding-top:40px;border-top:1px solid $gray02;
                .notification {
                    margin-top:10px;
                    &>li {display:block;}
                }
            }
        }
    }
    .subscribe-whole {
        .page-desc {
            margin-bottom:0 !important;padding-bottom:10px;border-bottom:1px solid $gray02;
            h2 {font-size:20px;}
        }
        .accordian {
            padding-top:20px;border-bottom:1px solid $gray02;
            &>.acc-trigger {
                &::after {right:40px;}
                p {padding:0 40px;color:$black;text-align:left;}
            }
            .acc-conts {
                .cardList {border:none;border-top:1px solid $gray02;border-radius:0;}
            }
        }
        .box-paging {
            margin-top:50px;
            .btn-text-more {
                font-size:16px;
                .count {
                    font-size:inherit;
                    &::after {width:13px;height:8px;}
                }
            }
        }
    }
    //최근본 상품
    .view-prodcuts-rate{
        .btn-group{
            margin-top: 50px;
            span{
                @include normalTxt; color:$white; margin-left: 5px;
            }
        }
    }
    .view-recently-phone{
        .btn-group{
            span{
                @extend %fontInherit; margin-left: 5px;
            }
        }
    }
    .puk-browse{
        .puk-num{color: $purple !important;}
        .cardList-wrap {margin-top:20px;}
        .step-process {
            &+.inner {padding-top:30px;}
        }
    }
    .communi-data{
        .box-input + .textarea-wrap{margin-top: 30px;}
        .btn-group {margin-top:50px;}
        .cardList-title {@include mainTxt;}
        .cardList-wrap {margin-top:20px;}
        .step-process {
            &+.inner {padding-top:30px;}
        }
        .notification-wrap {margin-top:80px;}
    }
    // 가입신청서 열람
    .appli-admission{
        .box-input{
            & + .textarea-wrap{margin-top: 30px;;}
        }
        .notification-wrap{
            margin-top: 80px;
        }
        .choose-appli{
            .normal-txt{display: block; margin-bottom: 16px;}
        }
        .box-table{
            margin-top: 0;
            .tit{margin-bottom: 10px;}
            .table-type-04 {
                th{
                    padding: 20px 0;
                    @include normalTxt; font-weight: 700;
                }
                td{
                    padding: 20px 0;
                }
                tbody{
                    .btn-radio-type2{
                        label{
                            strong{
                                display: block; flex-shrink: 0;@include defaultTxt;
                                span{display: inline-block; margin-right: 0;}
                                &:nth-of-type(1) {
                                    width: 10%; text-align: center;
                                }
                                &:nth-of-type(2) {
                                    width: 25%;
                                }
                                &:nth-of-type(3) {
                                    width: 20%;
                                }
                                &:nth-of-type(4) {
                                    width: 20%;
                                }
                                &:nth-of-type(5) {
                                    width: 25%;
                                }
                            }
                        }
                        input[type=radio] {
                            &:checked + label {
                                strong{
                                    color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .call-history-appli{
        .my-info {margin-bottom: 20px;}
        .rq-form {
            .ex {display: flex; justify-content: flex-end; padding-bottom: 10px; border-bottom: 1px solid $gray02;}
            .box-input-wrap {
                h2 {@include normalTxt;}
            }
        }
       .etc-fax-input{
        display: none;
       }
       .notification-wrap {
            .notification {
                .btn-text-right-s {padding-right: 30px;margin-left:15px;color:#000;font-weight:600;text-decoration: none;font-size:14px;}
            }
       }
    }
    .order-wireless-popup{
        .money-right{
            &.big{
                dt{
                    @include mainTxt; padding-bottom: 0 !important;
                }
                dd{
                     @include subContsTitle; color:$purple; padding-bottom: 0 !important;
                }
            }
        }
        .flex-box{
            display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;
            .count{
                @include subContsTitle; color:$purple;
            }
        }
        .my-bundle{
            & + .my-bundle{
                margin-top: 50px !important;
            }
        }
        .card-table{
            margin-top: 50px;
            .table-list-wrap{
                border:1px solid $gray02; border-radius: 10px; padding:40px;
                .table-title{
                    margin-bottom: 40px;
                }
                .table-default2{
                    border-bottom:0;
                    tr{
                        padding:5px 0;
                    }
                    tbody{
                        padding-bottom: 0; padding-top: 40px;
                    }
                }
            }
        }
        .one-title{
            dl{
                & + dl{
                    border-top:1px solid $gray02; margin-top:40px; padding-top:40px;
                }
            }
        }
        .cardList{
            & + .notification{
                margin-top: 10px;
            }
            .w-100{
                width:100%;
            }
        }
        .info-case{
            strong{
                @include mainTxt;
            }
        }
        .flex-end{
            display: flex; width:100%; justify-content: flex-end; gap:10px; align-items: center; margin-top: 10px;
        }
        .accordian{
            width:100%;
            .title {
                 text-align: left !important;
            }
            .acc-conts{
                background: $white; padding: 0;
            }
        }
    }
    .order-esim-active-popup{
        .one-title{
            dd{
                p{
                    & + p{
                        margin-top: 10px;
                    }
                }
            }
        }
        .unit-img{
            margin-top: 0 !important;
            img{
                display: block; margin:0 auto;
            }
        }
    }
    //가입신청 내역
    .order-list{
        .num-process-budle{
            border:1px solid $gray02; border-radius: 10px;  padding: 40px;
            .num-process-wrap{
                border:none; padding: 0; margin-top: 40px;
                li{
                    padding:60px 0 0;
                    &::before{left:-50%; z-index: -1;}
                    &:first-child{
                        &::before{display: none;}
                    }
                    &:last-child{
                        &::before{
                            width: 100%;height: 1px;margin-left: 0;background: $purple;
                        }
                        &::after{display: none;}
                    }
                    &.disabled::before{
                        background: $gray02;
                    }
                }
            }
        }
        .btn-position-wrap{
            position: relative;
        }
        .btn-position{
            position: absolute; top:3px; right:0;
        }
        .banner-img{
            li{
                & + li{
                    margin-top: 30px;
                }
            }
        }
        .my-bundle {
            .cardList {
                .accordian {
                    border-bottom: 1px solid #ddd;
                    .acc-trigger {margin-bottom: 0;border-bottom:0;}
                    .acc-conts {padding-bottom: 40px;}
                }
            }
        }
    }
    //개인정보열람 신청내역목록
    .privacy-info-list{
       .btn-text-right{
            font-size:14px;
       }
    }
    .privacy-info-no-list{
        .img{
            margin: 80px 0 30px !important;
        }
    }

    //요금납부조회
    .my-payment {
        .card-input{display: none;}
        .notification-wrap {margin-top:80px;}
        .page-desc {
            .txt-point {
                color:#191919;
                .link {margin-left:8px;}
            }
        }
    }
    .payment-account-admin{
        .box-select{
            padding-top: 40px; border-top: 1px solid $gray02;
        }
    }
    .payment-complete{
        .result-icon-wrap{
            background-image: url('/assets/images/pc/icon/ico-way-change.svg');
        }
        .payment-wrap{
            margin: 70px 0 0;
            & + .btn-group  {margin-top: 50px}
        }
    }
    .payment-wrap {
        // .bg-gy {background:$gray01;}
        margin-bottom:50px;
        .cardList-wrap {
            .cardList-title {@include mainTxt;}
        }
        // .cardList {
        //     dl {
        //         dt {width:210px;}
        //         dd {width:calc(50% - 210px);}
        //     }
        // }
        .payment-inquiry {
            .no-result {
                position:relative;
                &:before {content:'';display:block;position:absolute;left:-40px;top:0;width:720px;height:16px;background:$gray01;}
            }
        }
        .payment-way {
            .no-result {
                position:relative;
                &:before {content:'';display:block;position:absolute;left:-40px;top:0;width:720px;height:16px;background:$gray01;}
            }
        }
        .way-info {
            h2 {margin:30px 0 15px;
                &:first-of-type {margin-top:40px;}
                &+.box-input {margin-top:0;}
                &+.input-group {margin-top:0;}
                &+.group-input {margin-top:0;}
            }
            .box-input {margin-top:30px;}
            .box-select {margin-top:30px;}
            .input-group {margin-top:30px;}
            .accordian {margin-top:80px;}
        }
        &.admin {
            .cardList-wrap {margin-bottom:50px;}
            .box-select {
                &+.txt {margin-top:10px;@include defaultTxt;}
            }
        }        
    }

    .withdraw-guide {
        margin-top:40px;padding-top:20px;border-top: 1px solid #ddd;
        p { font-size: 16px;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #662d91;}
     }

    //납부확인서 확인서
    .payment-confirm{
        // .table-type-04{
        //     tr{
        //         page-break-before: always;
        //     }

        // }
        .sign{
            margin-top:30px; text-align: center;
            .normal-txt01{
                margin-top: 10px;
            }
            .official-seal{
                margin:0 auto;  margin-top: 20px; display: flex; justify-content: center; align-items: center; gap:10px;
                em{
                @include normalTxt;
                }
                .bg-official-seal{
                    @include backgroundImg('/assets/images/pc/icon/official-seal-img.png',center center,contain);
                    width: 50px; height: 50px; margin-left: -23px;
                }
            }
        }
    }
    .payment-confirm-application{
        .textarea-wrap{
            display: none;
        }
        .main-txt{
            &  + .txt{margin-top: 10px;}
        }
        .month-select{
            > div{
                display: flex;
                .nice-select{
                    height: 58px;
                    &:first-of-type{
                        margin-right: 20px;
                    }
                    .current{
                        display: flex; align-items: center; height: 58px;
                    }
                }
            }
            > span{
                display: flex; align-items: center; color: $gray02;
            }
        }
    }
    .payment-date-change{
        .cardList-wrap{
            margin-top: 0;
        }
    }
    .payment-date-change-completion{
        .page-desc{
            h2{
                @include subContsTitle;
            }
        }
        .no-result-txt{
            background-image: url('/assets/images/pc/icon/ico-pay-change.svg');
        }
    }
    //요금상세팝업
    .bill-payment-detail{
        .my-month-wrap{
            .my-month-date{
                display: flex; align-items: center; gap: 20px; margin-bottom: 10px;
                .main-txt{
                    margin-bottom: 0;
                    span{@extend %fontInherit;}
                }
                .date-num{
                    @include defaultTxt;
                }
            }
        }

        .table-list-wrap{
            .table-title{
                display: flex; justify-content: flex-end; gap: 30px; margin-bottom: 10px;
            }
        }
        .notification-wrap{
            .box-btn{display: inline-block; margin-left: 20px;}
        }
    }
    // 유심요금많이 팝업
    .rate-plan-popup-usimMore{
        .customer-center{
            display: flex; justify-content: center; gap: 10px;
            & + .customer-center{ margin-top: 10px}
            dt{
                @include normalTxt;
            }
            dd{
                @include defaultTxt;
            }
        }
    }


    .call-history {
        .inquir-box {
            margin-top:80px;
            .btn-radio-tit {display:block;margin-bottom:16px;}
            .btn-radio-group {
                margin-bottom:40px;
                label {cursor:pointer;}
            }
            .period-set {
                &>strong {display:block;margin-bottom:30px;}
                .box-input+.box-input {margin-top:30px;}

            }
        }
        //정보열람 신청내역 팝업
        .cardList-wrap{
            margin-top: 20px;
        }
    }
    .call-history-reason {
        .rq-form {border-top:1px solid $gray02;}
        .box-input {margin-bottom:30px;}
        .btn-group {margin-top:50px;}
        .notification-wrap {margin-top:80px;}
    }
    // 사용량조회
    .use-amount{
        .page-tit{
            strong{
                @include contsTitle;font-weight: 700;
                & > span{@extend %fontInherit; color: $purple;}
            }
        }
        .progress-amount{
            .title-wrap{
                &.month{
                    margin-bottom: 30px;
                    strong{display: block; @include normalTxt; margin-bottom: 10px;}
                    span{@include defaultTxt;}
                }
                &.day{
                    margin-bottom: 20px;
                    strong{
                        @include subContsTitle; font-weight: 700;
                        span{
                            @include subContsTitle;  font-weight: 700; color: $purple;
                        }
                    }
                }
            }
            .progress-wrap{
                margin-bottom: 60px;
                .progress-track{
                    position: relative; width: 100%; height: 14px; margin-bottom: 20px; background-color: $gray01;  border-radius: 50px;
                    .progress-bar{
                        position: absolute; top: 0; right: 0; width: 0; height: 100%; background-color: $purple; border-radius: 50px;transition: all 1s ease-in-out;
                    }
                }
                .progress-txt{
                    display: flex; justify-content: space-between;
                    em{
                        font-size: 20px; line-height: 1.2; color: $gray05; font-weight: 400;
                        span{
                            font-size: 20px; line-height: 1.2; color: $gray05; font-weight: 600;
                        }
                        &.left-date{@include mainTxt; color: $purple;}
                    }
                }
            }
        }
        .graph-wrap{
            .box-usage-wrap{
                display: flex;flex-direction: column;flex: 1;margin-bottom:53px;
                .usage-title{
                    display: flex; align-items: center; gap: 10px;
                    @include mainTxt; margin-bottom: 20px;
                    &::before{
                        content: ''; display: block; width: 30px; height: 30px;
                        @include backgroundImg('/assets/images/pc/icon/ico-my-data.svg',center, contain);
                    }

                }
                .circle-wrap{
                    position: relative; width: 150px; height: 150px;flex-shrink: 0;
                    svg {
                        width: 150px; height: 150px;
                        transform: rotate(-0.25turn) rotateX(180deg);
                        position: absolute;  top: 0;  left: 0;  bottom: 0;  right: 0;  margin: auto;  height: 100%;
                    }
                    .background {
                        fill: none;
                        stroke: $gray01;
                        stroke-width: 3;
                    }
                    .progress {
                        position: relative;
                        fill: none;
                        stroke: $purple;
                        stroke-dasharray: 100 100;
                        stroke-dashoffset: 100;
                        stroke-linecap: round;
                        stroke-width: 3;
                        transition: stroke-dashoffset 1s ease-in-out;
                    }
                    .bar-circle{
                        position: absolute; left: 0; top: 50%; transform: translateY(-50%) rotate(-90deg); width: 204px; height: 14px;transition: 1s ease-in-out;
                        &.on{transform: translateY(-50%) rotate(233deg);}
                        span{position: absolute; right: 5px; top: 0; width: 14px; height: 14px; background: #fff; border-radius: 50%;}
                    }
                    .txt-data {
                        position: absolute; top:50%; left: 50%; @include subContsTitle; font-weight: 700; color: $purple; transform: translate(-50%,-50%);
                        & > span{ @extend %fontInherit;}
                    }

                }

                .box-usage{
                    display: flex; justify-content: center; gap: 40px;height: 100%; padding: 50px;  border: solid 1px $gray02; border-radius: 10px;
                    .usage-wrap{
                        display: flex; flex-wrap: nowrap; flex-direction: column; align-items: center; width: 50%; height: 250px;;
                    }

                    .txt-wrap {
                        margin-top: 20px; text-align: center;
                        .tooltip-wrap{
                            display: inline-block; margin-left: 10px;
                            .txt-tooltip{
                                @include defaultTxt; line-height: 1.5;
                                left: 0; top: 26px; width: 448px; text-align: left; padding: 40px;
                            }
                        }
                        .usage {
                            &-desc{
                                display: block; position: relative; @include normalTxt01; margin-bottom: 10px;
                            }
                            &-amount{
                                @include mainTxt; color: $gray05;
                                .highlight{
                                    @include mainTxt; color: $purple;
                                }
                            }
                            &-detail{
                                @include smallDepthTxt; margin-top: 10px;
                            }
                        }
                    }
                    // .tooltip-wrap{
                    //     display: inline-flex; vertical-align: middle;
                    //     .box-tooltip {
                    //         // position: absolute; right: 0; top: 2px;
                    //         .btn-tooltip{width: 16px; height: 16px;@include backgroundImg('/assets/images/pc/icon/ico-notice.svg',center, contain);}
                    //     }
                    // }
                }
                &.call {
                    .usage-title::before{
                        background-image: url('/assets/images/pc/icon/ico-my-call.svg');
                    }
                }
                &.sms {
                    .usage-title::before{
                        background-image: url('/assets/images/pc/icon/ico-my-sms.svg');
                    }
                }

                .item-box { height: 100%; padding: 40px; border: solid 1px #ddd; border-radius: 10px;
                    .box-usage.graph-various {
                        padding: 0; border:0;
                       & + .box-usage.graph-various {margin-top:40px; padding-top:40px; border-top:1px solid #ddd; border-radius: 0;}
                        .usage-wrap { display: flex; width: 50%; height:100%; min-height:109px; flex-wrap: nowrap; flex-direction: row; align-items: start; align-content: center; gap: 20px;}
                      .circle-wrap {  position: relative; width: 109px; height: 109px; flex-shrink: 0;}
                      .circle-wrap svg { position: absolute;  top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; transform: rotate(-.25turn) rotateX(180deg); margin: auto;  }
                      .circle-wrap .txt-data {width: 100%; text-align: center; letter-spacing: -1px;font-size:20px;}
                      .circle-wrap .txt-data.not-txt {color:#666;}
                      .usage-wrap.old {align-items: center;}
                      .usage-wrap.old .txt-wrap .usage-view {margin-top:0;}

                      .txt-wrap {margin-top:0;text-align: left;
                         .usage-tit {display:block; font-size: 20px; font-weight: 600;  line-height: 1.7; letter-spacing: -0.4px;  text-align: left;  color: #191919;}
                         .usage-view {margin-top:9px;} 
                         .usage-view>div {position:relative; padding-left:10px; font-size: 16px; letter-spacing: -0.32px; text-align: left; color: #191919;}
                         .usage-view>div::before {content:''; position:absolute; left:0; top:7px; width:3px; height:3px; border-radius: 50%; background:#666;}
                         .usage-view>div + div {margin-top:10px;}
                         .usage-view>div.usage-amount {font-size:16px; color:#666;font-weight:600;}
                         .usage-view>div.usage-amount .highlight {color:#662d91;font-size:16px;}                     
                         .tooltip-wrap {display: inline-block; margin-left: 10px;}
                        }
                    }
                    
                }
            }
            
        }
        
        .chart-info{
            margin-bottom:50px;
            strong {display:block;@include subContsTitle;}
            .date {
                display:block;margin-top:16px;@include defaultTxt;
                span {margin-right:16px;}
            }
        }
        .chart-graph {
            display:flex;flex-direction: column; gap:60px;
            &>section {
                flex-grow:1;
                .chart-title {
                    display: flex; align-items: center; gap: 10px;margin-bottom:10px;@include mainTxt;
                    &::before{
                        content: ''; display: block; width: 30px; height: 30px;
                        @include backgroundImg('/assets/images/pc/icon/ico-my-data.svg',center, contain);
                    }
                }
                &.call {
                    .chart-title::before{
                        background-image: url('/assets/images/pc/icon/ico-my-call.svg');
                    }
                }
                &.sms {
                    .chart-title::before{
                        background-image: url('/assets/images/pc/icon/ico-my-sms.svg');
                    }
                }
            }
        }
        .box-chart-wrap{
            padding:40px;border:1px solid $gray02;border-radius:7px;

            .chart {
                &-title {
                    margin-bottom: 70px;
                    span{display: block; @include normalTxt;margin-bottom: 6px}
                    strong{
                        @include contsTitle; font-weight: 700;
                    }
                }
            }
            .box-chart {
                position: relative; width: 328px; margin: 0 auto;
                .chart {
                    &-line {
                        display:flex;flex-direction:column;gap:70px;
                        hr {display:block;border:0;margin:0;border-top:1px solid $gray02;width:100%; }
                    }
                    &-wrap {
                        display:flex;justify-content:space-around;
                        position:absolute;bottom:0;width:100%;height:100%;
                        .chart-conts {
                            position:relative;width:50px;height:100%;
                            .chart-bar {
                                position: absolute; bottom: 0; width:50px; height: 0;
                                transition: height 0.5s ease;
                                .bar {
                                    display: flex; justify-content: center; align-items: center; @include defaultTxt;  width: 100%; height: 100%; background-color: #f9f5ff;
                                }
                                .shadow{
                                    display: block; position: absolute; bottom: 0;left: 15%;z-index: -1;width: 100%; height: 90%; background-color: $gray02; opacity:0.4;
                                    transform: skew(-10deg);
                                }
                            }
                            .chart-month {
                                display: block; position: absolute; bottom: -28px; width: 100%; @include normalTxt; color: $gray05;  margin-top: 10px; text-align: center;
                            }
                        }
                        .box-average {
                            position: absolute;
                            bottom: 0%; left: 0; width: 100%; z-index: 2; opacity: 0; visibility: hidden;
                            transition: all 0.5s ease;
                            &.on {
                                margin-bottom:7px;opacity: 1; visibility: visible;
                            }

                            hr {display: block;border: 0;margin: 0; border-top: 1px dashed $purple; width: 100%; }
                            .average-txt{
                                display: inline-block; position: absolute; top:-52px; left: 50%; padding:6px 14px; border-radius:45px;background-color: $purple;@include defaultTxt; font-weight: 600; color: #fff; transform: translateX(-50%);opacity: 0; visibility: hidden;
                                transition: opacity 0.5s ease, visibility 0.5s ease;    min-width: 140px; text-align: center;
                                &.on{
                                    opacity: 1; visibility: visible;
                                }
                                &::after{
                                    content: ''; display: block;width:8px;height:8px;
                                    position: absolute; bottom:-3px; left: 50%; transform: translateX(-50%) rotate(45deg); background-color:$purple;
                                }
                            }
                        }
                    }
                }
            }
        }
        .result-icon-wrap{
            background-image: url('/assets/images/pc/icon/ico-not-support.svg');
        }
        .round-box {
            display:block; margin-top: 70px; padding:40px; border:1px solid $gray02; border-radius: 20px;
            &>strong {
                display:block;margin-bottom:10px; text-align: center;
                @include subContsTitle;
            }
            &>p {@include defaultTxt; text-align: center;}
            .box-btn {
                display: block; text-align: center; margin-top: 30px;
            }
        }

        .table-list-wrap {
            margin:50px 0;
            .table-title{
                display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;
                .box-btn{
                    display: flex; gap: 30px;
                }
            }
            &+.link-list {
                li {max-width:413px;}
            }
        }

        .text-ico-btn{
            display: flex;align-items: center;justify-content: space-between; padding: 50px 40px 50px 120px;border: 2px solid $gray02; border-radius: 14px;@include subContsTitle; @include backgroundImg('/assets/images/pc/icon/ico-inquiry.png', 40px center,60px 60px);
            & + .text-ico-btn{
                margin-top: 30px;
                background-image: url('/assets/images/pc/icon/ico-phone-plan.png');
            }
        }

        .notification-wrap{
            .notification{
                li{
                    a{color: $purple;}
                }
            }
        }

        // 실시간 사용량 내 툴팁 안내사항
        .tooltip-notice{
            min-width:630px !important;
            .tit{@include normalTxt; margin-bottom: 20px;}
            .notice{
                line-height:1.5;
                li{
                    margin-bottom: 20px; word-break: keep-all;
                    &:last-child{margin-bottom: 0;}
                    .ex{padding-left: 50px; text-indent: -50px;}
                }
            }
        }
    }
    .use-fee-time{
        .type-charge{
            .period{
                dt,dd{
                    *{@extend %fontInherit;}
                }
                dd{@include defaultTxt;}
            }
            .detail{
                .txt-vt{
                    @include contsTitle;
                    *{@extend %fontInherit;}
                }
            }
        }
    }
    // print 레이어
    .layer-print{
        // transform: translateY(0) !important;
        // .popup-header{
        //     @include backgroundImg('/assets/images/pc/subpage/img-print-logo.png', calc(100% - 30px) 50%, 66px 31px)
        // }
        .popup-body{
            // max-height: none !important; margin-top: 0 !important; overflow: visible !important;
            .cardList{
                padding: 0; border-width: 1px 0; border-radius: 0;
                dt,dd{
                    padding: 20px 30px !important;
                }
            }
            .notification-wrap{
                margin-top: 50px;
            }
            .sign{
                margin-top:30px; text-align: center;
                .normal-txt01{
                    margin-top: 10px;
                }
                .official-seal{
                  margin:0 auto;  margin-top: 20px; display: flex; justify-content: center; align-items: center; gap:10px;
                  em{
                    @include normalTxt;
                  }
                  div{
                    margin-left: -23px;
                  }
                }

            }
        }
    }
    //유심구매내역
    .order-usim{
        .my-bundle{
            margin-top: 50px;
        }
    }


    //bottom시트
    // &.bottom-sheet-wrap {
    //     .page-tit {
    //         margin-bottom:40px !important;
    //         h1 {@include normalTxt;}
    //         &+.bang-notice {
    //             margin-top:10px;padding-left:0;background:none;
    //             @include defaultTxt;
    //         }
    //     }
    // }
    // 정보 열람 신청 상단 정보
    em {font-style: normal;}
    .box-note {
        &>button {display:block;width:100%;position:relative;margin-bottom:20px;@include defaultTxt; text-align:left;}
        &>button:after {
            content: "";position: absolute;right: 0;top:50%;width: 25px;height: 16px;
            @include backgroundImg('/assets/images/pc/icon/ico-arrow-bottom.svg', 0 0, 100%);transform: translateY(-50%);
        }
        &>button.on:after {transform: translateY(-50%) rotate(180deg);}
        .acc-conts {padding:32px 40px;border-radius:20px;background:$gray01;}
    }
    .cont-tit{
        @include normalTxt;
        margin-bottom: 15px;
    }
    // 휴대폰 소액결제내역
    .bill-history{
        .cardList-wrap{margin-top: 15px;}
        .page-title{
            & + .box-select{margin-top: 16px;}
        }
    }
    .bill-plan{
        margin: 0 0 80px 0;
        h1{
            margin: 40px 0 30px 0; font-weight: 700;
            span{
                display: block; margin-bottom: 10px;
                @include defaultTxt; font-weight: 600;
            }
        }
        ul{
            li{
                display: inline-flex; padding-left: 40px;
                @include defaultTxt; color: $gray05;
                &:nth-child(1){
                    @include backgroundImg('/assets/images/pc/icon/ico-call-fill.svg', 0 50%, 40px)
                }
                &:nth-child(2){
                    @include backgroundImg('/assets/images/pc/icon/ico-sms-fill.svg', 0 50%, 40px)
                }
            }
        }
    }
    // 이용한도 설정
    .bill-setting-limit{
        .cardList-wrap{
            .bang-notice {
                margin-top: 10px;
                li {padding-left:0;@include defaultTxt;background:none;}
            }
            .cardList {
                margin-top:10px;
                dd{
                    *{@extend %fontInherit;}
                }
            }
        }
        .box-input{
            margin-top: 15px;
            .size-sm{
                width: 390px;
                .input-unit{color: $purple;}
            }
        }
    }
    //청구요금 조회
    .bill-inquiry{
        .past {
            h2{
                margin-bottom: 10px;
                @include mainTxt;
            }
            ul{
                border-top: 1px solid $gray02;
                li{
                    border-bottom: 1px solid $gray02;
                    a{
                        display:flex;width: 100%; height: auto; padding-right: 60px;
                        background-position: calc(100% - 20px) center;
                        span{
                            display: inline-block;
                            @include defaultTxt;color:$black;line-height:60px;
                            &.state{
                                width: 115px;text-align:left; flex-shrink: 0;
                            }
                            &.date{
                                width:100%;text-align:left;
                            }
                            &.price{
                                width: 100%;font-weight:600;text-align:right;
                            }
                        }
                        &.not-pay {
                            * {color: $red;}
                        }
                    }
                }
            }
        }
    }

    // 청구서 수신방법 변경
    .bill-method-change{
        .contents-title{
            margin-bottom:50px; font-weight:700;
        }
        .cardList-wrap{
            margin-bottom: 37px;
            .cardList-title {margin-bottom:20px;@include mainTxt;}
        }
        .box-line {
            margin-bottom:50px;padding:40px 0;border-top:1px solid $gray02;border-bottom:1px solid $gray02;
            .bang-notice {padding-left:0;background:none;@include defaultTxt;}
        }
    }

    // 청구서 재발행 신청
    .bill-reissue{
        .cardList-wrap{
            margin-top: 0;
        }
        .notification {margin-top:10px;}

        .receive-invoice{
            margin-bottom:80px;
            .box-flex + .box-input {margin-top:20px;}
        }

        .my-conts {
            .approve-form {
                .btn-check {
                    flex-direction: row; align-items: center;
                }
            }
        }
    }

    // 청구서 재발행 신청서 작성
    .bill-reissue-form{
        .input-desc{
            @include defaultTxt; margin-top: 10px;
        }
    }


    // 요금 즉시 납부
    .bill-immediate{
        .btn-group {margin-top:50px;}
        .no-result{
            &-txt{
                background-image: url('/assets/images/pc/icon/ico-search-dot.svg');
            }
        }

    }

    //요금 즉시 납부 카드 소유주 정보 입력
    .bill-immediate-payment{
        .field-tit {
            margin-bottom:30px;
            b {display:block;@include mainTxt;}
            p {display:block;margin-top:10px;@include defaultTxt;}
        }
        fieldset{
            margin-bottom:50px;padding:40px 0;border-top:1px solid $gray02;border-bottom:1px solid $gray02;
            & > div{
                margin-bottom: 30px;
            }
            .inquir-box{
                h2{
                    margin-bottom: 16px;@include normalTxt;
                }
                .btn-radio-group {
                    .btn-radio {width:auto;}
                }
                .txt {margin-top:10px} 
            }
        }
        .notification-wrap {margin-top:80px;}
    }

    // 신용카드 결제 정보 입력
    .bill-credit-form {
        fieldset {
            .box-input {
                .input-unit {top: auto; /*bottom:16px;*/ transform: translateY(0);}
                .input-label {
                    span {display: block; color: $gray05;}
                }
            }
            .box-select {
                .select-label {
                    span {color: $gray05;}
                }
                &+.txt {margin-top:-20px;}
            }
            &>div {margin-bottom: 30px;}
        }
        .txt {display:block;margin-top:10px;@include defaultTxt;}
    }

    // 앱마켓 결제 내역
    .bill-app {
        .default-txt {margin-bottom: 20px; @include normalTxt;}
        .select-label {@include normalTxt;}
        .bang-notice-tit {
            margin-bottom:40px;
            h1 {margin-bottom:20px;@include mainTxt;}
            .bang-notice {
                margin-top:0;
                li {
                    padding-left:0;background:none;
                    @include defaultTxt;
                }
            }
        }
        .radio-group-wrap{
            .btn-radio{
                label{
                    position: relative; padding-left: 70px;
                    &::before{
                        content: ''; display: inline-block; position: absolute; left: 40px; top: 50%; width: 20px; height: 20px; transform: translateY(-50%);
                    }
                    &.google-icon::before{
                        @include backgroundImg('/assets/images/pc/icon/ico-cir-google-play.png',center, contain);
                    }
                    &.appstore-icon::before{
                        @include backgroundImg('/assets/images/pc/icon/ico-cir-apple-store.png',center, contain);
                    }
                    &.onestore-icon::before{
                        @include backgroundImg('/assets/images/pc/icon/logo-onestore.png',center, contain);
                    }
                }
            }
        }
        // .inquir-box{
        //     margin-bottom: 30px;
        //     .btn-radio {
        //         label{
        //             padding-left:30px;@include normalTxt;
        //         }
        //         :checked {
        //             &+label{color:$purple;}
        //         }
        //     }
        // }
        .box-total {
            .total-wrap {
                display: flex; justify-content: space-between; align-items: center; border-radius:7px; border:1px solid $gray02; padding: 40px;
                & > strong {@include mainTxt;}
                & > span {
                    @include contsTitle;
                    em{@extend %fontInherit; color: $purple !important;}
                }
            }
        }
        .cardList-wrap{
            .cardList{
                dl > dd {
                    span {@extend %fontInherit; display: block;}
                }
            }
        }
        .box-table{
            .table-type-04{
                .date-time{
                    span{display: block;}
                }
                .complete{color: $purple;}
                td {
                    *{@extend %fontInherit;}
                }
            }
        }
        // .accordian {margin-top: 80px;}
    }

    //휴대폰 소액 결제 차단 / 해제 안내
    .bill-release-info{
        .cardList{
            .normal-txt{display: block; margin-bottom: 10px;}
        }
        .inquir-box{
            margin-top:30px;
            .default-txt{
                margin-bottom:16px;@include normalTxt;
            }
        }
        .notification-wrap {margin-top:80px;}
    }

    // 휴대폰 정보 변경 안내
    .subscript-phone-change {
        .process-box{
            .box-tit {margin-bottom:20px;@include mainTxt;}
        }
        .change-finish {
            padding:50px 0;
            p {
                display:block;padding-top:170px;
                @include backgroundImg('/assets/images/pc/icon/ico-opening-success.svg', center top, 120px 120px);
                strong {display:block;@include mainTxt;text-align:center;}
                span {
                    display:block;margin-top:10px;@include defaultTxt;text-align:center;
                    &+strong {margin-top:50px;}
                }
            }
        }
        .no-search-result-txt{
            background-image: url('/assets/images/pc/icon/ico-calender-60.svg');
        }
    }
    //모델명/일련번호 확인 방법
    .subscript-serial-number {
        .box-tab {
            margin-top:0;
            .tab-btn-wrap {margin-bottom:40px;}
            .cardList-wrap {
                .cardList-title {margin-bottom:10px;@include mainTxt;}
                .unit-img {
                    margin-top:30px;
                    img{
                        margin:0 auto; display: block;
                    }
                }
            }
        }
    }

    // 내 가입정보 안내
    .subscript-join-info{
        .ml30{
            margin-left: 30px;
        }
        .column{
            display: flex; gap:10px 0; flex-direction: column;
        }
        .flex-box{
            display: flex; gap:30px;
        }
        .info-txt{
            margin-bottom: 10px;
        }
        .basic-charge {display: inline-block; margin-left:28px;padding-right:8px; font-size: 14px;font-weight: 600;letter-spacing: -0.28px;text-align: left;color: #191919;}
        .dot-txt {
            position:relative; padding-left:10px; font-size: 16px;line-height: 1.19;letter-spacing: -0.48px;text-align: left;color: #666;
            &::before {content: ''; position:absolute;left:0;top:7px;width:3px;height:3px;background:#666;border-radius: 50%;}
            a {margin-left:3px; font-size: 14px;font-weight: 600;letter-spacing: -0.28px;text-align: left;color: #000;}
        }
    }
    // 휴대폰 정보 등록
    .subscript-phone-info{
        .box-input,
        .box-select {
            position:relative;
            .box-btn {position:absolute;right:0;top:0;}
        }
        .box-full {width:100%;}
        .os-wrap{
            margin-top: 40px;
            .default-txt{margin-bottom: 16px;}
        }
        .phone{
            &-ios{display: none;}
        }
        .bang-notice{
            margin-top: 10px;
        }
    }

    // 휴대폰 정보 등록 완료
    .subscript-complete{
        .icon-img{
            display: block; width:150px; height:150px; margin:95px auto 0;
        }
    }

    //지정번호 등록 / 변경
    .subscript-change{
        .rq-form {
            margin-bottom:0;padding-bottom:0;border-bottom:none;
            .main-txt-group {
                margin-bottom:0;padding-bottom:0;border-bottom:none;
                .main-txt {@include normalTxt;}
            }
        }
        /*.phone-desc{
            margin-bottom: 80px;
            h2{
               @include defaultTxt;
            }
            p{
                @include contsTitle; margin-top: 10px;
            }
        }*/
    }
    //선택약정 가입 신청서
    .subscript-select-discount{
        .money-right{
            .main-txt{
                margin-bottom: 30px;
            }
        }
        .fz24{
            font-size: 24px;
        }
        .check-wrap {
            margin-top:50px;
            .wrap-tit {@include mainTxt;}
            .box-btn {margin-top:10px;}
            .notification {margin-top:10px;}
        }
    }
    .subscript-cancellation-charge-popup{
        .box-table{
            margin-top: 50px;
        }
        .table-type-04{
            & + .table-type-04{
                margin-top: 30px;
            }
        }

    }
    //선택약정
    .subscript-installment-info {
        .page-desc{
            margin-bottom: 10px;
        }
        .month-plan-box{
            margin-bottom: 50px;
        }
        .mb10{
            margin-bottom: 10px;
        }
        .gary-txt{
            font-size: 20px; color:$gray05; font-weight: 400;
        }
        .table-list-wra{
            & +.table-list-wrap{
                margin-top: 50px;
            }
        }
     }
     // 일시정지
     .pause-data-free {
         .normal-txt {display:block;margin-top:10px;}
     }
    .pause-list {
        .page-desc {margin-bottom:20px;}
    }
    .pause-application{
        .rq-form {
            margin-bottom:0;padding-bottom:0;border-bottom:none;
            .radio-group-wrap {
                &+.notification {
                    margin-top:20px;
                    li {margin-bottom:0;}
                }
            }
        }
        .contents-wrap {
            margin-top:40px;
            .main-txt {display:block;margin-bottom:20px;padding-bottom:0;border-bottom:none;@include normalTxt;}
        }
        .notification-wrap {margin-top:40px;}
        .date-list {
            display: flex; gap: 30px;
            li {
                * {@include subContsTitle;}
            }
        }
        .round-gray-box {
            background-color:$bg;padding:20px;border-radius:7px;
            .btn-check {
                &+.txt {margin-top:10px;}
            }
        }
        .contents-wrap {
            .contents-title {margin-bottom:20px;@include mainTxt;}
            &+.btn-group {margin-top:50px;}
        }
        .cardList{
            &+.cardList {margin-top:30px;}
            .card-check label {
                span {@include mainTxt;color: $purple;}
            }
            .card-check {
                .box-info{
                    width: 100%;
                    .info-top{
                        display: flex;justify-content: space-between; align-items: center;
                        strong{@include subContsTitle; font-weight: 600;}
                        span{@include smallTxt; font-weight: 600;}
                    }
                    .product-name{
                        display: block; margin-top: 10px;
                        @include text(400,28px,1.43,$gray05,-1px)
                    }
                }
            }
            &.stop{
                .card-check {@include mainTxt; color: $purple;}
            }
            &.pause{
                .card-check {
                    @include mainTxt; color: $gray05;
                    .box-info .info-top{
                        span{color: $purple;}
                    }

                }
                b {
                    &.card-check {margin-bottom:0;padding-bottom:0;border-bottom:none;@include normalTxt;color:$gray05;}
                }
            }
        }
        .page-desc {
            margin-bottom:50px;
            h2 {margin-bottom:10px;@include mainTxt;}
            .notification {
                li {
                    margin-bottom:5px;
                    &:last-child {margin-bottom:0;}
                }
            }
        }
        .accordian{
            &.notification-wrap {
                .acc-trigger {
                    text-align: left;
                    h3 {margin: 0;}
                }
            }
        }

        /*
        .contents-wrap{
            & + .contents-wrap{margin-top: 80px;}
        }
        .contents-title{margin-bottom: 40px;}
        .notification{
            .default-txt{
                margin-bottom: 20px;
            }
        }
        .cardList-wrap{
            & + .box-btn{margin-top: 20px;}
        }
        .cardList{
            & + .cardList{margin-top: 30px;}
            .btns{margin-top: 50px;}
            .card-check label {
                span{@include mainTxt; color: $purple;}
            }
            .card-check {
                .box-info{
                    width: 100%;
                    .info-top{
                        display: flex;justify-content: space-between; align-items: center;
                        strong{@include subContsTitle; font-weight: 600;}
                        span{@include smallTxt; font-weight: 600;}
                    }
                    .product-name{
                        display: block; margin-top: 10px;
                        @include text(400,28px,1.43,$gray05,-1px)
                    }
                }
            }
            &.stop{
                .card-check{@include mainTxt; color: $purple;}
            }
            &.pause{
                .card-check{
                    @include mainTxt; color: $gray05;
                    .box-info .info-top{
                        span{color: $purple;}
                    }

                }
            }
        }
        .box-ico-txt{
            margin-top: 0;
            & + .cardList-wrap{margin-top: 80px;}
        }
        */

        // 일시정지 신청팝업
        /*&.full-popup-conts {
            .contents-wrap{margin-top: 60px;}
            .main-txt{display: block; margin-bottom: 20px;}
            .radio-group-wrap{
                .group-tit{@include mainTxt; margin-bottom: 20px;}
                .btn-radio {
                    label{
                        display: flex; align-items: center; justify-content: center; padding: 0; height: 120px;
                    }
                }
            }
            .bang-notice{
                margin-top: 20px;
                li{@include smallTxt;}
            }
            .box-input{
                & + .box-input{margin-top: 20px;}
            }
            .date-list{
                display: flex; gap: 30px;
                li{
                    * {@include subContsTitle;}
                }
            }
            .round-gray-box{
                background-color: $bg; padding: 30px;border-radius: 20px;
                .btn-check{
                    margin-bottom: 22px;
                    & + p{
                        @include smallTxt;
                    }
                }
            }
            .accordian{
                margin-top: 80px;
                .acc-conts{
                    .notification.bold-txt{
                        li{@include defaultTxt;}
                        .small-txt{margin-top: 10px;}
                    }
                }
            }
        }*/
    }
     //부가서비스 변경
    .serviceChange-list {
        .box-icon-info{
            background: $lightpurple; border:none;
            .desc{
                @include normalTxt;
                strong{
                   @extend %fontInherit;
                   & + strong{
                    margin-left: 10px;
                   }
                }
                span{
                    @extend %fontInherit;
                }
            }
            .btn-box{
                margin-top: 20px;
            }
        }
        // .used-service {
        //     h2 {margin-bottom:10px;@include contsTitle;}
        //     .desc{
        //         display:flex;gap:10px;
        //         li {
        //             @include normalTxt;
        //             strong {
        //                     @include normalTxt;color:$purple;
        //                 em {@extend %fontInherit;}
        //             }
        //         }
        //     }
        //     a {margin-top:20px;}
        // }
        .cardList-wrap{
            .money-right {
                padding-bottom:40px;
                dl {
                    dt,dd{
                        padding-bottom:30px;
                        strong {@include mainTxt;}
                        p {margin-top:5px;@include defaultTxt;}
                    }
                    dt {                        
                        width:calc(100% - 220px);
                        .txt-period {
                            position: absolute;
                            strong {font-weight:600;color:#662d91;font-size: 16px;}
                        }
                        .detail-view {                           
                            li {
                                display:flex;align-content: center;align-items: center; margin-top:10px;
                                .tit {font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                                .txt {margin-left:6px; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}                          
                                &.dte {
                                    display:block;
                                    .txt {margin:0;}
                                }
                                .dte-view {
                                    span {
                                        display:block;margin-top:6px;                                        
                                    }
                                }
                            }
                        }
                    }
                    dd {
                        width:220px;
                    }
                }
                .btn-group{
                    justify-content: flex-end;
                    .btns{
                        flex: initial; width: 200px;
                    }
                }
                & + .money-right{
                    margin-top: 30px;
                }
            }
        }
    }
    .serviceChange-blocking {
        .page-phone-info{
            margin-bottom: 0;
            .box-select{
                margin-top: 0; width: 100%;
            }
        }
        .notification-wrap{
            margin-top: 50px;
            .notification{
                margin-top: 10px;
            }
        }
        .cardList-wrap{
            .cardList-title{
                @include normalTxt; margin-bottom: 16px;
            }
            .one-title{
                padding: 16px 20px; border-radius: 7px;
                dt{
                    @include normalTxt01;
                }
            }
        }
    }

     //단순 소타이틀 컨텐츠 묶음
     .box-wrap {
         .wrap-tit {display:block;margin-bottom:20px;@include normalTxt;}
     }

     .subscript-terms-popup {
         .table-list-wrap {margin-top:10px;}
     }

    //가입사실확인서 정보확인
    .appli-confirm{
        .main-txt{
            margin-bottom: 20px;
        }
        .etc-input{
            display: none;
        }
        /*.cardList-wrap{
            display: flex; flex-wrap: wrap; gap: 20px; margin-bottom: 50px;
            .cardList{
                flex: 1;
            }
        }*/
    }

    //정보열람 신청내역 팝업
    // .appli-pop{
    //     .cardList-wrap{
    //         margin-top: 20px;
    //     }
    // }

    // 열람 신청 내역
    .appli-browse{
        .cardList {
            dt,dd{
                *{@extend %fontInherit;}
            }
        }
        .no-data-wrap{
            text-align: center; margin-top: 20px; padding:50px 0;
        }
    }

    // 컨텐츠페이지 휴대폰 정보
    .page-phone-info {
        margin-bottom:50px;
    }

    //팝업

        .reward-loss-report {
            .rq-form {
                padding-bottom:0;border-bottom:none;
                &>section {
                    &:first-of-type {margin-top:0;}
                }
            }
            .input-wrap {
                position:relative;
                .btn-text-right {margin-top: 10px;}
            }
            .txt {margin-top:10px;}
            .notification-wrap {margin-top:50px;}
        }


    .find-no-data {
        .no-result {
            margin-top:-100px;
            &+.btn-group {margin-top:50px;}
        }
    }
    .subscript-return-amount{
        .c-hotpink{
            dl{
                dt,dd{
                     color:$red;
                }
                dt{
                    @include mainTxt;
                }
                dd{
                    @include subContsTitle;
                }
            }
        }
    }
    //나의포인트(공통)
    .point-inquiry {margin-bottom:50px;}
    //포인트조회
    .point-lookup {
        .point-inquiry {
            display:flex;justify-content:flex-start;padding:40px;border:1px solid $gray02;border-radius:10px;
            dl+dl  {margin-top:27px;padding-top:28px;}
            dl {
                dt {display:block;@include defaultTxt;}
                dd {display:flex;justify-content:space-between;align-items:center;@include normalTxt;}
            }
            .point-num {
                display:flex;justify-content:flex-start;width:410px;gap: 50px;margin-right: 50px;
                // dl {margin-right:50px;}
                dl+dl  {margin-top:0;padding-top:0;}
            }
            .point-sum {
                width:310px;padding-left:50px;border-left:1px solid $gray02;
                em {margin-right:30px;@include subContsTitle;}
            }
        }
        .rq-form {margin-bottom:70px;padding-bottom:0;border-bottom:none;}
        .result-categ {
            margin-bottom:20px;
            ul {
                display:flex;justify-content:flex-end;
                li {
                    margin-left:20px;
                    input[type=radio] {
                        @include hidden;
                        &:checked+label {@include normalTxt;}
                    }
                    label {@include defaultTxt; cursor: pointer;}
                }
            }
        }
        .point-sort-card {
            +.point-sort-card {margin-top:30px;}
            .top {margin-bottom:20px;}
            .middle {
                &>p {margin-bottom:10px;}
                dl {
                    display:flex;justify-content:space-between;
                    dt {
                        width:auto;
                        .use-info {@include mainTxt;}
                        .btn-text-right-s {margin-left:30px;}
                    }
                    dd {width:auto;@include mainTxt;}
                    .txt-cancel {text-decoration: line-through;color:#959595 !important;}
                }
            }
            .bottom {
                margin-top:40px;padding-top:40px;border-top:1px solid $gray02;
                dl {margin-bottom:20px;}
            }
        }
        .cardList-wrap.point-inquiry {
            padding:0; border:0;
            dl {                
                dd {
                    flex-direction: row-reverse;
                    align-items: flex-start;
                    .txt-point {font-size: 30px; font-weight: 600;}
                }
                .lnk-point {
                    a {color:#666;font-size:16px; font-weight: normal;}
                }
            }
            .tp-point {
                align-items: center;
                dt {
                    margin:0;
                    .btn-text-right {display:inline-block;margin-top: 5px;font-size:14px;}
                }
            }
        }
        .number-recmnd {
            display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center; margin:40px 0 17px 0;
            p {
                font-size: 16px;font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: left; color: #191919;
                span {font-weight: normal; color:#191919;}
            }
            .btn {font-size:14px;}
        }
        .u-cach-cnt {
            .cash-inquiry {
                margin-bottom:50px;background:#f9f5ff;border:0;
                .cash-usable {
                    align-items: center;
                     span {
                        span {margin: -5px 0 0 5px;  font-size: 20px;font-weight: 600;letter-spacing: -0.4px;text-align: right;color: #191919;vertical-align: middle;}
                     }
                }              
            }
            .u-cach-aplcatin {
                padding: 40px;border-radius: 10px;border: solid 1px #ddd;background-color: #fff;
                dl {
                    display: flex; flex-wrap: wrap; 
                    & + dl {margin-top:20px;}                   
                    dt {width:210px; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
                    dd {width:510px;font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                }                
            }
            .agree-wrap {
                margin:0;
                .btn-check {margin:0}
                & + .notification {margin-top:10px}
            }
            section + section {margin-top:40px;}
            .sctn-tit {margin-bottom:15px; font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}
            .radio-group-wrap {
                .cash-list {
                    display: flex;
                    li {
                        width:254px;
                        label {width:100%;}
                    }
                }
                & +.box-input {margin-top:20px;}
            }
            .guide-msg {
                margin-top:10px;
                li {
                    font-size: 16px;letter-spacing: -0.32px;text-align: left;color: #666;
                    & + li {margin-top:8px;}
                }
            }
            .btn-link {margin-top:10px;}           
            .btn-group {
                margin-top:50px;
                &.choice-btn {margin-top:15px;}
            }
        }
    }

    .point-inquiry.use-point {
        padding:0; border:0;
        dl+dl  {margin-top:27px;padding-top:28px;}
        dl {                
            dd {
                flex-direction: row-reverse;
                align-items: flex-start;
                .txt-point {font-size: 30px; font-weight: 600;}
            }
            .lnk-point {
                a {color:#666;font-size:16px; font-weight: normal;}
            }
        }
        .tp-point {
            align-items: center;
            dt {
                margin:0;
                .btn-text-right {display:inline-block;margin-top: 5px;font-size:14px;}
            }
        }
    }

    //포인트사용
    .point-benefit {
        &>ul {
            display:grid;grid-template-columns:1fr 1fr;gap:30px;
            &>li {
                &.full {grid-column:1/3;grid-row:1/2;}
                &.u-kok {
                     grid-column: auto / span 2; 
                     .benefit {
                        position: relative;min-height:217px;
                        .unit-link {margin:10px 0 0 0;}
                        .img {position:absolute;right:40px;bottom:40px;}
                     }
                }
                .benefit {
                    display:flex;flex-direction:column;justify-content:space-between;border:1px solid $gray02;min-height:384px;padding:40px;border-radius:10px;box-sizing:border-box;
                    h2 {margin:0 0 15px 0;}
                    .img {font-size:0;text-align:right;}
                    .unit-txt{line-height: 1.5;}
                    .unit-tit-s {margin-bottom: 10px;}
                }
                .num-process-wrap {margin-top:50px;border:none;padding:0;}
            }
        }
    }

    //포인트신청
    .point-use-application {
        .rq-form {
            padding-bottom:0;border-bottom:none;
            .box-input,
            .box-select,
            .box-flex {width:100%;}
        }
        .approve-form {margin-top:80px;}
    }

    // 번호변경
    .num-change-want{
        .rq-form {
            margin-bottom: 0; padding-bottom: 0; border-bottom: none;
            .change-select {
                margin:0 0 50px 0;
                .main-txt {margin-bottom:15px;}
               .box-select {margin-top:0;}
            }
            .btn-group{
                margin-top: 50px;
            }
            .btn-radio-group{
                .btn-radio {
                    width: calc(25% - 15px);
                    label{width: 100%; padding: 0;}
                }
            }
        }
    }
    .num-change-complete{
        .page-desc{margin: 70px 0;}
        .num-process-wrap{
            margin-top: 10px;
        }
        .box-icon-info.change-guide {
            padding: 38px 40px;
        }
        .btn-group.revert{
            margin-top:30px;
            .change-revert {font-size: 16px;line-height: 1.5;letter-spacing: -0.32px;color: #666; text-decoration: underline;}
        }

        .default-txt{
            & + .box-btn{margin-top: 10px;}
        }
    }

    //요금제 변경
    .rate-plan-change {
        .info-area {
            section {
                &+section {margin-top:50px;}
                .main-txt {margin-bottom:10px;}
            }
            section.usm-esim {margin:20px 0 50px 0;}
            .box-tab.tab-ctrl .tab-btn-wrap {margin-bottom: 20px;}
        }
        .cardList {
            &.one-title {
                dt {
                    strong {
                        @include mainTxt;
                        &+p {margin-top:10px;}                        
                    }
                    .basic-charge {display: inline-block; margin-left:28px;line-height: 1.19;font-size: 16px;font-weight: 600;letter-spacing: -0.28px;text-align: left;color: #191919;}                        
                    p {@include defaultTxt;}
                }
                .dot-txt {
                     position:relative; padding-left:10px; font-size: 16px;line-height: 1.19;letter-spacing: -0.48px;text-align: left;color: #666;
                     &::before {content: ''; position:absolute;left:0;top:7px;width:3px;height:3px;background:#666;border-radius: 50%;}
                    a {margin-left:7px; font-size: 14px;font-weight: 600;letter-spacing: -0.28px;text-align: left;color: #000;}
                }
            }
            .box-table {
                margin-top: 40px;
                padding-top: 40px;
                border-top: 1px solid #ddd;
                .table-title {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.19;
                    letter-spacing: -0.32px;
                    text-align: left;
                    color: #191919;
                  }
                  .table-type-04 td {
                    color: #666;
                  }
                  .notification {
                    margin-top: 10px;
                  }
            }            
        }
        .payment-accordian-card {
            .header {
                justify-content:space-between;align-items:flex-start;padding-right:0;background:none !important;
                dt {
                    width:auto;
                    .feature {margin-top:35px;}
                }
                dd {width:auto;}
            }
        }
        .btn-group {margin-top:50px;text-align:center;}
        .mem-type-guide {
            .guide {
                &-txt {
                    @include mainTxt;
                    span {display:block;margin-top:10px;@include defaultTxt;}
                }
                &-img {padding:50px 0;text-align:center;}
                &-q-box {
                    padding:40px;border-radius:10px;background:$bg;
                    .tit {display:block;@include defaultTxt;color:$black;}
                    .txt {display:block;margin-top:10px;@include defaultTxt;}
                }
            }
        }
        .plan-view {
           overflow:hidden;height:168px; margin-bottom:50px;
            .plan-slider {
                .slide {
                    width:802px;height:168px;background:#f8f4ff;border-radius: 20px;
                    .plan-dtil {
                        position:relative; padding:40px 60px 38px 60px; 
                        .plan-tit {display:block;font-size: 28px;font-weight: bold;letter-spacing: -0.56px;text-align: left;color: #191919;}
                        .spc {
                            position:relative; margin-top:10px;padding-bottom:25px;
                            .spc-view {
                                display: flex; flex-direction: row; gap:20px;width:100%;
                                span {
                                    position: relative;padding-left:30px; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;color: #191919;
                                    &::before {content: ''; position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 100%;}
                                }
                                .vol::before { background: url('/assets/images/pc/icon/ico-sm-data-black.svg') no-repeat center; background-size: 20px 20px;}
                                .limit::before {background: url('/assets/images/pc/icon/ico-sm-call-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                                .supply::before {background: url('/assets/images/pc/icon/ico-sm-sms-fill-black.svg') no-repeat center; background-size: 20px 20px;}                        
                            }
                            .txt {
                                position: absolute;left:0;bottom:0; margin-top:7px;padding-left:13px; font-size: 14px;line-height: 1.14;letter-spacing: -0.28px;text-align: left;color: #959595;
                                &::before {content: ''; position: absolute;left:0;top:6px; width:3px;height:3px;border-radius: 50%; background: #959595;}
                            }
                        }
                    }
                    .price-box {
                        position:absolute; right:60px;bottom:53px;
                        .price {display:block;text-align: right; font-size: 16px;letter-spacing: -0.32px;color: #959595;text-decoration: line-through;}
                        .dc-price {
                            display: flex;gap: 1px;align-items: center;margin-top:3px;
                            .dc-txt {display: inline-block; font-size: 36px;font-weight: bold;line-height: 1;letter-spacing: -0.72px;text-align: right;color: #662d91}
                            .won {display: inline-block;margin-top:7px; font-size:18px;font-weight: bold;color: #662d91;}
                        }
                    }
                    &01 { background-color: #f8f4ff;}
                    &02 { background-color: #f1f7ff;}
                    &03 { background-color: #f3fff9;}
                }
                .slick-dots {
                    position: absolute; bottom:20px;padding:0;                    
                    li + li {margin-left:8px;}
                    button {width:8px; height:8px;background:#ddd;}                
                    li.slick-active button {width:20px;height:8px; background: #191919;}
                }                
            }
            & + .box-tab.tab-ctrl .tab-btn-wrap {margin-bottom:40px;}
        }
    }
    .rate-plan-popup {
        .notification-wrap {
            margin-top:50px;
            &>strong {display:block;margin-bottom:10px;@include normalTxt;}
        }
        .guide-q-box {
            padding:40px;border-radius:10px;background:$bg;
            .tit {display:block;@include defaultTxt;color:$black;}
            .txt {display:block;margin-top:10px;@include defaultTxt;}
        }

        &.rate-plan05 {
            .box-table {
                margin-top:50px;
                .charge-month {padding-left:40px;@include mainTxt;text-align:left;}
                .charge-money {
                    padding-right:40px;text-align:right;
                    .origin-pay {margin-right:10px;@include defaultTxt;text-decoration:line-through;}
                    .discount-pay {@include subContsTitle;color:$purple;}
                }
                tbody {
                    th {border-bottom:1px solid $gray02;background:none;font-weight:normal;}
                }
            }
            .guide-txt {
                display:block;margin-top:10px;@include normalTxt;
                &+.txt {margin-top:10px;}
            }
            .btn-check {margin-top:50px;}
        }
        &.rate-plan06 {
            .rate-plan06-guide {
                margin-top:50px;
                ul {
                    li {
                        &+li {margin-top:30px;}
                    }
                }
                p {margin-top:50px;padding-top:10px;border-top:1px solid $gray02;@include defaultTxt;}
            }
        }
    }
    .my-conts {
        .payment-type {
            margin:40px 0;
            .box-input-wrap {
                margin-top:20px;
                .input-default {padding-right:40px; text-align:right;}
            }
        }

        .cash-inquiry {
            padding:40px;border:1px solid #ddd;border-radius:10px;
            .cash-usable {
                display:flex;width:100%; align-content: flex-start;
                strong {display:inline-block; font-size: 20px; font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                span {display:inline-block;margin-left:auto; align-items: center; font-size: 30px; font-weight: 600;line-height: 1.2;letter-spacing: -0.6px;text-align: right;color: #662d91;}
                .sm-chsh {font-size:20px; letter-spacing: -0.4px;}
            }
            .cash-wastine {
                margin-top:10px;
                p {
                    font-size: 16px; line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;
                    span {display:inline-block; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                }
            }
            .btn-box {
                margin-top:20px;text-align: right;
                .btns {
                    width:200px;vertical-align:top;
                    & + .btns {margin-left:5px;}
                   &.line {border-color:#ddd;}
                }
            }
            & + .result-categ {margin-top:50px;}

            .devcechg-txt {margin-top:10px; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: right; color: #666;}
        }

        .u-cash {
            .cardList + .cardList {margin-top:30px;}
            .save-cash {
                display:flex;align-content: flex-start; align-items: center;width:330px;
                strong {font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                span {margin-left:auto; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px; text-align: right;color: #662d91;}
            }
            .date-wrp {margin:20px 0;padding-bottom:20px;border-bottom: 1px solid #ddd;}
            .save-date + .save-date {margin-top: 20px;}
            .save-date {
                .date-box {
                    display:inline-block; width:330px;
                    &.fade span {font-size: 16px; font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                    & + .date-box {margin-left:57px;}
                    &::after {content:'';display:block; clear:both;}
                    span {float:left; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666}
                    span + span {float:right; text-align: right;;}
                }
            }
        }

    }
    // 유심 변경 신청
    .subscript-usim-change{
        .info-area{
            .page-desc{
                &.line{border-bottom: 1px solid #ddd; padding-bottom: 30px;}
            }
            .usim-register{
                .box-tit{font-size: 16px; color: #191919; font-weight: 600; letter-spacing: -0.3px; margin-bottom: 16px; line-height: normal;}
                &-box{
                    border: 1px solid #ddd; border-radius: 10px; padding: 30px 30px 30px 10px; display: flex; align-items: center; gap: 10px;
                    .select-input-wrap{
                        .box-select{width: 180px !important;}
                    }
                }
            }
            .call-back{
                position: relative;
                .btn-check{position: absolute; top: 0; right: 0;}
            }
            .change-reason{
                .select-input-wrap{
                    width: 100%;
                    .box-select{width: 340px !important;}
                }
            }
            .change-agree{
                margin-top: 80px;
                .notification{margin-bottom: 15px;}
            }
            section{
                > .main-txt{margin: 80px 0 10px 0;}
            }
            .cardList-wrap {margin-top:10px;
                .card-img {width:80px;height:80px;} 
                .cardList + .cardList {margin-top:30px;padding:33px 20px;}
                .icon-txt-card {position:relative;}
                .ico-list {
                    position: absolute; right:20px; top:50%; transform: translateY(-50%);
                    li {float:left; width:40px;height:40px;}
                    li + li {margin-left:8px;}
                    li img {width:100%;}
                }
            }
            .certify-complet{
                border: 1px solid #ddd; border-radius: 10px; padding: 50px 0; text-align: center;
                img{width: 80px; margin-bottom: 20px;}
                p{font-size: 20px; font-weight: 600; line-height: 1.2; color: #191919;}
            }
            .uism-buy-move{
                display: inline-block; font-size: 14px; font-weight: 600; color: #000; line-height: 20px; letter-spacing: -0.28px; margin-top: 5px; padding-right: 30px; position: relative;
                &::after{
                    content: ''; width: 20px; height: 20px; position: absolute; right: 0; top: 0; background: transparent url('/assets/images/pc/icon/ico-arrow-black-s.svg') center center no-repeat; background-size: 70%;
                }
            }
        }
        .msg-box{
            text-align: center;
            .msg-icon{margin: 50px 0;}
            .msg-txt{
                font-size: 20px; font-weight: 600; letter-spacing: -0.4px; color: #191919;
                & + .box-btn{margin-top: 50px;}
                &.left{text-align: left;}
            }
            .txt{
                font-size: 16px; margin-top: 10px;
            }
            .msg-txt-box{
                padding: 40px; border-radius: 10px; background-color: #fafafa; text-align: left;
                .txt{margin-top: 0; line-height: 26px;}
            }
        }
    }

    .roaming-details {
        .page-desc {margin-bottom:40px;padding-bottom:30px;border-bottom:1px solid #ddd;}
        section {
            .form-box{
                & + .form-box {margin-top:40px;}
                & + p {margin-top:10px;}
            }
            .box-table {
                 .table-type-04 {
                    thead th {font-weight:700;}
                 }
            }
        }
        .notice {
            margin-top:80px;
            .txt-line {text-decoration: underline;}
        }
        .table {
            margin-top:50px;
            .btn-apply {display:inline-block; width: 70px;height: 30px;line-height:30px; border-radius: 7px;border: solid 1px rgb(102 45 145 / 41%);background-color: #fff;font-size: 14px;font-weight: 600;letter-spacing: -0.28px;text-align: center;color: #662d91;}
            .call-nber {
                display: flex;justify-content: space-around;
                strong, span {
                    color:#191919;font-size:16px;
                }
                & + .call-nber {margin-top:5px;}
            }
            .box-table {
                & + .box-table {margin-top:40px;}
            }
            .table-title {margin-bottom:15px; padding:0;border:0;font-size: 16px;font-weight: 600;letter-spacing: -0.32px;text-align: left;color: #191919;}
            table tfoot {
                th {background: none;border-bottom: 1px solid #ddd;}
                td {padding-left:113px;font-size: 16px;font-weight: 600;line-height: 1.13;letter-spacing: -0.32px;text-align: left;color: #191919;}
            }  
        }
        .amount-used {
            display:flex;align-items: center;justify-content: space-between; padding: 40px;border-radius: 10px;background-color: #f9f5ff;
            .amount-txt {
                font-size: 20px;font-weight: 600;letter-spacing: -0.4px;text-align: left;color: #191919;
                span {display: inline-block; margin-top:2px; font-size: 16px;font-weight: normal;letter-spacing: -0.32px;text-align: left;color: #666; vertical-align: top;}
            }
            .amount-price {font-size: 24px;font-weight: 600;letter-spacing: -0.48px;text-align: right;color: #662d91}
            & + .btn-group {margin-top:40px;}
        }

        .no-result {
            width:100%;padding:100px 0;text-align: center;
            p {margin-top:50px; font-size: 20px;font-weight: 600;letter-spacing: -0.4px;text-align: center;color: #191919;}
        }
    }

  
    .bill-immediate-payment {
        .card-certified {
            fieldset {margin:0;padding:40px 0 0 0;border-bottom: none;}
            .credit-card-certified {
                margin:60px 0 0 0;
                .sb-tite {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                .bx-tit {
                    padding-bottom:30px;border-bottom: 1px solid #ddd;
                }
                .txt-box {
                    margin-top:40px;
                    p{
                        font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;
                        & + p {margin-top:5px;}
                    }
                    .sb-txt {
                        color:#666;font-weight: normal;
                    }
                }
                .btn-check  {margin:15px 0 40px 0;}
                .appli-identification {
                    display: flex;flex-direction: column;gap:30px;margin-top:15px;
                    .identifi-box-simple {
                        width:100%;margin:0;
                        .identifi {
                            display: flex;justify-content: flex-start;gap: 0 20px;align-items: center;
                            img {width:80px;height:80px;}
                        }
                    }
                }
            }
            .btn-group {margin-top:50px;}            
        }
    }
    
}

.my .approve-form, .reward-loss-report .input-wrap  {
    .btn-check {
        align-items: flex-start; gap: 15px;flex-direction: column;
        .btn-chk {
            display: flex;align-items: center;gap:10px;
        }
    }
}

.layer-popup{
    .usim-change{
        .popup{
            &-header{
                .txt-main{letter-spacing: -1px;}
            }
            &-body{
                text-align: left;
                .box-tit{
                    font-size: 16px; font-weight: 600; margin: 10px 0; color: #191919;
                }
                .usim-change-info{
                    border: 1px solid #ddd; padding: 30px; border-radius: 10px;
                }
            }
        }
    }
    .model-code{
        .btn-radio-group{
            width:640px;gap: 10px;
            .btn-radio{
                width: 315px;
                label{width: 100%;}
            }
        }
        
    }

    .roaming-receipt {
        .txt {margin-bottom:30px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
    }

}

.my-month.type-total.instantly .total-bottom .detail-total a {padding:0; background:none;}

// 정보제공동의
.type2-agree {
    .btn-check-type2 {
        position:relative;width:100%;
        label {
            width:100%;padding:30px;border:2px solid $gray02;border-radius:24px;
            .nec {color:$purple;}
        }
        input[type=checkbox]:checked+label {border: 2px solid $black;transition: all .3s ease-out;}
    .ico-arrow-right {position:absolute;right:30px;top:50%;transform:translateY(-50%);}
    }
}
// 정보제공동의(팝업)
.layer-popup {
    .type2-agree {
        .btn-check-type2 {
            label {
                padding: 13px 20px;border: 1px solid $gray02;border-radius: 7px;
                strong {@include normalTxt;color:$purple;}
                span {@include normalTxt;}
            }
        }
    }
    .my-payment {
        .page-tit {
            border-bottom:1px solid #ddd;
            h1 {margin-bottom:30px; font-size:20px;}
        }
        table {width:100%;}
        .unit-tit-m {margin-top:40px;}
    }
}

//본인확인 방법 안내
.appli-identification {
    display:flex;justify-content:flex-start;gap:0 20px;
    &>a {display:block;width:413px;}
    .identifi-box {
        padding:40px 30px;border:1px solid $gray02;border-radius:7px;text-align:center;
        .ico-list {
            display:flex;justify-content:center;gap:8px;margin-bottom:40px;padding:14px 0;
            img {width:52px;height:52px;margin-bottom:0;}
        }
        &>img {margin-bottom:40px;width:80px;height:80px;}
        b {display:block;@include mainTxt;}
        span {display:block;margin-top:20px;@include defaultTxt;}

        &-simple {
            display:flex;justify-content:space-between;align-items:center;padding:20px;border:1px solid $gray02;border-radius:7px;
            &+.identifi-box-simple {margin-top:20px;}
            .identifi {
                display:flex;justify-content:flex-start;gap:0 20px;
                img {width:60px;height:60px;}
                .desc {
                    display:flex;justify-content:flex-start;flex-direction:column;
                    b {display:block;@include mainTxt;}
                    span {display:block;margin-top:10px;@include defaultTxt;}
                }
            }
            .ico-list {
                display:flex;justify-content:flex-end;gap:8px;
                li {
                    img {width:40px;height:40px;}
                }
            }
        }
    }
    .identifi-box-wrap {
        display:flex;justify-content:space-between;gap:10px;align-items:stretch;
        .identifi-box {margin:0;flex:1 1 30%;}
    }
    &.no-flex{
        display: block;
        .identifi-box-wrap{
            display: block;
        }
    }
}

.print-use{display: none !important;}
.pc-use{display: block;}

.layer-popup.print-view{ 
    .popup-header {
        position: relative;
        .issuance {
            position:absolute;right:75px;top:27px;
            strong {display: block;font-size: 16px;color:#191919;font-weight:normal;letter-spacing: -0.64px;}
        }
    }
}

@media print {
    .print-view{
        overflow: visible;
        width: 210mm !important;
        height: 297mm !important;
        margin:0 !important;
    }
    .payment-confirm-wrap{
        position: static;
        overflow: visible;
        width: 210mm !important;
        height: 297mm !important;
        margin:0 !important;
        //page-break-after: avoid;
        //page-break-before: avoid;
        margin-top: -1px;
        display:block;
    }
    .layer-popup-print{
        display: none !important;
    }
    .layer-print,.payment-confirm{
        max-width:210mm; margin:0 !important; height: 297mm !important;
        display: block !important;
        position: absolute; top:0; left:0; right:0; bottom: 0;
        overflow: visible !important;
        page-break-inside:avoid;
        page-break-after:auto;
        .popup-body{
            overflow-y: visible !important;
            overflow-x: visible !important;
            max-height: none !important;
        }
        .popup-footer{
         display: none;
        }
    }
    .payment-confirm{
        //position: absolute !important; top:100px !important; margin:0 auto !important;
        transform: none !important; max-height: none !important;
        .popup-header{
            margin-top: 20px;
        }
        .popup-body{
            .cardList-wrap{
                .cardList{
                    padding: 20px;
                }
            }
            .box-table{
                margin-top: 30px;
            }
        }
        .table-type-04{
            page-break-before: auto;
            page-break-inside: auto;
            thead{
                display: table-header-group;
            }
            th{
                background:#eff1f5 !important; -webkit-print-color-adjust: exact; padding: 15px 0;
            }
            td{
                padding: 15px 0;
            }
            tr:nth-child(12){
                page-break-after: always;
            }
        }
        .sign{page-break-after: auto;}
    }
    .bg-official-seal{
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        display: block;
    }
    .pc-use{display: none !important;}
    .print-use{display: block !important;}
}

.service-cancel {
    .page-desc{margin-bottom:10px; }
    
    .num-process-wrap {
        li.location.disabled {
            .number { display:inline-block;position:absolute;left:50%;top:0;width:30px;height:30px;margin-left:-15px;border-radius:50%;background-color:#662d91; font-size: 16px; font-weight: 400; line-height: normal; letter-spacing: -.3px;color:#fff;line-height:30px;text-align: center;}
            &::before {background:#ddd;}            
        }
        li.location.disabled::before {background:#ddd;}
        li.disabled.location:last-child::before {background:#662d91}
        li.disabled + li.disabled::before {background:#ddd;}
        li:last-child:after {display:none;}
        .txt-wrap strong  {font-size:16px}
        .pass {
            .txt-wrap strong  {color:#191919;}
        }
        &  + .main-txt {margin-top:50px;}
    }
    .agreement-box {
        margin-top:10px; padding:20px 10px 20px 20px; border:1px solid #ddd; border-radius: 10px;
        .agreement-inner {
           // overflow-y:auto; height:300px;
            .tite {font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: left;  color: #191919;}
            .tite + .tite {margin-top:20px;}
            .tite + .notification {margin-top:10px;}
            .notification + .tite {margin-top:40px;}
            &::-webkit-scrollbar{
                width: 10px;                       
            }
            &::-webkit-scrollbar-thumb{
                height: 40%;
                background-color: rgba(221,221,221,1);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track{
                border-radius: 10px;
                background-color:rgba(221,221,221,0.4);
            }
        }
        & + .main-txt {margin-top:50px;}
    }
    .btn-check {margin-top:6px;}
    .btn-check + .main-txt {margin-top:53px;}
    .btn-check label {font-size: 16px; font-weight: 600;line-height: 2.5;letter-spacing: -0.32px; color: #191919;}
    .btn-check label em {font-weight:600;}
    .btn-group {margin-top:50px;}

    .checking-msg {
        margin-top:100px; text-align: center;
        .msg-txt {margin-top:50px; font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: center; color: #191919; }
        .txt {margin-top:7px; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: center; color: #666;}
    }
    .cardList-wrap {margin-top:10px;
        .card-img {width:80px;height:80px;} 
        .cardList + .cardList {margin-top:30px;padding:33px 20px;}
        .icon-txt-card {position:relative;}
        .ico-list {
            position: absolute; right:20px; top:50%; transform: translateY(-50%);
            li {float:left; width:40px;height:40px;}
            li + li {margin-left:8px;}
            li img {width:100%;}
        }
    }

    .form-bx {
        margin-top:30px; border-top:1px solid #ddd;
        .box-input input[type=number]:disabled, .box-input input[type=text]:disabled {background:#ddd;}
        .box-select , .box-input {margin-top:40px;}
        .box-btn.inquire {display:inline-block; width:160px; vertical-align: top;}
        .box-btn.inquire button {width:100%;}
        .inquire-box {display: flex;}
        .inquire-view {
            display:inline-block; width:100%; height:58px; margin:0 0 10px 7px; border-radius: 10px; background:#eff1f5; vertical-align: top;
            ul  {display:inline-block; width:60%;margin:20px 0 0 20px;}
            ul li {
                display:inline-block; position:relative; padding-left:10px; font-size: 16px; line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #191919;
                &::before {content:'';position:absolute; left:0;top:9px; width:3px; height:3px; background-color: #666;}
                & + li {margin-left:20px;}
                 strong { font-weight: 600; line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #662d91;}
            }
            .date {display:inline-block;width:33%; margin-right:20px; text-align: right; color:#662d91}
        }
        .input-wrap + p {margin-top:10px;}
        .select-input-wrap {width:100%;margin-top:40px;
            .box-select {width:340px !important; margin:0;}
        }
    }
} 

.servicecancel-popup {
    .line-box {
        padding:28px 30px;border:1px solid #ddd;border-radius: 10px;
        .notification {
            text-align: left;
            li {
                color:#191919;
                .txt-vt {font-weight:600;}
            }
        }
        & +.notification {margin-top:10px;}
    }
    .btn-check {
        margin-top:20px;
        label {font-weight:600;}
    }
    .notification {text-align: left;}
}

.change-plan-conts{
    .line-box {
        padding:28px 30px;border:1px solid #ddd;border-radius: 10px;
        .notification {
            text-align: center;
            li {
                display: inline-block;
                text-align: center;
                color:#191919;
                .txt-vt {font-weight:600;}
            }
        }     
    }
}



.my-bundle {
    .guide-document {
        margin:50px 0; 
        .guide-box {
            padding:40px; border:1px solid #ddd; border-radius: 10px;
            .tit {font-size: 20px; font-weight: 600;  line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}
            .btn-wrp {display:flex; gap:10px; margin:15px 0 20px 0;}
            h4 {margin-bottom:5px; font-size: 16px; font-weight: 600;  line-height: 1.25; letter-spacing: -0.32px; text-align: left;  color: #191919;}
        }
    }

    .phone-serialnumber {
        margin-top:40px;
        .tit {display:block;font-size: 20px; font-weight: bold; line-height: 1.2; letter-spacing: -0.4px; color: #191919;}
        .btn-text-right {margin-top:22px;font-size: 14px; font-weight: 600; line-height: 1.71; letter-spacing: -0.28px; color: #000;}
    }
    .btn-group.serialnumber {margin-top:50px;}
     
 }

 .cardList {
    .txt-box {
        margin-top:40px; border-top:1px solid #ddd; padding-top:20px;
        p {
            font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: left; color: #666;
            & + p {margin-top:10px;}
        }
    }
 }

.layer-popup.bottom-sheet-wrap.dcmntwrite-layer {
    .layer-popup-item .popup-body .cardList-wrap {margin-top:50px; }
    .docment-write {
            .cardList-wrap .normal-txt {display:block; margin-bottom:5px;}
        .rq-form.type-col {width:560px; }
        .rq-form.type-col.docment {margin-top:40px; padding-top:40px; border-top:1px solid #ddd;}
        .rq-form.type-col>.box-flex {width:400px;}
        .rq-form.type-col>.box-flex:first-child {margin-top:0;}
        .rq-form.type-col>.box-flex + .box-flex {margin-top:20px;}
        .rq-form.type-col>.box-flex .box-input {padding-left:160px;}
        .rq-form.type-col>.box-flex .box-input>label {width:160px;}
        .rq-form.type-col>.box-flex .input-wrap {width:270px;}
        .rq-form.type-col>.box-flex p {margin-top:10px}
        .rq-form.type-col>.box-flex .input-wrap.wid {width:400px;}

        .basic-certificate  {
            position:relative;
            .box-flex {
                display: flex; flex-wrap: nowrap; align-items: flex-start; gap: 0 10px; position: relative; width: 400px; margin:0; padding-top:0;
                &+ .box-flex {margin-top:10px;}
                .box-input {padding-left:160px;}
                .input-wrap{width: 270px;}               
                .box-btn {
                    .btns.lightgray {min-width: 120px;  font-size: 16px; font-weight: 600; font-stretch: normal; font-style: normal; line-height: 1.75; letter-spacing: -0.32px; text-align: center; color: #191919; background: #dee1e5;}
                }
                .txt {margin-top:10px;}               
            }
            .input-label { position: absolute;  left: 0; top: 16px; width:160px; text-align: left; color: #191919; font-size: 16px; font-weight: 600; line-height: normal; letter-spacing: -.3px;}
            .file-add {
                margin-bottom:50px;text-align: right;
                .btn {margin-top:-19px;border-radius: 7px; border: solid 1px #dee1e5;  font-size: 16px;font-weight: 600; line-height: 1.75; letter-spacing: -0.32px; text-align: center; color: #191919;background-color: #fff;}
            }
        }
    }

    .dment-tab li + li {margin-top:20px;}
    .gide-bx {margin-top:10px; padding:40px; border:1px solid #ddd; border-radius: 10px;}
    .gide-bx.guide02 {padding:40px 77px;}
    .gide-bx h4 {margin:40px 0 10px 0; font-size: 16px; font-weight: 600;  line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #191919;}
    .gide-bx h4:first-child {margin-top:0;}
    .gide-bx ul {margin-top:5px;}
}

.layer-popup-item.change-nber{
    .cardList-wrap{
        margin-bottom:15px;
            .cardList {
            padding:30px 0;
            li {
                font-size: 16px;  line-height: 1.19; letter-spacing: -0.32px; text-align: center; color: #191919;
                & + li {margin-top:10px;}
                strong {color:#662d91;}
            }            
        }
    }
}        

.layer-popup {
    .isue_notice {
        margin-top:50px;
        .tite {margin-bottom:15px;font-size: 24px; font-weight: 600;line-height: 1.21;letter-spacing: -0.96px;text-align: center;color: #191919;}
        p {font-size: 16px; line-height: 1.5;letter-spacing: -0.32px;text-align: center;color: #666;}
        p +p {margin-top:5px;}
    }

    .order-address-pop {
        .popup-body {
            .rq-form {
                .box-flex {
                    & + .box-flex {margin-top:20px;padding-top:0;}
                }
                .txt-description {margin-top:10px;}
            }
        }
    }

    .application-points {
        min-width:700px;
        .tit {
            display:block; font-size: 24px;font-weight: 600;line-height: 1;letter-spacing: -0.48px;text-align: left; color: #191919;
            .txt {font-weight:normal;font-size:24px; color: #191919;}
        }
        .sb-tit {margin-top:20px;padding-bottom:20px; font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}
        .point-view {
            margin:20px 0 0 0;
            padding-bottom:20px;
            .view-line {
                padding:40px 40px 30px 40px;border-radius: 10px;border:1px solid #ddd;
                .tite {display: block; font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.3197px;text-align: left;color: #191919;}
                .box-select {width:100%; margin-top:15px;}
                .box-select + dl {margin-top:20px;}
            }
            dl {
                display: flex; flex-wrap: wrap;
                dt {width:50%; margin-bottom:10px; font-size: 16px; line-height:1; letter-spacing: -0.32px; text-align: left; color: #666;}
                dd {width:50%;margin-bottom:10px; font-size: 16px; font-weight: 600; line-height: 1; letter-spacing: -0.32px;text-align: right; color: #191919;}
            }            
        }
        .txt {font-size: 16px;line-height: 1.5;letter-spacing: -0.32px; color: #666; text-align: left;}
        .itm-choice {
            display: flex;flex-wrap: wrap;margin-top:22px; justify-content: space-between; align-items: center;
            p { font-size: 16px; line-height: 1.5; letter-spacing: -0.32px; text-align: left;color: #666;}
            ul {
                display: flex;
                li + li {margin-left:60px;}
            }

        }
        .point-sum {
            dl {
                display:flex; flex-wrap: wrap;justify-content: space-between;align-items: center;
                dt {font-size: 24px;line-height: 1;letter-spacing: -0.48px;text-align: left;color: #666;}
                dd {font-size: 24px;  font-weight: 600; line-height: 1; letter-spacing: -0.48px; text-align: right;color: #662d91;}
            }
            .txt-sum {margin-top:20px;  font-size: 16px; line-height: 1.5;  letter-spacing: -0.32px;text-align: left;color: #666;}
        }
        .recmnd-change {
            margin-top:20px;
            .notification-wrp {
                margin-top:50px;text-align: left;
                h3 {margin-bottom:10px; font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}
            }
        }
    }   
}

.notification-wrap {        
    .txt {font-size: 16px;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}    
    .silent-box {
        &:first-child {margin-top:0;}
        margin-top:20px;        
        .silent-tit {display:block;margin-bottom:5px; font-size: 16px;font-weight: 600; line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #191919;}
    }
}

.num-change-info {
    .my-bundle {
        .num-process-wrap {
            li {
                .txt-wrap {
                    p {
                        color:#191919;font-weight:600;
                     .normal-txt {font-weight:normal; letter-spacing: -1px;}
                    }
                }
                .blt-list {
                    width:160px; margin-top:10px;
                    li {
                        position: relative; padding-left:10px;line-height: 1.36;font-size: 14px;color:#666;letter-spacing:-1px;text-align:left;
                        &::before {content:'*'; position: absolute; left:0; top:2px;}
                        & + li {margin-top:2px;}
                    }
                }
            }
        }   
        .change-nberview {
            padding: 40px 0; border-radius: 10px;border: solid 1px #ddd;background-color: #fff;text-align: center;
            .txt01 {
                font-size: 20px;font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: center;color: #191919;
                strong {color:#662d91;font-size:20px;font-weight:600;}
            }
            .txt02 {
                margin-top:10px; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;text-align: center;color: #666;text-align: center;
                strong {color:#662d91;font-size:16px;font-weight: 600;}
            }
        }    
    }
    .notification {
        li {
            p {
                .btn-text-right-s {margin-left:10px; color: #191919;font-size: 14px;font-weight: 600; text-decoration:none;}
            }
        }
    }
}


    

@page {
  size:210mm 297mm; /*A4*/
  margin:0;
 }