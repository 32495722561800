@charset "UTF-8";


// DESKTOP (PORTRAIT)
@media (max-width: 1440px) {

}
// pc
@include response("pc") {
    html {font-size: 10px;}
}

// TABLET
@include response("tablet") {
    html {min-width:1280px; font-size: 52%;}
}

// MOBILE
@include response("mobile") {
    .pc {
        display: none !important;
    }
    .mobile {
        display: block !important;
    }
}