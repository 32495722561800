@charset "UTF-8";

// extend
%btn {
    @include normalTxt; color: $white;width:260px; height: 58px; background:$purple; padding:20px 0; border-radius:7px; text-align: center;
}
.btns{
    @extend %btn; display: inline-block; text-align: center;
    &.wid{
        width:100%;
    }
    &.w-md{
        @extend %btn; width:auto;min-width:200px; height:58px; padding:0 20px;
    }
    &.w-sm{
        @extend %btn; /*max-width:140px;*/width:auto;min-width:140px; height:58px; padding:0 30px; display: flex; justify-content: center; align-items: center;
    }
    &.w-xs{
        @include smallTxt; width:auto;min-width:84px; height:30px; padding:0 30px;line-height:30px;
    }
    &.line{
        @extend %btn; background: $white; border:1px solid $gray02; color:$black; transition: border 0.3s;
        &:hover{
            border:1px solid $black;
        }
        &.purple {
            border:1px solid $purple; color:$purple;
        }
    }
    &.lightgray{
        background:$gray06; border:none; color:$gray05;
    }
    &.corner-half{
         @extend %btn; padding:56px 0; border-radius:0 0 40px 40px;
    }
    &.btns-radio{
         @extend %btn;  padding: 50px 0; border-radius: 28px; border: 4px solid #ddd; background: #fff;  font-size: 60px;  line-height: 1.3; letter-spacing: -1px; transition:0.3s;
        &:hover, &:active, &:focus{
            border-color: $purple; color: $purple;
        }
    }

    &.sticky{
        @extend %btn; border-radius:0;
        &.white{
            @extend %btn; background: $gray06;
        }
    }

    &:disabled{
        @extend %btn; background-color:$gray02;  cursor: default; border: none !important;color:#afafaf;
    }

}
.btn-dark-gray{
    @include normalTxt; color: $white; height:58px; background:$gray05; border-radius:7px; min-width:120px;
    &:disabled{
        background-color:$gray02;  cursor: default; color:#afafaf;
    }
}
.btn-underline{
    @include defaultTxt; text-decoration: underline; display: block; margin:40px auto; text-align: center;
}
.btn-text{
    @include normalTxt;
    &-right{
        @extend .btn-text; padding-right:25px;
        @include backgroundImg('/assets/images/pc/icon/ico-arrow-right.svg', right,20px 20px); display: inline-block;

        &.line{
           width:100%; border:1px solid #ddd; padding:36px 40px; border-radius:7px;
        }
    }
    &-right-s{
        @extend .btn-text; padding-right:22px;@include smallTxt;
        @include backgroundImg('/assets/images/pc/icon/ico-arrow-right.svg', right,15px 15px); display: inline-block;
    }
    &-plus{
        @include backgroundImg('/assets/images/pc/icon/ico-plus-sm.svg', calc(100% - 2px) center, contain);
        @extend .btn-text; padding-right: 40px;
    }
    &-more{
        @extend .btn-text; min-width: 260px; padding: 20px 0; border-radius: 7px;
        .count{
           @extend .btn-text; padding-right: 40px; position: relative;
           &::after{
            @include backgroundImg('/assets/images/pc/icon/ico-arrow-bottom.svg',center center, contain);
            content: ''; position:absolute; top:50%; right:10px; transform: translateY(-50%); width: 15px; height: 25px;
           }
        }
    }
    &-gray{
        @extend .btn-text;
         justify-content: space-between; align-items: center; width:100%; background:$gray01; padding: 42px 60px; border-radius:28px;
    }
    &-num {
        @extend .btn-text;
        justify-content: space-between; align-items:center;display: flex; padding:16px 20px;border:1px solid $gray02; border-radius:7px;
        .text {
            @include smallTxt;
            .num {width:20px;height:20px;margin-left:20px;border-radius:50%;background:$purple;@include smallDepthTxt2; color:$white;font-size:14px;line-height:20px; display: inline-block; text-align: center;}
        }
    }
    &-line.btn-check{
        padding: 13px 20px; border:1px solid $gray02; border-radius: 7px;
        label{
            @include normalTxt;
             .txt-vt {margin-right:5px;display:inline-block;}
        }
    }
}

.downLoad-list{
    display: flex; flex-wrap: wrap; gap:20px;
    li{
        width: 48%;
        a{
            display: flex; justify-content: space-between; align-items: center; width: 100%; padding:30px; border-radius: 10px; border:1px solid $gray02; gap:20px;
            .downLoad-tit{
                width:calc(100% - 28px); @include normalTxt;
            }
        }
    }
}
.link-list{
    display: flex; flex-wrap: wrap; gap:20px;
    li{
        width: 48%;
        a{
            display: flex; justify-content: space-between; align-items: center; width: 100%; padding:20px; border-radius: 10px; border:1px solid $gray02; gap:20px; background-color: $white;
            .link-tit{
                width:calc(100% - 28px); @include normalTxt;
            }
        }
    }
}
.btn-group{
     flex:1; width:100%; display: flex; gap:10px; align-items: center; justify-content: center;
    // &.btn-fix{
    //      left:0;bottom:0;width:100%;
    // }
}
.popup-btn{
    border-radius:0 0 40px 40px;
}


//툴팁
.tooltip-wrap{display: flex; align-items: center; gap: 12px;}
.box-tooltip{
     display: inline-flex;align-items: center;  position: relative;font-size: 0;vertical-align:middle;  cursor: pointer;
    .btn-tooltip{
         width: 16px;height: 16px;@include backgroundImg('/assets/images/pc/icon/ico-tip.svg', center,contain); display: inline-block;    vertical-align: middle; z-index: 1;
        &.wh{
            @include backgroundImg('/assets/images/pc/icon/ico-tip-wh.svg', center,contain);
        }
    }

    .txt-tooltip{
        position: absolute; top: 30px; left:-75px; /* width: 75.4167vw;  */     min-width: 600px; width: 100%; background-color: $white; box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);border: solid 1px $gray02;border-radius: 7px; transform: translateY(-20%); opacity: 0; visibility: hidden; transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;padding:40px 50px; z-index: 2;
        &.on{transform: translateY(0); opacity: 1; visibility: visible; z-index: 3;}
    }
}

// 날짜 버튼
.month-box {
    display: flex; justify-content: space-between; align-items: center; border:1px solid $gray02; border-radius: 7px;padding:15px;
    strong {@include normalTxt;}
    .btn-month {
        width:20px !important;height:20px !important;
        &.ico-arrow-left{
            background-image: url('/assets/images/pc/icon/ico-arrow-thin-right.svg');transform: rotate(-180deg);background-size:20px 20px;
            &.disabled {background-image: url('/assets/images/pc/icon/ico-arrow-gray.svg');}
        }
        &.ico-arrow-right{
            background-image: url('/assets/images/pc/icon/ico-arrow-thin-right.svg');transform: rotate(0deg);background-size:20px 20px;
            &.disabled {background-image: url('/assets/images/pc/icon/ico-arrow-gray.svg');}
        }
    }
}

// 마크가 있는 버튼
.btn-mark {
    position:relative;
    .mark {
        position:absolute;left:50%;top:-20px;z-index:1;padding:5px 12px;background:#eb068b;transform:translateX(-50%);border-radius: 5px; min-width: 155px;text-align: center;
        @include smallDepthTxt; color: $white;
        &::before {content:'';display:block;position:absolute;left:50%;bottom:-5px;width:10px;height:10px;background:#eb068b;transform:rotate(45deg); z-index: -1;}
    }
}

// 카운트 버튼
.count-wrap{
    display: flex; align-items: center; gap: 30px;
    .count-btn{
        width: 40px; height: 40px;
        &.minus{
            @include backgroundImg('/assets/images/pc/icon/ico-btn-minus.svg',center center,contain);
            &.disabled{
                background-image: url('/assets/images/pc/icon/ico-btn-minus-disabled.svg');
            }
        }
        &.plus{
            @include backgroundImg('/assets/images/pc/icon/ico-btn-plus.svg',center center,contain);
            &.disabled{
                background-image: url('/assets/images/pc/icon/ico-btn-plus-disabled.svg');
            }
        }
    }
    .count-num{
        @include normalTxt;min-width: 10px; text-align: center;
    }
    .total-count{
        @include mainTxt;
        & > span{@extend %fontInherit;}
    }
}

//링크&버튼 언더라인
.underline-vt {color:$purple;text-decoration:underline;}
.underline-bk {color:$black;text-decoration:underline;}