@charset "UTF-8";

/*** 전체 공통 ***/
%fontInherit {
    font-size: inherit; font-weight: inherit; letter-spacing: inherit; line-height: inherit; color: inherit;
}

.box-address {
    .box-flex {
        padding-top:0;
        .box-input {margin-top:0;}
    }
    .box-input {margin-top:24px;}
    .btn-check {margin-top:20px;}
    .today-noti {margin-top:20px;@include defaultTxt;color:$purple;}
}

// 약관 동의
.agree-wrap {
    margin-top:50px;
    #agreeAll {
        &+label {
            span{@include defaultTxt;}
        }
    }
    .agree-group {
        margin-top:40px; padding:0 30px;
        .btn-check {
            margin-bottom:30px;
            label {
                span {
                    &>strong {@extend %fontInherit;color:$purple !important;}
                    &>b {@extend %fontInherit;color:$gray05 !important;}
                }
            }
        }
    }
}
.box-agree {
    &+.btn-check {
        margin-top:60px;
        label {
            span {font-weight:400;}
        }
    }
}


/*** usim 단독 ***/
.usim {
    .point {color:$purple;font-size:inherit;font-weight:inherit;line-height:inherit;}
    .box-tooltip {
        .txt-tooltip {
            bottom:auto;top:56px;left:-127px;z-index:5;
            &>span {

                &::after {bottom:auto;top:-18px;transform:rotate(-135deg);}
            }
        }
    }
    .link-box {
        &+.txt-dot {margin-top:30px;}
    }
    .box-btn {
        &.result-more {padding:40px 0;text-align:center;}
    }
    .bang-notice {line-height:1.33;}
    // 유심/eSIM 가입
    .unit-item{margin-top: 40px;}
    .usim-esim-join{
        .flex-layout{
            display: flex; gap: 30px;
            .payment-imgCard-btn{
                margin: 0;
                .cardList{
                    display: flex; flex-direction: column; align-items: center; justify-content: center; min-height: 432px;
                    .item {
                        &-top {
                            width: auto;
                            img{display: block; width: 200px; margin: 0 auto;}
                            .txt-top{
                                margin-top: 20px;
                                p {
                                    @include mainTxt; font-weight: 700;
                                }
                                .box-btn {
                                    display: flex; justify-content: center; gap: 30px; margin-top: 10px;
                                }
                            }
                        }
                        &-bottom {
                            border: 0; padding: 0; margin-top: 50px;
                            .btns{display: block;}
                        }
                    }
                }
            }
        }
        .usim-review {margin-top:140px;}
    }
    .usim-esim-phone-info{
        .btn-radio-group{
            .btn-radio {
                label{
                    display: flex; gap: 10px; align-items: center;
                    &.icon-iphone{
                        &::before {
                            content: ''; display: block; width: 24px; height: 24px;
                            @include backgroundImg('/assets/images/pc/icon/ico-apple-logo.png', center, contain);
                        }
                    }
                    &.icon-galaxy{
                        &::before {
                            content: ''; display: block; width: 64px; height: 18px;
                            @include backgroundImg('/assets/images/pc/icon/ico-galaxy-logo.png', center, contain);
                        }
                    }
                }
            }
        }
        .no-result {
            margin-top: 100px;
            .no-result-txt {
                padding-top: 170px; background-position: center top;
                background-image: url('/assets/images/pc/icon/ico-phone-check-big.svg');
            }
        }
        .box-table{
            .table-title {@include normalTxt;}
            .table-type-04 {
                th{
                    padding: 20px 0;
                    @include mainTxt;
                }
                td{
                    padding: 20px 0;
                }
                tbody{
                    .btn-radio-type2{
                        label{
                            strong{
                                display: block; flex-shrink: 0;@include defaultTxt;
                                span{display: inline-block; margin-right: 0;}
                                &:nth-of-type(1) {
                                    width: 20%; text-align: center;
                                }
                                &:nth-of-type(2) {
                                    width: 40%;
                                }
                                &:nth-of-type(3) {
                                    width: 40%;
                                }
                            }
                        }
                        input[type=radio] {
                            &:checked + label {
                                strong{
                                    color: $purple;
                                }
                            }
                        }
                    }
                }
            }
        }
        .cardList-wrap{
            .cardList-title{font-size: 16px; }
        }
        .box-input {
            .input-label{
                display: flex; justify-content: space-between; align-items: center;
            }
        }
        .btn-group{
            margin-top: 30px;
        }
    }
    .usim-esim-unique-num{
        .page-desc{
            margin-bottom: 10px;
        }
        .box-btn {
            &.flex-box{
                display: flex; gap: 20px; justify-content: center; margin-top: 20px;
            }
            .btn-dark-gray{
                min-width: 270px;
            }
            .btns{
                width: 270px;
            }
        }
        .phone-unique-num{
            .unit-item:first-of-type{margin-top: 50px;}
            .img-wrap{
                margin-top: 70px;
                img{
                    display: block; width: 240px; margin: 0 auto;
                }
            }
            .box-input {
                .txt-gy {
                    display: block; margin-top: 20px;
                }
            }
        }
        .btn-group{margin-top: 30px;}
    }
    .usim-esim-unique-check{
        .unit-item{margin-top: 50px;}
        .box-tab{
            margin: 0;
            .tab-btn-wrap{
                margin-bottom: 50px;                
            }
            .page-desc {
                .txt-rd02 {display:block; margin-top:10px; color:#cf192b}
            }
        }
        .cardList-wrap + .box-tab  {margin-top:50px;}
        .page-desc{margin-bottom: 10px !important;}
        .desc-sub-txt{
            @include defaultTxt;
            & + .desc-sub-txt {margin-top: 10px;}
        }
        .img-wrap{
            img{
                display: block;width: 100%;
            }
            & + .desc-sub-txt{margin-top: 20px;}
            &.type2 {
                width: 240px; margin-top: 20px;
            }
        }
        .cardList-wrap{
            .cardList{
                & > div{
                    & + div{margin-top: 30px;}
                    strong{@include normalTxt;}
                }
                .notification{margin-top: 10px;}
                &.flex-layout{
                    display: flex; gap: 80px;
                    & > div + div{margin-top: 0;}
                    .normal-txt {
                        & + p{
                            @include defaultTxt; margin-top: 10px;
                        }
                    }
                }
            }
        }
    }
    .usim-esim-support{
        .box-tab{
            margin: 0;
            .tab-btn-wrap{
                margin-bottom: 50px;
            }
        }
        .cardList{
            .phone-list{
                display: flex; flex-wrap: wrap; gap: 10px 0;
                li{
                    width: 50%;
                    @include defaultTxt;
                }
            }
        }
    }

    .subscribe-adress {
        .page-tit {
            h1 {margin-bottom:0;}
        }
        &.address-result {
            .popup-header {max-height:none !important;padding-bottom:10px;}
            .box-flex {
                .box-btn {margin-top:0;}
            }
            .result-noti {
                display:flex;justify-content:space-between;align-items:center;
                &>span {@include mainTxt;}
            }
            .radio-block {
                li {
                    border-bottom:1px solid $gray02;
                    &>div{
                        padding:20px 20px 20px 40px;
                        p {@include defaultTxt;color:$black;}
                    }
                }
            }
            .bang-notice {margin-top:20px;}
        }
    }
    .btn-dark-gray {
        &.h-sm {padding:24px 0;color:$white;}
    }
    .ualmo-point {
        .noti {margin-top:20px;}
    }

    .directmall {
        .rq-form{
            padding-bottom: 50px; margin-bottom: 0; border-bottom: none;
        }
        .txt-vt{
            * {@extend %fontInherit;}
        }
        .tooltip-wrap{
            .txt-tooltip{
                top: 30px; left: 0;
                .info-wrap{
                    & + .info-wrap{margin-top: 20px;}
                    strong{@include normalTxt; display: block;}
                    p{
                        @include defaultTxt; margin-top: 10px; line-height: 1.5;
                    }
                    .txt {margin:0;}
                }
            }
        }
        .box-select{width: 530px;}
        .box-input{
            &.size-sm {width: 340px; flex-shrink: 0;}
            &.size-md {width: 535px; flex-shrink: 0;}
        }
        .form-wrap{
            display: flex; align-items: center;margin-top: 40px;
            &.agree-wrap{
                margin-top: 80px;
            }
            &.top{
                align-items: flex-start;
                .form-title{padding-top: 20px;}
            }
            &.one-chip{
                margin-top: 75px;
                .form-cont {
                    position: relative;
                    .type-click {
                        position:absolute; right:0; top:-33px;
                        .click-tooltip{display: inline-flex;align-items: center;  position: relative;font-size: 0;vertical-align:middle; }
                        .btn-click {
                            position:relative; width:106px; height:26px; padding-left:12px;  border-radius: 100px; background-image: none; background-color: #efd9ff;font-size: 14px; font-weight: 500; line-height:26px; letter-spacing: -0.28px; text-align: left; color: #191919;
                            &::after {content: ''; position:absolute;right:12px;top:8px;width:6px;height:9px; background: url('/assets/images/pc/icon/ico-arrow-right.svg') no-repeat 50% 50%; background-size: 15px;}
                        }
                        .txt-tooltip {
                            position: absolute; top:0; left:115px; min-width: 600px; width: 100%; background-color: $white; box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16); border-radius: 7px; transform: translateY(-20%); opacity: 0; visibility: hidden; transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s ease;padding:40px; z-index: 2;
                            &.on{transform: translateY(0); opacity: 1; visibility: visible; z-index: 3;}
                            .tip-tite {display:block; margin-bottom:10px; font-size: 16px; font-weight: 600; line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #191919;}
                        }
                       .close-tooltip {position: absolute; right: 20px; top: 20px;width: 20px; height: 20px; font-size: 0; z-index: 1; background: url('/assets/images/pc/icon/ico-close.svg') no-repeat center;  background-size: 15px;}
                    }
                }
            }           

            &:first-of-type{margin-top: 0;}
            .box-flex{padding: 0; margin-top: 0;}
            & + .main-txt-group{
                margin-top: 80px;
            }
            .select-input-wrap{
                display: flex; gap: 10px; width: auto; flex-wrap: nowrap;
                .box-flex{
                    margin: 0; padding-top: 0; width: auto;
                }
                // .box-select{
                //     width: 180px;
                // }
                .btn-select{width: 185px; max-width: 185px;}
                .box-select{width: 185px;}
                & + .box-flex{margin-top: 20px;}
            }
            .input-depth{
                display: flex; flex-wrap: wrap; gap: 0;flex-direction: row;
                & > .box-input{
                    margin-top: 10px;
                    & + .box-input{margin-left: 10px;}
                }
                .btn-check{
                    width: 100%; justify-content: space-between; flex-direction: row-reverse;
                    margin-top: 10px;
                }
                .today-noti{
                    @include normalTxt; color: $purple; padding-left: 30px;
                    @include backgroundImg('/assets/images/pc/icon/ico-today-noti.svg',left center,20px 20px);
                }
            }
            .form-title{
                display: flex; flex-direction: column; gap: 10px; flex-shrink: 0; width: 200px; margin: 0;
                @include normalTxt;
            }          
            .delivery-way{
                display: flex; gap: 20px; flex-wrap: wrap;
                .btn-radio{
                    margin-top: 0;
                    label{gap: 10px;}
                }
            }

            .form-cont {
                .txt-usm {display:inline-block; margin-top:10px;}
                .usm-tip {
                    display:inline-block; 
                    .box-tooltip {margin-top:-2px;}
                    .txt-tooltip { left:-340px;}
                }
            }

            .textarea-wrap{
                display: none;margin-top: 10px;
            }
            .box-input,.box-select{margin-top: 0;}
            .find-convenience{
                width: 260px;
                button{width: 100%;}
            }
        }

        .recipient {
            margin-top:40px;
            .input-wrap {
                display: flex;width:800px;
                .inpt-area {
                    width:340px;
                    .ico-delete {right:480px;}
                }               
            }
            .btn-check {margin-left:10px;}
            .txt-msg {margin:10px 0 0 200px; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #662d91;}
        }
        .payment-form{
            display: flex; gap: 70px; justify-content: space-between;
            .payment-img{
                display: block; width: 250px;
            }
            .payment-conts{
                display: flex; flex-direction: column; gap: 40px;
                li{
                    display: flex; align-items: center;position: relative;
                    &:nth-of-type(2) {
                        .conts-title{
                            position: absolute; top: 22px;
                        }
                        .conts-box{padding-left: 200px;}                       
                    }
                    .total-count .dc-price {display:inline-block;margin-right:8px;font-size:16px;color:#959595;text-decoration: line-through;}
                    .payment-amount .dc-price {display:inline-block;margin-right:8px;font-size:20px;color:#959595;text-decoration: line-through;}
                }
                .conts {
                    &-title{
                        @include normalTxt;
                        display: block; width: 200px;
                    }
                    &-box {
                        .box-input{
                            input {
                                @include normalTxt; color: $purple;
                            }
                            .input-unit{margin-bottom: 0; top: 50%; bottom: auto; transform: translateY(-50%); @include normalTxt; color: $purple;}
                            .point-info{
                                @include defaultTxt;
                                margin-top: 10px;
                                & + .txt-bk {margin-top:10px;font-weight:600;}
                            }
                        }
                        .payment-amount{
                            font-size: 20px; font-weight: 400; letter-spacing: -0.4px; color: $gray05;
                            .txt-vt{
                                display: inline-block; @include contsTitle; font-weight: 700; margin-right: 10px;
                            }
                        }
                        .radio-group-wrap{margin: 0;}
                        .btn-radio-group{
                            width: 700px;
                        }
                    }
                }
            }
        }        
        .notice-wrap{
            margin-top: 80px;
            .main-txt {
                display: block; margin-bottom: 10px;
            }
        }
        // .select-input-wrap {
        //     &+.box-btn {
        //         margin-top:20px;
        //         button, a {width:100%;}
        //     }
        // }
        // .box-btn {
        //     &+.box-flex {padding-top:20px;}
        // }
        // .box-flex {
        //     .success {
        //         &+.box-btn {display:none;}
        //     }
        // }
        .box-present-corp {
            display:flex;flex-wrap:wrap;
            &>li {flex:1 1 30%;align-self:center;margin-bottom:30px;}
            &>li.btn-combo {
                display:flex;justify-content:flex-start;
                .btn-radio-type2 {align-self:center;width:auto;height:40px;}
                .box-select {
                    align-self:center;width:auto;min-width:186px;
                    select {width:auto;min-width:186px;}
                }
            }
        }
        .buy-finish {
            h2 {
                margin-bottom:80px;@include contsTitle;
                &+.txt {margin:-40px 0 40px;@include smallTxt;line-height:1.33;}
            }
            .cardList-wrap {
                &+.cardList-wrap {margin-top:30px;}
                .cardList {
                    dl {
                        dt {width:223px;}
                        dd {width:calc(100% - 223px);}
                    }
                    .box-btn {
                        margin-top:30px;
                        button {width:100%;}
                    }
                }
            }

        }
        .purchase-num {
            display:flex;justify-content:space-between;margin-bottom:80px;
            .box-num {
                display:flex;justify-content:space-between;width:215px;
                .num {
                    width:80px;border:none;@include defaultTxt;text-align:center;
                    &:disabled {background:$white;}
                }
                button {
                    display:block;overflow:hidden;width:60px;height:60px;border-radius:7px;background-color:$gray02 !important;font-size:0;text-indent:-70px;transition:0.3s;
                    &:focus {background-color:$purple !important;}
                    &.btn-minus {@include backgroundImg('/assets/images/pc/icon/ico-minus-wh.svg', center center, 26px 4px);}
                    &.btn-plus {@include backgroundImg('/assets/images/pc/icon/ico-plus-wh.svg', center center, 26px 26px);}

                }
            }
        }
        .payment-method {
            margin-bottom:80px;
            .btn-radio {
                label {
                    display:flex;justify-content:center;align-items:center;min-height:80px;padding:0;
                    img {max-height:50px;}
                }
            }
        }
        .btn-sum {
            display:flex;flex-wrap:wrap;border-top:2px solid $gray02;background:$white;
            .payment-sum {
                display:flex;justify-content:space-between;align-self:stretch;width:100%;height:108px;padding:0 40px;
                h2 {align-self:center;margin-bottom:0;}
                .sum {
                    align-self:center;
                    @include smallTxt;line-height:1.33;text-align:right;
                    strong {@include contsTitle;color:$purple;line-height:40px;}
                }
            }
            button {flex:1 1 40%;}
        }
        &-view {
            .unit-tit-b{margin-bottom: 50px;}
            .step-list{margin-top: 10px;}
            .openmarket-list-noti {margin-bottom:30px;@include defaultTxt;}
            .openmarket-list {
                .cardList-wrap {
                    &+.cardList-wrap {margin-top:30px;}
                }
            }
           .banner-box {
                min-height:200px; margin-top:70px;
                img {vertical-align: top;}
           }
        }
    }
    //팝업
    .map-view {
        .box-btn {margin-top:30px;}
        .map-wrap {margin-top:40px;}
    }
    .transfer-telecom {
        .telecom-top{
            width: 100%;
            .corp-tit {display:block;margin-bottom:30px;@include mainTxt;}
            .corp-list {
                margin-bottom:40px;
                .btn-radio {
                    label{
                        display: flex; align-items: center; justify-content: center;line-height: initial;
                        &::before {
                            content: '';
                            display: inline-block; margin-right: 10.0001px; width: 26px; height: 26px;
                            @include backgroundImg('/assets/images/pc/icon/logo-skt.svg', center, contain);
                        }
                    }
                    &:nth-of-type(2) {
                        label::before {
                            background-image: url('/assets/images/pc/icon/logo-kt.svg');
                        }
                    }
                    &:nth-of-type(3) {
                        label::before {
                            background-image: url('/assets/images/pc/icon/logo-lg.svg');
                        }
                    }
                }
            }
            .telecom-tit {display:block;margin-bottom:16px;@include normalTxt;}
        }
        .telecom-list{
            margin-top: 40px;
            .btn-radio-group{
                gap: 20px 0;
                .btn-radio{
                    width: calc(100% / 3 - 20px); margin-left: 20px;
                    &:nth-child(3n + 1){margin-left: 0;}
                    label{width: 100%;padding: 0;}
                }
            }
        }
    }
    // .delivery {
    //     &-tit {
    //         margin-bottom:30px;
    //         h2 {display:inline-block;@include defaultTxt;vertical-align:top;}
    //         .tooltip-wrap {display:inline-flex;margin:5px 0 0 20px;vertical-align:top;}
    //     }
    //     &-way {
    //         margin-bottom:80px;
    //         .btn-radio {
    //             &+.btn-radio {margin-top:30px;}
    //         }
    //     }
    //     &-place {
    //         margin-bottom:80px;
    //         button, a {width:100%;}
    //     }
    //     &-request {
    //         margin-top:40px;
    //         h2 {display:block;margin-bottom:16px;}
    //         .textarea-wrap {margin-top:20px;}
    //     }
    // }
    .pickup {
        .search-opt {
            .serach-input-wrap {padding-top:20px;}
            .opt-subway {
                display:flex;flex-wrap:wrap;gap:20px;padding-top:20px;
                .box-select {flex:1 1 40%;}
            }
        }
    }
    .used-phone {
        .phone-info {
            margin-bottom:80px;
            dt {margin-bottom:10px;@include defaultTxt;}
            dd {
                @include smallTxt;line-height:1.33;
                &+dt {margin-top:40px;}
            }
        }
        .notification {margin-bottom:80px;}
        .accordian {
            .notification {margin-bottom:0;}
        }
    }
    .usim-install {
        .install-guide {
            dt {display:block;margin-bottom:10px;@include mainTxt;}
            dd {
                display:block;position:relative;
                &+dd {margin-top:10px;}
                .num {
                    display:block;position:absolute;left:50%;top:0;width:60px;height:60px;margin-left:-30px;border-radius:50%;background:$purple;
                    @include smallTxt;color:$white;line-height:60px;text-align:center;
                }
                .txt {display:block;@include defaultTxt;}
                img{display: block;}
            }
            &+.install-guide {margin-top:70px;}
        }
    }
    //유심개통안내
    .opening-guide{
        &.esim-guide{
            .unit-tit-b{
                &.type2{
                    margin-bottom: 30px;
                }
            }
            .cardList-wrap{margin-top: 0;}
            .flex-layout{
                display: flex; flex-wrap: wrap; gap: 30px;
                .qr-wrap{
                    display: flex; align-items: center; gap: 30px; width: 100%;
                    .qr {
                        &-img {
                            width: 120px; height: 120px; border-radius:10px; border: 1px solid $gray02; padding: 20px; flex-shrink: 0;
                            img{display: block; width: 100%;}
                        }
                        &-desc {
                            @include defaultTxt;
                        }
                    }
                }
                .cardList-wrap{
                    width: calc(50% - 15px); height: 200px; margin: 0;
                    .cardList {
                        display: flex; height: 100%; align-items: center; gap: 40px;
                        .icon-wrap{
                            width: 80px; height: 80px;
                            img{display: block; width: 100%;}
                        }
                    }

                }
            }
            .company-wrap{
                .unit-tit-b{
                    display: flex; justify-content: space-between; align-items: center;
                    .box-btn {
                        .btn-text-right {
                            font-size: 20px;
                        }
                    }
                }
                .cardList-wrap {
                    height: 134px;
                    .icon-wrap{
                        width: 120px !important; height: 120px !important;
                    }
                }
            }
            .accordian-wrap{
                margin-top: 50px;
                .accordian{
                    .acc-trigger{
                        @include mainTxt; display: inline-block; text-align: left; width: auto; padding-right: 40px; margin-bottom: 50px;
                    }
                    .acc-conts{
                        padding-bottom: 50px;
                        .cardList-wrap {
                            margin-top: 0;
                            & + .cardList-wrap {margin-top: 30px;}
                        }
                        .top-wrap{
                            .main-txt{margin-bottom: 30px;}
                            .qr-img{
                                width: 280px; height: 280px; padding: 20px; border-radius: 7px; border: 1px solid $gray02;margin-bottom: 50px;
                            }
                            .cardList-wrap{margin-bottom: 30px;}
                        }
                    }
                }
            }
            .guide-process{
                &-title {
                    display: block; margin-bottom: 20px; @include normalTxt;
                }
                display: flex; gap: 60px 106px; flex-wrap: wrap;
                li{
                    position: relative; width: 25%; max-width: 220px;
                    &:not(:first-of-type) {
                        &::before{
                            content: ''; position: absolute; left: -70px; top: 102px; display: block; width: 40px; height: 40px;
                            @include backgroundImg('/assets/images/pc/icon/ico-right-arrow-thin.svg');
                        }
                    }
                    &:nth-of-type(5) {
                        &::before{display: none;}
                    }
                    img{
                        display: block; width: 100%;
                    }
                    p {
                        @include normalTxt01; margin-top: 20px;
                        display: flex; gap: 10px;
                        .num{
                            @extend %fontInherit; display: block;
                        }
                    }
                }
            }
            .video-thumb-list{
                margin-top: 50px;
                .video-img{
                    width:406px; border-radius: 20px;  overflow: hidden;
                    iframe{
                        width: 100%; height:254px; display: block;
                    }
                }
            }
        }
        .box-btn{
            margin-top: 10px;
        }
        .icon-left{
            .notification{
                margin-top: 10px !important;
            }
        }
        .cardList-wrap{
            margin-top: 50px;
        }
        .banner{
            .left{
                .img{
                    width: 270px;
                }
            }
            .right{
                button{
                    background: #9572ce;
                }
            }
        }
        .img-left{
            margin-top: 70px; padding-left: 270px;
        }
        .notification{margin-top: 10px;}
    }

    .usim-banner {
        padding:60px;background:#f9f5ff;
        &:after {content:'';display:block;clear:both;}
        .vdo-box {float:left;width:460px;height:260px;}
        .txt-box {float:left;margin:20px 0 0 70px; position: relative;}
        .txt-box .txt01 { display:block; font-size: 36px; line-height: 1;letter-spacing: -0.72px;color: #191919; font-weight: 700;}
        .txt-box .txt01 span {font-size: 36px;color:#662d91;  font-weight: 700;}
        .txt-box .txt02 {display:block;margin-top:20px;font-size: 20px; line-height: 1.2; letter-spacing: -0.4px; color: #191919;}
        .txt-box button {margin-top:75px;}
        .banner-randombox{
           left: -25px; bottom: 65px;
        }
     }



    //지도보기
    .buy-mapview{
        .map-area{
            width:100% !important; height:360px; background:#ddd; overflow: hidden; margin-top: 10px;
            // & > div{
            //     left: 50% !important;
            // }
        }
    }
    //편의점 조회 - 공통
    .buy-pickup{
        .main-txt{
            margin-top: 40px;
        }
        .filter-wrap{
            margin-top: 50px;
            .box-flex{
                padding-top: 20px;
            }
        }
        .place-address{
            .chip-wrap{
                    margin-bottom: 20px;
                    .chip{
                        &:first-child{
                            background-color:#b728bc;
                        }
                        &:nth-child(2){
                            background-color:#5257a1;
                        }
                    }
                }
            dt{
                h3{ @include mainTxt;}
            }
            .usm-lengt {
                font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: left; color: #191919; 
                & + .usm-lengt {margin-left:8px;}
            }
            .msg-quantity {margin-top:5px; font-size: 16px; font-weight: 600; line-height: 1.19;  letter-spacing: -0.32px;  text-align: left; color: #191919;}
        }
        .no-result{
            margin:70px auto; text-align: center; @include defaultTxt;
        }
        .btn-group{
            margin-top: 30px;
        }
    }
    //편의점 조회
    .buy-store-pop{
        .filter-wrap {
            .btn-radio-group{
                .btn-radio{
                    width: 140px;
                    label{width: 100%;padding: 0;}
                    .radio-link {display: inline-flex;align-items: center;justify-content: center; height: 58px;min-width: 140px;border-radius: 7px;border: 1px solid #ddd;color: #666; font-size: 16px;letter-spacing: -.3px; font-weight: 600;  cursor: pointer;background: #fff; }
                }
            }
        }
        .box-paging{
            margin-top: 0;
        }
        .place-search-result{
            margin-top: 30px;
        }
        .result-list{
            padding-bottom:30px; border-bottom:1px solid $gray02;
        }
    }
    //픽업가능 편의점 조회
    .buy-pickup-convenience-pop{
        .popup-body{
            margin-top: 20px;
        }
        .box-tab{
            margin:0;
        }
        .box-paging{
            margin-top:30px;
        }
        .select-input-wrap{
            width:100%; gap:20px; margin-top: 20px;
            .box-select{
                flex:1;
            }
        }
        .place-search-result-wrap{
            margin-top: 50px;
            .place-search-result{
                .result-list{
                    border-bottom: 1px solid $gray02;
                }
            }
        }
    }

    .product-detail .product-txt .product-price .dc-price {display:inline-block;margin-right:8px;font-size:26px;color:#959595;text-decoration: line-through;}

    .buy-convenience{
        .unit-tit-b{
            margin-bottom: 0;
            .p-clor {font-size: 30px;font-weight: 600;color: #e60033; }
        }        
        .page-tit {
            .p-clor {font-size: 36px;font-weight: bold;color: #e60033; }
        }
        .usim-buy-notice{
            & + .openmarket-list{margin-top: 50px;}
        }
        .unit-tit-b.tit-txt{
            margin-bottom:10px;
            + .unit-txt{
                margin-bottom:0;
                li.txt{color:#666;font-weight:normal;}
            }
        }
        .slick-default{
            & > div{
                height: 200px; overflow: hidden;display: none;
            }
            .slick-list{display: block;}
            .slick-track{
                height: 100%;
                .slick-slide {
                    img{
                        height: 100%; object-fit: contain;
                    }
                }
            }
        }
        .cardList-wrap {
            & +  .cardList-wrap {margin-top: 30px;}
            .btns  + .btns  {margin-left:18px}
        }

       .cardList.using-market-col02 {
        display:flex; justify-content: space-between; 
            .item-left {
                position: relative;display: flex; width: 50%; justify-content: space-between;
                .pay-box {
                    padding-right:70px;
                    &::after {content:'';position: absolute; right:0; top:0;width:1px; height:58px; background: #ddd;}
                }
            }
            .item-right {
                position: relative;display: flex; width: 50%;padding-left:70px; justify-content: space-between; justify-content: flex-end; text-align: right; align-items: flex-end;
                .market {margin-right:45px;}
                .pc-support {
                    position: absolute; right:0; top:0; font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px;text-align: right;color: #191919;
                    span { font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px;color: #662d91;}
                }
            }

            .pay-box {position: relative; display: flex; align-items: center; margin-top:44px;}
            .pay-amount {
                display:block; padding:0;
                .discount-pay {margin-left:8px; font-size:24px;}
                .dc-pcnt {display:block;margin-top:8px; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: right; color: #662d91;}
            }
            .pric {margin-top:2px;}
            .btns {width:200px; margin-left:30px;}
            .market {
                .logo {
                    display: block; height:24px;
                    img {height:100%; vertical-align: top;}
                }
                .info {display:flex; align-items: center; height:58px; margin-top:20px; font-size: 24px;  font-weight: 600; line-height: 1.67; letter-spacing: -0.48px; text-align: left; color: #191919;}
            }
        }    
    }
    //다이랙트몰 구매하기 완료
    .buy-gs25-finish{
        position: relative;
        .fix-unit-group{
            .notification-wrap {
                margin-top:40px;
            }
            margin-bottom: 70px;
        }
        .btn-text-right{
            margin-left: 30px;
        }
        .fz24{
            font-size: 24px;
        }
        .btn-group{
            margin-top: 70px;
        }
        .floating-btn{
            width:80px; height: 80px; border-radius: 50%; background-color: $purple; @include defaultTxt; color:$white; text-align: center; display: flex; justify-content: center; align-items: center;
        }
    }
    //주소찾기
    .buy-delivery-adress{
        .box-flex{
            padding-top: 0;
        }
        .ligt-gray-box{
            width: 100%;
            background: #f6f6f6;
            padding: 40px;
            box-sizing: border-box;
            border-radius: 20px;
            margin-bottom: 60px;
            margin-top: 20px;
            & > h3{
                @include normalTxt;
            }
            .desc{
                margin-top: 10px; @include normalTxt01;
            }
            .notification{
                margin-top: 20px;
            }
        }
        .radio-block{
            margin-top: 50px; border-top:0;
        }
        .searching-wrap{
            margin-top: 10px;
            & > ul{
                border-bottom:1px solid $gray02;
            }
        }
        .before-searching{
            .default-txt{
                display: block; margin-top: 70px;text-align: center; margin-bottom: 70px;
            }
        }
        /*.btn-group{
            margin-top: 50px;
        }*/
        .box-paging {margin-top: 30px;}
    }

    .now-delivery {
        .box-paging {
             .paging-list {border:0;}
             & + .input-depth {margin-top:50px;}
        }
        .input-depth { 
            .btn-group {
                margin:32px 0;
                .btns {width:260px;height:58px;}
                .dark-gray {background:#666;}
            }
            .btn-check {justify-content: space-between;}
            .delivery-result {
                padding-top:30px; border-top:1px solid #ddd;
                &.result-view {padding:0; border-top:0;}
                p { font-size: 16px; font-weight: 600;  line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #191919;}
                .result-inner {
                    padding: 30px 0; border-radius: 10px;  background-color: #eff1f5; text-align: center;
                    .txt-result {
                        display: inline-block; position: relative; padding-left:34px; font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: center; color: #1baa46;
                        &::before {content: ''; position:absolute; left:0;top:50%;width:24px;height:24px; margin-top:-12px; background:url('/assets/images/pc/icon/ico-check-red.svg') no-repeat 50%;}
                        &.unable {
                            color:#e34140;
                            &::before {content: '';background:url('/assets/images/pc/icon/ico-close-red.svg') no-repeat 50%;}
                        }
                    }
                    .btn-group {margin:22px 0 0 0;}
                }
            }        
        }
    }

        
    .sub-conts-wide.esim-guide {
        section {width:1280px;margin:0 auto;}
        section.eism-banner {
            width:100%;height:420px; margin-top:70px; background:#3aaaf5;
            .banner-iner {
                display: flex; align-items: center; width:1280px;margin:0 auto;
                .txt-bx {
                    margin: 0 175px 0 60px;
                    strong {display:block; font-size: 50px;font-weight: 700;line-height: 1.2; letter-spacing: -1px;text-align: left; color: #fff;}
                    span {display: block; margin-top:30px;font-size: 24px; line-height: 1.25;letter-spacing: -0.48px;text-align: left;color: #fff; }
                }

            } 
        }
        .group-bx {
            display:flex;gap:70px;  margin-top:70px;
            .img-box {width:605px;}
        }
    
        .publicity-box {
            display: flex; align-items: center; height: 220px;margin-top:70px; border-radius: 10px;background-color: #2553ce;
            .txt-bx {
                margin: 0 60px;
                .bx-tit {
                    font-size: 44px; font-weight: 700; line-height: 1.2;letter-spacing: -0.88px;text-align: left;color: #fff;
                    strong {font-size: 44px; font-weight: 700; line-height: 1.2;letter-spacing: -0.88px; color:#faff6b;}
                    del {font-size: 44px; font-weight: normal;color:#fff;}
                }
                .bx-txt {margin-top:20px; font-size: 24px;font-weight: normal; line-height: 1.25; letter-spacing: -0.48px; text-align: left;color: #cbd9ff;}
            }
        }
        section.esim-dscrn {
            width:100%; height: 885px;margin-top:70px; ;border-radius: 10px;background-color: #f9faff;
            .dscrn-inner {
                display:flex;width:1280px; margin:0 auto;
                .dscrn {
                    width:605px;padding:60px 60px 0;
                    .tit {min-height:120px;margin-bottom:30px; font-size: 24px;font-weight: normal; line-height: 1.21;letter-spacing: -0.48px;text-align: left;color: #191919;}
                    strong {display:block;margin-top:10px; font-size: 30px;font-weight: 700;line-height: 1.33;letter-spacing: -0.6px;text-align: left;color: #191919;}
                    .txt {
                        margin-top:30px; font-size: 20px;font-weight: normal;line-height: 1.4;letter-spacing: -0.4px;text-align: left;color: #191919;
                        span {display:block;margin-bottom: 10px;color:#666;font-size:20px;}
                    }
                    .btns {width:485px; margin-top:30px;}
                }
            }
        }

        .esim-self {
            display:flex;align-items: center; height:500px;margin-top:70px; border-radius: 10px; background-image: linear-gradient(111deg, #feefff, #fffaff 62%, #fff);
            p  {margin: 0 30px 0 60px; font-size: 24px; font-weight:normal; line-height: 1.2;  letter-spacing: -1px; text-align: left; color: #191919;}
            p + p {margin-top:30px;}
            strong { display:block;margin-top:10px;font-size: 50px; font-weight: 700; color: #191919;}
        }
    }    

    .product-detail .product-txt .box-btn {margin-top:83px;}
    .product-detail{
        .banner-randombox{
            bottom: 65px; left: 0;
        }
        .usim-buy-notice{font-weight: 600;}
        .delivery-msg {
            margin-top:30px;
            p {color:#191919;font-weight: 600;}
            .notification {
                margin-top:10px;                
                strong {font-weight: 700; color:#662d91;}
                li:last-child {color:#191919}
            }
        }
    }
    .usim-buy-notice{font-size: 16px; line-height: 1.19; color: #ec008c; margin-top: 10px;}

    .directmall-view {
        .unit-origin {
            .step-list.col6 {
                flex-wrap: wrap; justify-content: center;  row-gap: 40px;
                li {width:400px;}
            }
        }
    }
}


.onechip {
    .page-tit.unit-origin + .rq-form  {margin-top:100px;}
    .product-txt {
         .box-btn {
            .btn-lines {
                padding:10px 0;
                span {display:block; margin-top:3px; font-size:14px; color:#afafaf;}
            }
            .txt {display:inline-block;width:50%;margin-top:20px; color:#191919;font-size:16px;}
            .tooltip-wrap {
                display:block;
                .box-tooltip {
                    position:relative; display:inline-block;
                    .btn-tooltip { width:260px; height:58px;background-color: #fff; background-image: none;}
                    .txt-tooltip {
                        top:70px; left:0; min-width:580px; padding:25px 35px;
                        strong {display:block; margin-bottom:5px; font-size: 16px;font-weight: 600; line-height: 1.5; letter-spacing: -0.32px; text-align: left;  color: #191919;}
                    }
                }
            }
         }          
    }
    .directmall-view {
        .step-list {
            padding:40px;
            li {width:400px;}
        }
        .notification-wrap {
             .txt-mark {
                position:relative; margin-top:20px; padding-left:15px; font-size:16px; line-height: 1.19; letter-spacing: -0.32px; text-align: left; color: #666;
                &::before {content:'※'; position: absolute; left:0; top:0; font-size:16px; color:#666;}
            }
        }
    }
}

// 랜덤박스 기획전 배너
.banner-randombox-wrap{
    .banner-close{display: none !important;width: 24px; height: 24px; position: absolute; left: 244px; bottom: -15px; visibility: hidden; animation: box-up .8s cubic-bezier(0.65, 0.05, 0.36, 1) .8s both;}
}
.banner-randombox{
    width: 260px; height: 58px; display: none !important; position: absolute; visibility: hidden; animation: box-up .8s cubic-bezier(0.65, 0.05, 0.36, 1) .8s both;
}

@keyframes box-up {
    0% { 
        opacity: 0;
        transform: translateY(50px);
    }
    100% {   
        opacity: 1;
        visibility:visible;
        transform: translateY(0);
    }
}

.selfusim {
    .page-tit {width:1280px; margin:0 auto 50px;}
    .wide-banner {
        width:100%;background:#f9f5ff;
        .banner-inner {
            display: flex;gap: 70px;width:1280px;margin:0 auto;padding:80px 0;
            .obj-txtbox {
                .title {font-size: 36px;font-weight: 700; line-height: 1.28; letter-spacing: -0.72px;text-align: left;color: #000;}
                .notification-wrap {
                    margin-top:30px;
                    .notification {
                        li {color:#666;}
                    }
                }
                .btn-wrp {
                    margin-top:30px; position: relative;
                    .btns {
                        width:310px;
                        & + .btns {margin-left:7px;}
                    }
                }
                .link-box {
                    margin-top:30px;
                    a + a {margin-left:40px;}
                }
                .banner-randombox-wrap{
                    .banner-randombox{bottom: 65px;}
                    .banner-close{bottom: 111px;}
                }
                .banner-randombox{margin-top: 10px;}
            }
        }
    }

    .selfusim-wrp {
        width:1280px; margin:0 auto;
    }

    .self-usim {
        margin-top:70px;
        section + section {margin-top:70px;}
        .contents-title +  .notification-wrap {margin-top:50px;}
        .img-list {
                display:flex; flex-wrap:wrap; justify-content: space-between; gap:30px;margin-top:30px;
                & > li {
                    display:flex;justify-content:flex-start;align-items:center;flex:1 1 40%; border:1px solid $gray02; padding:40px;  border-radius:7px;
                    .img {display:block;width:80px;height:80px;margin-right:20px;}
                    .info {
                        .tit {@include mainTxt;}
                        .txt {margin:10px 0 0;@include defaultTxt;}
                    }
                }
                &.arro-rt {
                    display:flex;flex-wrap:wrap;gap:20px;padding:0;border:none;
                & > li {
                        display:flex;flex:1 1 40%;border-radius:7px;border:solid 1px $gray02; padding: 0;
                        ::before {
                            content:'';display:block;position:absolute;right:40px;top:50%;width:20px;height:20px;margin-top:-10px;
                            @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right.svg', center center, 20px 20px);
                        }
                        a {display:flex;align-items:center;position:relative;width:100%;padding:40px 60px 40px 40px;}

                    }            
                }       
        }
        .buy-card-list {
            margin-top:50px;
            .buy-list {
                display:flex;gap:30px;  margin-top:10px;
                li {
                    position: relative; width:100%; padding:40px; border-radius:10px; border: solid 1px #ddd; 
                    .tit {display:block;font-size: 20px;font-weight: 600; line-height: 1.2; letter-spacing: -0.4px;text-align: left; color: #191919;}
                    .txt {display:block;margin-top:10px; color:#666;}
                    ::before {
                        content:'';display:block;position:absolute;right:40px;top:50%;width:20px;height:20px;margin-top:-10px;
                        @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right03.svg', center center, 18px 18px);
                    }
                }
            }
            .notification {margin-top:10px;}
        }
        
        .guide-video-wrp {
            position: relative;
            margin-top:70px;
            .guide-video {
                margin-top:10px; padding:60px 0;background:#f9f5ff;
                .video {display:block; width:800px;height:450px; margin:0 auto;}
                .btn-wrp {margin-top:40px;text-align:center;}
            }
            .banner-randombox-wrap{width: 260px; position: absolute; bottom: 185px; left: 50%; transform: translateX(-50%);}
        }

        .usim-self-slide {
            position: relative; margin-top:30px;
            .slick-control {
                position:absolute;right:30px;bottom:30px;width:81px;height:30px;padding: 6px 14px; border-radius: 100px;background-color: #dadbe4;text-align: center;
                .slick-cunt {
                    display:flex; align-items: center;
                    .first-page {width:30px; font-size: 18px;font-weight: 600; line-height: 1.1;letter-spacing: -0.36px; color: #666;}
                    .end-page {
                        position:relative;width:30px;padding-left:10px;font-size: 18px; font-weight: 600; line-height: 1.1; letter-spacing: -0.36px;color: #959595;
                        &::before {content:'/'; position:absolute;left:0;top:0;font-size: 18px; font-weight: 600; line-height: 1.1; color:#bfbfc5}
                    }
                }            
            }
            .slick-arrow {
                width:90px;height:90px;border-radius:50%;font-size:0;
            &.slick-prev.slick-disabled {left:-48px;@include backgroundImg('/assets/images/pc/main/btn-prev-white-disabled.png', center, 90px 90px); transform:rotate(0deg) translateY(-50%) ;}
            &.slick-prev {left:-48px;@include backgroundImg('/assets/images/pc/main/btn-next-white02.png', center, 90px 90px); transform:rotate(-180deg) translateY(50%);}
            &.slick-next {right:-48px;@include backgroundImg('/assets/images/pc/main/btn-next-white02.png', center, 90px 90px); transform:rotate(0deg) translateY(-50%);}            
            &.slick-next.slick-disabled {right:-48px;@include backgroundImg('/assets/images/pc/main/btn-prev-white-disabled.png', center, 90px 90px); transform:rotate(-180deg) translateY(50%) ;}
        }
        }
        .esim-guide-view {
            .txt-btn {
               a {font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #000;}
                & + .txt-btn {margin-top:50px;}
            }
        }
        .esim-open-guide {
            margin-top:70px;
            .accordian {
                margin-top:50px;
                .acc-trigger {
                    display:block;width:auto; padding-right:40px; text-align: left;
                    .unit-tit {display:inline-block;font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px; text-align: left;color: #191919;}
                }
                .acc-conts {
                    margin-top:50px;
                    & + .acc-trigger {margin-top:50px;}                   
                    .qr-box {
                        display:inline-block;margin-top:30px; padding: 20px; border-radius: 10px; border: solid 1px #ddd; background-color: #fff;
                        img {width:240px;}
                        & + .unit-box-img {margin-top:50px;}
                    }
                    .code-box {
                        margin-top:10px; padding: 40px; border-radius: 10px; border: solid 1px #ddd;
                        dl {
                            display: flex;
                            dt {width:210px;font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: left;color: #666;}
                            dd {font-size: 16px; font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left; color: #191919;}
                            & + dl {margin-top:20px;}
                        }
                    }
                }                
               
                .unit-box {
                    &-img {
                        margin:10px 0;padding:50px 40px;border:1px solid $gray02;border-radius:10px;
                       & + .unit-box-img {margin-top:30px;}
                        .unit-box-tit {
                            margin-bottom:20px;
                            .box-tit {
                                display:block;@include subContsTitle;
                                &+.unit-txt {margin-top:10px;}}
                        }
                        .unit-box-ct {padding-top:30px;}
                        .unit-tit-s {
                            display:block;margin:0 0 20px;
                            @include normalTxt;
                            &>a {font-size:inherit;}
                        }
                    }
                    &-txt {
                        margin:10px 0;padding:30px;border:1px solid $gray02;border-radius:10px;
                        .unit-box-tit {
                            margin-bottom:20px;
                            .box-tit {
                                display:block;@include subContsTitle;
                                &+.unit-txt {margin-top:10px;}
                            }
                        }
                    }
                }
                .next-list {
                    overflow:hidden; display:flex; flex-wrap: wrap; margin-top:-59px;
                    &>li {
                        position:relative;width:220px;margin-top:59px; text-align:center;
                        &:nth-child(5) {margin-left:0;}
                        &+li {
                            margin-left:105px;
                            &::before {
                                content:'';display:block;position:absolute;top:50%;left:-74px;width:40px;height:40px;margin-top:-64px;
                                @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right04.png', center, 40px 40px);
                            }
                        }
                       .txt {
                            margin-top:20px;color:$black;text-align: left;padding-left:20px; letter-spacing: -0.42px;
                            span {display:inline-block;text-indent: -20px;}
                        }
                    }                   
                }
            }
        }

        .guide-txt {font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}

        .customer-service {
            margin-top:70px;
            .guide-txt {margin-top:50px;}
            .customer-guide {                
               margin-top:30px; padding:40px; border-radius: 10px;border: solid 1px #ddd;background-color: #fff;
                .tite {font-size: 20px; font-weight: 600; line-height: 1.2;letter-spacing: -0.4px; text-align: left;color: #191919;}
                .customer-inner {display:flex;    align-items: center;}
                .guide-box {
                   position: relative; display:flex;    align-items: center;width:50%; margin-top:20px;
                    img {width:80px;}
                    dl {
                        position:relative;margin-left:20px;
                        dt {margin-bottom:10px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                        dd {font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}                       
                    }                   
                    & + .guide-box::before {content:'';position:absolute;left:-50px;top:0;width:1px;height:80px;background: #ddd;}
                }
            }
        }

        .box-faq {
            display:flex;justify-content:space-between;align-items:center;gap:0 40px;margin-top:70px;padding:40px;border:1px solid $gray02;border-radius:7px;
            .tit {display:block;@include mainTxt;}
            a {margin-top:10px;@include smallTxt;}
            img {width:80px;height:80px;}
        }
    }
}
.self-use-tiem {
    .use-msg {
        dt {font-size: 20px; font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
        dd {margin-top:10px; font-size: 16px;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #666;}
        & +.use-msg {margin-top:30px;}        
    }
    .btn-wrp {
        margin-top:30px;text-align: center;
        .btns {width:216px;}
    }
}

.layer-popup {
    .layer-popup-item {
        &.transfer-telecom  {max-width: 900px;}
    }
}