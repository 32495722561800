@charset "UTF-8";

#footer {
    background:#191919; min-width: 1280px;
    a, button {cursor:pointer;}
    footer {position:relative;width:1280px;margin:0 auto;padding:40px 0 100px;}
    .footer {
        &-top {
            padding:32px 0;border-bottom:1px solid #303030;
            .wrap {
                display:flex;justify-content:space-between;align-items:center; width:1280px;margin:0 auto;
                .f-menu {
                    display:flex;justify-content:flex-start;flex-wrap:wrap;gap:20px;
                    a {@include normalTxt01;color:#ddd;}
                    li.strong{
                         a {@include defaultTxt;color:$white; font-weight: 600;}
                    }
                }               
            }
        }       
        &-mid {
            display:flex;justify-content:flex-start;
            address {
                margin-right:40px;font-style:normal;
                .f-logo {
                    display:block;margin-bottom:20px;@include normalTxt;color:$white;
                    a {@include normalTxt;color:$white;}
                }
                p {
                    display:flex;justify-content:flex-start;gap:20px;@include defaultTxt;
                    &.email {display:block;}
                }
            }
            .f-info {
                display:flex;justify-content:flex-start;
                dl {
                    &+dl {margin-left:40px;}
                    dt {display:block;margin-bottom:20px;@include normalTxt;color:$white;}
                    dd {
                        p {
                            @include defaultTxt;
                            &+p {margin-top:10px;}
                            .tel {display:flex;justify-content:flex-start;gap:20px;}
                        }
                    }
                }
            }
            .f-sns {
                margin-left:40px;
            }
        }
        &-btm {
            margin-top:40px;
            .f-etc {
                display:flex;justify-content:flex-start;gap:0 20px;
                span, a {
                    display:flex;align-items:center;@include defaultTxt;
                    img {margin-right:10px;}                
                }
                span + span {margin-left:181px}
            }
        }
    }
    .f-sns {
        display:flex;justify-content:flex-end;flex-wrap:nowrap;gap:0 10px;
        li {
            a {
                display:flex;justify-content:center;align-items:center;width:34px;height:34px;border-radius:50%;background-color:$white;
                img {width:24px;height:24px;}
            }
        }
    }
}
