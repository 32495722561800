@charset "UTF-8";

@font-face {
	font-family: 'Pretendard';
	font-weight: 100;
	font-display: block;
	src: local('Pretendard Regular'), url('/assets/fonts/Pretendard-Light.woff2') format('woff2');
}
@font-face {
	font-family: 'Pretendard';
	font-weight: 400;
	font-display: block;
	src: local('Pretendard Regular'), url('/assets/fonts/Pretendard-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 600;
	font-display: block;
	src: local('Pretendard SemiBold'), url('/assets/fonts/Pretendard-SemiBold.woff2') format('woff2');
}

@font-face {
	font-family: 'Pretendard';
	font-weight: 700;
	font-display: block;
	src: local('Pretendard Bold'), url('/assets/fonts/Pretendard-Bold.woff2') format('woff2');
}
