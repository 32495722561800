@charset "UTF-8";
// layout
body.white{
    #wrap{
        &.main{
            .sec-main-visual{
                .slick-control{
                    > span{
                        color: $white;
                    }
                    .slick-dots{
                        li{
                            button{
                                &:before{
                                    background-color: #fff;
                                }
                            }
                        }
                    }
                    .slick {
                        &-prev {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg data-name='그룹 465375'%3E%3Cpath data-name='패스 842334' d='m1054.216 723.492-5.331 5.331 3.554 3.554 1.777 1.777' transform='translate(-1042 -718.823)' style='fill:none;stroke:%23ffffff;stroke-linecap:round'/%3E%3C/g%3E%3C/svg%3E%0A");}
                        &-next {background-image: url("data:image/svg+xml,%3Csvg data-name='그룹 465377' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath data-name='패스 842334' d='m1048.886 723.492 5.331 5.331-3.554 3.554-1.777 1.777' transform='translate(-1041.102 -718.823)' style='fill:none;stroke:%23ffffff;stroke-linecap:round'/%3E%3C/svg%3E%0A");}
                        &-play {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg data-name='그룹 465378'%3E%3Cpath data-name='패스 845805' d='M-3433.028-18468.7v10.184l7.168-5.113z' transform='translate(3439.444 18473.604)' style='fill:none;stroke:%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");}
                        &-pause {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg data-name='그룹 465378'%3E%3Cpath data-name='사각형 341659' transform='translate(6 5)' style='fill:%23ffffff' d='M0 0h3v10H0z'/%3E%3Cpath data-name='사각형 341660' transform='translate(11 5)' style='fill:%23ffffff' d='M0 0h3v10H0z'/%3E%3C/g%3E%3C/svg%3E%0A");}
                    }                    
                }
            }          
                    
            .header-wrp {
                #header.on #gnb>ul>li>a {color:#191919;}
                #header.on .logo {background: url('/assets/images/pc/common/header-logo.png') no-repeat 0;}
                #header .top-util button.top-search,
                #header.on .top-util button.top-search {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg transform='translate(.818 .818)'%3E%3Cg data-name='타원 1' style='stroke:%23191919;stroke-width:2px;fill:none'%3E%3Ccircle cx='10.5' cy='10.5' r='10.5' style='stroke:none'/%3E%3Ccircle cx='10.5' cy='10.5' r='9.5' style='fill:none'/%3E%3C/g%3E%3Crect data-name='사각형 341685' width='2' height='7' rx='1' transform='rotate(-45 29.02 -10.607)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A");}
                #header .top-util button.top-login,
                #header.on .top-util button.top-login {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(1.154 1.147)'%3E%3Cellipse data-name='타원 13' cx='5.02' cy='4.994' rx='5.02' ry='4.994' transform='translate(5.826)' style='fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3Cpath data-name='패스 74' d='M23.191 25.972c0-4.393-4.856-7.954-10.846-7.954S1.5 21.58 1.5 25.972' transform='translate(-1.5 -4.268)' style='stroke-linecap:round;fill:none;stroke:%23191919;stroke-miterlimit:10;stroke-width:2px'/%3E%3C/g%3E%3C/svg%3E%0A");}
                #header .top-util button.top-menu,
                #header.on .top-util button.top-menu {background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath style='fill:transparent' d='M0 0h24v24H0z'/%3E%3Cg transform='translate(0 3)'%3E%3Crect data-name='사각형 340839' width='24' height='2' rx='1' style='fill:%23191919'/%3E%3Crect data-name='사각형 340840' width='24' height='2' rx='1' transform='translate(0 8)' style='fill:%23191919'/%3E%3Crect data-name='사각형 340841' width='24' height='2' rx='1' transform='translate(0 16)' style='fill:%23191919'/%3E%3C/g%3E%3C/svg%3E%0A");}    
            }

            
            .container.main {
                .sec-main-visual {
                    .slick-control{
                        .slick-cunt .first-page { color:#fff;}
                        .slick-cunt .end-page {color:#fff;opacity: 0.6;}
                        .slick-cunt::before {content:''; background-color: #000; opacity: 0.4;}
                        .slick-cunt .first-page:after {content:'/';  color:#fff; opacity: 0.6; }
                        .slick-prev {background: url('/assets/images/pc/main/main-visual-prev02.png') no-repeat 50%;    background-size: 40px;}
                        .slick-next {background: url('/assets/images/pc/main/main-visual-next02.png') no-repeat 50%;    background-size: 40px;}
                        .slick-play  {background: url('/assets/images/pc/main/main-visual-play02.png') no-repeat 50%;    background-size: 40px;}
                        .slick-pause {background: url('/assets/images/pc/main/main-visual-pause02.png') no-repeat 50%;    background-size: 40px;}
                    }          
                }    
            }

        }
    }
}

#wrap {
    &.main {
        padding-top:0;   
        .container {
            padding-top:0;
            &.main {
                padding-top:81px;
                .sec-main-visual {
                    height:auto;
                    margin:0;
                    .slick-control {
                        bottom:40px; 
                        width:auto;
                        margin-left:400px; 
                    }
                    & + .sec-payment {margin-top:0;}
                }

                .slick-control{
                    .slick-cunt {
                        display: flex; position: relative; width:102px;height:40px; justify-content: center; align-items: center; 
                        .first-page {font-size:18px; color:#000; font-weight: 300;}
                        .end-page {font-size:18px; color:#000; font-weight: 300; opacity: 0.6;}
                        &::before {content:''; position:absolute; left:0;top:0; width:102px;height:40px;border-radius: 100px; background-color: #fff; opacity: 0.4; transition: all .3s linear; }
                        .first-page {position:relative; display:inline-block;margin-right: 4px; padding-right:10px;}
                        .first-page:after {content:'/'; position: absolute; right:0; top:0; color:#000; font-size:18px;opacity: 0.6; }
                    }
                    .slick-btn {
                        margin:0 0 0 10px;
                        &::after {content:''; display:block; clear:both;}
                    }
                    .slick-next,
                    .slick-pause,
                    .slick-play, 
                    .slick-prev {float:left; width:40px; height:40px; margin: 0 5px 0 0; transition: all .3s linear;}                    
                    .slick-prev {background: url('/assets/images/pc/main/main-visual-prev.png') no-repeat 50%;    background-size: 40px;}
                    .slick-next {background: url('/assets/images/pc/main/main-visual-next.png') no-repeat 50%;    background-size: 40px;}
                    .slick-play  {background: url('/assets/images/pc/main/main-visual-play.png') no-repeat 50%;    background-size: 40px;}
                    .slick-pause {background: url('/assets/images/pc/main/main-visual-pause.png') no-repeat 50%;    background-size: 40px;}                
                }
            }
        }
                    
        #nav-skip a {top:-500px}
        .top-banner {overflow:hidden; width:100%;height:80px;}
        .top-banner.show {display:block;}
        .top-banner.hide {display:none;}

        .top-banner .strip-banner {width:100%;}        
        .top-banner .strip-banner .banner-bx .banner-inner {display:flex; position:relative;width:1280px; height:80px; margin:0 auto;align-items: center;}
        .top-banner .strip-banner a {display: block; width:100%; font-size:22px;}
        .top-banner .strip-banner .btn-close {position:absolute; right:0; top:20px; width:40px;height:40px;background: url('/assets/images/pc/common/banner_close.png') no-repeat 0 0;}
        .top-banner .strip-banner .img {position:absolute;right:40px;bottom:0; height:100%;}
        .top-banner .slick-slider .slick-list .slick-slide img {width:auto;}

        .header-wrp {position:relative;}
        .header-wrp #header {position:absolute; top:0; border-bottom:1px solid #ddd}
        .header-wrp  #gnb .gnb-depth1 {position:absolute;top:81px;left: 50%;transform: translateX(-50%);}
        .header-wrp #header.search-on .header-search {overflow: visible; position:absolute;height:512px;}
        .header-wrp .header-search::after {top:auto; bottom:-99%;height:100%;}
        .header-wrp #header.gnb-fixd {position:fixed;top:0;} 
    }

    .personal-message{
        position: fixed; left: 50%; top: 100px; transform: translateX(-50%); z-index: 100; width: 400px; transition: all .2s;
        li{
            position: relative;
            &+li{
                margin-top: 10px;
            }
            a{
                display: block; padding: 10px 30px; background-color: rgba(0,0,0, 0.7); border-radius: 24px; color: $white; 
            }
            button{
                position: absolute; right: 8px; top: 50%; transform: translateY(-50%); width: 24px; height: 24px; @include backgroundImg('/assets/images/pc/icon/ico-delete.svg', 0, 100%); font-size: 0;
            }
        }
    }  
    
    &.show-strip .personal-message {top:180px;}  

    .personal-message.gnb-scrl {top:100px;} 
  
    .main-conts {
        min-width: 1280px; max-width: 1920px; margin: 0 auto;
        &>section {
            margin-top:100px;
            .sectin-tit {min-height:35px;margin-bottom:30px;}
            .sec-tit {display:block;margin-bottom:30px;@include contsTitle;}
            .sec-origin {position:relative;width:1280px;margin:0 auto;}/*1280px 컨텐츠 중앙정렬일때 사용*/
            .sec-more {
                position:absolute;top:13px;right:0;
                .btn-more {
                    display:inline-block;padding-right:22px;@include normalTxt;color:$gray05;
                    @include backgroundImg('/assets/images/pc/main/ico-more-gray.svg', right center, 18px 18px);
                    &+.btn-more {margin-left:20px;}
                    &>b {@include normalTxt;color:$black;font-weight:bold;}
                }
            }
            .subscription-tit {
                margin-bottom:0;
                .sec-tit {margin-bottom:0;}
            }
            &.sec-main-event {margin-top:80px;}
          // &.sec-payment {margin-top:0;}
        }
    }
}
//모달 팝업
.open-modal-popup-wrap{
    position: fixed; top:0; left:0; right:0; bottom:0; z-index: 999999; background: rgba(0,0,0,0.5); display: none !important;
    .open-modal-popup{
        position:absolute; top:50%; left:50%; transform: translate(-50%,-50%);  border-radius: 20px; overflow:hidden;
        .slick-default{
             > div{
                display: none; width:100%; height: 100%;
                img{
                    width:100%; height:100%; display: block;
                }
            }
        }
        .slick-list{
            display: block !important;
        }
        .slick-track{
            height: 100%;
        }
        .slick-dots{
            position: absolute; bottom:15px;
            li{
                button{
                     width: 16px; height: 16px; background: $white; opacity: 0.5;
                }
            }
            li.slick-active{
                button{
                    width: 40px; height: 16px; background: $white; opacity: 1;
                }
            }
        }
    }
    .bottom-bar{
        width:100%; padding:23px 20px; background: $black; color:$white; display: flex; justify-content: space-between; align-items: center; border-radius: 0 0 20px 20px;
        .btn-check{
            label{
                color:$white;
            }
        }
    }
    &.on{
 display: block !important;
    }
}

.popup-group {
    .group-inner {
        display: flex; height: 100%; align-content: center; justify-content: center; align-items: center;
        .open-modal-popup {position:static; transform: translate(0);}
        .notify-popup {
            overflow:hidden; border-radius: 20px;
            img {vertical-align: top;}
           & + .open-modal-popup {margin-left:40px;}
        }
    }
}

.main-modal-popup  {
   position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 999999; background: rgba(0,0,0,.5); display: none!important;
   &.on {display: block!important;}
   .main-popup-inner{
        position: absolute;left: 50%; top: 50%; transform: translate(-50%, -50%);
        .popup-group {
            position:relative; display: flex; height:100%; justify-content: center; align-items: center; 
            .open-modal-popup { 
                overflow:hidden; position:static;  width:420px; transform: translate(0);  border-radius: 20px;
                .slick-default > div {display: none; width:100%; height: 100%;}
                .slick-default > div img{ width:100%; height:100%; display: block; }
                .slick-list{ display: block !important;}
                .slick-track{ height: 100%;}
                .slick-dots{
                    position: absolute; bottom:15px;
                    li {
                         button{ width: 16px; height: 16px; background: #fff; opacity: 0.5; }                                                  
                         &.slick-active button{width: 40px; height: 16px; background: #fff; opacity: 1; }
                    }
                    
                }
            }
            .open-modal-popup + .open-modal-popup {margin-left:40px;}
        }        
   }
   .bottom-bar {
      width:100%; padding:23px 20px; background: #000; color:#fff; display: flex; justify-content: space-between; align-items: center; border-radius: 0 0 20px 20px;
      .btn-check label {color:#fff;}
    }
   .all-popup-close {
        overflow: hidden; position: absolute; right:0; top: -60px; width: 40px; height: 40px; line-height: 40px; color: #fff; font-size:16px; color:#fff; border:1px solid #fff; border-radius: 25px; background: url('/assets/images/pc/icon/icon-popup-all-close.png') no-repeat 100% 50%; background-size: 40px; margin: 0 auto 15px; text-indent: -110px; transition: all .4s;
        &:hover{ width: 105px; text-indent: 0; background-position: 100% 50%; padding: 0 0 0 10px;}
    }
    .main-popup-close {display: block;width: 16px; height: 16px; font-size: 0; background: url('/assets/images/pc/icon/ico-close-wh.svg') no-repeat center; background-size: 16px 16px;}
  
}

//배너 모아보기
#wrap.main .container.main {
    .banner-more {
        visibility: hidden; position: fixed; top:0; left:0; right:0; bottom:0; z-index: 999999; background: rgba(0,0,0,0.5); z-index:-1 ;
        &.on {
            visibility: visible;  z-index:200; 
            .more-wrp {
                .more-list {visibility: visible; width:960px; opacity: 1; transform: translateY(0); transition:all 1s cubic-bezier(0, -0.1, 0.29, 1.01) }                                       
                .more-close {visibility: visible; opacity: 1; transform: translateY(0); transition: all 1s;}
                .more-list img {vertical-align: top;}
            }
        }
        .more-wrp {
            overflow:auto; display: flex; width:100%; height: 100%; padding:128px 0;  align-content: center;justify-content: center;align-items: flex-start;  -ms-overflow-style: none;
            &::-webkit-scrollbar { display: none !important;}  
            .more-list {visibility: hidden; overflow: visible; width:960px; margin:0 auto;  opacity: 0; transform: translateY(-80px);} 
            .more-close {visibility: hidden; position: absolute; right:50%; top:128px; transform: translateY(-80px); width: 40px; height: 40px; margin-right:-540px; line-height: 40px; border:1px solid #fff; border-radius: 25px; background: url('/assets/images/pc/icon/icon-popup-all-close.png') no-repeat 50%; background-size: 40px; font-size:0; opacity: 0;}        
        }    
    }

    .slick-control{ 
        .slick-cunt.more-box {width:152px;}
        .slick-cunt.more-box::before {width:152px;}
        .slick-cunt.more-box .btn-more {display:inline-block; margin-left:8px; font-size:14px;  font-weight: 500; letter-spacing: -0.28px;text-align: left; color: #191919; z-index: 5;}
    }
}
body.white #wrap.main  .container.main {
   .slick-control .slick-cunt.more-box .btn-more {color:#fff;}
}
body.black #wrap.main .container.main {
     .slick-control .slick-cunt.more-box .btn-more {color:#000;}
}

// 메인비주얼
.sec-main-visual {
    position:relative;height:730px;
    .slick-list {
        .slick-slide {
            display:flex;justify-content:center;overflow:hidden;
            img {
                width:1920px; max-width: none;
            }
        }
    }
    .slick-control {
        display:flex;justify-content:center;position:absolute;left:50%;bottom:80px;width:240px;margin-left:-120px;z-index:1;
        .slick-dots {
            display:flex;justify-content:center;align-items:center;width:100px;
            li {
                flex:1 1 auto;margin:0 !important;opacity:0.5;
                &.slick-active {opacity:1;}
                button {
                    display:flex;align-items:center;overflow:hidden;width:100%;height:20px;font-size:0;
                    &::before {content:'';display:block;width:100%;height:2px;background:$black;}
                }
            }
        }
        .first-page,
        .end-page {@include normalTxt;}
        .slick-btn {display:flex;justify-content:flex-start;flex-wrap:nowrap;margin-left:20px;}
        .slick-prev,
        .slick-next,
        .slick-play,
        .slick-pause {
            display:block;width:20px;height:20px;font-size:0;
            &.on {display:block;}
        }
        .slick {
            &.on {display:block;}
            &-prev {@include backgroundImg('/assets/images/pc/main/visual-prev.svg', center, 20px 20px);}
            &-next {@include backgroundImg('/assets/images/pc/main/visual-next.svg', center, 20px 20px);}
            &-play {display:none;@include backgroundImg('/assets/images/pc/main/visual-play.svg', center, 20px 20px);}
            &-pause {display:none;@include backgroundImg('/assets/images/pc/main/visual-pause.svg', center, 20px 20px);}
            &-page {padding:0 20px;}
        }
    }   
}

.sec-service-menu {
    .meun-list {
        display:flex;gap:48px;justify-content: center; width:1280px; margin:60px auto 80px;
       li {
            width:136px;
            .object-box {
                overflow:hidden;display:inline-block;position:relative; width:136px;height:136px; border-radius: 60px; background:#f2f4fc;
                img {max-width:none;}
                .object01-01 {position:absolute;left:50%;bottom:-5px;width:76px;height:103px; margin-left:-35px; background: url('/assets/images/pc/main/img-service-object01-01.png') no-repeat 0 0; z-index:5;}
                .object01-02 {position:absolute; left:13px; top:60px; width:37px;height:27px;background: url('/assets/images/pc/main/img-service-object01-02.png') no-repeat 0 0; z-index:1;transition: all 1s;;}
                &.object01.on .object01-02 {transition: all 1s cubic-bezier(0.250, 0.460, 0.450, 0.940); left:35px;}
                .object01-03 {
                    position:absolute; right:20px;bottom:-50px;width:42px;height:42px; background: url('/assets/images/pc/main/img-service-object01-03.png') no-repeat 0 0; z-index:5; opacity: 0; transform: scale(0);  transition: all 1s;
                    &::before{content:''; display: block; height: 14px;  position: absolute; left: 50%; top: 50%; width: 9px;  margin:0 0 0 -10px;  transform: scaleX(-1) rotate(135deg);   transform-origin: left top; border-right: 5px solid transparent; border-top: 5px solid transparent;;}
                }
                &.object01.on .object01-03{
                    transition: all 1s cubic-bezier(0.250, 0.460, 0.450, 0.940); bottom:10px; transform: scale(1); opacity: 1;
                    &::before {animation: check .8s alternate forwards 1s;border-color: #fff;z-index: 5; opacity: 0;}  
                }            
            }

            .object02 {
                background:#b791f8;
                .object02-01 {animation:  object02-01 2s infinite forwards;  position:absolute;left:16px;top:33px;width:74px;height:81px;background: url('/assets/images/pc/main/img-service-object02-01.png') no-repeat 0 0;}
                .object02-02 {animation:  object02-01 2s infinite forwards .2s; position:absolute;right:16px;top:46px;width:71px;height:73px;background: url('/assets/images/pc/main/img-service-object02-02.png') no-repeat 0 0;}
                .object02-03 {position:absolute; left:50%; top: 13px; width:119px; height:57px; margin-left:-60px; background: url('/assets/images/pc/main/img-service-object02-03.png') no-repeat 0 0; opacity:0; animation:object02-02 2s ease infinite 1s;}
            }

            .object03  {
                background:#3c64fb;  background-image: linear-gradient(#699eff, #3b63fb);
                .object03-01 {
                    position:absolute; left:50%;top:50%;width:100px;height:57px; margin:-28px 0 0 -50px; background: url('/assets/images/pc/main/img-service-object03-01.png') no-repeat 0 0;
                    &.on {transform:scale(1.1);}
                }
                .object03-02 {
                    position:absolute; right:-45px;bottom:12px;width:86px;height:45px;background: url('/assets/images/pc/main/img-service-object03-02.png') no-repeat 0 0;
                    &.on {transform:translateX(-22px) scale(1.1);}
                }
                .object03-03 {
                    position:absolute; right:-12px;top:9px;width:86px;height:45px;background: url('/assets/images/pc/main/img-service-object03-03.png') no-repeat 0 0;
                    &.on {transform:translateX(-10px)  scale(1.1);}
                }
                .object03-04 {
                    position:absolute; left:-30px;bottom:10px;width:86px;height:45px;background: url('/assets/images/pc/main/img-service-object03-04.png') no-repeat 0 0;
                    &.on {transform:translateX(22px)  scale(1.1);}
                }
                span {transition:transform .5s  cubic-bezier(0.390, 0.575, 0.565, 1.000); z-index: 1;}
                span.on {transition:transform .5s  cubic-bezier(0.390, 0.575, 0.565, 1.000) ; z-index: 5; }
            }

            .object04 {
                .object04-01 {position:absolute; left:24px;top:29px;width:90px;height:141px; background: url('/assets/images/pc/main/img-service-object04-01.png') no-repeat 0 0; transition: all 1.8s cubic-bezier(0.165, 0.840, 0.440, 1.000) .5s; transform:scale(0.9) translateY(20px);}
                &.on .object04-01 {transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000); transform:scale(1) translateY(0);}
                .object04-02 {
                    position:absolute; right:23px;bottom:25px;width:42px;height:42px; background: url('/assets/images/pc/main/img-service-object04-02.png') no-repeat 0 0; transition: all .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) .1s; transform:scale(0); opacity: 0;
                    &::before{content:''; display: block; height: 14px;  position: absolute; left: 50%; top: 50%; width: 9px;  margin:0 0 0 -10px;  transform: scaleX(-1) rotate(135deg);   transform-origin: left top; border-right: 5px solid transparent; border-top: 5px solid transparent;;}
                }
                &.on .object04-02 {
                    transition: all .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) .6s; transform:scale(1); opacity: 1;  
                    &::before {animation: check .8s infinite alternate forwards 1.5s;border-color: #fff;z-index: 5; opacity: 0;}  
                }
            }

            .object05 {
                background:#ff9fa5;
                
                .object05-01 {               
                    position:absolute; left:3px;top:34px;width:88px;height:105px;background: url('/assets/images/pc/main/img-service-object05-01.png') no-repeat 0 0; z-index: 3; transform: translateX(-35px); transition: all .8s; 
                    &::before {content: ""; position: absolute; left: 29px; top: 26px; width: 5px; height: 5px; background: #313131; border-radius: 100%; animation: object05-02 3s infinite alternate forwards;}
                    &::after {content: ""; position: absolute; right: 42px; top: 26px; width: 5px; height: 5px; background: #313131; border-radius: 100%;animation: object05-02 3s infinite alternate forwards;} 
                }                            
               &.on .object05-01 { transform: translateX(0px); transition: all 1.3s ; }
               
                .object05-02 {                  
                    position:absolute; right:2px;top:24px;width:91px;height:113px; background: url('/assets/images/pc/main/img-service-object05-02.png') no-repeat 0 0; z-index: 0;transform: translateX(35px); transition: all .8s; 
                    &::before {content: ""; position: absolute; left: 45px; top: 25px; width: 5px; height: 5px; background: #313131; border-radius: 100%; animation: object05-02 3s infinite alternate forwards 1.5s;}
                    &::after {content: ""; position: absolute; right: 28px; top: 26px; width: 5px; height: 5px; background: #313131; border-radius: 100%;animation: object05-02 3s infinite alternate forwards 1.5s; }
                }
                &.on .object05-02 { transform: translateX(0); transition: all 1.5s; }

                .object05-03 {position: absolute; background: #9845ed; top: 120%;  height: 200%;  width: 200%;  border-radius: 38%;  left: -50%;  transform: rotate(560deg); transition: all 1.5s ease .5s ; animation: wave 4.5s ease infinite 1.5s; opacity: .3; z-index: 5; }
                &.on .object05-03 { top: 0%; opacity: 1; transition: all 1.5s ease .5s; }
                .object05-04 { position:absolute;left:50%;top:0;width:136px;height:136px;margin-left:-68px;  background: url('/assets/images/pc/main/img-service-object05-03.png') no-repeat 50%; opacity: 0; transform: scale(0); z-index: 0; transition: all .8s ease .6s;}
                &.on .object05-04 {opacity: 1; transform: scale(1);  z-index: 7; transition: all 1s ease 1s;}  
            }

            .object06 {
                .object06-01 {position:absolute; left:14px; top:35px; width:108px; height:81px; background:url('/assets/images/pc/main/img-service-object06-01.png') no-repeat 0 0;}   
                .object06-02 {position:absolute; left:23px; top:54px; width:50px; height:50px; background:url('/assets/images/pc/main/img-service-object06-02.png') no-repeat 0 0;}
                .object06-03 {position:absolute; left:23px; top:54px; width:50px; height:50px; background:url('/assets/images/pc/main/img-service-object06-03.png') no-repeat 0 0;}
                .object06-04 {position:absolute; left:23px; top:54px; width:50px; height:50px; background:url('/assets/images/pc/main/img-service-object06-04.png') no-repeat 0 0;}
                .photo {
                    transition: all .8s ease-in-out; opacity: 0;  z-index: 0;
                    &.on {transition: all .8s ease-in;opacity: 1;  z-index: 5;}
                }                    
                .object06-06 {position:absolute;left:41px;top:41px;width:50px;height:10px;
                    .dot-flashing {position: relative; width: 4px; height: 4px; background-color: #ffffff;color: #ffffff; animation: dot-flashing 1s infinite linear alternate; animation-delay: 0.5s;}                   
                    .dot-flashing::before, .dot-flashing::after {content: ""; display: inline-block; position: absolute;top: 0;}
                    .dot-flashing::before {left: 10px; width: 4px; height: 4px; background-color: #ffffff;color: #ffffff;animation: dot-flashing 1s infinite alternate;  animation-delay: 0s;}
                    .dot-flashing::after { left: 20px; width: 4px; height: 4px; background-color: #ffffff; color: #ffffff; animation: dot-flashing 1s infinite alternate;animation-delay: 1s;}
                } 
            }

            .object07{
                .object07-01 {position:absolute; left:25px; top:24px; width:98px; height:73px; background:url('/assets/images/pc/main/img-service-object07-01.png') no-repeat 0 0;transition:all .8s ease-in-out 1s; transform:rotate(-10deg)  translateY(22px); }   
                .object07-01 {animation:object07 2s cubic-bezier(0.950, 0.050, 0.795, 0.035)  infinite alternate .5s; }
                .object07-02 {position:absolute; left:19px; top:53px; width:98px; height:73px; background:url('/assets/images/pc/main/img-service-object07-02.png') no-repeat 0 0;}  
                .object07-03 {
                    position:absolute; right:20px; bottom:18px; width:42px; height:43px; background:url('/assets/images/pc/main/img-service-object07-03.png') no-repeat 0 0; opacity: 0; transform: translateX(20px);  animation: object07-01 2s ease infinite alternate 1s;
                    &::before{content:''; display: block; height: 14px;  position: absolute; left: 50%; top: 50%; width: 9px;  margin:0 0 0 -10px;  transform: scaleX(-1) rotate(135deg);   transform-origin: left top; border-right: 5px solid transparent; border-top: 5px solid transparent; border-color: #fff;z-index: 5; opacity: 0;}
                    &::before {animation: check .8s alternate forwards 2.5s;  }
                }
            }
            /*
            .object08 {
                .object08-01 {
                    position:absolute;left:-10px;bottom:-10px;                 
                    .objt01 {position:absolute;left:0;top:0;z-index: 3;}
                    .objt02 {position:absolute;left:0;top:0;z-index: 3;opacity: 0;}
                }
                .object08-02 {position:absolute;right:-10px;bottom:-80px; z-index: 5;}
            }
            */

              .object09{
                .object09-01 {position:absolute;left:25px;bottom:0;width:77px; height:85px; background: url('/assets/images/pc/main/img-service-object09-01.png') no-repeat 0 0;background-size: 77px; z-index: 5; animation: object09 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) infinite alternate forwards 2.5s;  transform:translateY(10px)}
                .object09-02 {
                    position:absolute;left:24px;top:27px;width:91px; height:58px;
                    span:nth-child(1) {position: absolute; width:91px; height:59px; background: url('/assets/images/pc/main/img-service-object09-02.png') no-repeat 0 0;background-size: 91px; z-index: 0;}                           
                    span:nth-child(2) {position: absolute; width:91px; height:59px; background: url('/assets/images/pc/main/img-service-object09-03.png') no-repeat 0 0;background-size: 91px;z-index: 0;}
                    span {z-index: 0;transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1.000); transform: scale(0.5);}
                    span.on {z-index: 3;transition: all .5s cubic-bezier(0.215, 0.610, 0.355, 1.000); transform: scale(1);}
                }
            }
            
            .tit {display:block;margin-top:20px; font-size: 16px;  font-weight: 600; line-height: 1.63; letter-spacing: -0.32px;text-align: center;color: #191919;}
       }
    }
}

 @keyframes object09 {
    0% {
        transform:translateY(10px);
    }                 
    70%, 100% {                  
        transform:translateY(0);
    }
}

@keyframes wave {
    0% {
        transform: rotate(0deg);
    }
   80%, 100% {
        transform: rotate(50%);
    }
}

@keyframes check {
    0% {
        opacity: 1;
        height: 0;
        width: 0;
    }
    30% {       
        opacity: 1;     
        height: 0;
        width: 9px;
    }
    50%, 70%, 100% {
        height: 14px;
        width: 9px;
        opacity: 1;
    }        
}

@keyframes object02-01 {
    0%,
    100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    }
    15% {
    -webkit-transform: translateX(-4px) rotate(-6deg);
            transform: translateX(-4px) rotate(-6deg);
    }
    30% {
    -webkit-transform: translateX(6px) rotate(6deg);
            transform: translateX(6px) rotate(6deg);
    }
    45% {
    -webkit-transform: translateX(-7px) rotate(-3.6deg);
            transform: translateX(-7px) rotate(-3.6deg);
    }
    60% {
    -webkit-transform: translateX(7px) rotate(2.4deg);
            transform: translateX(7px) rotate(2.4deg);
    }
    75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
    }
}

@keyframes object02-02 {
    0% {
        transform:scale(0)  translateY(0);
    }
    20% {
        opacity: 1;
        transform:scale(.5) translateY(-10px);
    }
    20% {
        opacity: 1;
        transform:scale(1) translateY(-10px);
    }
    100% {
        opacity: 0;
        transform:scale(1) translateY(0px);
    }
}

@keyframes object05-02 {
0% {
    transform: scaleY(1);
}
18% {
    transform: scaleY(1);
}
20% {
    transform: scaleY(0);
}
25% {
    transform: scaleY(1);
}
38% {
    transform: scaleY(1);
}
40% {
    transform: scaleY(0);
}
45% {
    transform: scaleY(1);
}
80% {
    transform: scaleY(1);
}
}

@keyframes dot-flashing {
    0% {
        background-color: #ffffff;
    }
    50%,
    100% {
        background-color: rgba(152, 128, 255, 0.2);
    }
}

@keyframes object07-01{
    0%, 30%, 40%, 50% {
        opacity: 0;
        transform: translateX(20px);
    }
    80%, 100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes object07 {
    0%, 40% { 
    transform:rotate(-10deg)  translateY(22px); 
    } 
    50%, 80%, 100% {
    transform:rotate(0)  translateY(0px);
    }
}

@keyframes object05-06{
    0%{
      background-size: 80%;
      opacity: 0;
    }  
    100%{
      background-size: 100%;
      opacity: 1;
   
    }
  }

// 메인이벤트
.sec-main-event {
    .event-box {
        width:1280px;margin:0 auto;padding:12px 0;border-radius:10px;background:$gray01;
        a {
            display:flex;justify-content:space-between;flex-wrap:nowrap;align-items:center;min-height:204px;
            &>div {flex:1 1 32%;}
        }
        .day-time {
            display:flex;flex-direction:column;justify-content:center;align-items:center;
            &>span {display:block;margin-bottom:14px;@include normalTxt;}
            .num {
                @include mainContsTitle;
                &>span {margin-right:10px;}
            }
        }
        .info {
            display:flex;flex-direction:column;justify-content:flex-start;padding:0 80px;
            @include backgroundImg('/assets/images/pc/main/ico-more-black.svg', right 60px center, 20px 20px);
            strong {display:block;@include mainTxt;}
            p {display:block;margin-top:10px;@include defaultTxt;color:$black;}
        }
    }
    .event-time-box {
        position:relative; width:1280px; min-height: 110px; margin:0 auto;border-radius: 10px; background: #f2f4fc; 
        .img {position: absolute; left:61px;bottom:-3px; width:200px;}
        .info {
            padding:29px 0 0 293px;
            strong {display:block;line-height: 1.2; font-size:16px;color: #191919;letter-spacing: -0.32px;}
            p {margin-top:10px; font-size:26px;line-height: 1.2; letter-spacing: -0.52px; color:#191919; font-weight: 700;}           
        }
        .day-time {
            position: absolute; right:90px; top:30px; 
            .txt-day {line-height: 48px; color:#7c40ce;font-size: 48px;font-weight:700; letter-spacing: -0.96px;}
        }
    }
}

// 이벤트
.sec-event {
    .event-wrap {
        width:1280px;margin:0 auto;
        .list-bnr {
            display:flex;flex-wrap:wrap;gap:20px;
            &>li {
                flex:0 1 413px;
                img {width:413px;height:198px;}
            }
        }
    }
}

// 요금제
.sec-payment {
    padding:60px 0;background:#f2f4f5;
    .box-tab02 {
        .tab-btn-wrap {
            &.tab-wid {
                margin-bottom:60px;gap:0 40px;
                li {
                    flex:none;
                    a {
                        padding:12px 0;border-bottom:2px solid transparent;@include normalTxt;
                        &.on {background:none;border-bottom:2px solid $purple;color:$purple;}
                    }
                }
            }
        }
        .tab-contents-wrap {
            height:490px;
            .item-payment {
                display:flex;
                .tit {width:407px;text-align:left;}
                .list-payment {
                    width:898px;box-sizing:border-box;padding:0 25px;
                    .slick-slide {
                        overflow:hidden;width:414px;height:490px;border-radius:10px;background:$white;
                        &+.slick-slide { margin-left:20px;}
                    }
                    .slick-arrow {
                        width:50px;height:50px;border-radius:50%;font-size:0;background-color:rgba(0,0,0,0.6) !important;
                        &.slick-prev {left:-25px;@include backgroundImg('/assets/images/pc/main/btn-prev-white.svg', center, 30px 30px);}
                        &.slick-next {right:-25px;@include backgroundImg('/assets/images/pc/main/btn-next-white.svg', center, 30px 30px);}
                        &.slick-disabled{
                            display: none !important;
                        }
                    }
                }
            }
            .item {
                padding:40px 50px;
                dt {
                    height: 58px; @include ellipsis2;
                    strong {
                        display:block;@include subContsTitle;
                        &+span {display:block; height: 19px;@include defaultTxt; margin-top: 10px;}
                    }
                }
                dd {
                    margin-top:30px;
                    .data {
                        @include mainContsTitle; height: 90px; display: block;
                        span {display:block;@include defaultTxt; margin-top: 10px;}
                    }
                    .info {
                        li {
                            @include defaultTxt;
                            b {margin-right:9px;@include normalTxt;}
                            &+li {margin-top:10px;}
                        }
                    }
                    .box {
                        width: 100%; height: 88px; padding:20px;border-radius:10px;background:#f8f4ff; overflow: hidden; margin-top: 30px;
                        span {
                            display:block;@include defaultTxt; color:$black;
                            &+span {margin-top:10px;}
                        }
                    }
                    .payment {
                       width: 100%; margin-top: 20px;
                        strong {@include mainContsTitle;color:$purple;}
                        span {display:inline-block;padding-left:9px;@include mainTxt;color:$gray05;font-weight:normal;text-decoration:line-through;vertical-align:baseline;}
                    }
                }
            }
        }
    }
}

.sec-plan {
    position:relative; margin-top:100px;padding:100px 0; background:#f8f9fc;
    .box-tab02 {
        overflow: visible;margin:0;
        .tab-btn-wrap {
            &.tab-wid {
                margin-bottom:60px;gap:0 60px; justify-content: center;            
                li {
                    position:relative; flex:none;
                    .new {position:absolute;right:-15px;top:-5px;width:8px;height:8px;border-radius: 50%;background:#ff0200; box-shadow: 0 0 0 ; animation:2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite  new-pulse; font-size:0;}
                    a {
                        padding:0 0 5px 0;border-bottom:2px solid transparent; font-size: 22px; font-weight: 600; line-height: 1.18;letter-spacing: -0.44px;text-align: left;color: #666; background:none;
                        &.on {border-bottom:2px solid #191919; color:#191919;}
                    }
                }
            }
        }
    }
    .btn {
        position:absolute; right:0;top:-3px; display:inline-block; padding:12px 50px 10px 20px;border-radius: 100px;background-color: #e6e9f2; font-size: 16px; font-weight: 600;line-height:1;letter-spacing: -0.32px;text-align: left;color: #191919;
        &:hover:after {animation:moer-arrow 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;}
        &:after {content:''; position: absolute; right:20px;top:50%;width:20px;height:20px;margin-top:-10px; background:url('/assets/images/pc/icon/ico-arrow-more.svg') no-repeat 0 0;}        
    }

    .plan-view {
        .view-list {display:flex;gap:54px; justify-content: center;  align-items: center; }
        li {
            a {
                display:block;width:384px;min-height:466px; padding: 40px; border-radius: 30px; box-shadow: 2px 5px 10px 0 rgba(220, 217, 238, 0.7); background-color: #fff;transition:all .5s ease;
                &:hover {
                    transform: scale(108%); transition:all .5s cubic-bezier(0.65, 0.05, 0.36, 1)                  
                }
                .plan-info {
                    min-height:102px;text-align: center;
                    .plan-txt01 {display: block; font-size:18px;font-weight: 600; line-height: 1.17; letter-spacing: -0.36px;color: #191919;}
                    .plan-txt02 {display: block;margin-top:10px; font-size: 36px;font-weight: bold; line-height: 1.19; letter-spacing: -0.72px; color: #191919;}
                    .plan-txt03 {display: block;margin-top:10px; font-size: 16px; line-height: 1.19;letter-spacing: -0.32px;color: #662d91;}
                }
                .plan-spc {
                    margin-top:40px;
                    .spc {
                        display: flex; align-items: center; 
                        & + .spc {margin-top:20px;}
                        dt {width:67px;  font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                        dd { font-size: 18px;font-weight: bold;line-height: 1.17;letter-spacing: -0.36px;text-align: left;color: #191919;}
                    }
                }
                .plan-price {
                    display: flex; justify-content: space-between;  align-items: flex-end; margin-top:40px;
                    .fixed-price { font-size: 18px; line-height: 1.2;letter-spacing: -0.36px;text-align: right;color: #666; text-decoration: line-through;}
                    .disct-price {
                        font-size:18px; letter-spacing: -0.36px;font-weight:bold;line-height: 1.2; color:#662d91;
                        strong { font-size: 36px; font-weight: bold; line-height: 1; letter-spacing: -0.72px; text-align: right; color: #662d91;}
                    }
                }
                .plan-msg {
                    display: flex; justify-content: center;align-items: center; height:75px; margin-top:30px;padding-top:30px;border-top:1px solid #ddd;text-align: center;
                    p {
                        font-size: 16px; font-weight: 500; line-height: 1.38; letter-spacing: -0.32px; color: #191919;
                        strong {color:#ec008c;font-weight: bold;}
                    }
                }
            }
        }
    }
    .btn-custompaln {display: flex; justify-content: center; margin-top:40px;}
}

.sec-phone {
    .box-tab02 {
        overflow: visible;margin:0;
        .tab-btn-wrap {
            &.tab-wid {
                margin-bottom:48px;gap:0 60px; justify-content: center;            
                li {
                    position:relative; flex:none;
                    .new {position:absolute;right:-15px;top:-5px;width:8px;height:8px;border-radius: 50%;background:#ff0200; box-shadow: 0 0 0 ; animation: 2s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite  new-pulse;font-size:0;}
                    a {
                        padding: 0 0 5px 0;border-bottom:2px solid transparent; font-size: 22px; font-weight: 600; line-height: 1.18;letter-spacing: -0.44px;text-align: left;color: #666; background:none;
                        &.on {border-bottom:2px solid #191919; color:#191919;}
                    }
                }
            }
        }
    }
    .btn {
        position:absolute; right:0;top:-3px; display:inline-block; padding:12px 50px 10px 20px;border-radius: 100px;background-color: #e6e9f2; font-size: 16px; font-weight: 600;line-height:1;letter-spacing: -0.32px;text-align: left;color: #191919;
        &:hover:after {animation:moer-arrow 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;}
        &:after {content:''; position: absolute; right:20px;top:50%;width:20px;height:20px;margin-top:-10px; background:url('/assets/images/pc/icon/ico-arrow-more.svg') no-repeat 0 0;}        
    }

    .list-phone {
       box-sizing:border-box;
       a {   
            .phone-item {
                padding:40px;                 
                .item-img {
                height:180px;text-align:center;transform: scale(1); transition:all .5s cubic-bezier(0.22, 0.61, 0.36, 1);
                    img {display: inline-block; width:auto; height:100%;}
                }
                .item-tit {
                   height:88px; margin-top:20px;text-align:center;
                    .tit {overflow:hidden; display: block; font-size: 24px; font-weight: bold; line-height: 1.21; letter-spacing: -0.48px;color: #191919;}
                    .txt {display: block; margin-top:10px;font-size: 16px;line-height: 1.19; letter-spacing: -0.32px; color: #666;}
                }
                .item-price {
                    margin-top:20px;
                    dl {
                        display: flex; justify-content: space-between;
                        dt {font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;text-align: left;color: #191919;}
                        dd {font-size: 18px;font-weight: bold;line-height: 1.17;letter-spacing: -0.36px;text-align: right;color: #191919;}
                        & + dl {margin-top:10px;}
                    }
                }
                .price {
                    display: flex; justify-content: space-between;  align-items: flex-end;  margin-top:20px; padding-top:20px; border-top:1px solid #ddd; text-align: center; font-size: 18px; font-weight: bold; line-height: 1; letter-spacing: -0.72px;text-align: center;color: #662d91;
                    .fixed-price { font-size: 18px; line-height: 1.2;letter-spacing: -0.36px;text-align: right;color: #666; text-decoration: line-through;}
                    .disct-price {
                        font-size:18px; letter-spacing: -0.36px;font-weight:bold;line-height: 1.2; color:#662d91;
                        strong { font-size: 36px; font-weight: bold; line-height: 1; letter-spacing: -0.72px; text-align: right; color: #662d91;}
                    }
                    .selling-price {
                         width:100%;font-size:18px; letter-spacing: -0.36px;font-weight:bold;line-height: 1.2; color:#662d91;text-align:center;
                         strong { font-size: 36px; font-weight: bold; line-height: 1; letter-spacing: -0.72px; text-align: right; color: #662d91;}
                    }
                }             
            }
       }
        .slick-slide {
            overflow:hidden; display: block;width:384px;height:523px; margin: 0 20px 10px 0;border-radius: 30px;box-shadow: 2px 5px 10px 0 rgba(0, 0, 0, 0.03); border: solid 1px #eff1f5;;background:$white;transform: scale(1);
            &+.slick-slide { margin-left:42px;}            
            
        }
        .slick-arrow {
             width:90px;height:90px;border-radius:50%;font-size:0;
            &.slick-prev.slick-disabled {left:-45px;@include backgroundImg('/assets/images/pc/main/btn-prev-white-disabled.png', center, 90px 90px); transform:rotate(0deg) translateY(-50%) ;}
            &.slick-prev {left:-45px;@include backgroundImg('/assets/images/pc/main/btn-next-white02.png', center, 90px 90px); transform:rotate(-180deg) translateY(50%);}
            &.slick-next {right:-45px;@include backgroundImg('/assets/images/pc/main/btn-next-white02.png', center, 90px 90px); transform:rotate(0deg) translateY(-50%);}            
            &.slick-next.slick-disabled {right:-45px;@include backgroundImg('/assets/images/pc/main/btn-prev-white-disabled.png', center, 90px 90px); transform:rotate(-180deg) translateY(50%) ;}
        }
    }
    .phone-moer {
        margin-top:30px;text-align: center;
        a + a {margin-left:38px;}
    }

}

@keyframes new-pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(#ff0200, 0.4);
    }
    100% {
      box-shadow: 0 0 0 8px rgba(#ff0200, 0);
    }
  }

// 상품
.sec-products {
    .products-wrap {
        display:flex;justify-content:flex-start;
        .list-img {
            width:847px;height:400px;
            .slick-slide {
                overflow:hidden;width:847px;border-radius:10px;
                img {width:847px;height:400px;}
            }
        }
        .list-products {
            width:calc(100% - 897px);height:400px;box-sizing:border-box;padding-left:50px;
            .slick-slide {
                display:flex;align-items:center;height:80px;
                strong {
                    position:relative;padding:12px 0 12px 23px;border-bottom:2px solid $white;@include mainTxt;color:$gray05;transition:0.3s;
                    &>em {
                        display:inline-block;position:absolute;left:0;top:12px;
                        @include mainTxt;font-style:normal;color:$black;
                    }
                }
                &.slick-current {
                    strong {
                        border-bottom:2px solid $purple;color:$purple;
                        &>em {color:$purple;}
                    }
                }
            }
        }
    }
}

.sectin-tit {
    position:relative; width:1280px; margin: 0 auto;
    .btn {
        position:absolute; right:0;top:0; display:inline-block; padding:12px 50px 10px 20px;border-radius: 100px;background-color: #e6e9f2; font-size: 16px; font-weight: 600;line-height: 1;letter-spacing: -0.32px;text-align: left;color: #191919;
        &:hover:after {animation:moer-arrow 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;}
        &:after {content:''; position: absolute; right:20px;top:50%;width:20px;height:20px;margin-top:-10px; background:url('/assets/images/pc/icon/ico-arrow-more.svg') no-repeat 0 0;}
        
    }
}

// 구독서비스
.sec-subscription {
    
    .slick-service {
        padding-bottom:62px;
        .slick-slide {
            width:413px;
            &+.slick-slide {margin-left:20px;}
        }
        .slick-arrow {
            top:auto;width:32px;height:32px;font-size:0;
            &.slick-prev {left:50%; bottom: 16px; transform: rotate(180deg); margin-left:-42px;@include backgroundImg('/assets/images/pc/main/btn-cir-next.svg', center, 32px 32px);}
            &.slick-next {right:50%; bottom:0;  margin-right:-42px;@include backgroundImg('/assets/images/pc/main/btn-cir-next.svg', center, 32px 32px);}
        }
    }   
    .subscription-list {
        overflow: hidden; width: 100%;height: 190px;margin: 0 0 30px 0; padding-top:50px; 
        .subscription-box {
            display: flex;column-gap: 50px;width: 100%;
            .marquee2 {margin-top:-15px;}
            .mq {
                position:relative; flex-shrink: 0;width: 256px;height: 137px;
                .bubble {
                    position: absolute;top:-25px; right:10px; min-width: 100px;padding: 9px 20px; border-radius: 60px;background: #7c40ce; animation:bubble 3.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
                    p {font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #fff;;}
                    &:after{content: '';position: absolute;border-style: solid;border-width: 12px 10px 0;border-color: #7c40ce transparent;display: block;width: 0;bottom: -7px;right:30px;}
                }
            }
        }
    }
}

@keyframes moer-arrow {
    0% { 
        right:20px;
        opacity: 1;
    }         
     100% { 
        right:10px;
        opacity: 0;
    }
}

@keyframes bubble {
    0% { 
        top:-25px;
    }
     50% { 
        top:-35px;
    }
     100% { 
        top:-25px;
    }
}


//하단
.sec-main-btm {
    .btm-wrap {display:flex;justify-content:space-between;}
    .sec-inquire {
        display:flex;flex-wrap:wrap;gap:60px;width:calc(100% - 450px);padding:70px 0;border-top:1px solid #ddd;
        &>a {display:flex;justify-content:flex-start;flex:0 1 40%;gap:40px;}
        .img {display:inline-flex;justify-content:center;align-items:center;width:100px;height:100px;border-radius:50%;background:#eff1f5;}
        .txt {
            display:flex;flex-direction:column;justify-content:center;
            &>strong {display:block;@include mainTxt;text-align:left;}
            &>span {
                display:block;margin-top:10px;
                @include defaultTxt;color:$black;font-size:15px;line-height:1.6;
            }
        }
    }
    .sec-qr {
        display:flex;flex-direction:column;justify-content:center;align-items:center;width:420px;height:400px;border:1px solid #ddd;border-radius:10px;
        &>img {width:150px;height:150px;}
        &>strong {display:block;margin-top:15px;@include subContsTitle;text-align:center;}
        &>p {display:block;margin-top:10px;@include defaultTxt;color:$black;text-align:center;}
        .app-list {
            margin-top:20px;
            li {display:inline-block;}
            li + li {margin-left:7px;}
        }
    }
}

.layer-popup .self-alert {
    max-width:700px;padding-top:30px;
    .txt-main {text-align: left;}
    .btn-group button {width:220px;}
}