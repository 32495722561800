@charset "UTF-8";

.container {
    position:relative;width: 100%; min-height: 70vh; padding: 70px 0 100px 0;
    .sub-conts-wide{width: 100%;}
    .sub-conts {width: 1280px;margin: 0 auto;}
}

aside {display:none;}

#wrap {
    position: relative;width: 100%;height: 100%;padding-top:80px;
    header{
        min-width: 1280px;
    }
}

.scrollOff {position:fixed;overflow: hidden;width:100%;height: 100%;}

.breadcrumb {
    position: absolute; left: 50%; transform: translateX(-50%); z-index: 1; width:1280px;margin:20px auto 0;text-align:right;font-size:0; z-index: 2;
    &>li {
        display:inline-block;position:relative;padding:0 26px 11px 0;@include backgroundImg('/assets/images/pc/common/breadcrumb-ico-arrow.svg', 100% 5px, 10px);
        &+li {margin-left: 16px;}
        &:first-child {
            &>a {border-bottom:none !important;}
        }
        &:last-child {
            background:none;padding-right:0;
            &>a {@include normalTxt;}
        }
        &>a {@include defaultTxt;}
        &.on {
            &>a {z-index:106;border-bottom:2px solid $black;}
            .breadcrumb-sub {z-index:106;margin-top:0;opacity:1;}
        }
    }
    .breadcrumb-sub {
        display:none;position:absolute;right:0;top:30px;z-index:-1;margin-top:-5px;padding:20px;border:1px solid #ddd;border-radius:10px;background:$white;opacity:0;
        &>li {
            text-align:left;
            &+li {margin-top:10px;}
            &>a {
                @include defaultTxt;white-space:nowrap;text-align:left;
                &:hover,
                &.on {
                    @include normalTxt;letter-spacing:-0.3px;
                }
            }
        }
    }
}

.sub-visual {
    height:500px;margin-bottom:50px;text-align:center;
    img {position:relative;left:50%;max-width:none;transform:translateX(-50%);}
}

.page-tit {
    margin-bottom:50px;
    h1 {@include mainContsTitle;}
    h2 {@include mainContsTitle;}
}
.page-desc{
    margin-bottom: 50px;
    h2 {
        @include mainTxt;
        b{
            @include mainTxt; color:$gray05;
        }
    }
    p {
        margin-top: 10px;
        @include defaultTxt;line-height:1.5;/*line-height 예외처리*/
        &+p {margin-top:5px;}
    }
    .notification{
        margin-top: 10px;
    }
}

.full-popup-conts .page-tit{ margin-bottom:40px;}

.my-conts{
    display: flex; flex-direction: row-reverse;
    .fix-area{
        .my-fix-wrap{
            position: sticky; right: 0; top: 120px; width: 408px;
            a{display: block;}
            .inner{
                position: relative; margin-bottom: 30px; padding: 40px; background: #f7f8fa; border-radius: 10px;
                .my-info{
                    margin-bottom: 40px;
                    .box-select{
                        width: 100% !important; margin-bottom: 10px;
                        label{
                            display: flex; align-items: center;
                            img{
                                margin-right: 10px;
                            }
                        }
                    }
                    .single-case{
                       display: flex; align-items: center; height: 58px; margin-bottom: 10px; padding-left: 20px; background: $white; border: 1px solid $gray02; border-radius: 6px; letter-spacing: -1px;
                    }
                }
                .box-my-use {
                    .box-select {margin-bottom:60px;}
                    .user-txt {
                        margin-bottom:16px;@include normalTxt;
                        &>span{@extend %fontInherit;color:$purple;}
                    }
                    .user-info{
                        .user-product {
                            margin-bottom: 10px; @include mainTxt;
                        }
                        em {@include defaultTxt;vertical-align:baseline;}
                    }
                    .ico-wrap {
                        display: flex; gap: 20px;
                        span {
                            display: block;position: relative; padding-left:30px;
                            @include defaultTxt;
                            &.ico-call {@include backgroundImg('/assets/images/pc/icon/ico-call-fill.svg', left center, 20px 20px);width: auto;height:auto;}
                            &.ico-sms {@include backgroundImg('/assets/images/pc/icon/ico-sms-fill.svg', left center, 20px 20px);width: auto;height:auto;}
                        }
                    }
                    .use-time {
                        strong {margin-right:30px;@include contsTitle;}
                        em {@include defaultTxt;vertical-align:baseline;}
                    }
                }
                > div{
                    > .box-btn{
                        display: flex; gap: 10px;
                        a,button{
                            flex: 1;
                        }
                    }
                }
                .a-member{
                    p{
                        strong{
                            display: block; margin-bottom: 10px; @include normalTxt;
                        }
                    }
                    .img{
                       margin: 30px 0; font-size: 0; text-align: center;
                    }
                }
            }
            .my-link-list{
                padding: 20px 40px; border: 1px solid $gray02; border-radius: 10px;
                li{
                    & + li{
                        border-top: 1px solid $gray02;
                    }
                    a{
                        display: flex; align-items: center; gap: 20px; height: 60px; @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right.svg', 100% 50%, 20px); @include normalTxt;
                    }
                }
            }
        }
    }
    .info-area{
       width: 803px; margin-right: 69px;
       .box-input, .box-select, .input-group,.box-flex{
        width:100% !important;
       }
    }
}


// 쳇봇 및 설문
div[role="presentation"]{
    right: 0!important; bottom: 182px!important;
    iframe[style*="webSDKOnLoad"]{
        right:50% !important;bottom:123px !important;width:56px !important;height:56px !important;margin-right:-626px;opacity:0;z-index:0 !important;
    }
}
div{
    > div[role="presentation"]{
        > div{
            bottom:170px !important;
        }
        iframe{
            &+iframe{
                display: none;
            }
        }
    }
}

iframe[title='메시징 창을 시작하는 버튼'] {display:none;}
iframe[title='읽지 않은 메시지 수'] {display:none;}
iframe[title="메시징 창을 시작하는 버튼, 대화 진행 중"] {display:none;}
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 1개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 2개"], 
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 3개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 4개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 5개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 6개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 7개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 8개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 9개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 10개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 11개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 12개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 13개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 14개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 15개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 16개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 17개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 18개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 19개"],
iframe[title="메시징 창을 시작하는 버튼, 읽지 않은 메시지 20개"],
#launcher {display:none;}

iframe[title="메시징 창"] {bottom:185px !important;}


.chat-bot{
   position: fixed; right:60px; bottom: 124px; z-index: 90;
    .open{
        display: block; width: 54px; height: 54px;
        em{
            position: absolute; right: 0; top: 0; z-index: 10; width: 54px; height: 54px; @include backgroundImg('/assets/images/pc/icon/ico-floating-chatbot.svg', 100% 0, 54px); border-radius: 27px; font-size: 0;
        }
        &.on{
           width: 200px;
            > span{
                visibility: visible; width: 200px;
            }
        }
       > span{
            display: block; position: absolute; right: 0; top: 0; width: 54px; height: 54px; padding-left: 20px; background-color:#191919;  border-radius: 27px; overflow: hidden; visibility: hidden; transition: 0.3s;
            span{
                display: flex; align-items: center; width: 200px; height: 54px; color: $white; font-size: 14px;
            }
        }
    }
    .close{
        display: none; width: 54px; height: 54px; @include backgroundImg('/assets/images/icon/ico-btn-survey-close.svg', 0, 100%); font-size: 0;
    }
}
.chat-box,
#home {
    right: 0 !important; bottom: 182px !important;
}
.chat-box.chat-product{
    bottom: 265px !important;
}
.survey-conts.survey-product{
    bottom: 325px !important;
}

.float-top{
    position: fixed; right: 60px; bottom: 60px;
    a{
        display: block; width: 50px; height: 54px; @include backgroundImg('/assets/images/pc/icon/ico-top.svg', 0 , 100%); font-size: 0;
    }
}
.survey-wrap{
    position: fixed; right: 60px !important; bottom: 188px !important; z-index: 90 !important; width: 54px; height: 54px;
}
iframe.survey-conts{
    right: 0 !important; bottom: 245px !important; z-index: 100;
}
.survey-close{
    position: absolute; left: 0; top: 0; width: 54px; height: 54px; @include backgroundImg('/assets/images/icon/ico-btn-survey-close.svg', 0, 100%); font-size: 0;
}
.survey01{
    @include backgroundImg('/assets/images/icon/img_survey01.svg', 0, 100%); font-size: 0;
    .survey-btn01{
        width: 100%; height: 100%; font-size: 0;
    }
}

.survey02{
    @include backgroundImg('/assets/images/icon/img_survey02.svg', 0, 100%); font-size: 0;
    .survey-btn02{
        width: 100%; height: 100%; font-size: 0;
    }
}
.survey03{
    @include backgroundImg('/assets/images/icon/img_survey03.svg', 0, 100%); font-size: 0;
    .survey-btn03{
        width: 100%; height: 100%; font-size: 0;
    }
}
.survey04{
    @include backgroundImg('/assets/images/icon/img_survey04.svg', 0, 100%); font-size: 0;
    .survey-btn04{
        width: 100%; height: 100%; font-size: 0;
    }
}

