@charset "UTF-8";
.footer{
    .page-tit{
        h1{
            @include mainContsTitle;
        }
    }
    .terms-service-bottom{
            margin-top: 50px;
            .downLoad-list{
                gap: 10px;
                li{
                    width: 174px;
                    a{
                        height: 60px; align-items: center; padding: 0 20px;
                    }
                }
            }
            & > p{ margin: 20px 0;}
    }
    .terms-privacy{
        .version-box{
            margin-bottom: 20px;
            &:after{
                content: ''; display: block; clear: both;
            }
            .box-select{
                float: right; width: 328px;
            }
        }
        &-list{
            display: flex; flex-wrap: wrap; clear: both; padding: 40px; border: 1px solid $gray02; border-radius: 10px;
            li{
                flex: 1 1 40%; margin-top: 20px;
                a{
                    @include normalTxt;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
        .terms-cont{margin-top: 50px;}
        .person_rule{
            & + .person_rule{margin-top: 50px;}
            .title{
                @include mainTxt; margin-bottom: 10px;
            }
            .indent_list{
                & + .title{margin-top: 30px;}
                li{
                    @include defaultTxt;line-height: 1.5;
                    & + li{margin-top: 10px;}
                }
            }

            .table_privacy {
                margin-top: 10px;
                caption{display: none;}
                thead{
                    th{
                        background-color: $gray06; padding: 20px 10px; text-align: center;
                        @include normalTxt;vertical-align: middle;
                    }
                }
                tbody{
                    th,td{
                        padding: 20px 10px; border-bottom: 1px solid $gray02; vertical-align: middle;text-align: center;
                        @include defaultTxt; color: $black;
                    }
                }
            }
            #con6{
                & ~ .table_privacy_box{
                    .table_privacy {
                        tbody{
                            th{
                                background-color: $gray06; padding: 20px 10px; text-align: center;
                                @include normalTxt;vertical-align: middle;
                            }
                        }
                    }
                }
            }
            // as-is 꺼 가져옴
            .person_admin_wrap {
                display: flex; gap:40px; align-items: flex-start; 
                .person_admin{
                overflow: hidden;
                padding: 25px;
                border:1px solid $gray02; border-radius: 7px;
                & > span{
                    @include normalTxt;
                }
                }
            }
        }
    }
    .terms_cont, .privacy{
        .stitle01 { @include mainTxt; margin: 50px 0 10px;}
        .stitle02 {@include mainTxt; margin: 50px 0;}
        .stitle05 {
            @include normalTxt; margin-bottom: 10px; margin-top: 50px;
            & + .txt03{
                margin-bottom: 50px;
            }
              
        }
        .txt03 {
            margin-bottom:7px; @include defaultTxt;
            & + .txt03 {
                margin-top:27px
            }
            & + ol{
               // margin-top:-40px;
            }
        }
        ol,ul{
            margin:7px 0 10px; @include defaultTxt;
            li{
                @include defaultTxt; line-height: 1.3;
            }
        }
        .btn_top {margin:0 0 15px}
        .terms_btm {
            padding-top:8px; text-align:right; color:#666; line-height: 24px;
            p{
                margin-top: 15px;
                .btn{
                    margin-left:9px;    
                }
            }
        }
        .terms_tit {
            margin-bottom:13px; font-size:24px;
        }
        .select_box{
            &::after{
                content:''; clear:both; display:block
            }
            .left_box{
                float:left
            }
            .right_box{
                float:right
            }
        }
        .first_letter{
            margin-right: 5px;
        }
        .table-list{
            caption{
                display: none;
            }
            tbody{
                th{
                    background: $white; border-bottom:1px solid $gray02; @include defaultTxt;
                }
            }
        }
        .table-list-wrap{
            &.table-scroll {
                overflow-x: hidden;
                .table-list{
                    min-width: 1280px;
                }
            }
        }
        .table_privacy{
            margin-top: 20px;
        }
        .person_rule{
            margin-top: 50px;
            .title{
                @include mainTxt; margin-bottom: 10px;
            }
        }
        .kor_ord{
            margin-top: 10px;
        }
        .txtL{
            text-align: left !important;
        }
        .indent {margin-left:10px;}
    }
}