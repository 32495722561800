@charset "UTF-8";
// extend
%fontInherit {
    font-size: inherit; font-weight: inherit; letter-spacing: inherit; line-height: inherit; color: inherit;
}

/*** 공통(util하위) ***/
.util {
    .util-width{
        max-width: 630px; width: 100%;
    }
    //회원탈퇴
    .mem-withdraw-account-pop{
        .page-desc{margin-bottom: 40px;}
        .txt-sub-wrap{
            margin-bottom: 40px;
        .txt-sub{
            & + .txt-sub{
                margin-top: 10px;
            }
        }
        }
        .btn-check{
            label{
                @include normalTxt;
            }
        }
    }
    //법인회선 등록 팝업
    .regist-corp-line{
        .rq-form{
            border-bottom:none; margin-bottom: 0; padding-bottom: 0;
        }
        .bang-notice{
            margin-top: 10px;
        }
    }
    //이벤트 및 마케팅 수신
    .mem-withdraw-pop{
        .is-scroll{
            overflow-y: scroll; max-height: 224px; padding:16px 20px;
            &::-webkit-scrollbar{
                width: 10px;
            }
            &::-webkit-scrollbar-thumb{
                height: 40%;
                background-color: rgba(221,221,221,1);
                border-radius: 10px;
            }
            &::-webkit-scrollbar-track{
                background-color:rgba(221,221,221,0.4);
            }
        }
        .btn-check{
            margin-top: 40px;
            label{
                @include normalTxt;
            }
        }
    }
    //회원정보 수정
    .update-phone-mail{
        .cardList{
            padding:20px;
             .status{@include defaultTxt; }
            .receive-ing{
                display: flex; gap:30px; align-items: center; padding-top: 20px;

            }
            .receive-save{
                padding-top: 20px;
                .status{
                    margin-bottom: 10px;
                }
            }
            .receive-wrp {
                .receive-save {padding:0;}
                .receive-ing {padding:0;}
                & + .flex-check {margin-top:20px;padding:20px 0 0;border-top: 1px solid #ddd; border-bottom: 0;}
                .ico-arrow-right {margin-left:auto}
            }
        }
        .flex-check{
            justify-content: flex-start;  border-bottom:1px solid $gray02; width:100%; padding-bottom: 20px;
        }
        .cardList-title{
            @include normalTxt; margin-bottom: 16px;
        }
    }
    //회원정보 수정 _법인회원정보 안내
    .join{
        .rq-form{
            border-bottom: 0; padding-bottom: 0;
        }
        .flex-check{
            display: inline-flex; gap: 30px;
        }
        .tel-group{
            flex-wrap: nowrap;
            .btn-radio {
                width: calc(20% - 10px);
                .ico {
                    &::before{
                        content: '';
                        display: inline-block; margin-right: 10.0001px; width: 26px; height: 26px;
                        @include backgroundImg('/assets/images/pc/icon/logo-skt.svg', center, contain);
                    }
                }
                label{
                    display: flex; align-items: center; justify-content: center;line-height: initial; padding: 0; min-width: auto;
                    &.ico-skt {
                        @extend .ico;
                    }
                    &.ico-kt {
                        @extend .ico;
                        &::before {
                            background-image: url('/assets/images/pc/icon/logo-kt.svg');
                        }
                    }
                    &.ico-lg {
                        @extend .ico;
                        &::before {
                            background-image: url('/assets/images/pc/icon/logo-lg.svg');
                        }
                    }
                }
            }
        }
        .save-phone-radio-wrap{
            display: none; margin-top: 20px;
        }
    }
    //로그인
    .login{
        .rq-form{
            border-bottom: 0; padding-bottom: 0;
        }
    }
    //로그인 페이지
    .login-info{
        .tooltip-wrap{
            margin-top: 10px;
        }
        .box-input{
            & + .box-input{
                margin-top: 20px;
            }
        }
        .btn-check{
            label{
                @include normalTxt;
            }
        }
        .wid{margin-top: 40px; }
        .btn-list{
            margin-top: 50px;
            li{
                & + li{
                    margin-top: 20px;
                }
            }
        }
        .btn-underline{
            text-decoration: none; margin-top: 80px;
        }
        .box-sns{
        margin-top: 30px; text-align: center;
            .icon-list {
                display: flex;justify-content: center;align-items: center; gap: 24px;
                li{
                    & > a{
                            display: block; width: 64px; height: 64px;
                        &.icon {
                            &-sns-naver{@include backgroundImg('/assets/images/pc/icon/ico-sns-naver.png', center,contain);}
                            &-sns-google{@include backgroundImg('/assets/images/pc/icon/ico-sns-google.png', center,contain);}
                            &-sns-apple{@include backgroundImg('/assets/images/pc/icon/ico-sns-apple.png', center,contain);}
                            &-sns-kakao{@include backgroundImg('/assets/images/pc/icon/ico-sns-kakao03.png', center,contain);}
                        }
                    }
                }

            }
        }
    }
    //사업자정보입력
    .corporate{
        .rq-form{
            border-bottom: 0; padding-bottom: 0;
        }
        .check-wrap{
            display: flex; justify-content: space-between; align-items: center;margin-top: 10px;
            .bang-notice {
                margin-top: 0;
            }
        }
    }
    //약관목록
    .agree-list{
        .agree-wrap{
            margin-top: 0;
            .btn-check{
                margin-top: 0;
                & + .btn-check{
                    margin-top: 20px;
                }
                &:not(.btn-text-line) {padding: 0 20px;}
            }
            & + .btn-group{
                margin-top: 50px;
            }
        }
    }
    .order-phone-auth{
        .underline-bk{
            margin-top: 10px; font-size: 14px;
        }
    }
    .find-finish {
        .cardList-wrap {
            padding:0;
            .cardList{
                & + .cardList{margin-top: 30px;}
            }
            .identifi {
                display:flex;justify-content:flex-start;gap:0 20px;
                img {width:80px;height:80px;}
                .desc {
                    display:flex;justify-content:flex-start;flex-direction:column;    flex-shrink: 0;
                    b {display:block;@include mainTxt;}
                    span {display:block;margin-top:10px;@include defaultTxt;}
                }
            }
            // .icon-txt-card:first-child{
            //     justify-content: space-between;
            // }
            .id-txt{color: $purple;}
            & + .box-btn{margin-top: 40px;}
        }
        .btn-group{
            margin-top: 50px;
        }
        .sns-link{
            p{
                @include normalTxt; margin-bottom: 10px;
            }
        }
        .sns-no-link{
           &.accordian{
                .acc-trigger{
                    @include normalTxt; text-align: left;
                    &::after{
                        right: 230px;
                    }
                }
                .acc-conts{
                    background: $white; padding:0;
                    ul{
                        display: flex; align-items: center; gap:20px;
                        li{
                            width:40px;
                        }
                    }
                }
           }
        }
        .ico-list {
            display:flex;justify-content:flex-end;gap:8px;
            li {
                img {width:40px;height:40px;}
            }
        }
    }
    //정보없음
    .find-no-data{
        .round-box {
            padding:40px;border-radius:20px; margin-bottom: 50px;
            background-color: $bg;
            & > p {@include defaultTxt; margin-top: 10px;}
            .box-btn {margin-top:20px;}
        }
    }
    // 법인회선
    .corp-line{
        .box-radio{margin-top: 30px;}
        .table-type-04{
            .ico-delete{vertical-align: middle;}
            tr {
                td{
                    @include defaultTxt; color: $black;
                    .chip-wrap{
                        display: inline-block; margin-right: 10px;
                        img{vertical-align: middle;}
                    }
                }
            }
        }
        .info-area{
            margin-top: 80px;
            .notification{
                margin-top: 10px;
            }
        }
        .btn-group{
            margin-top: 50px;
        }
        .box-ico-txt{
            background-image: url('/assets/images/pc/icon/ico-corp-no-data.svg');
        }
    }

    //유알모 sns
    .introduce-sns{
        .grid {
            max-width: 1280px; counter-reset: grid-item;
            &::after{
                content: ''; display: block; clear: both;
            }
        }
        .grid-item {
            width: 416px; height: auto; min-height:150px;  margin-bottom: 16px;
            float: left; overflow: hidden;  border-radius: 7px; background: $gray02;
            img{ width:100%; height:100%; display: block;}
            a{
                position: relative; width:100%; height:100%; display: block;
                &::before{
                    content: ""; position: absolute; top: 0; left: 0; z-index: 1; display: block; width: 100%; height: 100%;  background-color: #161616; border-radius:7px; opacity: 0; transition: all 0.3s;
                }
            }
        }
        .grid-img{
            &-desc{
                position:absolute; top:50%; left:50%; transform: translate(-50%,-50%); width:100%; padding:20px; z-index: 2; opacity: 0; transition: all 0.3s;
                h3{
                 @include subContsTitle; color:$white; text-align: center;
                }
            }
            &.no-title{
                a::before{
                    display: none;
                }
                .grid-img-desc{
                    display: none;
                }
            }
            &:hover{
                a{
                    &::before{
                         opacity: .6;
                    }
                     .grid-img-desc{
                        opacity: 1;
                     }
                }
            }
        }
        .grid-txt{
            &-desc{
                display: flex; justify-content: center; align-items: center; height: 100%; min-height: 150px; width:100%; padding:40px; z-index: 2; opacity: 1; transition: all 0.3s;
                h3{
                 @include subContsTitle; color:$black; text-align: center;
                }
            }
        }
    }
    .mem-info-person-popup{
        .txt-main{
            strong{
                @include subContsTitle;
            }
        }
    }

    // 브랜드
    &.pb0{padding-bottom: 0;}
    .introduce-ualmo{
        .brand-banner{
            display: flex; justify-content: center; align-items: center;  width: 100%; height: 800px;
            @include backgroundImg('/assets/images/pc/subpage/introduce-ualmo/ualmo-visual.jpg',center, cover);
        }
        .brand-main-txt{
            font-size: 60px; font-weight: 600; line-height: 1.3; letter-spacing: -1.8px; color: $black;
        }
        .brand-sub-txt{
            @include mainTxt; line-height: 1.5; font-weight: 400; margin-top: 40px;
        }
        .img-wrap{
            img{display: block;}
        }
        .main-visual{
            .txt-wrap{
                *{text-align: center;}
                p{
                    @include contsTitle; color: $white;
                }
                .brand-main-txt{
                    margin-top: 20px; color: $white;
                }
                .sm-txt {font-size: 39px; line-height: 1.18; letter-spacing: -0.39px; color: #fff;font-weight: normal;}
                .md-txt { font-size: 58px;font-weight: 600;line-height: 1.38;letter-spacing: -1.45px;color: #fff;}
                .big-txt {margin-top:30px;font-size: 110px;font-weight: 600; line-height: 1.2; letter-spacing: 0; color: #fff;}
            }
        }
        .app-capture{
            display: flex; justify-content: space-between; align-items: center; margin-top: 60px;
            .img-wrap{
                width: 630px;
            }
        }
        .easy-ualmo{
            margin-top: 60px; background-image: url('/assets/images/pc/subpage/introduce-ualmo/easy-ualmo.jpg');
            .unit-origin{
                display: flex; flex-direction: column; align-items: flex-end;
                .txt-wrap{
                    width: 50%;
                    *{color: $white;}                    
                }
              
            }
        }
        .trust-ualmo{
            margin-top: 0px; background-image: url('/assets/images/pc/subpage/introduce-ualmo/trust-ualmo.jpg');
            .unit-origin{
                display: flex; flex-direction: column; align-items: flex-end;
                .txt-wrap{
                    width: 42%;
                    *{color: $white;}
                    .notification {
                        margin-top:40px;
                        li {
                            padding-left:15px; font-size: 20px; font-weight: normal; line-height: 1.5; letter-spacing: 0; text-align: left; color: #fff;
                            &::before {
                                content: "";
                                width: 4px;
                                height: 4px;
                                background: #fff;
                                border-radius: 50%;
                                position: absolute;
                                top: 12px;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }-ualmo{
            margin-top: 60px; background-image: url('/assets/images/pc/subpage/introduce-ualmo/easy-ualmo.jpg');
            .unit-origin{
                display: flex; flex-direction: column; align-items: flex-end;
                .txt-wrap{
                    width: 50%;
                    *{color: $white;}
                }
            }
        }
        .rolling-area{
            display: flex;align-items: center; overflow: hidden; position: relative;  padding: 220px 0; background-color:$white;
            .not-rolling{
                display: flex; justify-content: flex-end; align-items: center; padding-right: 150px; width: 50%; flex-shrink: 0; background-color:$white;position: relative; z-index: 1; height: 400px;
            }
            .rolling-banner{
                position: absolute; left: 50%; overflow: visible; padding-left: 20px;
                .slick-list,.slick-track{
                    overflow: visible;
                }
                .rolling-item{
                    display: flex;flex-direction: column;align-items: center; position: relative; width: 340px; height: 360px; border-radius: 60px; padding: 40px 0 0;background-color: #e748aa;overflow: visible; margin-right: 30px;
                    &.slick-active{
                        box-shadow: 16px 16px 30px rgba(0,0,0,0.2);
                    }
                    .rolling-txt{
                        text-align: center;
                        .item-chip{
                            display: inline-block; min-width: 100px; padding: 10px 30px; font-size: 16px; font-weight: 700; color: $white;text-align: center; letter-spacing: -0.3px; background-color: #c6387f; border-radius: 18px;
                        }
                        p{
                            margin-top: 20px; font-size: 26px; color: $white; line-height: 1.3; font-weight: 600; letter-spacing: -0.5px; text-align: center;
                        }
                    }
                    .rolling-icon{
                        position: absolute; bottom: 0px; z-index: 1;
                    }
                    &.item-02 {
                        background-color: #575757;
                        .item-chip{background-color: #4c4c4c;}
                        .rolling-icon{bottom: -30px;}
                    }
                    &.item-03 {
                        background-color: #1240aa;
                        .item-chip{background-color: #103892;}
                        .rolling-icon{bottom: -30px;}
                    }
                    &.item-04 {
                        background-color: #7a3ece;
                        .item-chip{background-color: #874fd5;}
                        .rolling-icon{bottom: -30px;}
                    }
                    &.item-05 {
                        background-color: #fc5d4a;
                        .item-chip{background-color: #db5140;}
                    }
                }
            }
        }
        .qr-area{
            background-color: $purple; padding: 100px 0;
            .unit-origin{
                display: flex; gap: 180px;align-items: center;
            }
            .txt-wrap{
                strong{
                    @include contsTitle; color: $white;
                }
                p{@include subContsTitle; color: $white; margin-top: 50px;}
                .brand-main-txt{margin-top: 20px; color: $white;}
                .app-download{
                    display: flex; gap: 10px; margin-top: 30px;
                    a{
                        display: block; width: 220px; height: 58px; display: flex; justify-content: center; align-items: center; background-color: $white; border-radius: 30px;
                    }
                }
            }
            .qr-wrap{
                width: 238px; height: 238px;
                img{
                    display: block;
                }
            }
        }
        .txt-wrap{
            opacity: 0; transform: translateY(20%); transition: opacity 0.7s,transform 0.5s;
            &.on{
                opacity: 1; transform: translateY(0);
            }
        }
    }

        
    .modify-certify {    
        .icon-txt-card {position:relative; min-height:120px;
        & >.identifi, & >.card-img  {width:80px;height:100%;}
        .ico-list {position:absolute; right:20px;top:50%; margin-top:-20px;}
        }
    }

    .cardList-wrap {
        .user-name {  text-align: right;
            .txt-user {display: inline-block;width:77%;text-align: left; vertical-align:top; font-weight:600; color:#191919;}
            .btns {display: inline-block;min-width:auto;padding:0 10px;}
            .user-mdify {
                margin-top:10px;
                .box-input {display: inline-block; width:230px;}
                .box-input .input-default {padding: 15px 45px 15px 8px;}
                .box-btn {display:inline-block;}
                .box-btn button {min-width:100px;}
            }
        }
    }

    .receive-consent {
        .agremt {
            display:block; margin-bottom:10px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;
            span {display:inline-block;margin-left:10px; color:#662d91;font-size:16px;font-weight: 600;}
        }
    }

    .receive-agree {
        .agree-chk {
            .txt-dt {font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}                
            li {
                display:flex; justify-content: space-between; align-items: center; height:28px; margin-top:20px;
                .txt {
                    a {margin-left:10px; text-decoration: underline; font-size: 16px;line-height: 2.5;letter-spacing: -0.32px;text-align: left;color: #191919;}
                }
                .btn-radio-type2 {
                    width: auto;
                    .radio-bx {
                        display: inline-block;
                        & + .radio-bx {margin-left:30px;}
                        label {display: inline-flex;  }
                    }
                }
            }           
        }
        .btn-group {margin-top:50px;}
    }

}

/*** total search ***/
.total-search {
    //공통
    .txt-vt,
    .discount-pay {color:#7c40ce !important;}
    .pay-amount {
        padding:0;
        .origin-pay {@include defaultTxt;color:$gray04;}
        .discount-pay {@include subContsTitle;}
    }
    .no-result {
        padding:100px 0 11px;
        .no-result-txt {padding:0;background:none;@include contsTitle;}
    }

    //개별
    .search-box {
        position:relative;padding:0 0 12px;
        .wrap {
            width:600px;margin:0 auto;
            fieldset {
                display:flex;justify-content:space-between;flex-wrap:nowrap;padding:28px 0 22px 0;border-bottom:1px solid #191919;
                .box-input{
                    display: flex; align-items: center; width: 100%;
                    .input-wrap{
                        width: 100%;
                        input {
                            width:100%;height: 31px;border:none;@include mainTxt;font-weight:normal;
                        }
                        &.txt-search {
                            input {
                                font-size: 30px; font-weight: 600;line-height: 1.2;letter-spacing: -0.6px;text-align: left; color: #662d91;
                            }
                            &.search-result {
                                margin:0;
                                input {color: #191919;}
                            }
                        }
                    }
                    .btn-search {
                        flex-shrink:1;overflow:hidden;width:24px;height:24px;font-size:0;
                        @include backgroundImg('/assets/images/pc/common/header-btn-search.svg', center, 24px 24px);
                    }
                }
            }
        }
    }
    .search-bundle {
        margin-top:89px;
       

        .search-title {
            display:block;margin-bottom:10px;@include contsTitle;
            .nber {display:inline-block;margin-top:-5px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #ec008c;vertical-align: middle;}
        }
        .search-more {
            margin-top:50px;text-align:center;
            .btn-more {
                position:relative;padding-right:30px;@include normalTxt;
                &::after {
                    content:'';display:block;position:absolute;right:0;top:0;width:20px;height:20px;
                    @include backgroundImg('/assets/images/pc/common/search-more.svg', right top, 20px 20px);
                }
                &.fold {
                    &::after {transform:rotate(180deg);}
                }
            }
        }

        .search-phone {
            .item-info {
                .tit {height:48px;}
            }
        }

        //bundle
        .search {

            &-vdo {
                .going-list-wrap {
                    margin-top:0;
                    &>div {display: flex; flex-wrap: wrap;  gap: 30px;}
                }
            }

            &-menu ,
            &-wrong {
                #viewMenu,#moreViewMenu{
                    display:flex;flex-direction:column;gap:10px 0;
                    a {display:flex;justify-content:flex-start;gap:0 20px;}
                    strong {width:200px;@include defaultTxt;color:$black;}
                    p {@include defaultTxt;color:$black;}
                }
                #moreViewMenu{
                    margin-top: 10px;
                }
             }

            &-payment {
                border-top:1px solid $black;
                dl {
                    display:flex;justify-content:flex-start;align-items:center;gap:0 20px;padding:25px 80px 25px 40px;border-bottom:1px solid $gray02;
                    @include backgroundImg('/assets/images/pc/icon/ico-arrow-gray-s.svg', right 40px center, 20px 20px);
                    dt {
                        width:360px;
                        .title {@include mainTxt;}
                    }
                    dd {
                        display:flex;justify-content:space-between;align-items:center;width:calc(100% - 360px);
                        .feature {
                            display:flex;justify-content:flex-start;margin:0;
                            span {
                                width:165px;height:20px;padding-left:30px;@include defaultTxt;color:$black;line-height:20px;
                                &.vol {
                                    &::before {@include backgroundImg('/assets/images/pc/icon/ico-data-black.svg', center, 20px 20px);}
                                }
                                &.limit {
                                    &::before {@include backgroundImg('/assets/images/pc/icon/ico-call-fill-black.svg', center, 20px 20px);}
                                }
                                &.supply {
                                    &::before {@include backgroundImg('/assets/images/pc/icon/ico-sms-fill-black.svg', center, 20px 20px);}
                                }
                            }
                        }

                    }
                }
            }

            &-service {
                #viewAdditionalService, #moreViewAdditionalService, #viewSubscriptionService, #moreViewSubscriptionService{
                    display:flex; gap:30px; flex-wrap: wrap;
                    a {
                        width: 625px; padding:40px;border-radius:10px;border:1px solid $gray02;
                    }
                    dl {
                        display:flex;justify-content:space-between;padding-right:40px;
                        @include backgroundImg('/assets/images/pc/icon/ico-arrow-gray-s.svg', right center, 20px 20px);
                        dt {
                            .title {@include mainTxt;}
                        }
                    }
                }
                #moreViewAdditionalService, #moreViewSubscriptionService{
                    margin-top: 30px;
                }
            }

            &-phone {
                #viewPhone, #moreViewPhone{
                    display: flex; flex-wrap: wrap; gap: 30px;
                    a {width: 297px;padding:40px;border-radius:10px;border:1px solid $gray02;}
                    .item-img {
                        width:100%;height:217px;margin-bottom:13px;text-align:center;
                        img {max-width:100%;max-height:100%;}
                    }
                    .item-info {
                        display:flex;align-items:center;flex-direction:column;
                        .tit {@include mainTxt;}
                        .desc {margin-top:10px;@include defaultTxt;}
                        .price {
                            margin-top:30px;
                            span {margin-right:10px;@include defaultTxt;color:$black;}
                            strong {position:relative;@include subContsTitle;color:$purple;}
                            span.dc-prc {position:absolute;right:0;top:-18px;min-width:160px;margin-right:0;font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: right;color: #666;text-decoration: line-through;}
                        }
                    }
                    .item-btm {
                        display:block;margin-top:30px;text-align:center;
                        button{
                            width: 100%;
                        }
                    }
                }
                #moreViewPhone{
                    margin-top: 30px;
                }

            }

            &-card {
                #viewAffiliatedCard, #moreViewAffiliatedCard{
                    display: flex; flex-wrap: wrap;
                    a {width:320px;padding:40px 40px 0;}
                    .item-img {
                        width:100%;height:126px;margin-bottom:20px;text-align:center;
                        img {max-width:100%;max-height:100%;}
                    }
                    .item-info {
                        display:block;
                        .tit {display:block;overflow:hidden;text-overflow:ellipsis;@include mainTxt;white-space:nowrap;text-align:center;}
                        .desc {display:block;overflow:hidden;text-overflow:ellipsis;margin-top:10px;@include defaultTxt;white-space:nowrap;text-align:center;}
                    }
                }
            }

            &-event {
                .going-list-wrap {
                    margin-top:0;
                    #viewEvent,#moreViewEvent{
                        display: flex; flex-wrap: wrap; gap: 30px;
                    }
                    .cardList-wrap {
                        width:297px;
                        .cardList-img {
                            width:297px;height:182px;
                            img {width:297px;height:182px;}
                        }
                    }
                    .cardList-desc {
                        .main-title {display:block;overflow:hidden;text-overflow:ellipsis;height:auto;white-space:nowrap;}
                        .period {
                            color:$gray04;text-align:center;
                            span {color:$gray04;}
                        }
                    }
                }
            }

            &-down {
                border-top:1px solid $black;
                .downLoad-list {
                    display: block; gap:0;                   
                   #viewDocFormFile, #moreViewDocFormFile{
                        display: flex; flex-wrap: wrap;
                        >li {
                            width:100%;border-bottom:1px solid $gray02;
                            a {gap:0 20px;padding:20px 40px;border:none;}
                            .downLoad-tit {@include defaultTxt;color:$black;font-size:18px;}
                            .ico-downLoad {width:30px;height:30px;margin-right:60px;background-size:30px 30px;}
                        }
                   }
                }
            }

            &-board {
                border-top: 1px solid $black;
                #viewNotice, #moreViewNotice{
                    a{
                        display: flex; justify-content: space-between; align-items: center; height: 60px; border-bottom: 1px solid $gray02;
                        p{
                            width: 980px; padding: 0 40px; color: $black; font-size: 18px;
                        }
                        span{
                            width: 300px; padding-right: 40px;
                        }
                    }
                }
            }

            &-banner {
                display:flex;justify-content:center;align-items:center;gap:30px;border:1px solid $gray02;border-radius:10px;padding:40px;
                .item-txt {
                    strong {@include mainTxt;}
                    p {margin-top:10px;@include defaultTxt;}
                    a {margin-top:12px;}
                }
            }

            &-adcn {
                border-top:1px solid #191919;border-bottom:1px solid #ddd;
                .accordian {
                    .acc-trigger {
                        margin-bottom:0;
                        .unit-tit {padding:20px 40px; font-size: 18px;line-height: 1.13;letter-spacing: -0.32px;text-align: left;color: #191919;}
                        &::after {content: ''; right:40px;}
                    }
                    .acc-conts {
                        padding:0 40px 40px;
                        & +  .acc-trigger {border-top:1px solid #ddd; }
                        p {line-height:1.5;}
                        img {padding:20px 0;}
                    }
                }
                
            }

            &-wrong {
                #viewMenu {margin-top:30px;}
            }
        }
    }   

    .search-faq {
        display: flex;gap:30px;
        .box-faq {
            display: flex;align-items: center; width:625px; padding: 40px;border-radius: 10px;border: solid 1px #ddd;background-color: #fff;
            img {width:80px;}
            .wrap {
                margin-left:30px;
                .tit {display:block;margin-bottom:10px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                .txt {display:block;margin-bottom:10px; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
            }
        }
    }

    .seacrh-cmnt {
        display: flex; position: relative; margin-top:30px; padding: 29px 40px;border-radius: 10px;background-color: #f5f9fc;
        .box-faq {
            display: flex; align-items: center;
            .wrap {
                margin-left:30px;
                .tit { display: block; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            }
            .btn-cmnt {
                position:absolute;right:40px;top:40px; width:310px; padding: 18px 0 19px 30px;border-radius: 10px;border: solid 1px #ddd;background-color: #fff; font-size: 16px;font-weight: 600; line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #000;
                &::after {content:''; position:absolute;right:20px;top:18px;width:20px;height:20px; @include backgroundImg('/assets/images/pc/icon/ico-arrow-right.svg', right,20px 20px);}
            }
        }
    }

    .search-msg {
        margin-top:100px; font-size: 30px;font-weight: 600;line-height: 1.2;letter-spacing: -0.6px;text-align: center;color: #191919;
        span {font-size: 30px;font-weight: 600;line-height: 1.2;letter-spacing: -0.6px;color:#662d91}
    }
  
    .search-category {
        display: flex;margin:60px 0 100px 0;  padding: 40px;border-radius: 7px;border: solid 1px #ddd;background-color: #fafafa;
        .category-total {
            p {
               width:150px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;
                span {display: inline-block; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px; color:#ec008c;}
            }
        }
        .category-list {
            display:flex; flex-wrap: wrap; gap:20px 30px;
            li {                                                            
                a {
                    font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;
                    span {display: inline-block;margin-left:2px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px; color:#ec008c;}
                }
            }
        }
    }

    .recmd-search {
        display: flex; justify-content: space-around; align-items: center; width:100%;margin-top:40px;text-align: center;
        dl {
            display: flex;
            dt {margin-right:40px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
            dd {
                & + dd {margin-left:30px;}
                a { font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;text-decoration: underline;}
            }
        }
    }
}

.error-wrap{
    display: flex; justify-content: center; align-items: center; width: 100%; height: 100vh;
    .error-conts{
        width: 1280px; padding-top: 166px; @include backgroundImg('/assets/images/pc/subpage/img-error.png', 50% 0, auto); text-align: center;
        h1{
            margin-bottom: 30px; color: $purple; font-size: 44px; font-weight: 600; letter-spacing: -0.88px;
        }
        h2{
            margin-bottom: 60px; @include subContsTitle;
        }
        .box-btn{
            margin-bottom: 70px;
        }
        .appli-group-wrap {
            padding:40px 0; border:1px solid #ddd;border-radius:7px;
            .appli-02 {
                display: flex; justify-content: center; width:100%; text-align: left;
               .cs{
                    display: flex; align-items: center;
                    img{
                        width: 80px; margin-right: 20px;
                    }
                    p{
                        margin-right: 150px;
                        strong{
                            display: block; @include normalTxt;
                            &+strong{
                                margin-top: 10px;
                            }
                        }
                    }
                    dl{
                        display: flex; margin-bottom: 10px;
                        dt{
                            margin-right: 20px; @include normalTxt;
                        }
                        dd{
                            @include normalTxt;
                        }
                    }
               }
            }
        }
    }    
}

.introduce-video {
    .is-filter-wrap {margin-bottom:50px;}
}

.out-service-wrap{
    display: flex; justify-content: center; width: 100%; background-color: #f4f6ff; padding: 80px 0;
    .out-service-conts{
        width: 900px;
        .img-area{
            img{
                display: block; width:200px; margin: 0 auto;
                &:first-child{margin-bottom: 20px;}
            }
        }
        h1{
            width: 100%; text-align: center; font-size: 36px; color: #662d91; line-height: 43px; margin-bottom: 30px; letter-spacing: -0.88px;
            span{font-size: 36px; color: #662d91; font-weight: 600;}
        }
        .date-area{
            width: 544px; line-height: 66px; border-radius: 100px; background-color: #191919; text-align: center; margin: 0 auto 40px; color: #fff; font-size: 22px; letter-spacing: -0.44px;
            span{color: #fff; font-size: 22px;}
        }
        .txt-area{
            width: 100%; text-align: center; margin: 40px 0; padding-top:40px; border-top:1px solid #ddd;
            .txt-bld {font-weight:600; font-size:20px;}
            p.txt{
                margin-top:20px; font-size: 18px; line-height: 1.56; letter-spacing: -0.36px; text-align: center; color: #191919;                
            }
            &.bottom{
                p{font-size: 18px; font-weight: normal;}
            }
        }
        .notice-area{
            width: 100%; background-color: #fff; border-radius: 20px; padding: 50px; margin-bottom: 40px;
            dl{
                margin-bottom: 20px;
                dt{@include normalTxt01; font-size: 18px; font-weight: 600; margin-bottom: 10px;}
                dd{
                    @include normalTxt01; line-height: 22px;
                    ul{
                        counter-reset: number 0;
                        li{
                            @include normalTxt01; line-height: 22px; padding-left: 20px; position: relative;
                            &::before{counter-increment: number 1; content: counter(number)')'; display: block; width: 20px; position: absolute; left: 0; top: 0;}
                        }
                        &.etc{
                            counter-reset: circle 0;
                            li{
                                &::before{counter-increment: circle 1; content: counter(circle); width: 12px; height: 12px; border: 1px solid #191919; border-radius: 50%; line-height: 12px; font-size: 10px; top: 3px; text-align: center;}
                            }
                        }
                    }
                }
            }
            p{@include normalTxt01;}
            .call-area{
                text-align: center;
                p{@include normalTxt; font-size: 20px; margin-bottom: 10px;}
                .sm-txt {font-size: 16px; line-height: 1.38; letter-spacing: -0.32px; color: #191919; font-weight:normal}
                .btns {margin-top:10px; line-height:1.2; font-size:20px;}
            }
        }
    }
}

.terms  {
    .page-tit {
        h1 {color: #191919; font-size: 20px; font-weight: 600; line-height: normal;  letter-spacing: -.4px; margin-bottom: 10px;}
    }
    .age_view {
        .age_table {
            caption {display: none !important;overflow: hidden;padding: 0 !important;margin: 0 !important;width: 1px;height: 1px;line-height: 1px;font-size: 1px;border: 0;clip: rect(0 0 0 0);}
            th {background-color: #eff1f5;padding: 20px 0;text-align: center;color: #191919;font-size: 16px;font-weight: 600;line-height: normal;letter-spacing: -.3px;vertical-align: middle;}
            td {
                padding: 20px 0;border-bottom: 1px solid #ddd;vertical-align: top;text-align: center;font-size: 16px;font-weight: 400;line-height: normal; letter-spacing: -.3px;  color: #666;
                p {line-height: 1.4;}
            }
        }
        p {margin-top: 10px;color: #666;font-size: 16px;font-weight: 400;line-height: 1.5;letter-spacing: -.3px;}
    }
}

