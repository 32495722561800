@charset "UTF-8";
// extend
%imgSize {
    display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 100%; height: 100%; object-fit: contain;
}
%fontInherit {
    font-size:inherit;font-weight:inherit;letter-spacing:inherit;line-height:inherit;color:inherit;
}
.phone{
    //휴대폰 목록
    .phone-intro{
        .slide-box{
            height: 400px; overflow: hidden;
            > div{
                > div{
                    display: none;
                }
                .slick-list{
                    display: block; height: 360px !important;
                }
            }
        }
        .box-filter-wrap{
            margin-top: 70px; width: 100%; position: relative;
            .box-filter{
                overflow: hidden;
                .btn-extend{
                    float: left; position: relative;
                }
                .accordian-search{
                    float:right; position: relative;
                    .ico-arrow-bottom{
                        margin-left: 10px; transform: rotate(0); transition: all 0.3s; margin-top: 3px;
                    }
                    &.on{
                        background: $bg; height:80px; border-radius: 7px 7px 0 0; border-bottom:none;
                         .ico-arrow-bottom{
                                margin-left: 10px; transform: rotate(180deg);
                        }
                    }
                }
            }
        }
        .border-gy{
            border:1px solid $gray02; border-radius: 7px; display: flex; padding:20px 0;  width:49%;
            @include normalTxt; justify-content: center;
        }
       .accordian-search-result{
            width: 100%; border:1px solid $gray02; border-radius:0 0 7px 7px; background: $bg; padding: 40px; margin-top:-1px; display: none;
            &.on{
                display: block;
            }
            .bulkhead{
                display: flex;
                li{
                    flex:1;
                    .main-txt{
                        margin-bottom: 20px;
                    }
                    .box-btn{
                        display: flex; gap:30px;
                        .btn-radio-type2{
                            width:auto;
                        }
                    }
                    .info-txt{
                        margin-top: 10px;
                    }                  
                }  
              &.intro {
                li:first-child {margin-right:50px;}
                & .btn-check label .ico-check {padding-left:34px;}                
                &.teens {
                    li:first-child {width:334px;flex:none;}
                    li:last-child {width:220px;flex:none;}
                }
              }
              
            }
       }
       .intro-list-wrap{
            margin-top: 70px;
            .link_group{
                display: flex; gap:20px; width:100%; justify-content: flex-end; margin-bottom: 20px;
                li{
                    transition: all 0.3s;
                    a{ @include defaultTxt;}
                    &.on{
                        a{ @include normalTxt;}
                    }
                }
            }
            .card-group{
                display: grid; gap:30px;grid-template-columns: 1fr 1fr 1fr;
                li{
                    width:406px; height: 100%;
                    a{
                        position: relative; border:1px solid $gray02; background: $white; border-radius: 7px; padding:40px;  text-align: center; display: block;height: 100%;
                        &:hover{
                            border:1px solid $purple;
                        }
                        .chip-wrap{
                            justify-content: center; height:20px;
                        }
                        .phone-img{
                            width:326px; margin-top: 30px; height: 326px;
                            & > img{
                                height: 100%; object-fit: contain;
                            }
                        }
                        .phone-cont{
                            .chip{
                                display: inline-block; width:31px; vertical-align: middle; margin-right: 10px;
                            }
                            &-top{
                                margin-top: 20px;
                                .main-txt{
                                    margin-bottom: 20px; min-height: 48px;
                                }
                                & > p{
                                    @include defaultTxt;
                                    & + p{
                                        margin-top: 10px;
                                    }
                                }
                            }
                            &-bottom{
                                border-top:1px solid $gray02; margin-top: 40px; padding-top: 40px;
                                & > .txt-vt{
                                    margin-left: 10px; font-size: 24px;
                                }
                                // &:has(.wid) {
                                //     padding-bottom: 78px;
                                //     .wid{
                                //         position: absolute;max-width: 324px;bottom: 40px;left: 50%;transform: translateX(-50%);
                                //     }
                                // }
                            }
                        }
                    }


                }
            }
       }
       .phone-cont-bottom{
            .normal-txt{
                .txt-vt{
                    position:relative;
                    font-size:24px; margin-left: 10px;
                }
                .dc-prc {position:absolute;right:0;top:-18px;min-width:300px;text-align:right;color:#666;font-size:16px;text-decoration: line-through;letter-spacing: -0.32px;}
           }            

       }
    }
    //단말별 지원금
    .support-device-list{
        .phone-cont-top{
            .main-txt{
                margin-bottom: 10px !important;
            }
        }
        .phone-cont-bottom{
            padding-bottom: 78px;
            dl{
                display: flex; flex-wrap: wrap;
                dt{
                    width:210px; text-align: left; margin-bottom: 10px; @include defaultTxt;
                }
                dd{
                    width:calc(100% - 210px); text-align: right;margin-bottom: 10px; @include normalTxt;
                }
            }
            // & > .wid{
            //     margin-top: 20px;
            // }
            .wid{
                position: absolute;max-width: 324px;bottom: 40px;left: 50%;transform: translateX(-50%);
            }
        }
        .notification-wrap{
            margin-top: 80px;
        }
        .banner{
            margin-top: 70px; background: #f4f6ff;
            .btns{
                background: #7385d8;
            }
        }
        .intro-list-wrap{
            .card-group{
                min-height:813px;height: 100%;
                li{
                    height: 100%;
                    a{
                        height: 100%;
                    }
                }
            }
        }
    }
    //휴대폰 상세
    .product-info{
        padding-bottom: 0;
        section {
            & + section{margin-top: 80px;}
        }
        .detail-title{
            display: block; padding-bottom: 10px; margin-bottom: 40px; border-bottom: 1px solid $black;
            @include mainTxt;
            .box-btn{
                display: inline-block; margin-left: 40px;
            }
        }
        .phone-type-form{
            display: flex; flex-wrap: wrap;
            dt{
                width: 130px;font-size: 20px; color: $gray05; letter-spacing: -0.4px; font-weight: 400; margin-top: 50px;
                &:first-of-type{margin-top: 0;}
                .tooltip-wrap{
                    .box-tooltip {
                        .txt-tooltip {
                            top:22px; left:25px; min-width:510px; padding:40px; border-radius: 10px; border: 0;  box-shadow: 1px 3px 30px 1px rgba(0, 0, 0, 0.16);
                            .info-wrap {
                                 strong {display:block; margin-bottom:10px;  font-size: 16px; font-weight: 600; line-height: 1.5;letter-spacing: -0.32px;text-align: left; color: #191919;}
                                 p {
                                    font-size: 16px; line-height: 1.5; letter-spacing: -0.32px; text-align: left; color: #666;
                                    & + p {margin-top:30px;}
                                }
                            }
                        }
                    }
                }
            }
            dd{
                width: calc(100% - 130px); margin-top: 50px;
                &:first-of-type{margin-top: 0;}
                &.box-pick-color{
                    display: flex; align-items: center; justify-content: space-between;
                    .box-color-name{
                        strong{
                            @include normalTxt;
                        }
                        .mark{
                            top: -40px; right: 0; min-width: 110px; white-space: nowrap; left: unset; transform: translateX(0); padding: 5px 4px 5px 12px;
                            &::after{
                                content: ''; display: inline-block;  width: 20px; height: 20px; vertical-align: middle;
                                @include backgroundImg('/assets/images/pc/icon/ico-arrow-white-sm.svg', center,contain);
                            }
                        }
                    }
                }
                .radio-list{
                    & + .box-btn{
                        margin-top: 20px;
                    }
                }
                &.box-gift{
                    display: flex; gap: 10px;
                    .box-btn{
                        .speech-bubble{
                            width: 338px; height: 40px; text-align: center; margin-bottom: 10px;
                            @include smallDepthTxt2; color: $white; line-height: 40px;
                            @include backgroundImg('/assets/images/pc/subpage/speech-bubble.png', center,contain);
                        }
                        .btn-text-right{
                            position: relative;
                            &::after{
                                content: ''; display: block; position: absolute; bottom: -2px; left: 0; width: calc(100% - 25px); height: 1px; background-color: $black;
                            }
                        }
                    }
                }
                &.call-consult{
                    display: flex; gap: 10px; align-items: center;
                    .box-btn{
                        .s-txt {margin-bottom:5px; font-size: 14px; line-height: 1.14;letter-spacing: -0.28px;text-align: left;color: #191919;}
                        .speech-bubble{                            
                            width: 314px; height: 40px; text-align: center; margin-bottom: 12px;
                            @include smallDepthTxt2; color: $white; line-height: 40px;
                            @include backgroundImg('/assets/images/pc/subpage/speech-bubble02.png', 0 50%,contain);
                        }
                        .btn-text-right{
                            position: relative;
                            &::after{
                                content: ''; display: block; position: absolute; bottom: -2px; left: 0; width: calc(100% - 25px); height: 1px; background-color: $black;
                            }
                        }
                    }
                }
                .txt-box {
                    height: 70px; padding: 25px 0 26px 40px; border-radius: 10px;  border: solid 1px #ddd; background-color: #fff;
                    p {
                        font-size:16px; font-weight: 600; letter-spacing: -0.32px;text-align: left; color: #191919;
                        strong {color:#662d91; font-size:16px; font-weight: 600;}
                    }
                }
                .txt-guide {
                    margin:10px 0 22px 0;font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;
                }
            }
            .ucash-tit {
                display:inline-block; margin-bottom:8px; font-size: 20px; letter-spacing: -0.4px; color: #666;
            }
        }
        .box-phone-info{
            display: flex; gap: 70px; justify-content: space-between;
            .phone-thumb{
                width:600px;
                .big-thumb-wrap{
                    width: 480px; height: 480px; margin: 60px; overflow: hidden;
                    .slick-list{
                        height: 480px !important; overflow: hidden;
                        .slick-slide{
                            width:480px; height: 480px !important;
                        }
                    }
                    .big-thumb-slide,.slick-list,.slick-slide{
                        width: 100%; height: 100%;
                        img{
                            width: 100%; height: 100%; object-fit: contain;
                        }
                    }
                    &+div{
                        height: 90px; overflow: hidden;
                        > div{display: none;}
                    }
                }
                .thumb-slide{
                    width: 520px; margin: 0 auto;
                    .slick-list{
                        display: block; margin: 0 40px;
                    }
                    .slick-slide{
                        width: 90px; height: 90px; padding: 18px; border: 1px solid $gray02; border-radius: 10px; overflow: hidden; margin: 0 10px; cursor: pointer;
                        &.slick-current{
                            border-color: $black;
                        }
                        img{width: 100%; height: 100%; object-fit: cover;}
                    }
                    .slick-arrow{
                        width: 30px; height: 30px; text-indent: -9999px; font-size: 0;
                        @include backgroundImg('/assets/images/pc/icon/ico-slide.svg',center,contain);
                        &.disabled{
                            background-image: url('/assets/images/pc/icon/ico-slide-disabled.svg');
                            cursor: default;
                        }
                        &.slick-prev{
                            transform-origin: center center;
                            transform: translateY(-50%) rotate(180deg);
                            &.disabled{
                                transform: translateY(-50%) rotate(0deg);
                            }
                        }
                        &.slick-next {
                            &.disabled{
                                transform: translateY(-50%) rotate(180deg);
                            }
                        }
                    }
                }
            }
            .phone-detail{
                width:610px;
                .detail-top{
                    .box-name{
                        display: flex; justify-content: space-between; gap:30px;
                        .product-name{
                            display: flex; gap: 10px;
                            & > span{
                                @include mainContsTitle;
                            }
                            .chip-wrap {
                                height: 20px; flex-shrink: 0;
                                img{height: 100%;}
                            }
                        }
                        .btn-share{
                            flex-shrink: 0;
                        }
                    }
                    .box-detail{
                        display: flex; margin-top: 10px;
                        li{
                            position: relative;
                            & + li{
                                margin-left: 10px; padding-left: 10px;
                                &::before{
                                    content: ''; display: block; position: absolute; top: 50%; left: 0; width: 1px; height: 14px; background-color:$gray02;transform: translateY(-50%);
                                }
                            }
                        }
                    }
                }
                .detail-bottom{
                    margin-top: 50px;

                    .banner-slide{
                        width: 610px; height: 200px; margin-top: 50px;
                        >div{
                            display: none;
                        }
                        .slick-list{
                            display: block;
                        }
                        .slick-slide{
                            img{width: 100%;}
                        }
                    }
                }
            }
        }

        .box-phone-rate{
            .box-tab.plan-type {
                margin:0; padding:10px 0 0 0;
                .tab-btn-wrap {
                    margin-bottom:20px;
                    li a {height:58px; padding:17px 40px;}
                }
            }
            .card-rate-wrap{
                display: flex; gap: 30px;
                &.col02 {
                    .cardList-wrap {width:50%;max-width: 100%;}
                }
            }
            .payment-imgCard-btn{
                position: relative; flex: 1; max-width: 406px; margin: 0;
                .cardList{
                    border:none; border-radius: 0; padding: 0;
                    & > input[type=radio] {
                         @include hidden;
                        &:checked + label {border-color: $purple;}
                    }
                    & > label{
                         border:1px solid $gray02; padding: 40px; padding-top: 80px; display: block; background:$white; border-radius: 7px; cursor: pointer; transition: all 0.3s;
                         .sb-txt {display:block;margin-top:10px; text-align: right; font-size: 14px; line-height: 1.14;letter-spacing: -0.28px; color: #959595;}
                    }
                }
                .title{
                    @include mainTxt;
                }
                .chip-wrap{
                    position: absolute; top: 40px; left: 40px; margin: 0;
                }
                p.txt-vt{
                    @include mainTxt; margin-top: 30px;
                    text-align: right;
                    span{@extend %fontInherit;}
                }
            }
            .phone-type-form{
                margin-top: 50px;
                .radio-group-wrap{
                    .btn-radio-group{
                        gap: 30px;
                        .btn-radio{
                            flex: 1;
                            label{
                                width: 100%; justify-content: space-between;
                                strong{
                                    @include normalTxt;
                                    span{@include normalTxt; font-weight: 400;}
                                }
                                .txt-vt{
                                    @include normalTxt;
                                    span{@extend %fontInherit;}
                                }
                            }
                        }
                    }
                }
                .card-area{
                    @include backgroundImg('/assets/images/pc/icon/ico-credit-card.svg',left center,20px 20px );
                    padding-left: 30px;
                    * {vertical-align: middle;}
                    strong{
                        @include normalTxt;
                        &.card-discount{
                            display: inline-block; margin-left: 20px;
                        }
                        span{
                            @extend %fontInherit;
                        }
                        & + span{
                            @include defaultTxt; display: inline-block; margin-left: 5px;
                        }
                    }
                }
            }
        }
        .banner-event{
            margin-top: 70px;
            img{width: 100%;}
        }
        .box-tab{
            margin-top: 70px; height: 100%; overflow:visible !important;
            .line-tab{
                position:sticky; top:80px; min-height: 62px; margin-bottom:70px; z-index: 2;
                li{
                    a{
                        background: $white;
                    }
                }
            }
            .conts-wrap {
                min-height:800px; margin-top: 70px;
                .unit-tit-page{margin:0 0 50px;@include mainContsTitle;}
                .box-table {
                    table {
                        border-top:1px solid #ddd;
                        th {border-bottom:1px solid #ddd;}
                        td {padding-left:30px; text-align: left; color:#666;font-size:16px;}
                    }
                }
            }
        }
        .phone-detail-info {
            img{display: block;width: 100%;}
        }
        .phone-review {
            img{display: block;width: 100%;}
        }
        .phone-regist-info{
            .banner {
                .right {
                    .img{
                        z-index: 1;
                    }
                    .btns{
                        display: block; line-height: 58px; min-width: 135px;
                    }
                }
            }
            .step-wrap{
                & + .step-wrap{margin-top: 50px;}
                .main-txt{
                    display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;
                }
                & > p{
                    @include smallDepthTxt; margin-top: 10px;
                }
            }
            .noti-wrap{
                margin-top: 70px;
                .unit-tit-b{
                    margin:0;
                    & + .notification{
                        margin-top: 10px;
                        .box-btn{
                            display: inline-block; margin-left: 20px;
                            .btn-text-right{
                                font-size: 14px;
                            }
                        }
                    }
                    & + p{margin-top: 10px;}
                }
                .noti-process-wrap{
                    margin-top: 50px;
                    .main-txt{
                        display: block; margin-bottom: 10px;
                        & + p{
                            @include defaultTxt; margin-bottom: 10px;
                        }
                        & ~ b{
                            @include normalTxt01;
                        }
                    }
                    .num-process-wrap{
                        .txt-wrap{
                            strong{margin-bottom: 10px;}
                        }
                    }
                    .box-check-list{
                        margin-top: 10px;
                    }
                }
                .box-as{
                    display: flex; gap: 30px; margin: 10px 0;
                    & + p{margin-top: 10px;}
                    .icon-txt-card{
                        flex: 1; gap: 40px; padding: 40px !important;
                        img{
                            width: 80px; height: 80px;
                        }
                        .txt-wrap{
                            strong{
                                display: block; margin-bottom: 10px;
                                @include mainTxt;
                            }
                            span{
                                @include defaultTxt;
                            }
                            .lng-txt {display:block;width:120px;}
                        }
                    }
                }
            }
        }
        .bottom-layer-pop{
            position: fixed; left: 0; bottom: 0; z-index: 101; width: 100%; background-color: $white; border-top: 1px solid $purple;
            .bottom-container{
                max-width: 1280px; margin: 0 auto;
            }
            &.on{
                .bottom-pop-btn{
                    .ico-pop-btn {
                        transform: translateX(-50%) rotate(0deg);
                    }
                }
            }
            .bottom-pop-btn{
                position: absolute; top: -20px; left: 50%; transform: translateX(-50%); width: 130px; height: 20px; background-color: $white; border-top: 1px solid $purple; border-left: 1px solid $purple;border-right: 1px solid $purple; border-radius: 20px 20px 0 0;

                .ico-pop-btn{
                    display: block; position: absolute;top: 50%;left: 50%; width: 20px; height: 20px; font-size: 0; text-indent: -9999px; transform: translateX(-50%) rotate(180deg); transition: transform 0.3s;
                    @include backgroundImg('/assets/images/pc/icon/ico-arrow-purple-type2.svg',center, contain);
                }
            }
            .show-conts{
                display: flex; justify-content: space-between; align-items: center;  padding: 30px 0 40px;
                .phone-conts{
                    display: flex;width: 40%; gap: 40px; align-items: center;
                    .thumb{
                        width: 58px; height: 58px;
                        img{width: 100%; height: 100%; object-fit: contain;}
                    }
                    strong{display: block; @include mainTxt;}
                }
                .price-conts{
                    display: flex; justify-content: space-between; align-items: center; width: 60%;
                    .price{
                        @include mainTxt;
                         .dc-prc {display:inline-block;margin-left:35px;font-size: 20px;line-height: 1.2; letter-spacing: -0.4px;text-align: right;color: #666;text-decoration: line-through;}
                         .dc-prc + .txt-vt {margin-left:5px;}
                        .txt-vt{
                            display: inline-block; margin-left: 10px;
                            @include mainContsTitle;
                            span{@extend %fontInherit;}
                        }                        
                    }
                    &.sello-prc {
                        width:auto;
                    }
                    .box-btn{
                        position: relative;
                        .banner-randombox{
                           left: -7px; bottom: 65px;
                        }   
                    }
                }
                .btn-conts {
                    position: relative; display: flex; justify-content: flex-end; gap: 10px; align-items: center;
                    .btn-sello {
                        width:350px; padding:19px 20px 20px 30px; border: solid 1px #662d91;background: #fff; 
                        span {
                            display: block; position:relative;font-size: 16px;font-weight: 600;letter-spacing: -0.32px;text-align: left;color: #662d91;
                            &::after {content: '';position:absolute; right:0;top:0;width:20px; height:20px; background:url('/assets/images/pc/icon/icon-arrow-right-purple.svg') no-repeat 50%;}
                        }
                        &.on {
                            width:auto;min-width:350px; border-color:#191919;
                            span {
                                padding:0 0 0 40px; color:#191919;
                                &::after {content: ''; display:none;}
                                &::before {content: '';position:absolute; left:0;top:0;width:20px; height:20px; background:url('/assets/images/pc/icon/icon-sello-check.png') no-repeat 50%; }
                            }
                        }
                    }

                    .ripper-area {
                        position:relative;
                        .sello-bubble{
                            position:absolute;left:50%;top:-40px;min-width: 200px; transform: translateX(-50%);  padding: 8px 20px;text-align: center;border-radius: 8px;background: #eb068b;font-size: 16px;
                            font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;  box-sizing: border-box; color:#fff; animation: MoveUpDown 3s linear infinite;
                            &::before {content: ''; position: absolute; left:50%; bottom: 0;transform: translateX(-13%); height: 0px;border: 8px solid transparent;border-top-color: #eb068b;border-bottom: 0;margin-left: -6px;margin-bottom: -6px;}
                        }
                        @keyframes MoveUpDown {
                            0%, 100% {
                               top:-25px;
                            }
                           50% {
                                top:-20px;
                            }
                        }                 
                    }
                }
            }
            .table-conts{
                display: none; padding-bottom: 40px;
                .table-wrap{
                    display: flex; justify-content: space-between; gap: 20px;
                    .box-table{
                        flex: 1;
                        .table-type-01{
                            border-top: 1px solid $gray02; border-bottom: 1px solid $gray02;
                            thead {
                                tr{
                                    th,td {
                                        padding: 40px 40px 5px;
                                        @include normalTxt;
                                        span{@extend %fontInherit;}
                                    }
                                    th {
                                        vertical-align: bottom;
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    th,td {
                                        padding: 5px 40px;
                                        @include defaultTxt;
                                        span{@extend %fontInherit;}
                                    }
                                    &:last-of-type {
                                        th,td {
                                            padding: 5px 40px 40px;
                                        }
                                    }
                                }
                            }
                            .minus{
                                @include defaultTxt; color: $purple;
                            }
                            .tooltip-wrap{
                                @include defaultTxt;
                                .box-tooltip{
                                    .txt-tooltip{
                                        top: auto;bottom: 27px;left: 0px; min-width: 404px;
                                        line-height: 1.5;
                                    }
                                }
                            }
                           .dc-prc {display:block;font-size: 14px;text-decoration: line-through;line-height: 1.14;letter-spacing: -0.28px;text-align: right;color: #666;font-weight:normal;}
                        }
                        .table-desc{
                            @include defaultTxt;
                            padding-right: 40px; text-align: right; margin-top: 10px;
                        }
                    }
                }
            }
        }
        .box-tab02 {
            overflow: visible; margin-top:110px;
            .tab-btn-wrap.tab-wid {
                gap:0;
                li a {padding:23px 0;border-radius: 10px 0 0 0; font-size: 20px;font-weight: 600; line-height: 1.2;letter-spacing: -0.4px;text-align: center;color: #666;}
                li + li a {border-radius: 0 10px 0 0;}
                li a.on {color:#fff;}
            }
        }
        .box-tab02.tab-ctrl .tab-contents-wrap {
            .box-phone-rate .payment-imgCard-btn  {
                label {  min-height:255px;}
            }
        }

    }
    //지원금 반환금 및 정산금 안내
    .refund-guide{
        .unit-tit-m{
            margin:50px 0 30px;
        }
       .unit-tit-s{
            margin: 30px 0 20px;
       }
    }
    //입고 알림 신청하기
    .product-receipt{
        .rq-form{
            border-bottom:0; margin-bottom: 0; padding-bottom: 0;
            & > .box-input{
                margin-top: 0;
            }
        }
        .cardList{
            dl{
                dd{
                    width:calc(100% - 230px);
                }
            }
        }
        .notification-wrap{
            margin-top: 50px;
            h3{
                margin-bottom: 10px;
            }
            .notification{
                li{
                    display: block;
                }
            }
        }
        .btn-check-wrap {
            margin-top: 16px; margin-top: 40px;
            .normal-txt{
                margin-bottom: 16px;
            }
        }
    }
    //무이자 할부 카드 안내
    .interest-free{
       .gb-gy{
            border-radius: 10px; background: $bg; padding:40px; margin-bottom: 10px;
            li{
                @include normalTxt01;
                & + li{
                    margin-top: 10px;
                }
            }
       }
    }
    //사은품 안내
    .phone-gifts{
        .fix-unit-group{
            .unit-tit-s{
                margin: 30px 0 10px;
                span{
                    @include normalTxt;
                }
            }
            .gift-list{
                display: flex; flex-wrap: wrap; gap:20px; margin-top: 10px;
                li{
                    border:1px solid $gray02; border-radius: 10px; overflow: hidden; padding: 25px; text-align: center;
                    .item-img{
                        width:250px;
                    }
                    .item-txt{
                        @include defaultTxt; margin-top: 20px; font-weight: 600;
                    }
                }
            }
            & + .fix-unit-group{
                margin-top: 30px;
            }
        }
        .notification-wrap{
            margin-top: 50px;
            h3{
                margin-bottom: 10px;
            }
        }
    }
    //휴대폰 추천
    .phone-recommend-result{
        .payment-list-card-wrap{
            margin-top: 50px;
            & > .btn-text-right{margin-bottom: 10px;}
        }
        .payment-list-card{
            display: flex; gap:40px;
            .left{
                width:100px;
            }
            .right{
                width:100%;
                .sub-title{
                    display: flex; align-items: flex-start; gap:10px;
                    h3{
                        @include mainTxt; width:calc(100% - 155px);
                    }
                    img{
                        display: block;
                    }
                    .chip-wrap{
                        margin-top: 2px; flex-wrap: nowrap;
                    }
                }
                dl{
                    display: flex; justify-content: space-between; margin-top: 18px;
                    dt{
                        p{
                            @include defaultTxt;
                            & + p{
                                margin-top: 10px;
                            }
                        }
                    }
                    dd{
                        text-align: right;
                        p{
                            @include smallDepthTxt;
                            &.txt-vt{
                                @include mainTxt; color:$purple;
                            }
                            & + p{
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
        .box-paging{
            margin-top: 0;
        }
    }
    //휴대폰 추천 내용 없을 시
    .phone-recommend-no-result{
        .no-result-txt{
            margin-bottom: 150px;
            @include backgroundImg('/assets/images/pc/icon/ico-search-dot.svg',center center,120px 120px);
        }
    }
    //요금제 선택
    .rate-plan-choose {
        .month-data {
            li{
                gap:30px; display: flex;
                &  + li{
                    margin-top: 50px;
                }
              &> h2{ @include mainTxt; width:calc(100% - 524px);}
                .range-slider{
                    width:524px;
                }
            }
        }
        .plan-list {
            margin-top: 50px;
            &.not-data {margin-top:0;}
            .select-link{
                display: flex; gap:20px; justify-content: flex-end; margin-bottom: 20px;
                li{
                    a{
                        @include defaultTxt;
                    }
                    &.on{
                        a{
                            @include normalTxt;
                        }

                    }
                }
            }
            .box-select {
                margin-bottom:30.0002px;
            }
        }
        .info-detail {
            .cardList-wrap{
                .cardList{
                    .chip-wrap{
                        margin-bottom: 20px;
                    }
                    dl{
                        position: relative;
                        dt{
                            width:100%;
                            .feature{
                                margin-top: 10px;
                            }
                            .notification{
                                margin-top: 20px;
                            }
                        }
                        dd{
                            position: absolute; top:0; right: 0;
                            .pay-amount{
                                padding-top: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .range-slider {
        .irs--flat {height:65px !important; }
        .irs--flat .irs-min,
        .irs--flat .irs-max,
        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single {display:none;}
        .irs--flat .irs-from,
        .irs--flat .irs-to,
        .irs--flat .irs-single,
        .irs--flat .irs-bar {background:$gray02;}
        .irs--flat .irs-bar {top:20px;height:0px;}
        .irs--flat .irs-handle {
            top:10px;width:24px;height:24px;
            @include backgroundImg('/assets/images/pc/icon/ico-range-slider.svg', center, contain);

        }
        .irs--flat .irs-line{
            background: none; height: 45px; top:0; z-index: 1; position: relative;
             &::after{
                position:absolute; top:20px; width:100%; height: 4px; background:$gray02; content: '';
            }
        }
        .irs--flat .irs-handle>i {background:none !important;}
        .irs-grid{
            &-pol {display: none;}
            &-text{
                color: $black !important; @include normalTxt;
            }
        }
        .slider-dots{
            position: absolute;top: 17px; width: 10px; height: 10px; background-color: $gray02; border-radius: 50%;
            &.on{background-color: $purple;}
        }
    }
    //휴대폰 추천
    .phone-recommend{
        .step-process-wrap{
            display: flex; width:100%; align-items: center; justify-content: space-between;
            .step-process-wrap{
                display: flex; width:630px;

            }
            .step-process{
                 margin-left: 0; background-color: $white;
                    ol{
                    border-radius: 30px; overflow:hidden;
                    li{
                        height: 4px;  background-color: $gray02;
                        &.complete{
                            background: $black;
                            &::after{
                                background:$black; width: 4px; height: 4px;
                            }
                        }
                    }
                }
            }
            .step-count{
                span{
                    @include defaultTxt; color:$gray04;
                    &.now{
                        @include normalTxt;
                    }
                }

            }
        }
        .rq-form{
           border-bottom: 0; margin-top: 50px;
            .radio-group-wrap{
                margin-top: 30px;
                .btn-radio{
                    label{
                        width:310px; justify-content: flex-start;  position: relative; padding-left: 70px;
                        &::before{
                            width:20px; height: 20px; content: ''; position: absolute; top:50%; transform: translateY(-50%); left:40px; @include backgroundImg('/assets/images/pc/icon/ico-checkbox.svg', center center, 20px 20px); display: block;
                        }
                    }
                    input[type=radio] {
                        &:checked + label{
                            &::before{
                            width:20px; height: 20px; content: ''; position: absolute; top:50%; transform: translateY(-50%); left:40px; @include backgroundImg('/assets/images/pc/icon/ico-checkbox-on.svg', center center, 20px 20px); display: block;
                        }

                        }
                    }
                }
            }

            .btn-group{
                margin-top: 50px;
            }
        }
    }
}

.vreview-overlay-modal.open {z-index: 110 !important;}

.sello-compenstin {
    padding-bottom: 50px;
    .txt-box {
        .sect-tit {font-size: 20px;font-weight: 600;letter-spacing: -0.4px;text-align: center;color: #191919;}
        .sect-txt {margin-top:10px; font-size: 16px;letter-spacing: -0.32px;text-align: center;color: #666;}
    } 
    .benefit-banner {margin-top:30px;}
    .benefit-box {
        margin-top:30px; padding:40px 0;border-radius: 10px; background: #f9f5ff;text-align: center;
        img + .benefit-view {margin-top:20px;}
        .benefit-view {
            .view-tit {display:inline-block;margin-bottom:15px; padding: 6px 16px 5px 14px;border-radius: 100px;background-color: #662d91; font-size: 18px;font-weight: 600;letter-spacing: -0.36px;text-align: center;color: #fff;}
            p {font-size: 21px; font-weight: 600;letter-spacing: -0.42px;text-align: center;color: #191919;}
        }
    }
    .tit {margin-bottom:15px; font-size: 16px;font-weight: 600;letter-spacing: -0.32px;text-align: left;color: #191919;}
    .price-option {
        margin-top:40px;
        .option-list {
            display: flex;flex-wrap: wrap;gap: 20px;
             .nice-select {width:305px;height:58px;line-height: 56px;
                &:after {right:17px; width:9px;height:9px; margin-top: -9px; border-bottom: 3px solid #191919;border-right: 3px solid #191919;}
                .current {color:#191919;}
                &.disabled{
                     .current {color:#959595;}
                }
                .list {width:100%;}
             }
        }
        .not-result {
            margin-top:15px; padding: 40px 0; border-radius: 10px; border: solid 1px #ddd; background-color: #fff; text-align: center;
            p { font-size: 16px;font-weight: 600;line-height: 1.31;letter-spacing: -0.32px;text-align: center;color: #191919;}
            .btn-wrp {margin-top:30px;}
        }
    }
    .btn-wrp {
        margin-top:40px;text-align: center;
        .btns {
            width:232px;
            & + .btns {margin-left:8px;}
        }
    }
    .notice {
        margin-top:50px;padding:0 30px; border-top:1px solid #ddd;border-bottom:1px solid #ddd;
        .acc-trigger {
            margin:0; padding:20px 0; font-size: 16px;letter-spacing: -0.32px;text-align: left;color: #666;
            &.on {color:#191919;font-weight: 600;}
        }
        .acc-conts {
            overflow-y:auto; max-height:300px;padding-bottom:20px;
            .notification li ul {margin-left:-10px;}
           &::-webkit-scrollbar{width: 10px;}
           &::-webkit-scrollbar-thumb{height: 40%;background-color: rgba(221,221,221,1);border-radius: 10px;}
           &::-webkit-scrollbar-track{border-radius: 10px;background-color:rgba(221,221,221,0.4);}
        }
    }
    .price-view {
        margin-top:40px;
        .view-box {
            padding: 40px;border-radius: 10px;border: solid 1px #ddd;background-color: #fff;
            .box-tit {font-size: 20px;font-weight: 600;letter-spacing: -0.4px;text-align: center;color: #191919;}
            .table-top {
                display: flex;justify-content: space-between;align-items: center;margin-top:20px;
                p {font-size: 16px;font-weight: 600; letter-spacing: -0.32px;text-align: left;color: #191919;}
            }
            .table-type-04 {
                margin-top:15px;
                td {color:#666;}
            }
            .box-table {
                .notification {margin-top:10px;}
                .btn-wrp {margin-top:30px;}
            }
        }
    }
}

.layer-popup{
    .sello-table {
        td {color:#666;}
    }
}