@charset "UTF-8";

// grayscale
$white: #fff;
$gray01: #f6f6f6;
$gray02: #ddd;
$gray03: #eee;
$gray04: #959595;
$gray05: #666;
$gray06: #eff1f5;
$black: #191919;
$bg: #fafafa;

// color
$hotpink: #F03B79;
$red: #e34140;
$lightbrick: #bb4b4b;
$brick: #8f5757;
$purple: #662d91;
$lightpurple:#f9f5ff;
$pinkpurple: #d91e88;
$pink: #f87fc6;
$deeppink:#df3fba;
$lightorange: #fbaa75;
$yellow: #f6a518;
$orange: #FF8400;
$blue: #267DD4;
$skyblue: #38b1e5;
$deepblue: #347bb9;
$paleblue: #64abc1;
$purpleblue: #5257a1;
$green: #5eaf01;
$lightgreen: #b1b934;
$deepgreen: #139b38;
$yellowgreen: #70bf3c;
$mint: #2cb9c9;
$silver: #63677c;
$gold: #987643;
$jade: #5b9e85;