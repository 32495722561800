@charset "UTF-8";
// extend
%usageTitle {display:block;@include subContsTitle;}

%fontInherit {color:inherit;font-size:inherit;font-weight:inherit;letter-spacing:inherit;line-height:inherit;}

.event{
    // 기본 템플릿 용
    .evt-section{
        display: flex; justify-content: center;
        .evt-conts{
            position: relative; width: 800px;
            .conts760{
                width: 760px; margin: 0 auto;
            }
        }
    }
    .unit-wide{
        > div{
            img{
                display: block; margin: 0 auto;
            }
        }
    }
    /* 필터 */
    //이벤트 프로모션
    .event-promotion {
        img {display:block;}
        .intro,
        .sec-common {width:100%;margin:0 auto;position: relative;}
        .intro {
            .txt {
                position: absolute; top:90px; left:50%; transform: translateX(-50%); @include mainVisualTxt; color:$white; width:100%;
                .title,
                em,
                .point {@extend %fontInherit; text-align: center;}
                .title {
                    .point {color:#EBFF00;}
                }
                .tit {
                    line-height: 1.28;
                    .txt-vt {color:#842cfd !important}
                }
                
                .desc {
                    @include mainTxt; color:$white; text-align: center; font-weight: 400; margin-top: 60px;
                    strong {@extend %fontInherit;  font-weight: 600 !important;}
                }
            }
            .btn-position{
                position:absolute; top:313px; left:50%; transform:translateX(-50%); width:310px; height:70px; display:block; font-size:0;
            }
            .notice-box {
               padding:20px 0 100px 0; background: #fcf2ff;
                .notice-msg {
                    width:1010px;margin:20px auto 0; padding: 60px 0;  border-radius: 30px; background: #fff; text-align: center;
                    .msg-txt {
                        font-size:36px;color:#191919; font-weight:700;
                        strong {color:#842cfd; font-size:36px; font-weight:700; }
                    }
                    .text {margin-top: 30px; font-size: 20px; text-align: center; color: #191919;}
                    .btn-link {display:inline-block; width:260px; height:58px; line-height:58px; margin-top:30px; border-radius: 7px; text-align: center; color:#fff;font-size:16px; font-weight:600; background:#191919;}
                }
            }
        }
        .sec-common {
            display:flex;flex-wrap:wrap;justify-content:space-between;align-items:center;width:100%;min-height:520px;padding:80px 160px;box-sizing:border-box;
            .txt {
                width:calc(100% - 400px);
                h3 {@include mainContsTitle;}
                p {margin-top:40px;@include mainTxt;}
            }
            .txt.pstin-typ {
                position:relative;
                .s-txt {position: absolute; left:0;bottom:-30px; min-width:615px; margin-top:0; font-weight:normal; font-size:16px;line-height: 1.19;color: #666;}
            }
            .img {display:block;align-self:flex-start;width:400px;height:100%;}
            .box-btn {
                margin-top:40px;
                & + .box-btn {margin-top:10px;}
            }
        }
        .sticky {
            position:sticky; bottom:0px;
            .btns {
                border-radius: 0; height: 100px; @include mainTxt; color:$white;
                &.btn-pnk {background:#ec008c;}
            }
            &.btn-col02 {gap:0;}
        }
    }
    //유알모 안내
    .friend-usim-ualmo-info{
        .intro {
            .txt {
                text-align:center;
                .tit {@include mainVisualTxt;font-weight:bold;}
                .desc {
                    margin-top:40px;font-weight:400;line-height:normal;letter-spacing:-.3px;color:#000;font-size:20px;
                    strong {display:block;margin-bottom:10px;@include mainTxt;color:#000 !important;font-weight:normal;}
                }
            }

        }
        .sec-common{
            &:nth-child(odd){
                background-color: #F9F8F9;flex-direction: row-reverse;
            }
            .btns {background:$black;}
        }
    }
    .friend-usim-ualmo-info02{
        .intro{
            .btn-position{position:absolute; top:302px; left:50%; transform:translateX(-50%); width:350px; height:70px; display:block; font-size:0;}
        }
        .tit{
            &+.sec-common{padding-top: 0;}
        }
        .sec-common{
            &:nth-child(even){
                background-color: #F9F8F9;flex-direction: row-reverse;
            }
            .btns {background:$black;}
        }
    }
    //친구 추천 안내
    .friend {
        .sec-common {width:100%; margin:0 auto; position: relative;}
        .btn-position{position:absolute; bottom:0; left:50%; transform: translateX(-50%); width:400px; height: 80px; display: block; background: none; border-radius: 50px; display: block; font-size:0;}
        .intro {
            .btn-position {bottom:190px;}
        }
        .sec04 {
            .btn-position {bottom:90px;}
        }
        .sec06 {
            .btn-position {bottom:146px;}
        }
        img {display: block;}
        .btn-group {gap:0;}
        .sticky {
            position:sticky; bottom:0;
            .btns{
                border-radius: 0; height: 100px; @include mainTxt; color:$white;
                img {margin:0 auto;}
                &.lightgray {
                    color:$black;
                }
            }
        }
        .notification{
            p{
                margin-left:5px;
            }
        }
        .fix-unit-group{
            margin-top: 70px;
        }
    }
    //포인트
    .ualmo-point {
        .box-tab02 {overflow: inherit !important;}
        .tab-btn-wrap {
            gap:0;
            // position:fixed;left:50%;bottom:0;z-index:1;width:1280px;margin-left:-640px;margin-bottom: 0 !important; gap:0;
            // &.fixed {position:absolute;margin-bottom:0px !important;}
        }
        .intro {
            position: relative;
            .btn-position {display:block;position:absolute;top:359px;left:50%;transform:translateX(-50%);width:318px;height:69px;margin-left:-12px;padding:10px 0;font-size: 0;}
        }
        .sec-common {
            border-bottom: 1px solid $gray02;
            .img {height:auto;}
            &:last-of-type {border-bottom:0;}
            .btns {background: $black;}
        }
        .point-payment {
            padding-top:119px;
            align-items:flex-start;
            .img {margin-top:-9px;}
            .accordian {
                align-self:flex-start;width:100%;margin-top:30px;
                &.type-01 {margin-top:0;}
                &>.acc-trigger {
                    width:auto;padding-right:30px; @include normalTxt;text-align:left;
                    &.on {
                        .acc-conts {display:block;}
                    }
                }
                .acc-conts {
                    background-color: $white; padding:0;
                    .notification-wrap {
                        margin:0;
                        .txt {width:100%;}
                    }
                }
            }
        }
    }
    /* [팝업] 사은품 안내 */
    .evt-freebies {
        .inner {padding:200px 40px 0px 40px;}
        .page-desc {margin-bottom:50px;}
        .freebies-tit {display:block;@include normalTxt;}
        .freebies-tit-txt {display:block;margin-top:10px;@include defaultTxt;}
        .freebies-list {
            display:flex;flex-wrap:wrap;gap:20px;margin:20px 0 40px;
            &>li {
                flex:1 1 40%;position:relative;max-width:305px;
                input[type=checkbox] {position:absolute;opacity:0;}
                label {
                    display:flex;justify-content:space-between;flex-direction:column;min-height:317px;padding:20px;border:1px solid #ddd;border-radius:20px;text-align:center;cursor:pointer;
                    .product-img {
                        width: 230px; height: 230px; margin:0 auto; overflow: hidden;
                        img {width: 100%; display: block; height:100%;}
                    }
                    .txt {@include defaultTxt;text-align:center;margin-top:6px}
                }
                input[type=checkbox]:checked {
                    &+label {
                        border:1px solid $black;
                        .txt {color:$black;font-weight:600;}
                    }
                }
                .freebies {
                    display:flex;justify-content:space-between;flex-direction:column;min-height:317px;padding:20px;border:1px solid #ddd;border-radius:20px;text-align:center;
                    .product-img {
                        width: 230px; height: 230px; margin:0 auto; overflow: hidden;
                        img {width: 100%; display: block; height:100%;}
                    }
                    .txt {@include defaultTxt;text-align:center;margin-top:6px}
                }
            }
        }
        .notification-wrap {margin-top:0;}
    }
    /* [팝업] 사전예약신청 */
    .evt-reservation {
        .page-desc {margin-bottom:20px;}
        .rq-form {
            padding-bottom:0;border-bottom:none;
            .btn-radio-group {gap:10px;}
            .box-flex {
                &+.box-flex {margin-top:20px; padding-top: 0;}
            }
            .agree-wrap {
                +.txt {margin-top:20px;}
            }
        }
        .phone-info {
            // margin-bottom:20px;
            strong {display:block;margin-bottom:10px;@include mainTxt;}
            p {display:block;@include defaultTxt;}
            &.underline {padding:0;padding-bottom:40px;margin-bottom:40px;border-bottom:1px solid $gray02;}
        }
        .info-txt{
            margin-top:40px;@include defaultTxt;
            .txt {
                &+.txt {margin-top:10px;}
            }
        }
        .notification{margin-top: 10px;}
        .txt-notice{@include defaultTxt; margin-top: 10px;}

        /*.appli-ct {
            margin-bottom:30px;
        }
        .phone-info {
            strong{ @include subContsTitle; margin-bottom: 10px; display: block;}
            &.underline{
                padding: 0; padding-bottom:40px; margin-bottom: 40px; border-bottom:2px solid $gray02;
            }
        }
        .section{margin-top: 80px;}
        .btn-check-wrap{
            margin-top: 40px;
            li{
                margin-bottom: 30px;
            }
        }
        .info-txt{
            @include smallTxt; margin-top: 80px;
        }*/
    }

    // [바텀시트]공유하기 링크
    .evt-share {
        .list-share {display:flex;gap:16px;}
    }
    //진행중인 이벤트 목록
    .evt-going-list {
        .is-filter-wrap {
            .is-filter {
                .is-filter-list {
                    li {
                        label {min-width:120px;}
                    }
                }
            }
        }
        .going-list-wrap {margin-bottom:70px;}
        .cardList-img{
            height: 250px;
            img {width:100%;height: 250px;}
        }
    }
    //진행중인 이벤트 상세 페이지 - 기본 템플릿
    .template {
        &.evt-default-template,
        &.evt-usim-template,
        &.evt-phone-template {position:relative;}/*마케팅메세지 고정 기준점*/

        .chip-wrap {
            .chip {border-radius:5px;}
        }
        .pre-reservation{
            width:100%;  display: flex;  gap:30px; align-items: center; justify-content: center;
            .btns{
                display: block; 
            }
        }
        &-tit {
            display:flex;justify-content:space-between;margin-bottom:20px;
            .title-wrap {
                display:flex;flex-direction:column;width:calc(100% - 40px);
                .tit {
                    width:calc(100% - 40px);@include mainTxt;
                }
                .period {margin-top:10px;@include defaultTxt;color:$gray04;}
            }
        }
        &-view {
            position:relative;margin-bottom:100px;border-top:1px solid $gray02;
            &.mb0{
                margin-bottom: 0 !important;
            }
            .evt-count {
                position:relative;text-align:center;
                &::before {content:'';display:block;position:absolute;left:0;top:0;width:100vw;height:100%;background:$black;opacity:0.9;z-index:0;}
                p {
                    display:block;position:relative;padding:20px;@include mainTxt;color:$white;font-weight:normal;text-align:center;z-index:1;
                    &>span {
                        padding:0 20px;@include mainTxt;color:$white;font-weight:normal;
                        &>em {display:inline-block;@include mainTxt;color:$white;font-weight:700;vertical-align:baseline;}
                        .dday {margin-right:10px;}
                    }
                }
            }
            .evt-banner {
                height:1000px;text-align:center;
                img {/*position:relative;left:50%;transform:translateX(-50%);*/width:800px;height:1000px;}
            }
            .evt-txt {
                display:block;position:absolute;left:50%;bottom:20px;overflow:hidden;text-overflow:ellipsis;width:1280px;padding:10px 20px;background:$black;white-space:nowrap;transform:translateX(-50%);
                @include defaultTxt;color:$white;text-align:center;
            }
            .marketing-msg {
                display:flex;justify-content:center;align-items:center;
                position:fixed;left:50%;bottom:10px;z-index:1;
                overflow:hidden;text-overflow:ellipsis;width:800px;height:50px;margin-left:-400px;padding:0 50px;box-sizing:border-box;border:2px solid $black;border-radius:25px;background:$white;@include mainTxt;white-space:nowrap;
            }
        }
        &.fix-msg {/*마케팅메세지 고정*/
            .template-view {
                /*position:static;*/
                .marketing-msg {position:absolute;bottom:-77px;}
            }
        }
        &-info {
            margin-bottom:100px;
            .info-tit {
                margin-bottom:70px;text-align:center;
                .tit {@include mainVisualTxt;}
                .txt {margin-top:20px;@include mainTxt;color:$gray05;font-weight:normal;}

            }

            .fix-info-tit {
                margin-bottom:30px;
                .tit {@include contsTitle;}
            }
            .img-wrap {
                * {text-align:center;}
                img {margin:0 auto;}
            }
            .accordian-list {
                padding:20px 40px 0;border-top:1px solid $gray02;border-bottom:1px solid $gray02;
                &>li {
                    &+li {margin-top:40px;}
                    &:last-child {
                        .acc-conts {margin-bottom:20px;}
                    }
                    .title {
                        @include mainTxt;color:$gray05;font-weight:normal;text-align: left !important;transition:0.3s;
                        &.on {color:$black;font-weight:600;}
                    }
                    .acc-conts {
                        padding:0;background:none;
                        p {
                            &+.list-hyphen {margin-top:-20px;}
                        }
                        .list-hyphen {margin-bottom:10px;}
                    }
                }
            }
            .box-btn {margin-top:100px;text-align:center;}
        }
        .template-video {
            width:800px;height:450px;margin:60px auto;
            iframe {width:100% !important;height:100% !important}
        }

        /*.evt-slick {
            .slick-slide {
                width:406px;margin-right:30px;
                img {width:406px;}
            }
        }*/
        .other-evt {
            display:flex;justify-content:flex-start;gap:30px;
            li {
                overflow:hidden;width:406px;border-radius:7px;
                img {width:406px;height:250px;vertical-align:top;}
            }
        }
        .slick-default {
           div{
                border-radius: 20px; overflow:hidden; 
                img{
                    object-fit: cover;
                }
            }
            .slick-slide {
                .btn-play {
                    position:relative;
                    &::after {
                        content:'';display:block;position:absolute;left:50%;top:50%;width:80px;height:80px;transform:translate(-50%, -50%);
                        @include backgroundImg('/assets/images/pc/icon/ico-cir-play.png', center, 80px 80px);
                    }
                }
            }
        }
        .evt-review {
            .info-tit { margin-bottom:50px;}
        }
        .evt-movie{
            margin-top:-100px;padding:100px 0;background:#F2F4F5;
            .info-tit {margin-bottom:50px;}
        }
        .evt-cms-img {
            margin-top:-100px;
            img {display:block;margin:0 auto !important;}
        }
        .evt-payment {
            padding:100px 0;
            .is-filter-wrap {
                margin-bottom:50px;
                .is-filter {
                    .is-filter-list {
                        flex-wrap:wrap;gap:10px 20px;overflow-x:hidden;
                        li {
                            label {padding:20px 40px;font-weight:normal;}
                        }
                    }
                }
            }
            .payment-imgCard-btn {
                .cardList {border-radius:10px;}
            }
        }
        .template-btn {margin-top:70px;text-align:center;}

        .evt-product,
        .evt-movie,
        .evt-payment {
            &.unit-origin {width:800px !important;}
            .unit-origin {width:800px !important
                ;}
        }
    }

    //진행중인 이벤트 상세 페이지 - 유심 템플릿
    .evt-usim-template {
        .evt-movie {margin-bottom:0;}
        .phone-filter-wrap{
            width: 100%; margin-bottom: 50px; overflow-x: auto; border-bottom: 2px solid #662d91;
            &::-webkit-scrollbar{
                display: none;
            }
            ul{
                display: flex;
                li{
                    flex-shrink: 0; padding-right: 5px;
                    input[type=radio]{
                        @include hidden;
                    }
                    label{
                        display: inline-block; padding: 0 40px; font-size: 24px; letter-spacing: -0.5px; font-weight: 600; line-height: 55px;  color: #666; background-color: #f6f6f6; border-radius: 8px 8px 0 0; cursor: pointer;
                    }
                    &.on{
                        label{background: #662d91; color: #fff;}
                    }
                    &:last-child{padding-right: 0;}
                }
            }
        }
        .filter-box {
            position:relative; 
            &::before {content:'';position:absolute; right:0;top:0; width: 100px; height: 58px; background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0));}
        }
        .badge-wrap{
            min-height: 27px; margin-bottom: 20px;
            .bge{display:inline-block; padding:4px 10px; border-radius: 4px; color:#fff; font-size: 16px;  font-weight: 600; line-height: 1.19; letter-spacing: -0.32px;}
        }
        .feature{
            &>span{font-size: 20px;}
        }
        .template-info{
            .cardList-wrap{
                position: relative;
                .banner-randombox{
                    top: 15px; right: 40px; animation: none; visibility: visible;
                }
            }
        }
    }

    //진행중인 이벤트 상세 페이지 - 휴대폰 템플릿
    .evt-phone-template {
        .evt-product {
            margin-top:-100px;padding:100px 0;
            .info-tit {margin-bottom:50px;}
            .is-filter-wrap {
                margin-bottom:50px;
                .is-filter {
                    .is-filter-list {
                        flex-wrap:wrap;gap:10px 20px;overflow-x:hidden;
                        li {
                            label {padding:20px 40px;font-weight:normal;}
                        }
                    }
                }
            }
            .product-imgCard {
                .cardList {border-radius:10px;}
                .phone-txt-box {
                    .box-btn {
                        .btns {width:100%;}
                    }
                    & >.txt{
                        @include normalTxt; margin-top: 20px;
                    }
                }
                
            }
        }
        // 휴대폰 템플릿 카테고리 버튼 디자인 수정
        .phone-filter-wrap{
            width: 100%; margin-bottom: 50px; overflow-x: auto; border-bottom: 2px solid #662d91;
            &::-webkit-scrollbar{
                display: none;
            }
            ul{
                display: flex;
                li{
                    flex-shrink: 0; padding-right: 5px;
                    input[type=radio]{
                        @include hidden;
                    }
                    label{
                        display: inline-block; padding: 0 40px; font-size: 24px; letter-spacing: -0.5px; font-weight: 600; line-height: 55px;  color: #666; background-color: #f6f6f6; border-radius: 8px 8px 0 0; cursor: pointer;
                    }
                    &.on{
                        label{background: #662d91; color: #fff;}
                    }
                    &:last-child{padding-right: 0;}
                }
            }
        }
        
        .filter-box {
            position:relative; 
            &::before {content:'';position:absolute; right:0;top:0; width: 100px; height: 58px; background-image: linear-gradient(to left, #fff, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0));}
        }
    }
    //대상자 발표 상세페이지
    .evt-traget-announce {
       .announce-btn {
            margin-bottom:30px;
       }
    }

    /* K-패스 요금제 [팝업]  */
    &.layer-popup {
        &.bottom-sheet-wrap {
            .layer-popup-item {
                &.evt-76970-popup { 
                    .page-desc{
                        & + .page-desc{
                            margin-top: 30px;
                        }
                        p{
                            font-weight: 600;
                        }
                    }
            
                    .phone-certification{
                        .agree-wrap{
                            margin-top: 40px;
                            .btn-check{
                                margin-top: 0;
                            }
                            .link-underline{
                                margin-left: 0;
                            }
                        }
                    }
            
                    .btn-agree-box{
                        padding: 17px 20px;
                    }            
                    .popup-footer{
                        padding: 50px 30px 50px;
                        .btns.w-sm{
                            min-width: 200px;
                        }
                    }

                    &.evt-76970-popup03 {
                        h2 {
                            font-size:20px;
                            font-weight:600;
                            color:#191919;
                            text-align:center;
                            letter-spacing:-0.4px;
                        }
                        .result-cont {
                            margin-top:20px;
                            padding:30px;
                            font-size:16px;
                            font-weight:600;
                            color:#191919;
                            text-align:center;
                            line-height:1.38;
                            letter-spacing:-0.32px;
                            background-color:#f9f5ff;
                            border-radius:10px;
                        }
                    }
                }
            }
        }
    }
    
    // 친구 추천
    .event-friend{
        .cont-wrap{
            margin-bottom: 40px;
            &:last-of-type{
                margin-bottom: 0;
            }
        }
        &.friend-ranking{
            .table-info {
                display:flex;justify-content:space-between;margin-bottom:20px;
                .main-txt-group {
                    margin-bottom:0;
                    .txt {color:$gray04; margin-top: 10px;}
                }
                .box-select {max-width:350px;}
            }
            .table-list-wrap {
                .table-list {
                    tr {
                        &.medal {
                            td {padding:9px 0;background:$lightpurple;}
                        }
                    }
                    td {
                        &.txt-vt {font-weight:600;}
                    }
                }
            }
        }
        &.friend-usim{
            .page-desc {
                h2{
                    p{@extend %fontInherit;}
                }
                .mem {@include mainTxt;}
                .btn-text-right {margin-top:10px;@include smallTxt;font-weight:600;}
            }
            .rq-form {
                padding-bottom:0;border-bottom:none;
                .main-txt-group {
                    .txt {font-size: 20px;font-weight: 600; line-height: 1.2; letter-spacing: -0.4px;text-align: left; color: #191919;}
                }
            }
        }
        &.friend-my-announce {
            .page-desc {
                h2 {
                    &>span {color:inherit;font-size:inherit;font-weight:inherit;}
                }
                .btn-text-right {margin-top:10px;@include smallTxt;font-weight:600;}
            }
            .announce-img {
                img {vertical-align:top;}
                &+.box-list {margin-top:70px;}
            }

            .announce-tble {
                padding-top:20px;
                .table-title {margin-bottom:30px; padding:0; border:0;}}
                table th {font-weight:700;}
                .box-table {
                    & + .box-table {margin-top:70px;}
                     .w-xs {min-width:68px;height:30px;padding:0; border:1px solid #191919;  border-radius: 7px;}
                }    
                .btn-group {margin-top:50px;}           

            .box-tab {
                margin-top:70px;
                .tab-btn-wrap {display:flex;gap:0 20px;margin-bottom:40px;}
            }
            .box-flex{
                width:860px;
                .box-input{
                    width:730px;
                }
                & + .box-flex{
                    padding-top: 20px;
                }
            }
            .box-list {
                margin-top:40px;
                .tit {
                    &>span {color:inherit;font-size:inherit;font-weight:inherit;}
                }
            }
            &>.box-btn {margin-top:50px;text-align:center;}
            .rq-form {
                border-bottom:none;
                .main-txt-group {
                    padding-bottom:52px;
                    .box-btn {margin-top:12px;text-align:left;}
                }
                .rq-name {width:540px;}
            }

        }
        &.friend-usim-history {
            .box-table{
                margin-top:40px;
                .table-defult {
                    border-top:1px solid $gray02;
                    td {
                        padding:20px 40px;
                        &:nth-child(2) {text-align:right;}
                        strong {
                            display:block;@include normalTxt;font-weight:400;text-align:left;
                            &+strong {margin-top:5px;}
                        }
                        .state {
                            span {display:block;@include defaultTxt;color:$black;}
                            .date {margin-top:5px;@include smallDepthTxt2;}
                            button {padding-bottom:1px;border-bottom:1px solid $gray04;@include defaultTxt;color:$gray04;}
                        }
                    }
                }
            }
            .bang-notice {margin-top:20px;@include mainTxt;color:#707070;font-weight:normal;}
            .box-btn {margin-top:50px;text-align:center;}
        }
    }

    // 친구추천 유심 선물하기 - 편의점 바로 픽업
    .friend-usim{
        .usim-delivery{
            margin:40px 0 80px; align-items:flex-start; display:flex;
            .txt-tip {
                margin-top:10px;font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;
                strong {font-size:16px; color:#191919; font-weight: 700;}
            }
            .form-tit{
                padding-top:16px; display:flex; flex-direction:column; flex-shrink:0; gap:10px; width:200px;
                .box-tooltip{
                    .txt-tooltip{
                        top: 30px; left: 0;
                        .info-wrap{
                            & + .info-wrap{margin-top: 20px;}
                            strong{@include normalTxt; display: block;}
                            p{
                                @include defaultTxt; margin-top: 10px; line-height: 1.5;
                            }
                        }
                    }
                }
            }
            .form-cont{
                .delivery-way{
                    display:flex; gap:20px; flex-wrap:wrap;
                    .btn-radio{
                        label{
                            gap:10px;
                        }
                    }
                }
            }
        }
        .usim-find{
            .form-tit{
                position:absolute; left:0; top:16px; width:190px;
            }
            .find-convenience{
                width:260px;
                button{
                    width:100%;
                }
            }
        }
        .box-input{
            .txt-description{margin-top:10px;}
            &.phone-wrap{
                input{width:300px;}
                .ico-delete{
                    left:256px; right:0;
                }
            }
        }
        .notification-wrap li {
            .link {color:#3392ff;text-decoration: none;}
        }
        .box-input.sending {
             .txt-sending {letter-spacing: -1px;}
        }
        
    }
}

//등록된 내용이 없을때
.no-regi {
    margin-top:50px;padding:100px 0;border-top:1px solid $black;border-bottom:1px solid $gray02;
    &>p {@include mainTxt;text-align:center;}
}

// 팝업
// [바텀시트]공유하기 링크
.evt-share{
    .list-share{
        display: flex; gap: 16px;
        li{
            width: 100%;
            img{width: 148px;}
            *:not(.pop-toast){@include defaultTxt; font-weight: 600;}
        }
    }
}

//[팝업] 사전예약신청 관련
.evt-pop {
    .evt-reservation-posting,
    .evt-traget-check {
        .rq-form {
            margin-bottom:0;padding-bottom:0;border-bottom:none;
            .box-input {
                &:first-of-type {margin-top:0;}
            }
        }
    }
    .reservation-model-info {
        margin-bottom:10px;
        dt {display:block;@include normalTxt;text-align:center;}
        dd {margin-top:5px;@include defaultTxt;color:$black;line-height:1.5;text-align:center;}
    }
}
.layer-popup-item {
    &.evt-reservation {
        .rq-form {margin-bottom:0;}
        .agree-group {
            .btn-check {
                label {font-weight:normal !important;}
            }
        }
    }
    &.evt-prize {
        .box-icon {margin-bottom:20px;}
    }
}

//[팝업] 이용불가
.unavailable {
    .box-icon {margin-bottom:20px;}
}



.phone-type01 {
    .phone-info {
        display: flex; align-items: center;  gap:20px;
        .phone-img {
            width:300px;
            img {width:100%;}
        }
        .phone-spc {width:400px;
            .model-tit {
                margin-top:5px; padding:20px;   border-radius: 10px; background:#f7f8fa; text-align: center;
                .tit {display:block; font-size: 36px; font-weight: 700; line-height: 1.22;  letter-spacing: -0.72px; color: #191919;}
                .txt {display:block; margin-top:5px; font-size: 28px; line-height: 1.21; letter-spacing: -0.56px; color: #666;}
            }
            .chge {
                dl {display: flex; margin:24px 0 20px 0;}
                dt {width:200px; padding-bottom:10px; text-align: left;}
                dd {width: calc(100% - 200px); padding-bottom:10px; text-align: right; font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; color: #191919;}
            }
            .chge-prce {
                display:flex; align-items: center; padding-top:20px; border-top:1px solid #ddd;
                .prce-tit {width:80px;   font-size: 20px;font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}
                .prce {width: calc(100% - 80px); font-size: 40px;  font-weight: 700; line-height: 40px; letter-spacing: -0.8px;text-align: right; color: #662d91;}
            }
            .box-btn {
                margin-top:20px;
                .btns {width:400px;}
            }        
        }

    }
}

.phone-type02 {
    .phone-info {
        display: flex; gap:50px; align-items: center;
        .phone-img {
            width:200px;
            img {width:100%;}
        }
        .phone-spc {
            width:470px;
            .model-tit {
                margin-top:15px; text-align: left;
                .tit {display:block; font-size: 36px; font-weight: 700; line-height: 36px; letter-spacing: -0.72px; color: #191919;}
                .txt {display:block; margin-top:5px; font-size: 26px; line-height: 1.19; letter-spacing: -0.52px; text-align: left; color: #666;}
            }
            .chge-prce {
                display:flex; margin-top:20px; gap:28px; align-items: center; 
                .prce-tit {font-size:20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919; }
                .prce {min-width:120px; font-size: 30px; font-weight: 700; font-stretch: normal; font-style: normal; line-height: 1.2; letter-spacing: -0.6px; text-align: right; color: #662d91;}
                .btns {width:200px;}
            }
        }
    }
}
.phone-info {
    .phone-spc{
        .badge-box{
            .bge {display:inline-block; padding:4px 10px; border-radius: 4px; color:#fff; font-size: 16px;  font-weight: 600; line-height: 1.19; letter-spacing: -0.32px;}
        }
    }
}


.layer-popup .recommended-result {
    width:700px; max-width: 700px; padding-top:30px;
    .popup-header {
        .tit {font-size: 24px; font-weight: 600; line-height: 1.25; letter-spacing: -0.48px; text-align: left; color: #000;}
    }
    .popup-body{
        .txt-main {margin-top:20px;}
        .phone-certify {
            margin-top:40px; text-align: left;            
            .txt-main {margin-top:0; font-size:20px; text-align: left;}
            .form-bx + .form-bx {margin-top:40px}
        }
        .result-view {
             text-align: left;            
            .txt-main {margin:0 0 30px 0; font-size: 20px;  font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left;  color: #000;}
            .msg-txt {
                font-size:20px;line-height:24px;color:#000;letter-spacing: -0.4px; font-weight:600;
                &+p {margin-top:10px; line-height: 1.5;}
                & + .notification {margin-top:10px;}
                & + .box-table {margin-top:50px;}
            }
            p +  .msg-txt {margin-top:50px;}
            .sm-txt {font-size:16px; color:#000; font-weight:600;}
            .bg-bx {
                width: 640px; height: 99px; margin: 10px 0 20px; padding: 40px; border-radius: 10px; background-color: #f7f8fa;
                p {font-size: 16px; font-weight: 600; line-height: 1.5; letter-spacing: -0.32px; text-align: center; color: #662d91;}
            }
            .btn-group{
                margin-bottom:50px;
                .btns {width:260px;}
            }
            .box-table {
                margin-top:30px;
                & + p {margin-top:50px;}
                .txt {
                    font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: left; color: #191919;
                    & + table {margin-top:10px;}
                } 
                & + .notification  {margin-top:20px;}
            }
        }
        .btn-group {
            margin-top:30px;
            .btns {width:220px;}
        }
        .txt-sub {margin-top:30px;}
    }
}

.layer-popup{
    .page-desc{
        p{
            padding-left: 20px; position: relative;
            &::before{
                content: '※'; width: 20px; height: 20px; font-size: 24px; position: absolute; left: 0; top: -7px;
            }
        }
    }
    .evt-youandme{
        .confirm-box{
            padding: 0; margin: 20px 0 0;
            & + .box-tit{margin-top: 40px;}
        }
        .box-tit{
            font-size: 16px; font-weight: 600; letter-spacing: -0.3px; color: #662d91;
            & + .box-flex{margin-top: 10px;}
        }
    }
       
    .iphone15-event {
        margin-top:50px;
        .box-input + .box-flex  {margin-top:40px;}
        .rq-phone +.rq-phone {margin:20px 0 0; padding-top: 0;}
        .space {margin-top:20px;}
    }

    .pull-view {
        .pull-cunt {
            font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;
            strong {color:#662d91; font-size: 20px;font-weight: 600;}
        }
        .pull-result {
            margin-top:30px;
            .result-list {
                display:flex; width:100%;;padding:40px 0; border-radius: 7px;border: solid 1px #ddd;background-color: #fff;
                li {
                    width:20%;text-align: center;
                    & + li {border-left:1px solid #ddd;}
                    span {display:block; margin-top:5px; font-size: 16px; font-weight: 600; line-height: 1.19; letter-spacing: -0.32px; text-align: center; color: #191919; }
                    .txt-nber {display: block; margin-top:15px;  font-size: 36px;font-weight: 600; line-height: 1.19; letter-spacing: -0.72px; text-align: center; color: #662d91;}
                }                
            }
            .box-table {
                margin-top:50px;
                table td {color:#666;}
            }
        }
    }

    .phone-certification {
        .rq-phone + .rq-phone {margin-top:20px;padding:0;}
        .box-input + .rq-phone {margin-top:40px;}
    }

    .share-box {
        margin-top:40px;padding-top:40px;border-top:1px solid #ddd;
        .tit { font-size: 20px;  font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}
        .txt {
            margin-top:20px; font-size: 16px; font-weight: 600; line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;
            strong { font-size: 16px; font-weight: 600;color:#662d91}           
        }
        .share-lik {
            position:relative; display: flex;gap:40px; width:240px; margin:40px auto 0;text-align: center;
            a strong {display:block;margin-top:20px;font-size: 16px;font-weight: 600; line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #191919; }
            .ts-msg {
                display: inline-block;position:absolute;left:50%;bottom:0; width:200px; padding: 11px 0 10px;opacity: 0.67;border-radius: 7px; box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16); background-color: #191919; transform: translateX(-50%);
                p {color:#fff;}
            }
        }
    }

    .blackfriday-wrap{
        .page-desc{
            h2{
                span{font-size: 20px; font-weight: 600; line-height: normal; letter-spacing: -.4px; color: #662d91;}
            }
            ul{
                margin-top: 20px;
                li{font-size: 16px; color: #191919; line-height: 1.5; font-weight: 600; letter-spacing: -.3px;}
            }
        }
        .confirm-box{margin: 20px 0 0; padding-top: 0;}
    }

    .evt-udolf{
        .page-desc{
            h2{font-size: 24px;}
            .desc-01, span{
                font-size: 30px; font-weight: 600; line-height: normal; letter-spacing: -.4px;  color: #191919;
                span{color: #662d91;}
            }
            ul{
                margin-top: 20px;
                li{font-size: 16px; color: #191919; line-height: 1.5; font-weight: 600; letter-spacing: -.3px;}
            }
            &.desc-tit{font-size: 30px; margin-bottom: 30px;}
        }
        .share-box{
            margin-top: 0; padding-top: 0; border: 0;
            .txt{margin-top: 0;}
            .share-txt-box{
                background-color: #f9f5ff; padding: 20px 0; margin: 20px 0 10px; text-align: center; font-size: 20px; font-weight: 600; letter-spacing: -0.4px; color: #191919; border-radius: 10px;
                span{color: #662d91; font-size: 20px; font-weight: 600; letter-spacing: -0.4px;}
                &+p{font-size: 16px;}
            }
            .share-lik{margin-top: 60px;}
        }
        .udolf-stamp{
            padding: 20px 10px 50px 10px;
            ul{
                display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr; gap: 15px;
                li{
                    width: 110px; height: 110px; background: transparent url('/assets/images/event/udolf-stamp-off.png') 0 0 no-repeat; background-size: 100%;
                    &.on{background: transparent url('/assets/images/event/udolf-stamp-on.png') 0 0 no-repeat;}
                }
            }
        }
        .udolf-txt-box{
            background-color: #f9f5ff; padding: 40px 0; text-align: center;
            p,span{
                font-size: 16px; font-weight: 600; color: #191919; letter-spacing: -0.6px;
                span{
                    &.under-line{text-decoration: underline;}
                }
                &.upoint{
                    font-size: 30px; margin-bottom: 20px;
                    span{font-size: 30px;}
                }
            }
        }
    }

    .evt-s24{
        .share-box{
            margin-top: 0; padding-top: 0; border: 0;
            .txt{margin-top: 0; font-size: 20px;}
            .share-txt-box{
                background-color: #f9f5ff; padding: 20px 0; margin: 20px 0 10px; text-align: center; font-size: 20px; font-weight: 600; letter-spacing: -0.4px; color: #191919; border-radius: 10px;
                span{color: #662d91; font-size: 20px; font-weight: 600; letter-spacing: -0.4px;}
                &+p{font-size: 16px; letter-spacing: -0.4px; color: #959595;}
            }
            .share-lik{margin-top: 60px;}
        }
    }
    
    .carnival {
        .popup-footer{
            .btn-group{
                .btns {width:260px;}
                .btns:disabled {background:#eff1f5; color:#666;}
            }
        }
        .carnival-certify {
            .rq-phone {
                margin-top:40px;
                & + .rq-phone {margin-top:20px;padding:0;}               
                .agree-wrap {
                    margin-top:20px; 
                    .btn-text-line.btn-check {margin:0;}
                }
            }
            .notification {margin-top:50px;}
        }
    }

    .evt-randombox{
        .page-desc{ 
            p{
                padding-left: 20px; position: relative;
                &::before{content: '※'; width: 20px; height: 20px; font-size: 24px; position: absolute; left: 0; top: -7px;}
            }
        }
        .box-btn{text-align: center; margin-bottom: 50px;}
        .phone-certification{
            h2{color: #191919; font-size: 20px; font-weight: 600; line-height: normal; letter-spacing: -0.4px; margin-bottom: 40px;}
        }
        .box-input{
            &+.box-input{margin: 40px 0 0;}
        }
        .agree-wrap{margin: 30px 0 50px;}
    }

    .evt-5g{
        .page-desc{ 
            p{
                padding-left: 20px; position: relative;
                &::before{content: '※'; width: 20px; height: 20px; font-size: 24px; position: absolute; left: 0; top: -7px;}
            }
        }
        .evt-desc{
            text-align: center; margin-bottom: 30px;
            h2{
                font-size: 30px; font-weight: 600; color: #191919;
                span{font-size: 30px; font-weight: 600; color: #191919; color: #662d91;}
            }
        }
        .choice-wrap{
            ul{
                li{
                    margin-bottom: 20px;
                    label{
                        width: 100%; height: 78px; justify-content: flex-start; padding: 0 0 0 60px; color: #666; background-color: #f6f6f6; font-weight: normal;
                        &::before{
                            content: ''; position: absolute; left: 20px; width: 20px; height: 22px; background: transparent url(/assets/images/pc/icon/ico-radio-check.png) no-repeat center;
                        }
                    } 
                    input[type=radio]{
                        &:checked{
                            &+label{
                                background-color: #fff; font-weight: 600; color: #662d91;
                                &::before{background: transparent url(/assets/images/pc/icon/ico-radio-check-on.png) no-repeat center;}
                            }
                        }
                    }
                }
            }
        }
    }
    .evt-kyobo{
        .agree-wrap{
            margin: 0 0 30px;
            .agree-chk{
                width: 100%; border: 1px solid #ddd; border-radius: 10px; padding: 20px;
                .btn-check{
                    padding-left: 0; margin-top: 0;
                    label{font-weight: 600;}
                }
                ul{
                    border-top: 1px solid #ddd; margin-top: 20px; padding-top: 20px; display: flex; gap: 30px;
                }
            }
            .agree-desc{
                width: 100%; font-size: 24px; color: #191919; font-weight: 600; text-align: center; margin: 30px 0;
            }
        }
    }
    .evt-thepeoplelife{
        .box-select-group{margin: 40px 0;}
        .box-select{
            & + .box-select{margin-top: 10px;}
        }
        .rq-form{margin-bottom: 20px;}
    }
    .evt-paikdabang{
        .box-tit{
            font-size: 18px; font-weight: 600; letter-spacing: -0.3px; color: #662d91; border-top: 1px solid #ddd; margin: 30px 0 20px; padding-top: 30px;
        }
        .box-flex{margin-top: 0;}
        .confirm-box{padding: 0; margin: 10px 0 0;}
    }
    // 바캉스 용품 찾기 기획전
    .evt-vacance{
        .evt-notice{
            background-color: #f9f5ff; border-radius: 10px; padding: 45px 0; text-align: center; margin-bottom: 40px;
            .tit{font-size: 32px; color: #662d91; font-weight: 900; margin-bottom: 15px;}
            .btn-more{font-size: 20px; color: #191919; font-weight: 600; padding-right: 30px; background: transparent url('/assets/images/pc/icon/ico-arrow-thin-right03.svg') center right no-repeat}
        }
        .box-btn{
            text-align: center; margin-bottom: 50px;
        }
        .box-input{
            & + .box-input{margin: 40px 0 0;}
        }
        .phone-certification{
            h2{color: #191919; font-size: 20px; font-weight: 600; line-height: normal; letter-spacing: -0.4px; margin-bottom: 40px;}
        }
        .vacance-find-box{
            background-color: #f9f5ff; padding: 50px 40px; text-align: center; border-radius: 8px;
            h3{margin-bottom: 25px; font-weight: 600; color: #191919; font-size: 30px;}
            p{color: #191919; font-size: 16px; font-weight: 600; margin-bottom: 50px;}
            ul{
                display: flex; gap: 25px;
            }
        }
    }
    // 사전예약 팝업(공통, 나이스인증) 
    .evt-reservation{
        .page-desc{
            & + .box-btn{text-align: center; margin-bottom: 50px;}
        }
        .phone-certification{
            h2{color: #191919; font-size: 20px; font-weight: 600; line-height: normal; letter-spacing: -0.4px; margin-bottom: 40px;}
        }
        .box-input{
            & + .box-input{margin: 40px 0;}
            & + h2{margin-top: 50px;}
        }
        .box-flex{
            & + .box-input{margin: 40px 0;}
        }
    }
    .evt-zflip6{
        .share-box{border: 0; margin: 60px 0 30px; padding-top: 0;}
    }
    .evt-iphone16{
        .box-flex{
            & + .box-input{margin: 20px 0;}
        }
        .box-input{
            & + .box-input{margin: 20px 0;}
        }
        .benefit-radio-wrap{
            margin-bottom: 20px;
            h3{color: #191919; font-size: 20px; font-weight: 600; line-height: normal; letter-spacing: -0.4px; margin: 50px 0 40px;}
            .btn-radio-group{
                display: grid; grid-template-columns: 1fr 1fr;
                .btn-radio{
                    width: 100%;
                    label{width: 100%; height: auto; padding: 20px 0; text-align: center;}
                } 
            }
        }
        .notice{
            margin-top: 10px;
            li{font-size: 16px; line-height: 1.5; font-weight: normal; margin-bottom: 5px;}
        }
        .share-box{margin: 0; padding: 0; border: 0;}
        .img-box{
            text-align: center; margin: 20px 0;
        }
    }
}
/* carnival */
.event-carnival {
    padding:0;
    .event-inner {
        padding-bottom:100px;text-align: center;background:#000;
        .event-top {background:#d1d1d1;}
        .event-cont {
            position:relative; width:800px; margin:0 auto;
            .carnival-btn01{position:absolute;top:1934px;left:50%;margin-left:-247px;}
        }     
        .btn-wrap{
            width: 100%; height: 260px; position: relative;
            .carnival-btn01{top: 80px;}
        } 
        .event-benefit {
             width:760px; margin:0 auto; padding-bottom:40px; background:#fff; border-radius: 40px; margin-top: 20px;
            .txt {
                width: 567px;margin:0 auto; text-align: left;
                strong {width:124px;}
            & strong,
            & span {display:inline-block; font-size:24px; color:#000;}
            & + .txt {margin-top:20px;}
            }
            .btn {
                display: inline-block; margin-top:40px;
                & + .btn {margin-top:23px;}
            }
            
        }
    }   
    .template-info {width:800px; margin:100px auto;}
    .event-end{
        img{width: 495px; height: 101px; margin: 80px auto;}
    }
}

/* 유돌프를 찾아라 기획전 */
.udolt-wrap{
    ul{
        li{position: absolute; z-index: 1;}
    }
    &.posi01{
        li{
            &:nth-child(1){top: 10%;}
            &:nth-child(2){top: 40%;}
            &:nth-child(3){top: 70%;}
            &:nth-child(4){top: 90%;}
        }
    }
    &.posi02{
        li{
            &:nth-child(1){top: 5%;}
            &:nth-child(2){top: 50%;}
            &:nth-child(3){top: 60%;}
            &:nth-child(4){top: 79%;}
        }
    }
    &.posi03{
        li{
            &:nth-child(1){top: 8%;}
            &:nth-child(2){top: 20%;}
            &:nth-child(3){top: 65%;}
            &:nth-child(4){top: 85%;}
        }
    }
    &.posi04{
        li{
            &:nth-child(1){top: 10%;}
            &:nth-child(2){top: 25%;}
            &:nth-child(3){top: 50%;}
            &:nth-child(4){top: 70%;}
        }
    }
    &.posi05{
        li{
            &:nth-child(1){top: 15%;}
            &:nth-child(2){top: 30%;}
            &:nth-child(3){top: 64%;}
            &:nth-child(4){top: 86%;}
        }
    }
    ul{
        li{cursor: pointer;}
        &.horizontal01{
            li{
                &:nth-child(1){margin-left: 70%;}
                &:nth-child(2){margin-left: 50%;}
                &:nth-child(3){margin-left: 20%;}
                &:nth-child(4){margin-left: 60%;}
            }
        }
        &.horizontal02{
            li{
                &:nth-child(1){margin-left: 20%;}
                &:nth-child(2){margin-left: 60%;}
                &:nth-child(3){margin-left: 10%;}
                &:nth-child(4){margin-left: 70%;}
            }
        }
        &.horizontal03{
            li{
                &:nth-child(1){margin-left: 50%;}
                &:nth-child(2){margin-left: 70%;}
                &:nth-child(3){margin-left: 30%;}
                &:nth-child(4){margin-left: 40%;}
            }
        }
    }
}
.udolf-cha{
    width: 110px; height: 110px;
    background: transparent url('/assets/images/event/udolf-sprites.png') 0 0 no-repeat;
    background-size: auto 110px;
    animation: udolf-move 1.5s infinite steps(10);
    &.del{
        visibility: hidden;
    }
}

@keyframes udolf-move{
    100%{
        background-position: -1100px 0;
    }
}

/* S24 사전 프로모션 */
.evt-s24{
    .area-desc{
        margin: 50px 0 10px 0;
        h2{color: #191919; font-size: 20px; font-weight: 600; line-height: normal; letter-spacing: -0.4px;}
    }
    .recommend-table{
        margin-top: 50px;
        .table-type-04{
            &:first-child{
                td{border: 0;}
            }
            .state{
                td{font-weight: 900;}
            }
            & + ul{
                margin-top: 10px;
                li{line-height: 1.5;}
            }
        }
    }
}

.details-recdtin{
    .bg-box {
        height: 70px;padding:0; border-radius: 10px; background-color: #f9f5ff;
        p {margin:0; font-size: 24px; font-weight: 600;  line-height: 70px;letter-spacing: -0.48px;text-align: center; color: #662d91;}
    }
    .notification +  .box-table  {margin-top:10px}
    .box-table {
        margin-top:40px;
        .table-title {margin-bottom:10px; padding:0; border:0;}
        table {
            th {padding:27px 0 23px 0;}
            td {color:#666; font-size:16px;}
            tbody {
                th {border-bottom: 1px solid #ddd; font-size: 16px; font-weight: normal; line-height: 1.19; letter-spacing: -0.32px; text-align: center; color: #191919; background:none; vertical-align: top;}
                td {text-align: left;}
                tr.odd {
                  background-color:#fafafa;
                }
            }
        }       
    }
}

//다모아 가족결합
.family-union {
    .main-txt-group {
        .box-btn {
            margin-top:10px;
            a {padding-right:30px;background-size:18px;}
            .line {padding-bottom:2px;border-bottom:1px solid #ddd;font-size:14px;font-weight: 600;letter-spacing: -0.28px;color:#000;}
        }        
    }
    .union-histy {
        margin-top:50px;
        .histy-not {
            padding:50px 0;border-radius: 10px;border: solid 1px #ddd;background-color: #fff; text-align: center;
            p {margin-top:20px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: center;color: #191919}
         }
         .histy-list {
            .tite {margin-bottom:30px; font-size: 20px; font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            .cont-section + .cont-section {margin-top:80px;}
            .histy-top {
                display: flex; align-items: center;justify-content: space-between;width: 100%;margin-bottom:20px;                      
                .tite {margin:0;}
                .select-default {width:340px;}        
                .histy-msg {font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}        
                .txt-date {font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: right;color: #959595;}
            }
            .box-table {
                table + .txt {margin-top:10px;}
                .txt {font-size: 16px; line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
            }
            
         }
         .accordian {
            .title {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            .notification-wrp {
                .noti-tit {display:block;margin-bottom:10px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                & + .notification-wrp {margin-top:20px}
            }
         }
    }
}

.layer-popup {
    .family-form {
        width:640px;
        .form-box + .form-box {margin-top:40px;}
        .form-box {
            .box-input input[type=number]:disabled, .box-input input[type=text]:disabled {background:#ddd;}
            .box-flex {
                .input-wrap {
                    width:150px;
                    &.tel {width:190px;}
                }
            }
            .box-flex.flex-btn .box-btn {flex:1 0 120px;}
            .tite {margin-bottom:16px; font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}
            .line-box {
                padding:40px; border-radius: 7px;border: solid 1px #ddd;background-color: #fff;
            }
            .notification-wrp {
                .tit {display:block;margin-bottom:10px; font-size: 16px;font-weight: 600;line-height: 1.25;letter-spacing: -0.32px;text-align: left;color: #191919;}
            }
            .input-file {
                width:560px;margin-top:40px; padding-top:40px; border-top:1px solid #ddd;
                .box-flex {             
                    width:auto;padding:0;     
                    .box-input {
                        margin-top:0;
                        input[type=text]:disabled {
                            background-color: #f6f6f6;
                        }
                    }
                    & + .box-flex {margin-top:20px;}
                }
                .box-input {     
                    width:auto;            
                    .input-wrap {width:430px;}
                }
                .box-btn {
                    button {
                        position:relative;
                        input[type=file] {position:absolute;top:0;right:0;width:100%;height:100%;opacity:0;cursor:pointer;}
                    }
                }
            }
            .file-add {
               width:560px; margin-top:10px;padding:0;justify-content: flex-end;
                .btns {width:120px;}
            }
        }
    }
}

//다모아 가족결합
.family-union {
    .main-txt-group {
        .box-btn {
            margin-top:10px;
            a {padding-right:30px;background-size:18px;}
            .line {padding-bottom:2px;border-bottom:1px solid #ddd;font-size:14px;font-weight: 600;letter-spacing: -0.28px;color:#000;}
        }        
    }
    .union-histy {
        margin-top:50px;
        .histy-not {
            padding:50px 0;border-radius: 10px;border: solid 1px #ddd;background-color: #fff; text-align: center;
            p {margin-top:20px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: center;color: #191919}
         }
         .histy-list {
            .tite {margin-bottom:30px; font-size: 20px; font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            .cont-section + .cont-section {margin-top:80px;}
            .histy-top {
                display: flex; align-items: center;justify-content: space-between;width: 100%;margin-bottom:20px;                      
                .tite {margin:0;}
                .box-select {width:340px;}
                .histy-msg {font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}        
                .txt-date {font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: right;color: #959595;}
            }
            .box-table {
                table + .txt {margin-top:10px;}
                .txt {font-size: 16px; line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
            }
            
         }
         .accordian {
            .title {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            .notification-wrp {
                .noti-tit {display:block;margin-bottom:10px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                & + .notification-wrp {margin-top:20px}
            }
         }
    }
}

// 무료 혜택관
.free-benefit{
    .banner-b{background-color: #fcefff;}
    .free-list-wrap{
        margin-top: 20px;
        li{
            & + li{margin-top: 30px;}
            a{
                width: 100%; padding: 40px 100px; border: 1px solid #ddd; border-radius: 10px; display: flex; align-items: center; justify-content: space-between; transition: all 0.5s;
                &:hover{border-color: #662d91; transition: all 0.5s;}
            }
            .ltst-txt{
                dt{font-size: 30px; font-weight: 900; letter-spacing: -0.6px; margin-bottom: 20px; color: #191919;}
                dd{font-size: 20px; font-weight: normal; letter-spacing: -0.6px; color: #191919;}
            }
        }
        
    }
    .free-service-cont {
        width:1100px; margin:0 auto;
        .unit-tit-b {font-size:20px;margin:50px 0 20px 0;}
        .coupon-list {
            .coupon-box {
                display:flex;align-items: center;justify-content: space-between; padding: 20px 40px;border-radius: 22px;background-color: #fff;transition: all 1s;
                & + .coupon-box {margin-top:20px;}
                .left-box {
                    display: flex;align-items: center;gap:60px;
                    img {width:100px;}
                    dl {
                        dt {
                            margin-bottom:10px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;
                            &.icon-new {
                                display: inline-block; position: relative;padding-right:26px;
                                &:after { content:''; position: absolute;right:0;top:50%;transform: translateY(-50%); width:16px;height:16px;background:url('/assets/images/pc/icon/ico-new.png') no-repeat 0 0;}
                            }
                        }
                        dd {overflow: hidden; max-width: 700px;max-height:24px; white-space: normal; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical;word-break: keep-all;  font-size: 16px;line-height: 1.4;letter-spacing: -0.32px;text-align: left;color: #666;}
                    }
                }
                .lnk-btn {
                    .lnk {
                        display:inline-block;width: 140px;height: 40px;line-height: 40px; border-radius: 10px;background-color: #f2e9ff; color: #864bb2;font-size: 14px;  font-weight: 600;text-align: center;
                        &.type01 {background: #eff1f5;color:#666;}
                        &.type02 {background: #ddd;color:#afafaf;}
                    }
                }
                &:hover {transition: all 1s; box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;}
            }
        }
    }
}
.event.free-service {
    background:#f6f8fc;
}

.coupon-form {
    .tit {margin-bottom:20px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #000;}
    .line-box {padding:50px 40px 40px; border-radius: 10px; border: solid 1px #ddd;}
    .coupon-nuber {
        text-align: center;
        .nuber-bx {
            display: inline-flex; align-items: center; min-width:532px; height:70px; padding-right: 20px; border-radius: 7px;border: solid 2px #191919;background-color: #fff;
            .cpn-tit {margin-left:30px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919}
            .cpn-txt {width:320px; margin: 0 20px 0 30px; text-align: center; font-size: 24px;font-weight: 600;line-height: 1.21;letter-spacing: -0.48px;color: #662d91;}
            .btn-copy {width:50px;height:34px;  border-radius: 7px; background-color: #eff1f5;font-size: 14px;font-weight: 600;line-height: 1.14;letter-spacing: -0.28px;text-align: center;color: #666;}
        }
        .list {
           margin-top:20px;text-align:center;
            li {
                font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;color: #666;
                & + li {margin-top:8px;}
            }            
        }
        .txt {margin-top:10px; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: center;color: #191919;}
        .btn-wrp {margin-top:30px;}
    }
    .dscrtin-box {
        margin-top:50px; padding: 40px; border-radius: 10px;background-color: #f7f8fa;
    }
    .notification-wrap {margin-top:50px;}
    
    .not-coupon {
        text-align: center;
        .txt {margin-top:10px; font-size: 16px;font-weight: 600;line-height: 1.38;letter-spacing: -0.32px;text-align: center;color: #191919;}
        .btn-wrp {margin-top:30px;}
    }
}


.sub-conts.benefit-plans-view {
    width:800px;
    .benefit-plans {
        width:800px;margin:0 auto 80px;
        .sb-tit {margin-bottom:20px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #000;}
        .benefit-list {
            li {
                & + li {margin-top:20px;}
                a {
                    display: flex;gap:40px; padding:0; border-radius: 10px; border: solid 1px #ddd;background-color: #fff;
                    .dscrt {
                        padding:24px 0;
                        .tit {display:block;margin-bottom:10px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919; }
                        p { font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
                    }
                }
            }
        }
        section + section {margin-top:50px;}
        .plans-list {
            .list {
                li {
                    position: relative;
                    & + li  {margin-top:20px;}
                    a {
                        display:flex;align-items: center;gap:40px; padding:20px; border-radius: 10px;border: solid 1px #ddd;background-color: #fff;
                        .dscrt {
                            .prc-box {
                                display: flex;gap:10px; align-items: flex-end;margin-bottom:10px;
                                .prc {display: inline-block; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: right;color: #959595;text-decoration: line-through;}
                                .dc-prc {display: inline-block; font-size: 24px;font-weight: 600;line-height: 1.21;letter-spacing: -0.48px;text-align: right;color: #662d91;}
                            }
                            p {  font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
                        }
                    }
                    .balloon {
                        position:absolute;right:40px;top:50%;padding: 8px 17px;border-radius: 6px;background-color: #e2d4f8; transform: translateY(-50%);font-size: 14px; font-weight: 600;line-height: 1.2;letter-spacing: -0.28px;text-align: left;color: #662d91; 
                        &::before {content:''; position:absolute;left:-5px;top:50%; transform: translateY(-50%); width: 0;height: 0;border-style: solid;border-width: 7px 10px 7px 0px;border-color: transparent #e2d4f8 transparent transparent;}
                    }
                }
            }
        }
    }
    .btn-group {
        position: sticky;bottom:0; gap:0;
       .btns {
        height:80px;line-height: 80px;padding:0;border-radius: 0; font-size: 20px;font-weight: 600; text-align: center;color: #666;color:#fff;
        &:first-child { background-color: #eff1f5;  color: #666;}
    }
    }
}

.layer-popup {
    .family-form {
        width:640px;
        .form-box + .form-box {margin-top:40px;}
        .form-box {
            .box-input input[type=number]:disabled, .box-input input[type=text]:disabled {background:#ddd;}
            .box-flex {
                .input-wrap {
                    width:150px;
                    .input-default {padding:16px 11px;}
                    input::placeholder {letter-spacing: -0.3px; }
                    &.tel {width:190px;}
                }
            }
            .box-flex.flex-btn .box-btn {flex:1 0 120px;}
            .tite {margin-bottom:16px; font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}
            .line-box {
                padding:40px; border-radius: 7px;border: solid 1px #ddd;background-color: #fff;
            }
            .notification-wrp {
                .tit {display:block;margin-bottom:10px; font-size: 16px;font-weight: 600;line-height: 1.25;letter-spacing: -0.32px;text-align: left;color: #191919;}
            }
            .input-file {
                width:560px;margin-top:40px; padding-top:40px; border-top:1px solid #ddd;
                .box-flex {             
                    width:auto;padding:0;     
                    .box-input {
                        margin-top:0;
                        input[type=text]:disabled {
                            background-color: #f6f6f6;
                        }
                    }
                    & + .box-flex {margin-top:20px;}
                    .btns.lightgray {width:120px; background: #dee1e5;color:#191919;}
                }
                .box-input {     
                    width:auto;            
                    .input-wrap {width:430px;}
                }
                .box-btn {
                    button {
                        position:relative;
                        input[type=file] {position:absolute;top:0;right:0;width:100%;height:100%;opacity:0;cursor:pointer;}
                    }
                }
            }
            .file-add {
               width:560px; margin-top:10px;padding:0;justify-content: flex-end;
                .btns {width:120px;}
            }
        }
    }
    .free-benefit{
        .page-desc{
            border-bottom: 1px solid #ddd; padding-bottom: 30px;
            h2{font-size: 24px;}
        }
        h3{font-size: 20px; font-weight: 600; letter-spacing: -0.4px; margin-bottom: 20px; color: #191919; text-align: left;}
        .benefit-step{
            li{
                font-size: 16px; font-weight: normal; line-height: 1.63; letter-spacing: -0.32px; padding-left: 20px; position: relative;
                &::before{content: ''; display: block; position: absolute; top: 0; left: 0; font-size: 16px;}
                &:nth-child(1)::before{content: '①';}
                &:nth-child(2)::before{content: '②';}
                &:nth-child(3)::before{content: '④';}
            }
        }
        .noti-box{
            width: 100%; padding: 30px; background-color: #f6f6f6; border-radius: 10px; margin-top: 30px;
            dt{font-size: 16px; font-weight: 600; letter-spacing: -0.32px; color: #191919; margin-bottom: 10px;}
            dd{
                padding-left: 16px; font-size: 16px; letter-spacing: -0.32px; position: relative;
                &::before{content: '*'; display: block; position: absolute; top: 3px; left: 0; font-size: 16px;}
            }
        }
        .box-btn{
            margin-top: 30px; text-align: center;
        }
        .notification-wrap{
            margin-top: 50px;
            h3{margin-bottom: 0;}
        }
    }
}

.phone-redress {
    .banner-b {
        .banner-wrap {
            justify-content: flex-start;gap:70px; height:427px;
            .baner-tit{display:block;margin-bottom:30px; font-size: 36px;font-weight: bold;line-height: 1.28;letter-spacing: -0.72px;text-align: left;color: #000;}
            .baner-txt{font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            .right {
                .box-btn {
                    margin-top:30px;
                    button {
                        width:310px; max-width: 310px; border-radius: 8px;
                        & + button {margin-left:7px;}
                    }
                }
            }
        }
    }
    .section {
        width:1280px; margin:0 auto;
       & + .section {margin-top:70px;}    
       .sectin-tit {margin-bottom:20px; font-size: 30px;font-weight: 600;line-height: 1.2;letter-spacing: -0.6px;text-align: left;color: #191919;}     
       .sello-service {
            display: flex;gap:40px;margin-top:20px;
            .service-box {
                width: 400px;height: 322px;padding: 40px;border-radius: 10px;background-color: #edfafd;text-align:center;
                .img-bx {display: inline-block; width:160px;height:160px;}
                .tit {
                    display:block;margin:10px 0; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: center;color: #191919;
                    span {font-size: 20px;color:#662d91;font-weight: 600;}
                }
                &:nth-child(2) {background: #eaf1fe;}
                &:nth-child(3) {background: #fbedf9;}
            }
       }
       .step-list {
            .step-box {
                .txt + p {margin-top:3px; color:#959595;font-size:14px;}
            }
       }
       .btn-wrp {margin-top:50px;text-align: center;}
       .notice-wrp {
            margin-top:80px;
            .tite {margin-bottom: 10px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
        }
    }
}
// 11509-re
.event-11509-re{
    position: relative;
    .menu-wrap{
        width: 100%; height: 88px; background-color: #061f60;  z-index: 2; 
        &.sticky{position: fixed; top: 80px; height: 95px;  transition: top ease .5s;}
        .menu-item{
            width: 800px; height: 100%; margin: 0 auto; display: flex;
            li{
                width: 25%;
                
                &.item01 { 
                    background: url('/upload/event/images/event11509-menu01.png') no-repeat center center;
                    background-size: auto 20px;
                }
                &.item02 { 
                    background: url('/upload/event/images/event11509-menu02.png') no-repeat center center;
                    background-size: auto 20px;
                }
                &.item03 { 
                    background:  url('/upload/event/images/event11509-menu03.png') no-repeat center center;
                    background-size: auto 20px;
                }
                &.item04 { 
                    background: url('/upload/event/images/event11509-menu04.png') no-repeat center center;
                    background-size: auto 20px;
                }
                a{width: 100%; height: 100%; font-size: 0;}
            }
        }
    }  
    a{ font-size: 0;}  
    
    .kv-area{       
        position: relative;  width: 100%; max-width: 100%;height: 700px; overflow: hidden;
       -webkit-animation: background-swich 8s ease-in-out infinite both;
        animation: background-swich 8s ease-in-out infinite both;
        .tit{ padding-top: 120px; width: 519px; height: 204px; margin: auto;
            img{
                display: block; width: 100%;
                    }
            }
        .bg-cloud{left: calc(50% + 340px) ; transform: translateX(-50%); position:absolute;top:260px;
            animation: cloude-2 22s linear infinite;
            -webkit-animation: cloude-2 22s linear infinite;      
        }
        .bg-cloud2{left: calc(50% - 340px) ; transform: translateX(-50%); position:absolute;top:380px;
            animation: cloude-1 22s linear infinite;
            -webkit-animation: cloude-1 22s linear infinite;      
        }
        .object-sun{ position: absolute; left: calc(50% + 266px); top: 120px;
            width: 140px;
            -webkit-animation: evt11509-sun 8s ease-in-out infinite both;
            animation: evt11509-sun 8s ease-in-out infinite both;        
        }
        .object-moon{ position: absolute; left: calc(50% + 266px); top: 120px;
            width: 100px;
            -webkit-animation: evt11509-moon 8s ease-in-out infinite both;
            animation: evt11509-moon 8s ease-in-out infinite both;        
        }
        .store-area { 
            position: absolute;
            bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
            width: 574px;
            height: 322px;
            text-align: center;
            animation: evt11509-store 8s ease-in-out infinite both;
        }
        .people-area1 { 
            z-index: 2;
            width: 100%;
            height: 195px;
            position: absolute;
            bottom:-2px;
            left: 50%;
            transform: translateX(-50%);
            animation: evt11509-people 8s ease-in-out infinite both;
        }
        .people-area2 { 
            z-index: 2;
            width: 100%;
            height: 195px;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            animation: evt11509-people2 8s ease-in-out infinite both;
        }
        .object-coupon{
            position: absolute; left: calc(50% - 24px); bottom: 0;
            width:360px;
        }
    }
    .pay-btn-wrap{
        padding-bottom: 80px;
        a{position: absolute;top: 0; height: 100% ;width: 100%;}
    }
}
@keyframes background-swich {
    0%{
     background-color: #95d3ff;
    }
    50%{
     background-color: #2b2e5a; 
    }
    100%{
     background-color: #95d3ff;
    } 
}
@keyframes cloude-1 {
    0% {
      left: 35%;
    }
    25% {
      left: 70%;
    }
    50%{
        left: 105%;
    }
    50.001% {
      left: -10%;
    }
    100% {
      left: 35%;
    }
}
@keyframes cloude-2 {
    0% {
      left: 75%;
    }
    25% {
      left: 110%;
    }
    25.001% {
      left: -10%;
    }
    100% {
      left: 75%;
    }
}
@keyframes evt11509-sun {
    0%{
    opacity: 1;        
    }
    50%{
    opacity: 0;
    transform: translateX(90px);
    } 
    100%{
    opacity: 1;
    transform: translateX(0);
    } 
}
@keyframes evt11509-moon {
    0%{
    opacity: 0;    
    transform: translateX(90px);    
    }
    50%{
    opacity: 1;
    transform: translateX(0);
    } 
    100%{
    opacity: 0;
    transform: translateX(90px);   
    } 
}
@keyframes evt11509-store {
    0%{
    background-image: url(/upload/event/images/event11509-store-a.png) ; 
    background-size: contain;
    }
    50%{
    background-image: url(/upload/event/images/event11509-store-b.png) ; 
    background-size: contain;
    } 
    100%{
    background-image: url(/upload/event/images/event11509-store-a.png) ;
    background-size: contain; 
    } 
}
@keyframes evt11509-people {
    0%{
        background: url('/upload/event/images/event11509-people-a.png') center bottom no-repeat;
        background-size: contain;
       }
       30%{
        background: url('/upload/event/images/event11509-people-a.png') center bottom no-repeat;
        background-size: contain;
       }
       50%{
        background: url('/upload/event/images/event11509-people-b.png')  center bottom no-repeat; background-size: contain;
       } 
       80%{
        background: url('/upload/event/images/event11509-people-b.png')  center bottom no-repeat; background-size: contain;
       } 
       100%{
        background: url('/upload/event/images/event11509-people-a.png') center bottom no-repeat ; background-size: contain;
       } 
}
// 11526 modify250226
.evt-roulette {
    &.modify250226{
        .wishlist-envet{
            .loading{
                display: none;
                .loading-img02{
                    width: 4000px;
                    height: 300px;
                    background: transparent url('/upload/event/images/event-loading-img-0227.png') repeat-x; background-size: auto 100%;
                    animation: slide 10s linear infinite;
                    position: absolute; top: 40%;
                    z-index: 2;
                }
                &.on{
                    display: block;
                }
            }
            .result{
                position: relative;
                display: none;
                &.on{
                    display: block;
                }
                .result-goods{
                    width: 800px;
                    height: 800px;
                    position: absolute;
                    bottom: 180px;
                    left: 50%;
                    transform: translateX(-50%);
                    a{
                        font-size: 0;
                        position: absolute;
                        width: 510px;
                        height: 75px;
                        bottom: 61px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            .swiper {
                width: 800px;
                height: 100%;
                .swiper-wrapper{
                    position: absolute;
                    left: 0;
                    top: 352px;
                    width: 800px;
                    height: 1372px;
                    z-index: 10;
                    img{
                        width: 600px;
                    }
                }
            }
        }
        .swiper-slide{
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 800px;
        }
        .btn-11526-share{
            width: 630px;
            height: 116px;
            position: absolute;
            bottom: 243px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
        }
        .btn-group {
            width: 100%;         
            height: 100px;
            gap: 0;
            z-index: 2;
            &.sticky{
                position: fixed;
                bottom: 0;
                left: 0;
            }
            .btns{
                height: 100%;
                border-radius: 0;
                width: 100%;
                font-size: 0;
                background-color: #2a2a2a;
                padding:0;
                &.item01{
                    position: relative;
                    text-align: right;
                    img { display: inline-block; height:100px; margin: 0;}
                    &::after { position: absolute; right: 0; top:22px; content: ''; width: 2px; height: 56px; background-color: #fff;}
                }
                &.item02{
                    text-align: left;
                    img { display: inline-block; height:100px;}
                }
            }
        } 
    }
}

// 이즐
.event-11673{
    img{
        display: block;
        margin: 0 auto;
    }
    .cont{
        position: relative;
    }
    a{
        font-size: 0;
    }
    .menu-wrap{
        width: 100%;
        height: 76px;
        z-index: 2; 
        &.sticky{
            position: fixed;
            top: 80px;
            height: 80px;
            transition: top ease 0.5s;
        }
        .menu-item{
            width: 100vw;
            max-width: 800px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            li{
                width: 25%;
                &.item01 { 
                    background: url('/upload/event/images/event11673-menu01.png') no-repeat center center;
                    // background-size: auto 2.5vw;
                }
                &.item02 { 
                    background: url('/upload/event/images/event11673-menu02.png') no-repeat center center;
                    // background-size: auto 2.5vw;
                }
                &.item03 { 
                    background:  url('/upload/event/images/event11673-menu03.png') no-repeat center center;
                    // background-size: auto 2.5vw;
                }
                &.item04 { 
                    background: url('/upload/event/images/event11673-menu04.png') no-repeat center center;
                    // background-size: auto 2.5vw;
                }
                a{
                    width: 100%;
                    height: 100%;
                    font-size: 0;
                }
            }
        }
    }  
    .kpass-plan{
        position: relative;
        a{
            position: absolute;
            width: 746px;
            height: 119px;
            left: 50%;
            transform: translateX(-50%);
        }
        .plan01{
            height: 140px;
            top:264px;
        }
        .plan02{
            top:402px;
        }
        .plan03{
            top:519px;
        }
        .plan04{
            top:636px;
        }
        .plan05{
            height: 140px;
            top:756px;
        }
    }
    .cont-btn{
        position: absolute;
        width: 590px;
        height: 86px;
        bottom: 82px;
        left: 50%;
        transform: translateX(-50%);
    }
    .use_pc{
        img{        
            width: 100%;
        }

    }
}
// 이즐 end

.telenursing-box {
    position: sticky;bottom:0; width:1280px;height:100px; margin:0 auto;border-radius: 18px 18px 0 0; background-image: linear-gradient(to bottom, #be7def, #8b17e3);
    .box-inner {
        display:flex;justify-content: center;align-items: center;
        .img-bx {margin:6px 30px 0 0;}
        p {font-size: 20px;line-height: 1;letter-spacing: normal;text-align: left;color: #f9f5ff;}
        .telenursing-btn {
            display:flex;justify-content: center;align-items: center; margin-left:20px;
            a{display:block;height:54px;line-height: 54px; padding:0 35px 0 91px; border-radius: 100px;background: #4e147a url('/assets/images/pc/subpage/img-telenursing02.png') no-repeat 35px 50%; font-size: 32px;font-weight: bold;letter-spacing: normal;text-align: left;color: #fff;}
        }
    }
}

.plus-combine {
    .page-desc {
        p {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
       .btn-box {
            margin-top:20px;
            .btns {height:44px; padding:0 20px;font-size:14px;}            
        }
    }
    .announce-tble {
        .box-table {
            & + .box-table {margin-top:80px;}
            .table-title {padding: 0 0 30px 0;border:0;}
            .layout-align {
                margin-bottom:20px;
                display:flex;align-content: center;align-items: center;justify-content: space-between;
                .table-title {padding:0;}
            }
            .box-select {width:340px;}
        }

        .notification-wrap {
            .txt { font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #e34140;}
            h3 {margin-bottom:20px;}
            .notification {
                li {
                    margin-top:10px;padding-left:23px;font-size: 16px;line-height: 1.25;letter-spacing: -0.32px;text-align: left;color: #666;text-indent:-23px;
                    &::before {content: '';display:none;}
                    strong {font-size: 16px;line-height: 1.25;letter-spacing: -0.32px;text-align: left;color: #666;}
                }
            }
        }
    }
    .accordian {
        margin-top:80px;
        .title {margin-bottom:18px; font-size: 20px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}        
        .notification-wrap {margin:0;}
    }
}

.plus-combine-pop {
    .combine-pop-inner {
        .iner-tit {margin-bottom:30px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
        .btn-wrp {margin-top:30px;text-align: center;}
        .box-table {
            margin-top:40px;
            table {
                td {color:#666;font-weight: normal;}
            }
        }
        .msg-bx {
            margin-top:30px; padding:30px 0; border-radius: 10px; background-color: #f9f5ff;text-align: center;
           p {font-size: 16px;line-height: 1.5;letter-spacing: -0.32px;text-align: center;color: #191919;}
           .msg01 {
               margin-bottom:8px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: center;color: #191919;
                strong {color:#662d91;font-size: 20px;font-weight: 600;}
            }
        }
        .box-flex.phone {
            margin-top:40px;
            & + .box-flex.phone {margin-top:20px;padding-top:0;}
        }
        .btn-check  {margin-top:50px;}
        &.finish {
            .img-bx {text-align: center;}
            .msg01 {
                span {font-size: 20px; font-weight: 600;color:#eb008b;}
            }
            .notic {
                margin-top:30px;
                .noti-tit {font-size: 16px;font-weight: 600;line-height: 1.25;letter-spacing: -0.32px;text-align: left;color: #191919;}
                .notification {
                    li {margin-top:8px; color:#666;}
                    & + .noti-tit {margin-top:10px;}
                }
            }
        }
    }
}

.terms-combine {
    .combine-pop-inner {
        .iner-tit {margin:0 0 30px 0; font-size: 20px;font-weight: 600; line-height: 1.2; letter-spacing: -0.4px;text-align: left;color: #191919;}
        .txt-b {color:#191919;font-weight:600;}
        ul{
            li {
                position:relative; margin-top:8px;padding-left:13px; font-size: 16px; line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;
                &::before {content: '';position:absolute;left:0;top:10px;width:6px;height:1px; background: #191919;}
            }
        }
        h3 {margin-top:30px;font-size:16px;}
        p {color:#191919}
        .table-list-wrap {margin-top:30px;}
    }
}