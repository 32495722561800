@charset "UTF-8";

.products{
    .payment-list {
        .life-list-wrap {margin-bottom:70px;}
        .box-tab {overflow:visible}
        .banner {margin-top:70px;}
        .banner-b{
            background-color: #fcfaea;
        }
    }
    //유심요금제
    .customer-list {
        .banner {
            &.bnr-beige {margin-top:70px;}
        }
        .banner-b{
            &.usim-ch{
                background: #effbf5;
            }
            &.esim-ch{
                background: #f8f4ff;
            }
            &.phone-ch{
                background: #f5f8ff;
            }
            &.usim-all {background:#fcfaea;}
            &.usim-plan {background:#f6f2ec;}
            &.esim-all {background:#fcfaea;}
            &.esim-ch {background:#f8f4ff;}
            &.esim-plan {background:#f6f2ec;}
            &.phne-all {background:#fcfaea}
            &.phne-ch {background:#f5f8ff;}
            &.phne-plan {background:#f6f2ec;}
        }
    }

    //신청불가 요금제
    .payment-unable{
        .box-tab {margin:0 0 10px;}
        .popup-body {
            margin-top:0 !important; overflow-y: hidden !important;
            .page-desc {margin-bottom: 50px;@include mainTxt;}
            .tab-btn-wrap {margin-bottom:50px;}
            .search-input-wrap{width:100%; margin-bottom:50px;}
            .guide {display:block;margin-bottom:20px;@include defaultTxt;text-align:right;}
            .tab-contents-wrap {
                .tab-h{
                     overflow-y: auto !important;
                     max-height: 265px;
                    &::-webkit-scrollbar{
                        width: 10px;
                    }
                    &::-webkit-scrollbar-thumb{
                        height: 40%;
                        background-color: rgba(221,221,221,1);
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track{
                        background-color:rgba(221,221,221,0.4);
                    }
                }
            }
            .payment-list-card-wrap {
                .card-header {
                    display: flex; justify-content: space-between; margin-bottom:10px;
                    .pay-amount {
                        padding-top: 0;
                        .undeter-pay{color: $purple;}
                    }
                }
                .payment-list-card {
                    &+.payment-list-card {margin-top:20px;}
                    &>.pay-amount {
                        margin-top:24px;padding-top:0;
                        .undeter-pay {color: $purple;}
                        &.type-item {
                            display:flex;justify-content:space-between;@include smallTxt;
                            &+.type-item {margin-top:15px;}
                        }
                    }
                }
                .notification {margin-top: 30px;}
            }
        }
    }
    //요금조회 결과보기
    .payment-result{
        .popup-header {
            .txt-main {margin-bottom: 40px;}
        }
       .page-desc{ margin-bottom: 30px;}
       .popup-body {margin-top:-10px !important;padding-top:0 !important;}
       .cardList-wrap {
            .cardList {padding-bottom:40px;}
       }
       .feature {
            margin:0;
            &>span {
                &.vol {
                    &::before {@include backgroundImg('/assets/images/pc/icon/ico-call-fill.svg', center, 24px 24px)}
                }
            }
       }
       .pop_feature{
            display: flex; flex-direction: row; justify-content: center; gap: 40px; width: 100%; margin: 20px 0 0 0; padding: 20px 0; border-radius: 10px; background: #f7f8fa; text-align: center;
            span{
                font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; color: #191919; position: relative; padding-left: 25px;
                &::before{
                    content: ''; width: 20px; height: 20px; position: absolute; left: 0; top: 50%; transform: translateY(-50%);
                }
                &.vol{
                    &::before{background: url('/assets/images/pc/icon/ico-sm-data-black.svg') no-repeat center; background-size: 20px 20px;}
                }
                &.limit{
                    &::before{background: url('/assets/images/pc/icon/ico-sm-call-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                }
                &.supply{
                    &::before{background: url('/assets/images/pc/icon/ico-sm-sms-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                }
            }
       }
       .pay-amount {padding-top:21px;}
       .box-btn {margin-top:40px;}
    }
    //요금제 상세페이지
    .payment-detail-list{
       /* .payment-accordian-card{
            .header{
                .title{
                    @include contsTitle;
                }
            }
            .pay-amount{
                padding-top: 30px;
                .discount-pay{
                    font-size:30px;
                }
            }
            .btns{margin:0 auto; display: block; margin-top: 50px;}
        }*/
        .payment-detail {
            padding:0;
            &::before {display:none;}
            .detail-conts {
                padding:60px 0;background:#f7f8fa; overflow: visible;
                .detail-major {
                    position:relative;width:1280px;margin:0 auto;
                    .major {
                        display:flex;justify-content:space-between;align-items:flex-end;
                        .detail-header {
                            display:flex;justify-content:space-between;flex-direction:column;
                            .chip-wrap {margin-bottom:20px;}
                            .tit {margin-bottom:50px;@include mainContsTitle;}
                            .feature {
                                justify-content:flex-start;gap:0 90px;
                                &>span {
                                    min-width:80px;margin:0;padding:90px 0 0;@include mainTxt;text-align:center;
                                    &::before {top:0;width:100%;height:80px;transform:none;}
                                    &.vol {
                                        &::before {@include backgroundImg('/assets/images/pc/icon/ico-data-color.svg', center top, 80px 80px)}
                                    }
                                    &.limit {
                                        &::before {@include backgroundImg('/assets/images/pc/icon/ico-ars-line.svg', center top, 80px 80px)}
                                    }
                                    &.supply {
                                        &::before {@include backgroundImg('/assets/images/pc/icon/ico-email-color.svg', center top, 80px 80px)}
                                    }
                                }
                            }
                        }
                        .detail-footer {
                            display: flex; flex-direction: column; align-items: flex-end;
                            .pay-amount {
                                margin-bottom:20px;
                                .origin-pay {@include defaultTxt;}
                                .discount-pay {@include mainContsTitle;color:$purple;font-weight:600;}
                            }
                            .btns {width:260px;}
                        }
                    }
                    .ico-share {position:static;right:0;top:43px;}
                    .banner-randombox{
                        position: static;
                        &+.pay-amount{padding-top: 5px;}
                    }
                }
                .detail-info {
                    width:1280px;margin:40px auto 0;padding-right:500px;
                    .notification {
                        &.free {
                            display:flex;flex-wrap:wrap;gap:10px 40px;
                            li {margin-top:0;}
                        }
                    }
                }
            }
        }
    }
    //요금제 - 설계하기
    .payment-design-list {
        .products-radio-box {
            margin-bottom:50px;
            .group-tit {
                display:inline-block;margin-bottom:20px;padding-right:30px;@include mainTxt;
                @include backgroundImg('/assets/images/pc/icon/ico-check.svg', right center, 20px 20px);
                &.on {@include backgroundImg('/assets/images/pc/icon/ico-purple-check.svg', right center, 20px 20px);}
                /*.number{
                    display: inline-block; width: 30px; height:30px; background-color: $purple; border-radius: 50%; text-align: center; @include defaultTxt; color: $white;line-height:30px; margin-right: 10px;
                }*/
            }
        }
        .data-range-box {
            padding:40px 90px;border:1px solid $gray02;border-radius:10px;
        }
    }
    //요금제 - 설계하기(range slide)
    .data-range-bar {
        padding:0 40px;
        input {width:100%;}
        .irs-min,
        .irs-max {display:none;}
        .irs-single {
            left:50% !important;top:-15px;@include mainTxt;background:none;transition:0.3s;
            &:before {display:none;}
        }
        .irs-bar {background:$purple;}
        .irs-handle {border:4px solid $purple;}
        .irs-grid-text {bottom:-14px;@include defaultTxt;}
        .irs-grid-pol {display:none;}
    }

    //생활구독서비스
    .banner-b .banner-wrap .left .title {letter-spacing: -0.1em;}
    .life-service {
        .white {margin-top:50px;}
        .is-filter-wrap {margin-bottom:50px;}
        .life-list-wrap {
            display:flex;flex-wrap:wrap;gap:30px;padding-bottom:40px;
            .life-list {
                width:406px;margin-bottom:40px;padding-bottom:0;
                .life-list-img {
                    height:250px; width:100%;  min-height:auto;
                    & > img{
                        @extend %imgSize; object-fit: cover;
                    }
                }
                figcaption {
                    display:flex;flex-wrap:wrap;gap:10px 0;align-items:center;
                    .chip-wrap {
                        display:flex;justify-self:flex-start;gap:5px;
                        .chip{
                            &:first-child{
                                background-color: #b728bc;
                            }
                            &:nth-child(2){
                                background-color: #5257a1;
                            }
                        }
                    }
                    .life-tit {display: block; width:100%;@include mainTxt;text-align:left;}
                    .life-info {
                        display:inline-flex;flex:1 1 60%;align-items:baseline;@include defaultTxt;color:$black;
                        em {display:inline-block;margin-left:10px;font-weight:600;}
                    }
                    .life-pay {flex:1 1 36%;align-items:baseline;@include subContsTitle;color:$purple;text-align:right;}
                }
            }
        }
        .box-paging {margin-top:0;}
    }
    //생활구독서비스 - 상세
    .life-detail {
        .life-list-wrap {
            .life-list {
               /*display:flex;gap:50px;padding-bottom:70px;border-bottom:1px solid $gray02;
                .life-list-img {width:500px;}
                figcaption {
                    position:relative;width:calc(100% - 550px);margin-top:0;
                    .life-list-text {
                        &+.life-list-text {margin-top:10px}
                        &.tit {
                            dt {@include contsTitle;}
                            dd {@include contsTitle;color:$purple;}
                        }
                        &.desc {
                            dt {@include defaultTxt;}
                            dd {@include defaultTxt;}
                        }
                    }
                    .btn-group {justify-content:flex-start;position:absolute;left:0;bottom:0;}
                }*/
                display:flex;padding:60px 0;
                .life-list-img {
                    width:500px;text-align:center;
                    img {display:inline-block;max-width:268px;}
                }
                figcaption {
                    width:calc(100% - 500px);
                    .chip-wrap {
                        display:block;margin-bottom:20px;
                        span {border-radius:5px;;}
                    }
                    .life-tit {display:block;margin-bottom:20px;@include mainContsTitle;}
                    .life-info {
                        display:block;@include mainTxt;font-weight:normal;color:$black;
                        em {display:inline-block;margin-left:10px;font-weight:600;}
                    }
                    .life-pay {display:block;@include mainContsTitle;color:$purple;text-align:right;}
                    .btn-group {
                        justify-content:flex-end;margin-top:30px;
                        .type-ing {background:#c5b4dd;}
                    }
                }
            }
        }
        .detail-sec-desc {
            .btns {
                &.line {margin-top:20px;}
            }
        }
        .fix-unit-group {
            .box-btn {margin-top:20px;}
        }
    }
    //생활구독서비스 - 이용약관
    .subscribe-agree {
        .cardList-wrap {
            &+.notification {
                &>li {margin-top:20px;}
            }
        }
        .btn-group {margin-top:50px;}
    }

    //생활구독서비스 - 구독신청
    .subscribe-phone-adress {
        .rq-form {
            padding-bottom:0;border-bottom:none;
            .main-txt-group {
                margin-top:80px;
                &:first-of-type {margin-top:0;}
            }
        }
        .btn-group {margin-top:50px;}
    }
    .subscribe-completed {
        /*.my-month {
            &.type-charge {
                &+.btn-text-line {margin-top:20px;}
            }
        }*/
        .cardList-wrap {
            &~.cardList-wrap {margin-top:80px}
            .cardList-title {margin-bottom:30px;}
            .cardList {
                display:flex;justify-content:flex-start;align-items:baseline;
                .title {width:200px;}
                strong {
                    margin:0;
                    &>span {@include mainTxt;color:$gray05;font-weight:normal;}
                }
            }
        }
        .notification {margin-top:20px;}
        .notification-wrap {margin-top:80px;}
        .btn-check {
           &.btn-text-line {margin-top:30px;}
        }
        .btn-group {margin-top:50px;}
    }
    //주소찾기
    .subscribe-adress {
        .box-flex {
            margin-bottom:30px;padding-top:0;
            .box-input {flex:1 1 calc(100% - 140px);}
            .box-btn {flex:1 1 120px;}
        }
        .ligt-gray-box {
            width:100%;background: $gray01;margin-bottom:40px;padding:30px 40px;box-sizing:border-box;border-radius:7px;
            h3 {margin-bottom:10px;@include normalTxt;}
            .desc {margin-bottom:20px;@include defaultTxt;color:$black;}
        }
        .table-defult {
            margin-top:30px;
            td {
                padding:16px 30px;
                .adress-list {
                    li {@include defaultTxt;text-align:left;}
                }
            }
        }
        .adress-step02 {
            .page-tit {
                margin-bottom:20px;
                h1 {@include mainTxt;}
            }
        }
        .adress-step02-title {
            display:flex;justify-content:space-between;align-items:center;margin-bottom:10px;
            span {@include normalTxt;}
            a {width:40px;height:40px;background: #ffdede;font-size:0;}
        }
        .box-select {margin-top:40px;}
        .self-write-wrap {
            width:100%;margin-top:30px;display: none;
            textarea {
                width:100%;padding:30px;box-sizing:border-box;border:1px solid #ddd;border-radius:7px;
                @include normalTxt;
                &::placeholder {@include normalTxt;}
            }
            p {
                 margin-top:10px;@include smallTxt;text-align:right;
                strong {color:$black;font-weight:inherit;font-size:inherit;}
            }
        }
    }
    //결제하기
    .subscribe-payment {
        .unit-tit-m {
            &~.unit-tit-m {margin-top:50px;}
        }
        .unit-tit-s {margin-top:80px}
        .cardList-wrap {
            &~.cardList-wrap {margin-top:80px}
            .cardList-title {margin-bottom:30px;}
            .cardList {
                display:flex;justify-content:flex-start;align-items:baseline;
                .title {width:200px;}
                strong {
                    margin:0;
                    &>span {@include mainTxt;color:$gray05;font-weight:normal;}
                }
            }
        }
        .notification {margin-top:20px;}
        .btn-check {margin-top:20px;}
        .btn-group {margin-top:50px;}
    }
    //구독신청 완료
    /*.subscribe-completed {
        &.full-popup-conts{padding-bottom:80px;}
        .detail-sec{ margin-top:80px;}
    }*/
    //통신부가서비스 - 인트로
    .addition-intro {
        .is-filter-wrap {
            margin-bottom:50px;
            .is-filter {
                .is-filter-list {flex-wrap:wrap;gap:20px;overflow-x:hidden;}
            }
        }
        .cardList-wrap {
            display:flex;flex-wrap:wrap;gap:30px;
            &>li {
                flex:0 1 406px;overflow:hidden;padding-bottom:40px;
                .cardList {
                    height:250px;padding:40px;
                    dt {
                        display:flex;justify-content:space-between;gap:0 10px;width:100%;margin-bottom:10px;
                        .tit {
                            flex:1;@include mainTxt;}
                        .store {
                            display:flex;justify-content:flex-end;gap:0 10px;
                            img {width:24px;height:24px;}
                        }
                    }
                    dd {
                        display:flex;justify-content:space-between;width:100%; gap:10px;
                        .txt {
                            width:calc(100% - 110px); position: relative; min-height:100px;
                            & > p{
                                @include defaultTxt; color:$black;
                            }
                            .status{
                                position:absolute; bottom:0; left:0; display: block; @include defaultTxt; color:$purple;
                            }
                        }
                        .img {
                            width:100px;  height:100px; overflow: hidden; box-sizing:border-box;text-align:right; border-radius: 7px; margin-top: 36px;
                            img {width:100%;display: block; }
                        }
                    }
                }
                .cardList-btm {
                    display:flex;justify-content:space-between;margin-top:20px;
                    .chip-wrap {
                        .chip {border-radius:5px;}
                    }
                    .charge {
                        @include subContsTitle;color:$purple;
                        span {@include defaultTxt;color:$black;}
                    }
                    .discount-pay{
                        font-size: 24px; font-weight: 600; line-height: normal; letter-spacing: -0.4px; color: #662d91;
                        & > span{color: #666; font-size: 16px; font-weight: 600; line-height: normal; letter-spacing: -0.3px; margin-left: 10px;}
                    }
                }
            }

        }
        /*.cardList-wrap {
            display:flex;flex-wrap:wrap;gap:20px;
            .cardList {
                position:relative;width:413px;border:1px solid $gray02;border-radius:7px;
                .chip-wrap {margin-bottom:20px;}
                .service-desc {
                    display: block;width:100%;
                    dt {
                        display:flex;justify-content:space-between;align-items:flex-start;width:100%;min-height:78px;padding-bottom:30px;border-bottom:1px solid $gray02;
                        .tit {
                            @include mainTxt;
                            span {display:block;margin-top:5px;@include defaultTxt;}
                        }
                        .charge {
                            @include mainTxt;color:$purple;
                            span {display:block;margin-top:5px;@include defaultTxt;text-align:right;}
                        }
                    }
                    dd {
                        width:100%;padding-top:30px;
                        .box-use {
                            display:flex;justify-content:space-between;margin-top:30px;
                            .store {
                                img {margin-left:16px;}
                            }
                        }
                        .box-btn {
                            display:flex;margin-top:30px;
                            button, a {flex:1 1 40%;}
                        }
                    }
                }
                .state {position:absolute;right:40px;top:40px;@include mainTxt;text-align:right;}
            }
        }*/
    }
    // 서비스 상세 공통
    .service-product {
        .service-top {
            margin-bottom:70px;
            .wrap {display:flex;gap:0 70px;padding:60px 0;}
            .img {
                display:flex;justify-content:center;align-items:center;overflow:hidden;width:500px;height:240px;
                img {/*width:228px;*/height:228px;}
            }
            .summary {
                width:530px;box-sizing:border-box;
                .chip-wrap {
                    gap:0 5px;margin-bottom:20px;
                    .chip {border-radius:5px;}
                }
                .service-wrap {
                    display:flex;justify-content:space-between;flex-direction:column;height:100%;
                    .service-desc {
                        display: block;width:100%;
                        dt {
                            margin-bottom:20px;
                            .tit {
                                @include mainContsTitle;
                                span {display:block;margin-top:5px;@include defaultTxt;}
                            }
                        }
                        dd {
                            .txt {margin-bottom:10px;@include mainTxt;font-weight:normal;}
                            .store {
                                margin-top:30px;
                                img {margin-right:16px;}
                            }
                            .info {
                                margin-top:40px;
                                strong {display:block;margin-bottom:5px;@include normalTxt;}
                                p {display:block;@include defaultTxt;}
                            }
                            .btn {
                                display:flex;gap:0 10px;margin-top:20px;
                                .btn-store {
                                    display:inline-flex;justify-content:center;align-items:center;width:260px;padding:9px;border:1px solid $gray02;border-radius:7px;background:$white;
                                    @include normalTxt;
                                    img {margin-right:5px;}
                                }

                            }
                        }
                    }
                    .charge {
                        display:block;@include mainContsTitle;color:$purple;text-align:right;
                        span {margin-left:10px;@include mainTxt;color:$gray05;font-weight:normal;}
                        .box-btn {
                            margin-top:20px;
                            .type-ing {background:#c5b4dd;}
                        }
                    }
                }
            }
        }
    }
    .customer-review{
        iframe{
            max-width:1280px !important;
        }
        .page-tit{
            &:nth-of-type(2){
                margin-top: 70px;
            }
        }
    }
    //휴대폰 분실/파손 보험
    .breakage-list {
        .cardList {
            .col-line {
                display:flex;justify-content:flex-start;margin:0 -40px;
                &>li {position:relative;padding:0 40px;text-align:center;}
                &-02,
                &-03 {
                    display:flex;justify-content:flex-start;margin:0 -40px;
                    &>li {
                        position:relative;padding:0 40px;text-align:center;
                        &+li {
                            &::before {content:'';display:block;position:absolute;left:0;top:0;width:1px;height:87px;background:$gray02;}
                        }
                    }
                }
                &-02 {
                    &>li {width:50%;}
                }
                &-03 {
                    &>li {width:33.3%;}
                }
            }
            &.reverse {
                dl {
                    dt {width:57px;padding-bottom:0;}
                    dd {width:calc(50% - 57px);padding-bottom:0;}
                }
            }
            img {width:80px;height:80px;}
            b {margin:10px 0 0;}
            p {margin:10px 0 0 !important;line-height:1.19;}
            a, button {margin:10px 0 0;@include smallTxt;background-size:15px 15px;}
        }
        .join {
            &-way {
                .tel {@include mainTxt;}
                .btns {margin-top:0 !important;}
                &+.box-select {margin-top:70px;}
            }
            &-reward {
                .notification-free {
                   .tit {display:block;margin-bottom:10px;@include mainTxt;}
                }
            }
        }
        .ico-txt-cardList {
            &>li {
                p {
                    span {margin-top:20px;}
                }
            }
        }
        .insurance {
            margin-top:10px;
            .box-table {
                .table-type-04 {
                    th, td {text-align:center;}
                    tbody {
                        th {border-bottom:1px solid $gray02;background:none;padding:0 30px;@include normalTxt;font-weight:normal;text-align:left;}
                        td {@include defaultTxt;}
                    }
                }
            }
        }
        .box-faq {
            display:flex;justify-content:space-between;align-items:center;gap:0 40px;margin-top:70px;padding:40px;border:1px solid $gray02;border-radius:7px;
            .tit {display:block;@include mainTxt;}
            a {margin-top:10px;@include smallTxt;}
            img {width:80px;height:80px;}
        }
        .product-guide {
            padding:40px;border:1px solid $gray02;border-radius:10px;
            &>strong {
                display:block;@include subContsTitle;text-align:center;
                &>span {display:block;margin-top:10px;@include mainTxt;}
            }
            &>ul {
                display:flex;justify-content:space-between;margin-top:30px;
                &>li {
                    width:25%;text-align:center;
                    img {width:80px;height:80px;margin-bottom:20px;}
                    strong {display:block;@include normalTxt;}
                    span {display:block;margin-top:10px;@include defaultTxt;}
                }
            }
        }
        .recommend-target {
            display:flex;justify-content:space-between;align-items:center;padding:0 40px;border:1px solid $gray02;border-radius:10px;
            ul {
                display:flex;justify-content:flex-start;align-items:center;gap:0 40px;
                li {
                    p {padding-left:22px;@include backgroundImg('/assets/images/pc/icon/ico-black-check.svg', left center, 16px 16px);@include defaultTxt;}
                }
            }
            img {width:231px;}
        }
        .num-process-wrap {
            &+.notification {margin-top:10px;}
        }
        //공통유닛 예외처리
        .unit-wrap {margin-top:-40px;}
        .accordian{
            .unit-tit-m{margin: 0; text-align: left;}
            & >.acc-trigger{
                &::after{left: 160px}
            }
        }
        .unit-origin {
            .unit-tit-s {margin-bottom:10px;}
            .unit-tit-b {margin:50px 0 10px 0;}
           .unit-tit-m.txt-dt {
                position: relative;padding-left:10px;
                &::before {content:'';width:3px;height:3px;background: #191919;border-radius: 50%;position: absolute;top:9px;left:0;}
           }
           .link-btn {           
                display: flex; align-items: center; width: 330px; height:58px; padding:19px 0 20px 30px; border-radius: 10px; border:1px solid $gray02; gap:20px; background-color: $white;
                .link-tit{
                   @include normalTxt;
                }
                .ico-link {margin-top:3px;}
            }
            .product-guide + .box-select {margin-top:70px;}
            .accordian {
                margin-top:50px;
                .acc-trigger::after {left:auto; right:0;}
                .acc-conts + .acc-trigger {margin-top:50px;}
                .unit-tit-b {margin:0;text-align: left;}
            }            
            .img-list {
                display:flex; flex-wrap:wrap; justify-content: space-between; gap:30px;
                & > li {
                    display:flex;justify-content:flex-start;align-items:center;flex:1 1 40%; border:1px solid $gray02; padding:40px;  border-radius:7px;
                    .img {display:block;width:80px;height:80px;margin-right:20px;}
                    .info {
                        .tit {@include mainTxt;}
                        .txt {
                            margin:10px 0 0;font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;
                            span {font-weight:normal;color:#666;}
                        }
                    }
                }
            }
            .ico-txt-cardList>li  {
                padding:37px;
                p {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
            }
        }
    }
    .breakage-insurance-step02 {
        .rq-form {border-bottom:none;}
        .notification {margin-top:50px;}
        .btn-group {margin-top:50px;}
    }
    .breakage-insurance-step03 {
        .rq-form {
            margin-top:80px;padding-bottom:0;border-bottom:none;
            .box-input {
                &.group {
                    .input-wrap {width:340px;}
                }
            }
            .box-input-wrap {
                .box-input {max-width:340px;}
            }
            .txt {display:block;width:100%;margin-top:10px;@include normalTxt01;}
            .btn-text-right-s {margin-top:10px;}
        }

    }
    .breakage-insurance-step04 {
        h2 {margin-bottom:30px;@include mainTxt;}
        h3 {margin-bottom:20px;@include normalTxt;}
        .box-table {
            margin-bottom:80px;
            .table-type-04 {
                th {text-align:center;}
                td {padding:30px 40px;text-align:center;}
                .btn-check {
                    label {
                        font-size:0;
                        .ico-check {padding-left:0;}
                    }
                }
                .btn-radio-type2 {
                    width:24px;margin:0 auto;
                    label {
                        font-size:0;
                        .ico-radio {margin-right:0;}
                    }
                }
            }
        }
        .agree-wrap {
            margin:0;
            .btn-text-line {
                display: grid; justify-content:flex-start;gap:20px;margin:0;
                .chk-bx{
                    display: flex;align-items: center;                   
                }
            }
        }
        .btn-group {margin-top:50px;}
    }
    .breakage-insurance-finish {
        .page-desc {
            margin-bottom:70px;
            h2 {@include subContsTitle;}
            p {display:block;margin-top:20px;@include normalTxt01;}
        }
        .cardList-wrap {
            .cardList-title {margin-bottom:30px;}
        }
        .btn-group {margin-top:50px;}
    }
    .breakage-receipt {
        .unit-tit-m {
            &:first-of-type {margin-top:0;}
        }
        .unit-img {margin-top:-40px;}
    }
    .identification-simple {
        .rq-form {
            margin-bottom:0;padding-bottom:0;border-bottom:none;
            .box-input {
                &:first-of-type {margin-top:0;}
            }
        }
    }
    .identification-page {
        .page-desc {
            margin-bottom:10px;
            h2 {margin-bottom:40px; font-size:24px;font-weight: 600;}
            p {font-size:20px;color:#191919;font-weight: 600;}
        }
        /*.appli-identification {
            display:flex;gap:0 20px;
            .identifi-box {
                flex:1 0 30%;margin:0;
                .ico-list {
                    justify-content:center;gap:8px;height:80px;margin-bottom:40px;
                    li {
                        display:flex;align-items:center;
                        img {width:52px;height:52px;}
                    }
                }
            }
        }*/
    }

    // 부가서비스 신청/해지
    .addition-service-pop {
        .cardList-wrap {
            .cardList-title {
                margin-bottom:30px; @include mainTxt;
                span{
                    @include mainTxt;
                }
            }
            .box-select{
                margin-bottom: 20px; width:100%;
                .select-default{
                    .current{
                        @include normalTxt01;
                    }
                }
            }
        }
        .appli-result {
            h2 {
                margin-bottom:30px;@include mainTxt;
                span {@extend %fontInherit}
            }
            .u-cube-msg {display:flex;justify-content:center;align-items:center;min-height:197px;padding:40px;border:1px solid $gray02;border-radius:10px;}
        }
        .notification-wrap {
            h3, h4 {@include normalTxt;}
            margin-top:40px;
        }
        .btn-group{
            margin-top: 50px;
        }
        //착신전환
        &.add-forwarding {
            .popup-body {padding-bottom:10px;}
            .top-infobox {margin-bottom:50px;}
            .cardList-wrap {
                .cardList-title {
                    span {@extend %fontInherit}
                }
            }
            .radio-group-wrap {
                margin-top:40px;
                .group-tit {
                    span {margin-left:5px;}
                }
                .btn-radio {
                    label {
                        span {
                            &+span {margin-left:10px;}
                        }
                    }
                }
                &.type-col02 {
                    .btn-radio-group {
                        .btn-radio {
                            flex-grow:1;
                            label {width:100%;padding:0 10px;text-align:center;}
                        }
                    }
                }
            }
            .box-input {margin-top:40px;}
            .identification {
                padding:100px 0;
                &>p {
                    .img {
                        display:block;margin-bottom:50px;text-align:center;
                        img {width:120px;height:120px;}
                    }
                    &>strong {
                        display:block;@include mainTxt;text-align:center;
                        &+span {margin-top:10px;}
                    }
                    span {display:block;@include defaultTxt;text-align:center;}
                }
                .box-btn {margin-top:50px;text-align:center;}
            }
            .notification-wrap {
                h3, h4 {@include mainTxt;}
            }
        }
    }
    .addition-service-pop-zero{
        .cardList-wrap {
            .cardList-title {
                margin-bottom:30px; @include mainTxt;
                span{
                    @include mainTxt;
                }
            }
            .box-select{
                margin-bottom: 20px; width:100%;
                .select-default{
                    .current{
                        @include normalTxt01;
                    }
                }
            }
        }
       
        .radio-group-wrap{
            .bang-notice{
                margin-top: 10px;
            }
        }
        .notification-wrap {
            h3, h4 {@include normalTxt;}
            margin-top:40px;
        }
        .time-select-wrap{
            margin-top: 40px;
            .group-tit{
                @include normalTxt;
            }
             .flex-box{
                display: flex; gap:20px;
            }
            .box-input, .box-select{
                margin-top: 16px;
            }
            .box-select{
                width:230px;
            }
            .box-input{
                label{
                    width:310px;
                }
            }

        }
        .w-wide{
            width:100%;
        }
        .btn-group{
            margin-top: 50px;
        }
    }
    //이용안내 - (공통)이미지&설명 리스트
    .img-txt-method {
        &>li {
            display:flex;justify-content:space-between;align-items:center;padding:50px 120px;border-top:1px solid $gray02;
            .method {
                &>strong {@include contsTitle;}
                &>p {
                    display:block;margin-top:10px;@include defaultTxt;font-size:20px;
                    &+.link-box {display:block;margin-top:10px !important;}
                }
                .notification {
                    margin-top:30px;
                    &>li {
                        display:flex;justify-content:flex-start;align-items:flex-end;gap:0 30px;
                        a, button {margin-bottom:3px;}
                    }
                }
                .btn-text-right {@include smallTxt;}
            }
        }

    }
    //이용안내 - pc 버전
    .img-txt-method.pc{
        & > li{
            padding: 50px 0px; gap:100px;
            &:first-child{
                gap:80px;
            }
            &:nth-child(even){
                padding-right:40px;
            }
            &:nth-child(odd){
                padding-left:40px;
            }
            .img{
                width:620px; flex:1;
                img{
                    display: block; width: 100%;
                }
            }
            .method{
                flex:1;
                .link{
                    margin-top: 10px;
                    li{
                        margin-top: 10px;
                    }
                }
            }
        }
    }


    .method-instruction {
        &>li {
            &+li {margin-top:30px;}
            &>p {@include defaultTxt;color:$black;}
            .img {margin-top:10px;font-size:0;}
            .link {display:flex;justify-content:flex-start;gap:20px;margin-top:10px;}
        }
    }

    //이용안내 - 셀프 개통
    .use-self-opening {
        .page-tit {
            &+.banner {margin-bottom:70px;}
        }
        .bnr-beige{
            .right{
                .img{
                    top:-61px; width: 184px;
                }
            }
        }
        .unit-box {
            margin-top:30px;padding:40px;border: 1px solid $gray02;border-radius:10px;
            .unit-tit-s {margin-bottom:10px;}
            .link-box {display:block;margin-top:20px !important;}
        }

    }
    //이용안내 - 바로픽업
    .use-pickup {
        .page-tit {
            &+.banner {margin-bottom:70px;}
        }
        .banner{
            .right{
                .img{
                    top:-54px;
                }
            }
        }
    }
     // 이용안내 - 배송안내
     .use-shipping-info {
        .page-tit {
            &+.banner {margin-bottom:70px;}
        }
        .num-process-wrap {
            li {
                .txt-wrap {
                    .img {
                        display:block;margin-bottom:10px;text-align:center;
                        img {width:80px;height:80px;}
                    }
                    strong {margin-bottom:10px;}
                }
            }
        }
        .cardList-wrap {
            &+.notification {margin-top:10px;}
        }
        .img-txt-method {margin-top:70px;}
        .method {
            p {
                &+.notification {margin-top:10px;}
            }
            .link-box {
                &+.notification {margin-top:30px;}
            }
        }
    }
    // 이용안내 - 휴대폰 정보
    .use-phone-info{
        .intro-banner{
            margin-left:328px; position: relative;
            .img{
                margin-bottom: -5px;
            }
            .unit-txt{
                position:absolute; left:50%; top:50%; transform: translate(-50%,-50%); @include normalTxt; margin-left: -40px;
            }
        }
        .info-reg-benefit {
           border: 1px solid $gray02;border-radius:10px; padding: 40px 60px; margin-top: 10px;
           .unit-tit-m{
            text-align: center; margin: 0;
           }
            .flex-box{
                display: flex; margin-top: 40px;
                li{
                    width: 300px;
                    .img{
                        width: 80px; height: 80px; margin:0 auto;
                    }
                    & > p{
                        text-align: center; margin-top: 10px; @include defaultTxt;
                    }
                }
            }
        }
    }
    //제휴카드 할인
    .use-card{
        //목록
        &-list {
            .page-tit {
                &+.banner {margin-bottom:70px;}
            }
            .bnr-beige{
                .right{
                    .img{
                        width:180px;
                    }
                }
            }
            .card-list {
                display:flex;flex-wrap:wrap;gap:30px;
                li {
                    flex:0 1 625px;;
                    a {
                         display:flex;align-items:center;border:1px solid $gray02;border-radius:10px;padding:40px;transition:0.3s;
                        .card-img-wrap {
                             width:200px;
                            // img {width:180px;height:126px;}
                        }
                        .card-desc{
                            margin-left:40px; width:calc(100% - 210px);
                            strong {
                                -webkit-line-clamp:2;-webkit-box-orient:vertical;
                                @include mainTxt;
                            }
                            .bang-notice{
                                @include ellipsis3;
                            }
                        }
                        &:hover {border-color:$black;}
                    }
                }
            }
        }
        //상세
        &-detail {
            .card-thumb {
                display:flex;justify-content:flex-start;align-items:center;margin-bottom:70px;padding:24px 0;
                .card-img-wrap {
                    display:flex;justify-self:center;align-items:center;width:500px;height:340px;
                    img {border-radius:15px;box-shadow:20px 20px 40px 0 rgba(0,0,0,0.16);}
                }
                .card-info-wrap {
                    width:calc(100% - 500px);box-sizing:border-box;padding:0 80px 0 70px;
                    .card-desc {
                        strong {display:block;margin-bottom:20px;@include mainContsTitle;}
                    }
                    .box-btn {
                        margin-top:20px;text-align:right;
                        a, button {@include smallTxt;}
                    }
                    .box-table {
                        margin-top:10px;
                        table {
                            thead {
                                th {padding:20px 40px;text-align:left;}
                            }
                            tbody {
                                th {background:none;font-weight:normal;border-bottom:1px solid $gray02;}
                                td {color:$gray05;}
                            }
                        }
                    }
                }
            }
        }
        //카드혜택 한눈에 보기
        &-benefit{
            .card-benefit-list {
                li {
                    &+li {margin-top:50px;}
                    .card-thumb {
                        display:flex;justify-content:flex-start;align-items:center;gap:0 30px;margin-bottom:30px;
                        .card-img-wrap {width:120px;}
                        .card-info-wrap {
                            width:calc(100% - 120px);
                            .card-desc {
                                strong {@include mainTxt}
                            }
                        }
                    }
                    .box-table {
                        table {
                            tbody {
                                th {background:none;font-weight:normal;border-bottom:1px solid $gray02;}
                                td {color:$gray05;}
                            }
                        }
                    }
                }
            }
        }
    }
    //선택약정할인
    .use-select-discount {
        .bnr-beige{
            .right{
                .img{
                    width:168px; top:-13px;
                }
            }
        }
        .benefit-target {
            margin-top:-40px;padding-left:370px;
            &>p {
                height:240px;padding:143px 0 0 226px;
                @include backgroundImg('/assets/images/pc/subpage/img-select-discount-customer.png', left top, 538px 240px);
                strong {@include mainTxt;color:$purple;}
            }
        }
        .target-info {
            display:flex;flex-wrap:wrap;gap:0 20px;
            &>li {
                display:flex;justify-content:space-between;width:630px;padding:40px;border:1px solid $gray02;border-radius:10px;
                .info {
                    width:342px;
                    strong {@include mainTxt;}
                    p {margin-top:10px;@include defaultTxt;color:$black;}
                    .notification,
                    .link-box {display:block;margin-top:10px !important;}
                }
                .img {display:flex;align-items:center; width:136px; height: 116px; margin-top: 17px;}
            }
        }
        .discount-box-img {
            font-size:0; margin-top: 10px;
            &+.notification {margin-top:10px;}
        }
        .box-table {
            margin-top:-20px;
            table {
                margin-top:10px;
                th, td {text-align:center;}
            }
        }
    }
    
    .fix-unit-wide .page-tit {margin-bottom:40px;}
    .page-tit.plan-tit {margin-bottom:40px;}
    .sub-conts .box-tab.tab-ctrl .tab-btn-wrap {margin-bottom:40px;}

    .banner-b.bnr-sky {
        .banner-wrap {
            .right {
                .roming-img {
                    height:100%; vertical-align: middle;
                    img {height:170px;}
                }                
            }   
        }
    }

    .banner-b.basic {
        width:1280px;margin:0 auto 50px;border-radius: 10px;
        .banner-wrap {
            height:100px;
            .left {
                padding-left:40px;
                .desc { font-size: 24px; line-height: 1.21; letter-spacing: -0.48px; text-align: left; color: #191919;}
                .desc .txt-b {font-weight:600;font-size: 24px;color:#191919}
            }
            .right {
                    .img {height:170px;margin:-70px 40px 0 0;}
                    .img img {height:100%;}
            }
        }
        
    }


    .recmend-fee {
        min-height:300px;margin-top:50px;
        .fee-list {
            display: flex; flex-wrap: wrap; gap: 30px;
            &>li {position:relative;visibility:hidden; width:625px; min-height:257px;top:40px;opacity: 0; transition: all .4s; background:#fff;}
            &>li.show {visibility: visible; opacity: 1;top:0;}
            &>li a {display:block;width:100%;height:100%;padding:40px 40px 30px 40px;border:1px solid #ddd;border-radius: 10px;box-sizing: border-box;transition:all .3s;}
            &>li a:hover {border-color:#662d91;}

            li{
                .fee-tit {display:block;min-height:54px; font-size: 20px; font-weight: 600;line-height: 1.2; letter-spacing: -0.4px;text-align: left;color: #191919;}
                .bge {display: flex;gap:5px; margin-top:10px; align-items: center;}
                .pay-amount {
                    position:relative; display: flex;padding-top: 20px;align-items: flex-end; justify-content: flex-end; gap: 10px;
                    .origin-pay { font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: right; color: #959595; text-decoration: line-through;}
                    .discount-pay {font-size: 24px; font-weight: 600; line-height: 1.21; letter-spacing: -0.48px; text-align: right;color: #662d91;}
                    .txt-limited {position:absolute;right:0; bottom:-18px; font-size: 14px; line-height: 1.14; letter-spacing: -0.28px;  text-align: right;color: #959595;}
                }                
                .feature {
                    display: flex; flex-direction: row; justify-content: center;gap:40px;width:100%;margin:30px 0 0 0;padding:20px 0;border-radius: 10px;background:#f7f8fa;text-align:center;
                    span {font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;color: #191919;}
                    span.vol::before {background: url('/assets/images/pc/icon/ico-sm-data-black.svg') no-repeat center; background-size: 20px 20px;}
                    span.limit::before {background: url('/assets/images/pc/icon/ico-sm-call-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                    span.supply::before {background: url('/assets/images/pc/icon/ico-sm-sms-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                }
                .notification {margin-top:20px;}
                .notification li {font-size: 14px; letter-spacing: -0.28px;text-align: left;color: #959595;}
                .notification li::before {background:#959595;}
                .compare-box {
                    position:absolute;right:0;top:0;border:1px solid #ddd; border-radius: 0px 8px 0px 10px; text-align:center;background:#fff;
                    span {
                        position: relative; display:block;width:102px;height:40px;padding-left:18px; font-size: 14px;font-weight: 600;line-height: 40px;letter-spacing: -0.28px;text-align: left;color: #662d91;
                        &::after {content:''; position:absolute;right:18px;top:50%;width:12px;height:12px;margin-top:-6px; background: url('/assets/images/pc/icon/icon-plus.png') no-repeat center;}
                    }
                    button.checked {
                       span {
                        color:#bbb;
                        &::after {background: url('/assets/images/pc/icon/icon-plus-gray.png') no-repeat center;}
                    }
                    }
                }
            }
            &.acc-pay-list{
                & > li{
                    visibility: visible; opacity: 1; top: 0; height: auto;
                    .mark{position: absolute; top: 0; left: 0;}
                    .bge{
                        img{height: 20px;}
                    }
                }
            }
        }
    }

    .bottom-layer-pop{
        position: fixed; left: 0; bottom: 0; z-index: 101; width: 100%; background-color: $white; border-top: 1px solid $purple;
        .bottom-container{
            max-width: 1280px; margin: 0 auto;
        }
        &.on{
            .bottom-pop-btn{
                .ico-pop-btn {
                    transform: translateX(-50%) rotate(0deg);
                }
            }
        }
        .bottom-pop-btn{
            position: absolute; top: -20px; left: 50%; transform: translateX(-50%); width: 130px; height: 20px; background-color: $white; border-top: 1px solid $purple; border-left: 1px solid $purple;border-right: 1px solid $purple; border-radius: 20px 20px 0 0;

            .ico-pop-btn{
                display: block; position: absolute;top: 50%;left: 50%; width: 20px; height: 20px; font-size: 0; text-indent: -9999px; transform: translateX(-50%) rotate(180deg); transition: transform 0.3s;
                @include backgroundImg('/assets/images/pc/icon/ico-arrow-purple-type2.svg',center, contain);
            }
        }
        .show-conts{
            display: flex; justify-content: space-between; align-items: center;  padding: 25px 0 25px;     
            .tite {
                display: block; font-size: 20px;font-weight: bold;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;
                .cunt {display:inline-block;margin-left:8px;font-size: 20px;font-weight: bold;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #662d91;}
            }            
        }              
        .compare-cont {
            display:none;padding-bottom:40px;
            .compare-inner {
                display: flex;gap:30px;align-items: center;
                .compare-view {
                width: 406px;min-height: 158px;padding: 40px 30px 40px 30px;border-radius: 10px;border: solid 1px #ddd;background-color: #fff;
                    .tit {max-width:300px; min-height:48px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                    .pric {display: block;margin-top:5px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;color: #662d91; text-align: right;}
                    .compare-tit {
                        position:relative; 
                        .btn-del {position:absolute;right:0;top:0;}
                    }
                }
                .before-comparison {
                    width: 406px;height: 158px;margin: 0 0 0 30px;padding: 40px;border-radius: 10px;border: dashed 1px #ddd;background-color: #fff;
                    p {
                        padding-top:34px;font-size: 18px;line-height: 1.28;letter-spacing: -0.36px;text-align: center;color: #999;background:url('/assets/images/pc/icon/icon-plus02.png') no-repeat 50% 0;                        
                    }
                }
            }
        }   
    }

    .video-banner{
        padding:60px;background:#f9f5ff;
        &:after {content:'';display:block;clear:both;}
        .vdo-box {float:left;width:460px;height:260px;}
        .txt-box {float:left;margin:20px 0 0 70px;}
        .txt-box .txt01 { display:block; font-size: 36px; line-height: 1;letter-spacing: -0.72px;color: #191919; font-weight: 700;}
        .txt-box .txt01 span {font-size: 36px;color:#662d91;  font-weight: 700;}
        .txt-box .txt02 {display:block;margin-top:20px;font-size: 20px; line-height: 1.2; letter-spacing: -0.4px; color: #191919;}
        .txt-box button {margin-top:75px;}
    }
    .banner-wrap {
        .evt-txt {
            display: flex; margin-bottom:10px; font-size: 20px; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919; align-items: center; gap:10px; 
            .btn {display:inline-block; width:122px;height:30px;line-height:30px;  border-radius: 7px; border: solid 1px #ddd; background-color: #fff; color: #191919; font-size:14px; text-align: center; font-weight: 600;}
        }
    }

    .banner-b.breakage {
        margin-bottom:50px;
        .banner-wrap {
            height:300px;
            .left {
                 .title {margin-bottom:30px;letter-spacing: -0.72px;}
                 p {font-size: 16px; font-weight: 600; line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;}
                 .s-txt {font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                 .notification {
                    margin-top:20px;
                    li {
                        font-size: 16px;font-weight: 600; line-height: 1.2; letter-spacing: -0.4px;text-align: left;color: #191919;
                       &::before {top:6px; width:5px; height:5px; background:#191919;}
                       .txt-emp {margin-top:5px;font-size: 18px; font-weight: 600; line-height: 1.2; letter-spacing: -.4px; text-align: left;}
                    }
                    li + li {margin-top:10px;}                    
                }
            }
            .right{
                .img {
                    height:300px;
                    img {height:100%}
                }
            }
        }
    }
    .roaming-abroad {
        .is-filter-wrap {
            .is-filter {
                .is-filter-list {
                     li{
                        label {font-weight: normal;}
                     }
                }
            }
        }
        .roaming-service {
            width:1280px;margin:50px auto 0;
            .accordian {
                .acc-trigger {
                    margin-bottom:10px;
                    .unit-tit {margin:0;text-align: left;}
                }
                .acc-conts {
                    padding:0;background: none;
                    .unit-sec-tit {
                        margin:0;
                        p {margin:0; font-size: 16px; line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #666;}
                    }

                    .table-list-wrap{
                        & + .table-list-wrap{margin-top: 10px;}
                        .table-list{
                            border: 0;
                            th {
                                background-color: #eff1f5;
                                @include normalTxt;
                            }
                            td {
                                padding: 20px 0; color: $gray05;
                                .plan-link {
                                    position:relative;padding-right:30px; font-size: 20px;font-weight: 600; line-height: 1.2;  letter-spacing: -0.4px; text-align: left; color: #191919;
                                    &::after {content:''; position:absolute;right:0;top:50%;width:20px; height:20px;margin-top:-10px;background: url('/assets/images/pc/icon/ico-arrow-thin-right.svg') no-repeat 50%;background-size: 12px; }
                                }
                                &:first-child {padding-left:50px;text-align: left;}
                                .bge {
                                    margin-bottom:5px;
                                    img {
                                        height:21px;
                                        & + img {margin-left:5px;}
                                    }
                                }
                                .txt-price {display: block; font-size: 16px; font-weight: 600;line-height: 1.19;letter-spacing: -0.32px;text-align: center; color: #662d91;}
                                .txt {display:block;margin-top:3px;font-size: 14px; line-height: 1.19;letter-spacing: -0.32px;text-align: center; color: #959595;}
                                .btn {
                                    display:inline-block;width: 68px; height: 30px; line-height: 30px; border-radius: 7px; border: solid 1px #662d91;color:#662d91; font-size: 14px; font-weight: 600;  background-color: #fff;
                                    &.cancel {border-color:#191919; color:#191919;}
                                }                   
                             }                           
                        }                        
                    }
                }
            }

            .arro-rt {
                display:flex;flex-wrap:wrap;gap:20px;padding:0;border:none;
               & > li {
                    display:flex;flex:1 1 40%;border-radius:7px;border:solid 1px $gray02; padding: 0;
                    ::before {
                        content:'';display:block;position:absolute;right:40px;top:50%;width:20px;height:20px;margin-top:-10px;
                        @include backgroundImg('/assets/images/pc/icon/ico-arrow-thin-right.svg', center center, 20px 20px);
                    }
                    a {display:flex;align-items:center;position:relative;width:100%;padding:40px 60px 40px 40px;}
                    .img {display:block;width:80px;height:80px;margin-right:20px;}
                    .info {
                        .tit {@include mainTxt;}
                        .txt {margin:10px 0 0;@include defaultTxt;}
                    }
                }
                &.img-wd{
                    .img{
                        width:200px; margin-right: 10px; display: inline-flex; align-items: center;
                    }
                }
            }

            .roaming-guide {
                display: flex; width:1280px;height:180px; padding:30px 40px 30px; background:#f5f9fc;border-radius: 10px; justify-content: space-between;
                .left-cont {
                    display: flex;width:40%; align-items: center;
                    .img {margin-right:30px;}
                    p {font-size: 20px; font-weight: 600; line-height: 1.2; letter-spacing: -0.4px; text-align: left; color: #191919;}
                }
                .right-cont {
                    display: flex;gap: 10px; width:60%;text-align: right;align-items: center; flex-direction: row-reverse;
                    .link {
                        display:inline-block; width:310px;height:58px;padding:19px 0 0 30px; border-radius: 10px; border: solid 1px #ddd; text-align: left;   font-size: 16px; font-weight: 600;letter-spacing: -0.32px; color: #000; background:#fff url('/assets/images/pc/icon/ico-arrow-right.svg') no-repeat 95% 50%;
                      
                    }
                }
            }
        }   
        .roaming-guilde {
            width:1280px;margin:0 auto;
            .banner-wrap {
               height:100%;padding:60px;border-radius: 20px; background-color: #f9f5ff;
               .title {font-size: 36px; font-weight: 600;letter-spacing: normal; line-height: 1.19; text-align: left; color: #191919;}
               .desc {font-size: 20px; line-height: 1.4; letter-spacing: -0.4px; text-align: left; color: #191919;}
               .box-btn {margin-top:30px;}
               .btn-link {
                    display:inline-block; width:250px;height:58px;padding:19px 0 0 30px; border-radius: 10px; border: solid 1px #ddd; text-align: left;   font-size: 16px; font-weight: 600;letter-spacing: -0.32px; color: #000; background:#fff url('/assets/images/pc/icon/ico-arrow-right.svg') no-repeat 95% 50%;
                    & + .btn-link {margin-left:5px;}
                }
            }
            .banner-b {margin-bottom: 30px;}
            .about-service {
                margin-top:70px;
                .accordian {
                    .acc-trigger {
                        margin-bottom:0;
                        .unit-tit {margin-bottom: 0; text-align: left;}
                    }
                    .acc-conts {margin-top:50px; padding:0;background:none;}
                }
                .guide-linebox {
                    padding: 60px 40px; border-radius: 10px; border: solid 1px #ddd; background-color: #fff;
                    .img-box {display: flex; gap:30px;}
                    .title {margin-bottom:20px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
                    .txt {
                        margin-top:20px;font-size: 16px; line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #666;
                        & + .title {margin-top: 60px;}
                    }                   
                    .sb-txt {margin-bottom:20px;font-size:16px;letter-spacing: -1px;color:#191919;}
                    .notification {
                        margin-top:15px;
                        & + .title {margin-top: 60px;}
                    }
                    .guide-step {
                        display:flex; gap:150px; align-items: flex-end;
                        li {
                            position: relative;
                            p {margin-bottom:20px;font-size:16px;letter-spacing: -1px;color:#191919;}
                            &::before {content:''; position:absolute; left:-100px;bottom:300px;width:40px;height:40px;background: url('/assets/images/pc/icon/ico-arrow-thin-right02.svg') no-repeat 0 0;}
                            &:first-child::before {content:''; display:none;}
                        }
                        & + .title {margin-top: 60px;}                       
                    }

                }
            }
        }   
        .roaming-center {
            .accordian {
                .acc-conts {
                    margin-top:50px;
                    .unit-sec-tit {
                        h3 + p {margin-top:20px; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #191919;} 
                    }
                }
                .center-location {
                    display: flex; justify-content: space-between; margin-top:10px;
                    & + .unit-sec-tit {margin-top:50px;}
                }
            }
            .box-faq {
                display:flex;justify-content:space-between;align-items:center;gap:0 40px;margin-top:70px;padding:40px;border:1px solid $gray02;border-radius:7px;
                .tit {display:block;@include mainTxt;}
                a {margin-top:10px;@include smallTxt;}
                img {width:80px;height:80px;}
            }
        }  
    }

    .plan-view {
        overflow: hidden; width:1280px;height:126px;margin:0 auto 60px;
        .plan-slider {
            .slide {
                width:100%;height:126px;padding:40px 60px 0; background:#f8f4ff;border-radius: 20px;
                .plan-dtil {
                    display: flex;align-items: center; position: relative;
                    .plan-tit {overflow:hidden; display:block;width:480px; font-size: 30px;font-weight: bold;line-height: 1.2;letter-spacing: -0.6px;text-align: left;color: #191919;}
                    .spc {
                        .spc-view {
                            display: flex; flex-direction: row; justify-content: center;gap:30px;width:100%;
                            span {
                                position: relative;padding-left:30px; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;color: #191919;
                                &::before {content: ''; position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 100%;}
                            }
                            .vol::before { background: url('/assets/images/pc/icon/ico-sm-data-black.svg') no-repeat center; background-size: 20px 20px;}
                            .limit::before {background: url('/assets/images/pc/icon/ico-sm-call-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                            .supply::before {background: url('/assets/images/pc/icon/ico-sm-sms-fill-black.svg') no-repeat center; background-size: 20px 20px;}                        
                        }
                        .txt {
                            position: relative; margin-top:7px;padding-left:13px; font-size: 14px;line-height: 1.14;letter-spacing: -0.28px;text-align: left;color: #959595;
                            &::before {content: ''; position: absolute;left:0;top:6px; width:3px;height:3px;border-radius: 50%; background: #959595;}
                        }
                    }
                    .price-box {
                        position:absolute; right:0;bottom:0;display: flex; align-items: flex-end;
                        .price {display:block;margin-right:10px; text-align: right; font-size: 16px;letter-spacing: -0.32px;color: #959595;text-decoration: line-through;}
                        .dc-price {
                            display: flex;gap: 1px;align-items: center;
                            .dc-txt {display: inline-block; font-size: 36px;font-weight: bold;line-height: 1;letter-spacing: -0.72px;text-align: right;color: #662d91}
                            .won {display: inline-block;margin-top:7px; font-size:18px;font-weight: bold;color: #662d91;}
                        }
                    }
                }
                &01 { background-color: #f8f4ff;}
                &02 { background-color: #f1f7ff;}
                &03 { background-color: #f3fff9;}
            }
            .slick-dots {
                position: absolute; bottom:20px;padding:0;
                li + li {margin-left:8px;}
                button {width:8px; height:8px;background:#ddd;}                
                li.slick-active button {width:20px;height:8px; background: #191919;}
            }
        }
    }

    .tool-tip {
        min-height:58px;
        .is-filter {
            overflow: visible;float:none;
            .is-filter-list {
                overflow: visible;position: relative;                
            }
        }
       &.box-tab {position:relative;}
    }
    .special {
        overflow: visible; position: relative;       
    }
    .spec-plan {
           position:absolute;top:-48px;left:50%; padding:8px 25px;border-radius:40px;box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12); background-color: #fff; z-index: 10; 
            &:after{content: '';position: absolute;display: block;width: 0;bottom: -7px;left:50%; border-style: solid;border-width: 12px 10px 0;border-color: #fff transparent;transform: translateX(-50%)}
            &.col03 {
                padding:8px 25px 8px 10px;
            }
        ul {
            display: flex;gap:20px; align-items: center;
            li {
                img {vertical-align: middle;}
            }
        }        
    }
}

// 보험 설명서 보기 팝업
.breakage-instruction-pop{
    .fix-unit-group{
        .unit-tit-m{
            &:first-of-type{margin-top:0;}
        }
    }
}


.layer-popup{
    &.compare {
        .layer-popup-item {
            max-width:1140px;
            .popup-body  {
                overflow: visible;
            }
        }
        .compare-list-view {
            margin-top:30px;
            .call-dibs {
                position:relative;height: 100px;padding: 38px 60px 38px;border-radius: 10px;background-color: #f4f6ff;
                .img {position:absolute;left:60px;top:-20px;}
                p {
                    padding-left:120px; font-size: 20px;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;
                    strong {font-weight:bold; font-size: 20px;line-height: 1.2;color: #191919;}
                }
                .btn {
                position: absolute;top:50%;right:60px; width:180px; margin-top:-29px; padding: 19px 54.5px 19px;border-radius: 50px;background-color: #7385d8;
                    span {
                        display: block;position: relative; font-size: 16px;font-weight: 600;line-height: 1.19;letter-spacing: normal;text-align: left;color: #fff;
                        &::after {content: ''; position: absolute; right:0;top:0;width:20px;height:20px;background:url('/assets/images/pc/icon/icon-arrow-white.png') no-repeat 0 0;}
                    }
                }
            }
            .compare-list {
                overflow-y:auto; overflow-x:hidden; max-height:430px; margin-top:40px;
                &::-webkit-scrollbar{
                    width: 6px;
                }
                &::-webkit-scrollbar-thumb{
                    height: 40%;
                    background-color: rgba(221,221,221,1);
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-track{
                    background-color:rgba(221,221,221,0.4);
                }
                p {font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
                &.usim-pl{
                    .list {
                        li {min-height: 680px;}
                    }
                }
                .list {
                    display: flex; gap:25px; margin-top:15px;
                    li {
                    width:340px;min-height:378px; padding: 40px 30px;border-radius: 10px;border: solid 1px #ddd;background-color: #fff;  
                    .tite {display:block;height:50px; font-size: 20px;font-weight: 600;line-height: 1.3;letter-spacing: -0.4px;text-align: left;color: #191919;}                
                    .price-box {
                            display: flex; justify-content: space-between;align-items: center;margin-top:23px;
                            span {font-size: 16px;font-weight: 600;line-height: 1.5;letter-spacing: -0.32px;text-align: left;color: #191919;}
                            strong {font-size: 24px;font-weight: 600;line-height: 1.21;letter-spacing: -0.48px;text-align: right;color: #662d91;}
                        }
                        .feature {
                            display: flex; flex-direction: row; justify-content: center;width:100%;margin:25px 0 0 0;padding:20px 0;border-radius: 10px;background:#f7f8fa;text-align:center;
                            span {
                                display: block;width:33%; margin:0; padding:30px 0 0; font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;color: #191919;
                                &::before {content: ''; position: absolute;left:50%;top:0;width:20px;height:20px; transform: translate(-50%, 0 );}
                            }
                            span.vol::before {background: url('/assets/images/pc/icon/ico-sm-data-black.svg') no-repeat center; background-size: 20px 20px;}
                            span.limit::before {background: url('/assets/images/pc/icon/ico-sm-call-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                            span.supply::before {background: url('/assets/images/pc/icon/ico-sm-sms-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                        }
                        .btn-box {
                            margin-top:20px;
                            .btns {width:280px;}
                        }
                        .benefit-view {
                            overflow-y: auto; max-height:260px; margin-top:40px;
                            &::-webkit-scrollbar{
                                width: 8px;
                            }
                            &::-webkit-scrollbar-thumb{
                                height: 40%;
                                background-color: rgba(221,221,221,1);
                                border-radius: 10px;
                            }
                            &::-webkit-scrollbar-track{
                                background-color:rgba(221,221,221,0.4);
                            }
                            .tit {
                                display:block;font-size: 20px;font-weight: 600;letter-spacing: -0.32px;text-align: left;color: #191919;
                                span {font-size:16px;}
                            }
                            dl {
                                margin-top:20px;
                                dt { font-size: 16px;font-weight: 600;letter-spacing: -0.32px;text-align: left;color: #191919;}
                                dd {
                                    position: relative;margin-top:10px;padding-left:8px; font-size: 16px;letter-spacing: -0.32px;text-align: left;color: #666;
                                    &::before {content:''; position: absolute; left:0; top:50%;width:3px;height:3px;margin-top:-2px;border-radius: 50%; background: #666;}
                                }
                            }
                            .no-results {margin-top:20px;font-size: 16px;font-weight: 600;line-height: 1.63;letter-spacing: -0.32px;text-align: left;color: #191919;}
                        }
                    }
                }
            }
        }
    }

    .interest-plan {
        .btn-box {
            text-align: center;
            .btns {display:inline-block}
        }
        .interest-view {
            margin-top:40px;
            .box-input {
                & + .box-input {margin-top:40px;}
            }
            section {
                margin-top:40px;
                .tit {margin-bottom:10px; font-size: 20px;font-weight: 600;letter-spacing: -0.4px;text-align: left;color: #191919;}
            }
            .plan-list {                                
               .list-view {
                     width: 640px;padding: 40px;border-radius: 10px;border: solid 1px #ddd;
                    .list-bunch {
                        display: flex;
                       & + .list-bunch {margin-top:30px;}                   
                        strong {display:block;width:210px; font-size: 16px;line-height: 1.19;letter-spacing: -0.32px;text-align: left;color: #666;}
                        ul{
                            li {
                                font-size: 16px;font-weight: 600;letter-spacing: -0.32px;text-align: left;color: #191919;
                                & + li {margin-top:10px;}
                            }
                        }
                    }
                }
            }
            .btn-check {
                margin-top:15px;
                span + span {margin-left:30px;}
            }
            .agree-wrap {
                .btn-box {margin:0;}
            }
        }
    }
    &.breakage-phone.bottom-sheet-wrap {
         .layer-popup-item {
            .popup-body {
                overflow: visible;
                .box-select {
                    .nice-select {
                        .list {
                            max-height: 120px;
                        }
                    }
                }
            }
         }
    }
}

//공통요소
.life-list {
    display:block;padding-bottom:30px;
    .life-list-img {
        width:100%;border-radius:7px;box-sizing:border-box;position:relative;overflow:hidden;
        img {display:block;width:100%;height:100%;}
        .badge {position:absolute;top:30px;right:30px;}
    }
    figcaption {
        margin-top:20px;
        .life-list-text {
            display:flex;flex-wrap:wrap;margin-top:20px;align-items:center;
            dt {width:calc(100% - 220px);text-align:left;}
            dd {width:220px;text-align: right;}
            &.tit {
                dt {@include mainTxt;}
                dd {@include mainTxt;color:$purple;}
            }
            &.desc {
                dt {position:relative;@include defaultTxt;color:$black;}
                dd {@include defaultTxt;color:$black;}
            }
            &+.life-list-text {margin-top:13px;}
        }
    }
}
.search-result {
    margin-top:50px;
    @include normalTxt;
}
.detail-sec {
    .sub-conts {
        padding:70px 0 0;
        .detail-sec-tit {margin-bottom:10px;@include contsTitle;}
        .detail-sec-desc {
            h3 {margin:50px 0 10px;@include mainTxt;}
            &+.detail-sec-desc {margin-top:30px;}
        }
    }
    .btn-text-arrow {margin-top:40px;}
}

.plan-detail{
    padding-top: 70px;
    section{
        &+section{
            margin-top: 80px;
        }
        &.basic{
            .plan-detail-conts{
                .m-tt{
                    &+.m-tt{
                        margin-top: 50px;
                    }
                }
            }
        }
        .plan-detail-conts{
            display: block !important;
        }
        .b-tt{
            @include contsTitle;
        }
        .m-tt{
            margin-top: 50px;  @include mainTxt;
        }
        .notification{
            margin-top: 10px;
        }
        .txt{
            margin-top: 10px;
        }
    }
}

.layer-popup.plan-find  {
    .layer-popup-item  {
        .popup-body.plan-popup {
            max-height:100%; margin:0; padding:0 30px;
            .plan-search {
                overflow: hidden; width:640px;
                .stp-list {
                    width:640px;
                    .stp {
                        position: relative;float:left;width:640px;height:620px;color:#333;
                        .character-obj {overflow: hidden;width: 320px;height: 320px;position: absolute;left:50%;top: 0;margin-left:-160px;border-radius: 50%;transform: scale(0.48);transition:all 1.3s cubic-bezier(0.34, 1.56, 0.64, 1);background: url('/assets/images/pc/img-plan-search-obj01.png') no-repeat 0 0;animation: marche 2.8s infinite steps(4) ;
                            &::after {content:'';position: absolute;left:0;top:0;width:100%;height:100%; background: url('/assets/images/pc/img-plan-search-obj03.png') no-repeat 0 0; z-index: 10; }  
                            &::before {content:'';position: absolute;right:20px;top:70px;width:158px;height:302px; background: url('/assets/images/pc/img-plan-search-obj02.png') no-repeat 0 0; background-size: 110px;  animation: glasses 2.5s ease-in infinite alternate both;z-index: 5;}   
                        }
                        .stp-tite {
                           margin-top:30px; font-size: 28px;font-weight: bold;letter-spacing: -0.56px;text-align: left;color: #191919;
                            strong {font-size: 28px;font-weight: bold;letter-spacing: -0.56px;color:#662d91;}
                        }
                        &.intro {   
                            .stp-inner {  
                                .step-box  {display:none;}
                                .intro-top {
                                    position:relative;
                                }
                                .intro-txt {
                                    font-size: 16px;letter-spacing: -0.32px;text-align: left;color: #666;
                                    strong {display:block;margin-bottom:10px; font-size: 26px;font-weight: 600;letter-spacing: -0.52px;text-align: left;color: #191919;}
                                }                            
                                .character-obj {
                                    top:143px; transform: scale(1);
                                }  
                                .stp-tite {display: none;}
                                &.show { visibility: visible;opacity: 1;}                                                   
                            }   
                          
                            .reply-list {visibility: hidden;opacity: 0; z-index: -1; transform: translateY(200px);transition: all .8s cubic-bezier(0.34, 1.56, 0.64, 1); }
                            .btn-group {                                                
                                .btn-previous {display:none;}
                            }                              
                            &.show {
                                .intro-txt {display:none;}
                                .character-obj {top:-26px;transform: scale(0.50); transition: all 1s cubic-bezier(0.34, 1.56, 0.64, 1);}
                                .reply-list {visibility: visible;bottom: 100px; z-index: 1; opacity: 1;transform: translateY(0); transition: all .8s cubic-bezier(0.34, 1.56, 0.64, 1);}
                                .btn-group {
                                    .btn-plan {display:none;}
                                    .btn-previous {display:block;}
                                }  
                                .step-box  {display:block;} 
                                .stp-tite {display: block;}
                            }           
                        } 

                        .step-box {
                            overflow:hidden; width:100%;height:4px;background:#ddd;
                            li {
                                float:left; height:100%;
                                &.check {background:#662d91;}
                            }
                            &.step03 {
                                li {width:33.33%;}
                            }
                            &.step04 {
                                li {width:25%;}
                            }
                            &.step05 {
                                li {width:20%;}
                            }
                        }    

                        .btn-group {
                            margin-top:27px;
                            .btns {min-width:260px;}               
                        }      

                        .reply-list {
                            overflow-y:auto; height:265px; margin-top:200px; width: 100%;bottom: 0; z-index:-1;transition: all .5s;
                            &::-webkit-scrollbar{width: 5px;}
                            &::-webkit-scrollbar-thumb{height: 40%;background-color: rgba(221,221,221,1);border-radius: 10px; }
                            &::-webkit-scrollbar-track{background-color:rgba(221,221,221,0.4);}      
                            .btn-radio-group {
                                display: flex; flex: 1; gap: 10px; flex-wrap: wrap; flex-direction: column;
                                li {
                                    width:100%;text-align: center;
                                    label {
                                       display:inline-block; width:100%;line-height:58px; font-weight:600;
                                        strong {color:#191919;font-weight:600;}
                                    }
                                    input[type=radio]:checked+label {border: 1px solid #ddd; color: #666;}
                                }
                            }
                        }
                    
                        @keyframes marche {
                            from { background-position: 0 0; }
                            to { background-position: -1280px 0; }
                        }
                        
                        @keyframes glasses {
                            0%,
                            20% {   
                            transform: scale(1);        
                            }    
                            80% {
                            transform: scale(0.8);
                            }
                        }        
                    }
                }
                .find-list {
                   overflow-y: auto;overflow-x: hidden; height: 445px; margin-top:20px;
                   &::-webkit-scrollbar{width: 5px;}
                    &::-webkit-scrollbar-thumb{height: 40%;background-color: rgba(221,221,221,1);border-radius: 10px; }
                    &::-webkit-scrollbar-track{background-color:rgba(221,221,221,0.4);}      
                    .plan{
                        margin-bottom:30px;
                        a {display:block;padding:40px 40px 30px 40px;border:1px solid #ddd;border-radius: 10px;box-sizing: border-box;transition:all .3s;}
                        .fee-tit {display:block; font-size: 20px; font-weight: 600;line-height: 1.2; letter-spacing: -0.4px;text-align: left;color: #191919;}
                        .bge {
                            display: flex;gap:5px; margin-top:10px; align-items: center;
                            img{height: 20px;}
                        }
                        .pay-amount {
                            position:relative; display: flex;padding-top: 20px;align-items: flex-end; justify-content: flex-end; gap: 10px;
                            .origin-pay { font-size: 16px; line-height: 1.19; letter-spacing: -0.32px; text-align: right; color: #959595; text-decoration: line-through;}
                            .discount-pay {font-size: 24px; font-weight: 600; line-height: 1.21; letter-spacing: -0.48px; text-align: right;color: #662d91;}
                            .txt-limited {position:absolute;right:0; bottom:-18px; font-size: 14px; line-height: 1.14; letter-spacing: -0.28px;  text-align: right;color: #959595;}
                        }                
                        .feature {
                            display: flex; flex-direction: row; justify-content: center;gap:100px;width:100%;margin:30px 0 0 0;padding:20px 0;border-radius: 10px;background:#f7f8fa;text-align:center;
                            span {font-size: 16px; line-height: 1.19; letter-spacing: -0.32px;color: #191919;}
                            span.vol::before {background: url('/assets/images/pc/icon/ico-sm-data-black.svg') no-repeat center; background-size: 20px 20px;}
                            span.limit::before {background: url('/assets/images/pc/icon/ico-sm-call-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                            span.supply::before {background: url('/assets/images/pc/icon/ico-sm-sms-fill-black.svg') no-repeat center; background-size: 20px 20px;}
                        }
                        .notification {margin-top:20px;}
                        .notification li {font-size: 14px; letter-spacing: -0.28px;text-align: left;color: #959595;}
                        .notification li::before {background:#959595;}
                        .box-tooltip {
                            .txt-tooltip {left: -220px;top: -255px;}
                        }
                    }
                }
            }   
        }
        .popup-footer {height:50px; padding:0;}
    }
}

.breakage-instruction-pop {
    .insure-typ {
        .txt {font-size: 20px;font-weight: 600;line-height: 1.4;letter-spacing: -0.4px;text-align: left;color: #191919;}
        .btn-radio-group {
            margin-top:40px;
            li {
                width:100%;
                label {
                    flex-direction: column; width:100%;height:82px;padding:19px 0;line-height: 1.5; text-align: center;font-weight:normal;
                    strong {display:block;font-weight:600;}
                }
                input[type=radio]:checked+label {
                    strong{color:#662d91;}
                }
            }
        }
    }
}

.roaming-notice {
    .notice-bx {
        .tit {margin-bottom:10px; font-size: 20px;font-weight: 600;line-height: 1.2;letter-spacing: -0.4px;text-align: left;color: #191919;}
        .txt {margin-top:10px;}
        & + .notice-bx {margin-top:30px;}
    }
}